import styled from '@emotion/styled';
import CustomSelect from 'components/Select';

export const Select = styled(CustomSelect)`
  .MuiInputBase-input {
    padding: 8.5px 8px;
  }

  & .MuiSelect-select {
    height: 32px;
    box-sizing: border-box;
    font-size: 10px;
  }

  label {
    font-size: 10px;
  }
`;
