import React, { PropsWithChildren } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

// Hooks
import useAuth from 'hooks/useAuth';

// Constants
import { URLS } from 'constants/URL';

// Utils
import StorageService from 'utils/storage';
import { buildUrl } from 'utils/utils';

/**
 * @description Wrapper for routes that can only be accessed if user has at least one existing project
 * @param  {React.ReactNode} children
 */
function ProjectGuard({ children }: PropsWithChildren) {
  const { user } = useAuth();
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const { projects } = user;
  const { owner_projects: ownerProjects, share_projects: sharedProjects } = projects;
  // put all user projects in one array
  const allProjects = [...ownerProjects, ...sharedProjects];
  // does user have at least one project?
  const hasMoreThanZeroProjects = allProjects.length > 0;
  // user owns/shares a project with id from url
  const hasSpecificProject = allProjects.some(
    (project) => project.project_id.toString() === projectId
  );
  // project id already stored in local storage
  const localStorageProjectId = StorageService.getLocalStorageLastProjectId();
  // does user have the project with id stored in localStorage?
  const hasProjectInLocalStorage = allProjects.some(
    (project) => project.project_id.toString() === localStorageProjectId
  );
  const isDashboard = pathname === URLS.DASHBOARD;

  // If user does not have any project, redirect user to create it
  if (!hasMoreThanZeroProjects) {
    return <Navigate to={URLS.PROJECT_CREATE} />;
  }

  // If pathname is `/app/dashboard` and user has projectId in the localStorage, and project belongs to user
  // redirect to the localStorage projectId
  if (isDashboard && localStorageProjectId && hasProjectInLocalStorage) {
    return <Navigate to={buildUrl(URLS.PROJECT, { projectId: localStorageProjectId })} />;
  }

  // If pathname is `/app/dashboard` and user has no projectId in localStorage but has at least one project,
  // redirect to this project
  if (isDashboard && hasMoreThanZeroProjects) {
    return <Navigate to={buildUrl(URLS.PROJECT, { projectId: allProjects[0].project_id })} />;
  }

  // If user does not have a project with the projectId provided in the url
  // (pathname is e.g.: `/app/projects/14`) redirect to create it
  if (!hasSpecificProject) {
    return <Navigate to={URLS.PROJECT_EMPTY} />;
  }

  // else display the page content
  return <React.Fragment>{children}</React.Fragment>;
}

export default ProjectGuard;
