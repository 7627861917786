import React from 'react';

// Styles
import { RangeFilterInput, RangeFilterWrapper, RangeInputBox } from './styled';
import { Typography } from 'pages/app/Application/KeywordTrackingTable/styled';

interface Props {
  label: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  value: string[];
  width: number;
  className?: string;
  size?: 'small' | 'medium';
}

/**
 * @name RangeFilter
 * @description Filter a range of values
 * @param  {String} label Header name of the corresponding column for this filter.
 * @param  {String} name Name of the corresponding column for this filter.
 * @param  {Function} onChange Function that sets up the filter for this component
 * @param  {Function} onBlur Formik onBlur handle.
 * @param  {String[]} value Current filter values
 * @param  {'small' | 'medium' } size Size of the input. Defaults to 'small'
 * @param  {Number} width
 * @param {String} className
 */
function RangeFilter({
  label,
  name,
  onChange,
  onBlur,
  value,
  width,
  className,
  size = 'small'
}: Props) {
  return (
    <RangeInputBox width={width} className={className}>
      <Typography align="center">{label}</Typography>
      <RangeFilterWrapper>
        <RangeFilterInput
          fullWidth
          onBlur={onBlur}
          onChange={onChange}
          // Do not validate if the `max` value is empty. Otherwise we won't be able
          // to pass anything there.
          InputProps={{ inputProps: { max: value[1] === '0' ? null : value[1] } }}
          type="number"
          inputMode="numeric"
          name={`${name}_min`}
          value={value[0] === '0' ? '' : value[0]}
          size={size}
          sx={{
            width: `${width}px`
          }}
          variant="outlined"
          placeholder="10"
        />
        <RangeFilterInput
          fullWidth
          onBlur={onBlur}
          onChange={onChange}
          InputProps={{ inputProps: { min: value[0] } }}
          type="number"
          name={`${name}_max`}
          value={value[1] === '0' ? '' : value[1]}
          size={size}
          sx={{ width: `${width}px` }}
          variant="outlined"
          placeholder="100"
        />
      </RangeFilterWrapper>
    </RangeInputBox>
  );
}

export default RangeFilter;
