import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Components
import Assets from './Assets';

// Utils
import withAndroidCheck from 'utils/withAndroidCheck';

// Hooks
import useProject from 'hooks/useProject';

/**
 * Renders the Assets generator.
 */
function MetadataGeneratorAI() {
  const { project } = useProject();
  const { t } = useTranslation('pages', {
    keyPrefix: 'AI'
  });

  if (!project.ai_project) {
    return (
      <Grid mt={2} display="flex" justifyContent="center">
        <Typography variant="h6">{t('not_available')}</Typography>
      </Grid>
    );
  }

  return <Assets />;
}

export default withAndroidCheck(MetadataGeneratorAI);
