import React, { useMemo, useState } from 'react';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

// Components
import TooltipWithIcon from 'components/TooltipWithIcon';

// Styles
import { Select, DateRangePicker } from './styled';

/**
 * @name DateRangePickerWithShortcuts
 * @description reusable date range picker, with optional shortcuts, options to be passed as props
 * for all the props, refer to https://mui.com/x/api/date-pickers/date-range-picker/
 * extra props/props override can be passed as {...rest} from parent. eg {...{ disableFuture: false }}
 * They include: disableFuture, maxDate, minDate, loading, calendars (number of calendars)
 * */

interface Props {
  dates: [Dayjs | null, Dayjs | null];
  setDates: (newValue: [Dayjs | null, Dayjs | null]) => void;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  shortcutOptions: { label: string; value: number | string }[];
}
function DateRangePickerWithShortcuts({
  dates,
  setDates,
  label,
  loading,
  disabled,
  shortcutOptions,
  ...rest
}: Props) {
  const { t } = useTranslation('components', { keyPrefix: 'DateRangePickerWithShortcuts' });
  const optionsWithTranslation = shortcutOptions.map(({ label, value }) => {
    return {
      label: t(label),
      value
    };
  });

  // date range errors
  const [error, setError] = useState<any>(null);
  const errorMessage = useMemo(() => {
    if (!error) return;
    const errorString = error.join();
    if (errorString.includes('disableFuture')) return t('select_day_past');

    return t('invalid_range');
  }, [error]);

  // calculate value to pass to select
  const calculateSelectValue = () => {
    // if both start and end date are not null
    if (dates[0] && dates[1]) {
      // calculate the difference in days
      const diff = dates[1].diff(dates[0], 'day');
      // check if the difference is in the options array
      const optionValue = shortcutOptions.find((option) => option.value === diff)?.value;
      // return the value if found, else return empty string
      // (this way the select will be empty without the out of range error)
      return optionValue || '';
    }
    return '';
  };

  // create a display value for the select
  const renderSelectValue = () => {
    //check that both values in array are not null
    if (dates[0] && dates[1]) {
      const diff = dates[1].diff(dates[0], 'day');
      const optionLabel = shortcutOptions.find((option) => option.value === diff)?.label;
      const renderValue = optionLabel && t(optionLabel);
      return renderValue || t('custom_range_diff', { diff });
    }
    return t('choose_second_date');
  };
  const onChangeShortcut = (e: any) => {
    const today = dayjs();
    setDates([today.subtract(e.target.value, 'day'), today]);
  };
  return (
    <Stack direction="row" alignItems="center" spacing={6}>
      {/* picker */}
      <DateRangePicker
        value={dates}
        onChange={setDates}
        onError={(newError) => setError(newError)}
        disableFuture
        label={
          <Box display="flex" alignItems="center" justifyItems="space-between">
            <Box mr={1}>{label}</Box>
            <TooltipWithIcon title={t('click_on_year_manual_date_input')} size="small" />
          </Box>
        }
        format="DD/MM/YYYY"
        // minDate
        loading={loading}
        disabled={disabled}
        {...rest}
        slots={{
          field: SingleInputDateRangeField
        }}
        slotProps={{
          textField: {
            helperText: errorMessage,
            size: 'small'
          }
        }}
        sx={{ width: 200, marginRight: '-18px', justifyContent: 'space-between' }}
      />
      {/* range shortcuts */}
      <Select
        renderValue={renderSelectValue}
        value={calculateSelectValue()}
        options={optionsWithTranslation}
        onChange={onChangeShortcut}
        id="date-range-options"
        displayEmpty
        disabled={disabled}
        width={140}
      />
    </Stack>
  );
}

export default DateRangePickerWithShortcuts;
