import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import HeartBrokenOutlinedIcon from '@mui/icons-material/HeartBrokenOutlined';

// Components
import CreateProjectForm from 'pages/app/CreateProject/Form';
import CreateProjectSuccess from 'pages/app/CreateProject/CreateProjectSuccess';
import BrandIcon from 'components/BrandIcon';

// Hooks
import useAuth from 'hooks/useAuth';

// Constants
import { URLS } from 'constants/URL';

// Styles
import { Wrapper, Container } from './styled';

// Utils
import StorageService from 'utils/storage';
import { buildUrl } from 'utils/utils';

// Fetchers
import { projectCreate } from 'api/api';

export interface CreateProjectProps {
  name: string;
  reporting: boolean;
  isPrivate: boolean;
  AI: boolean;
}

/**
 * @name CreateProject
 * @description Create Project page, contains form to create new project
 * */

function CreateProject() {
  const { t } = useTranslation('pages', {
    keyPrefix: 'CreateProject'
  });
  const { user, updateProjectsList } = useAuth();
  const { group } = user;
  // todo add proper permissions when ready
  const canCreateProject = group === 'Standard (phiture)';
  const localStorageProjectId = StorageService.getLocalStorageLastProjectId();

  const {
    mutateAsync: createProject,
    data: newProject,
    isLoading,
    isError
  } = useMutation(
    (mutationData: CreateProjectProps) => {
      return projectCreate(mutationData);
    },
    {
      onSuccess: async (responseData) => {
        const { data } = responseData;
        const { id: projectId, name: projectName } = data;
        updateProjectsList(projectId, projectName);
      }
    }
  );

  const newProjectId = newProject?.data?.id;
  const newProjectName = newProject?.data?.name;

  // Handle create project
  const handleOnSubmit = async (values: CreateProjectProps) => {
    await createProject(values);
  };

  if (!canCreateProject) {
    return (
      <Wrapper>
        <HeartBrokenOutlinedIcon fontSize="large" color="warning" />
        <Typography variant="h5">{t('no_permission_ask_to_share_project')}</Typography>
        <Grid item xs={12} my={2}>
          <Button component={Link} to={URLS.PROJECT_EMPTY} fullWidth color="primary">
            {t('go_to_dashboard')}
          </Button>
        </Grid>
      </Wrapper>
    );
  }
  const url = localStorageProjectId
    ? buildUrl(URLS.PROJECT, { projectId: localStorageProjectId })
    : URLS.PROJECT_EMPTY;

  return (
    <Container>
      <BrandIcon isPhiture size="md" />
      <Helmet title={t('create_project')} />
      <Wrapper>
        {/* when the project is created successfully show user button to go to project dashboard*/}
        {newProjectId ? (
          <CreateProjectSuccess
            successMsg={t('project_created_successfully', { projectName: newProjectName })}
            newProjectId={newProjectId}
          />
        ) : (
          <React.Fragment>
            <Typography component="div" variant="subtitle1" align="center" gutterBottom>
              {t('create_project')}
            </Typography>
            <CreateProjectForm
              handleOnSubmit={handleOnSubmit}
              isLoading={isLoading}
              isError={isError}
            />
            <Grid item xs={12} my={2}>
              <Button component={Link} to={url} fullWidth color="primary">
                {t('go_to_dashboard')}
              </Button>
            </Grid>
          </React.Fragment>
        )}
      </Wrapper>
    </Container>
  );
}
export default CreateProject;
