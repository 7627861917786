import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DateSpan, TooltipWrapper, ValueSpan } from './styled';
import { getTextValue } from './utils';

interface Props {
  active?: boolean;
  payload?: any[];
  label?: string;
  dataKey: string;
}
function HistoricalDataGraphTooltip({ active, payload, label, dataKey }: Props) {
  const { t } = useTranslation('common');
  if (active && payload) {
    const date = dayjs(label).format('DD MMM YY');
    const originalSeries = payload[0].value; // has missing data and 251 as null
    const integratedSeries = payload[1].value; // has estimated values added except for 251 that stays null
    let text = getTextValue(dataKey, originalSeries, integratedSeries, t);
    return (
      <TooltipWrapper data-testid="historical-data-graph-tooltip">
        <DateSpan>{date}</DateSpan>
        <ValueSpan>{text}</ValueSpan>
      </TooltipWrapper>
    );
  }

  return null;
}

export default HistoricalDataGraphTooltip;
