// eslint-disable-next-line import/named
import { useQuery, UseQueryResult } from '@tanstack/react-query';
// Constants
import { CACHE_KEYS } from 'api/constants';

// Fetchers
import { getAIKeywordListByIdToReview } from 'api/api';

//Types
import { AIReviewList } from 'types/Keywords/AI';

interface Props {
  projectId: number;
  appId: number;
  platform: string;
  reviewListId: number;
}

/**
 * Query function that returns the AI keyword lists with their keywords ready for review
 * @param projectId     Project id
 * @param appId         App id
 * @param platform      App platform
 * @param reviewListId  Review list id
 *
 * */

function useAIReviewListByIdQuery({
  projectId,
  appId,
  platform,
  reviewListId
}: Props): UseQueryResult<{ data: AIReviewList[] }> {
  return useQuery({
    queryKey: [CACHE_KEYS.AI_REVIEW_LISTS, { projectId, appId, platform, reviewListId }],
    queryFn: () => getAIKeywordListByIdToReview({ projectId, appId, platform, reviewListId }),
    enabled: !!reviewListId
  });
}

export default useAIReviewListByIdQuery;
