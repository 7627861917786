import React, { ReactNode } from 'react';

// Components
import SidebarNavList from 'components/Sidebar/NavList';

// types
import { Page } from 'components/Sidebar/Navigation/sidebarUtilsItems';

interface Props {
  pages: Page[];
  component?: React.ComponentType<{ children: ReactNode }> | string;
}

const SidebarNavSection = ({ pages, component: Component = 'nav' }: Props) => {
  return (
    <Component>
      <SidebarNavList pages={pages} depth={0} />
    </Component>
  );
};

export default SidebarNavSection;
