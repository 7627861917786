import React from 'react';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';

import { KeywordContainer, KeywordText, Padding1, Padding3IconBtn } from './styled';
import { CombinationKeywordComponentProps } from '../types';
import { FETCH_STATE_ENUM } from 'api/constants';

/**
 * @name CombinationKeyword
 * @description keyword combination chip, resulting from the shuffle. Contains: name, popularity and tracked/start tracking action
 * */
function CombinationKeyword({
  keyword_id,
  keyword,
  keyword_popularity,
  keyword_tracked,
  addKeywords,
  addKeywordsStatus,
  addKwVariables,
  updateTrackedKeywords
}: CombinationKeywordComponentProps) {
  const { t } = useTranslation('common');

  const handleTrackKeyword = () => {
    addKeywords({ keywords: keyword }, () => updateTrackedKeywords(keyword));
  };

  return (
    <KeywordContainer key={keyword_id}>
      <KeywordText>{keyword}</KeywordText>
      <KeywordText variant="subtitle2">{keyword_popularity || 'N/D'}</KeywordText>
      <Tooltip title={keyword_tracked ? t('tracking') : t('start_tracking')}>
        {keyword_tracked ? (
          <Padding1>
            <DoneIcon sx={{ color: 'primary.main' }} />
          </Padding1>
        ) : (
          <Padding3IconBtn onClick={() => handleTrackKeyword()}>
            {addKeywordsStatus === FETCH_STATE_ENUM.LOADING &&
            addKwVariables?.keywords === keyword ? (
              <CircularProgress size={20} />
            ) : (
              <AddIcon />
            )}
          </Padding3IconBtn>
        )}
      </Tooltip>
    </KeywordContainer>
  );
}

export default CombinationKeyword;
