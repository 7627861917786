// eslint-disable-next-line import/named
import { useQuery, UseQueryResult } from '@tanstack/react-query';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Fetchers
import { getAIAssetsGenerated } from 'api/api';

interface Props {
  projectId: number;
  appId: number;
  platform: string;
}

export interface GeneratedAsset {
  title: string;
  title_total_estimated_download: number;
  title_total_impressions: number;
  subtitle: string;
  subtitle_total_estimated_downloads: number;
  subtitle_total_impressions: number;
}
interface Response {
  data: GeneratedAsset[];
}

/**
 * Query function that returns the AI generated assets {title, subtitle} options
 *
 * @param projectId   Project id
 * @param appId       App id
 * @param platform    App platform
 *
 * */

function useAIAssetsGeneratorQuery({
  projectId,
  appId,
  platform
}: Props): UseQueryResult<Response> {
  return useQuery<Response>({
    queryKey: [CACHE_KEYS.AI_ASSETS_GENERATED, { projectId, appId, platform }],
    // queryFn: () => ({
    //   data: [
    //     {
    //       title: 'Adobe Acrobat: Fill & Sign App',
    //       title_total_estimated_download: 1514.959,
    //       title_total_impressions: 6880.422476821794,
    //       subtitle: 'Snap, Fill, Sign Paper Forms',
    //       subtitle_total_estimated_downloads: 0.02,
    //       subtitle_total_impressions: 1652.2528425534613
    //     },
    //     {
    //       title: 'Adobe Acrobat: Create PDFs',
    //       title_total_estimated_download: 1516.67,
    //       title_total_impressions: 5051.173648164522,
    //       subtitle: 'Convert from Any File Type',
    //       subtitle_total_estimated_downloads: 0.0,
    //       subtitle_total_impressions: 82.81517033630794
    //     },
    //     {
    //       title: 'Adobe Acrobat: Liquid Mode',
    //       title_total_estimated_download: 1514.939,
    //       title_total_impressions: 5045.409189429315,
    //       subtitle: 'Best PDF Reading Experience',
    //       subtitle_total_estimated_downloads: 1270.807,
    //       subtitle_total_impressions: 4721.759249078972
    //     },
    //     {
    //       title: 'Adobe Acrobat: Store Files',
    //       title_total_estimated_download: 1514.939,
    //       title_total_impressions: 5045.409189429315,
    //       subtitle: 'Access Files across Devices',
    //       subtitle_total_estimated_downloads: 0,
    //       subtitle_total_impressions: 0
    //     }
    //   ]
    // }),
    queryFn: () => getAIAssetsGenerated({ projectId, appId, platform }),
    refetchOnWindowFocus: false
  });
}

export default useAIAssetsGeneratorQuery;
