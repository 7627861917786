import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Fetchers
import { getOKSMetadata, updateOKSMetadata } from 'api/api';

// Types
import { OKSMetadata } from 'types/OKS/Metadata';

interface Props {
  projectId: number;
  appId: number;
  platform: string;
}

/**
 * Query function that returns the OKS metadata
 *
 * @param projectId   Project id
 * @param appId       App id
 * @param platform    App platform
 */
export function useOKSMetadataQuery({ projectId, appId, platform }: Props) {
  return useQuery<OKSMetadata>({
    queryKey: [CACHE_KEYS.OKS_METADATA, { projectId, appId, platform }],
    queryFn: () => getOKSMetadata({ projectId, appId, platform }),
    suspense: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
}

export function useOKSMetadataMutation({ projectId, appId, platform }: Props) {
  const queryClient = useQueryClient();
  return useMutation((data) => updateOKSMetadata({ projectId, appId, platform, data }), {
    onSuccess: () => {
      queryClient.invalidateQueries([CACHE_KEYS.OKS_METADATA, { projectId, appId, platform }]);
      queryClient.invalidateQueries([CACHE_KEYS.OKS_GENERATOR]);
    }
  });
}
