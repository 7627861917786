import React, { ReactNode } from 'react';
import i18n from 'i18next';
import Grid from '@mui/material/Grid';

// Styles
import {
  Box,
  InputText,
  Label,
  MinMaxInput,
  Select,
  SelectBox,
  SelectLabel
} from 'pages/app/Application/AI/Research/TableFilters/styled';

export enum FilterTypes {
  Text = 'text',
  MultiSelect = 'multi-select',
  Range = 'range'
}

export enum Filters {
  Word = 'word',
  Type = 'keyword_type_id',
  Relevancy = 'relevancy',
  Popularity = 'popularity_score',
  TotalApps = 'total_apps',
  Competition = 'competition',
  Rank = 'rank',
  EstimatedDownloads = 'estimated_downloads'
}

export type Filter = {
  name: Filters;
  type: FilterTypes;
  label: string;
  placeholder: string;
  min?: number;
  max?: number;
  options?: { label: string | ReactNode; value: string }[];
};

export const getFilter = (filter: Filter, values, setForm) => {
  switch (filter.type) {
    case FilterTypes.Text:
      return (
        <InputText
          placeholder={filter.placeholder}
          label={filter.label}
          value={values[filter.name]}
          handleChange={(e) => {
            setForm((prev) => ({ ...prev, [filter.name]: e.target.value }));
          }}
          name={filter.name}
        />
      );

    case FilterTypes.MultiSelect:
      if (Array.isArray(filter.options)) {
        return (
          <SelectBox>
            <SelectLabel>{filter.label}</SelectLabel>
            <Select
              id={filter.name}
              options={filter.options}
              multiple
              name={filter.name}
              value={values[filter.name]}
              displayEmpty={true}
              renderValue={(selected) =>
                !selected.length || selected.length === filter.options.length
                  ? i18n.t('components:KeywordTrackingTable.SelectFilter.all', {
                      count: selected.length
                    })
                  : i18n.t('components:KeywordTrackingTable.SelectFilter.multiple_selected', {
                      count: selected.length
                    })
              }
              onChange={(e) => {
                setForm((prev) => ({ ...prev, [filter.name]: e.target.value }));
              }}
            />
          </SelectBox>
        );
      }

    case FilterTypes.Range:
      return (
        <Box>
          <Label>{filter.label}</Label>
          <Grid>
            <MinMaxInput
              placeholder={'Min'}
              handleChange={(e) => {
                setForm((prev) => ({
                  ...prev,
                  [filter.name]: { ...prev[filter.name], min: Number(e.target.value) }
                }));
              }}
              type="number"
              value={values[filter.name].min}
            />
            <MinMaxInput
              placeholder={'Max'}
              handleChange={(e) => {
                setForm((prev) => ({
                  ...prev,
                  [filter.name]: { ...prev[filter.name], max: Number(e.target.value) }
                }));
              }}
              type="number"
              value={values[filter.name].max}
            />
          </Grid>
        </Box>
      );

    default:
      return null;
  }
};
