import { ChangeEvent, KeyboardEvent, FocusEventHandler } from 'react';
import type { SxProps } from '@mui/material';

// Styles
import { Box, TextFieldInput } from './styled';

interface Props {
  value: string | number;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: FocusEventHandler<HTMLInputElement>;
  type?: string;
  name?: string;
  label?: string | JSX.Element;
  fullWidth?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
  error?: boolean;
  helperText?: string;
  className?: string;
  id?: string;
  maxLength?: number;
  placeholder?: string;
  multiline?: boolean;
  disabled?: boolean;
  rows?: number;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
  InputProps?: any;
  sx?: SxProps | undefined;
}

/**
 * Renders a controlled text input component.
 *
 * @param value             Input value
 * @param handleChange      Input Change handler
 * @param handleBlur        Input blur handler
 * @param type              Input type
 * @param name              Input name
 * @param label             Input Label
 * @param fullWidth         Input width boolean
 * @param variant           Input variant
 * @param error             Error state
 * @param helperText        Text to render underneath the input
 * @param className         Classname
 * @param id                Input id
 * @param maxLength         Input maxlength
 * @param placeholder       Input placeholder
 * @param multiline         Boolean to render select
 * @param disabled          Boolean to disable input
 * @param rows              Number of rows to render for textarea
 * @param onKeyPress        Key press handler
 */
function TextField({
  value,
  handleChange,
  handleBlur,
  type = 'text',
  name,
  label,
  fullWidth = false,
  variant,
  error,
  helperText,
  className,
  id,
  maxLength,
  placeholder,
  multiline = false,
  disabled = false,
  rows,
  onKeyPress,
  InputProps,
  sx
}: Props) {
  return (
    <Box className={className}>
      {label}
      <TextFieldInput
        id={id}
        name={name}
        type={type}
        value={value}
        error={error}
        placeholder={placeholder}
        onChange={handleChange}
        fullWidth={fullWidth}
        variant={variant}
        helperText={helperText}
        onBlur={handleBlur}
        onKeyDown={onKeyPress}
        inputProps={{
          maxLength
        }}
        InputProps={InputProps}
        minRows={rows}
        maxRows={rows}
        multiline={multiline}
        disabled={disabled}
        sx={sx}
      />
    </Box>
  );
}

export default TextField;
