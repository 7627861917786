import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Card from '@mui/material/Card';
import SaveIcon from '@mui/icons-material/Save';

// Hooks
import useApp from 'hooks/useApp';

// Constants
import { FETCH_STATE, API, CACHE_KEYS } from 'api';

// Components
import Skeleton from 'components/Skeleton';

// Fetchers
import useAppNotesQuery from 'api/queries/App/useAppNotesQuery';

// Styles
import {
  Button,
  Box,
  Typography,
  TextField,
  LoadingButton,
  CardContent,
  StyledNotes
} from './styled';

// Types
import { PLATFORMS } from 'types/Platform';
import { AppNote, Params } from 'types/App';

// app note max length
export const MAX_LENGTH = 3000;

/**
 * @name Notes
 * @description This component is responsible for displaying and updating app-specific user notes for each user.
 */
function Notes() {
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const { appId, projectId, platform } = useParams() as Params;
  const { status: appStatus } = useApp();
  const [notes, setNotes] = useState('');

  const { data, isLoading } = useAppNotesQuery({
    projectId,
    appId,
    platform
  });

  const noChanges = data?.data?.note === notes;

  useEffect(() => {
    if (data?.data) {
      setNotes(data.data.note);
    }
  }, [data?.data]);

  /**
   * Mutation for creating the note
   */
  const appCreateNoteMutation = useMutation(
    (mutationData: { note: string; appId: string; projectId: string; platform: PLATFORMS }) => {
      return API.createProjectAppNote(mutationData);
    },
    {
      onSuccess: (res: { data: AppNote }) => {
        queryClient.setQueryData([CACHE_KEYS.APP_NOTE, { projectId, appId, platform }], {
          data: res?.data
        });
      }
    }
  );

  /**
   * Mutation for updating the note
   */
  const appUpdateNoteMutation = useMutation(
    (mutationData: {
      projectId: string;
      appId: string;
      platform: string;
      note: string;
      noteId: number;
    }) => {
      return API.updateProjectAppNote(mutationData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [CACHE_KEYS.APP_NOTE, { projectId, appId, platform }]
        });
      }
    }
  );

  /**
   * Update the notes state
   */
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNotes(e.target.value);
  };

  /**
   * Create a note if it doesn't exist, otherwise update it
   */
  const handleSubmit = () => {
    const foundNote = data?.data?.id;

    if (foundNote) {
      appUpdateNoteMutation.mutate({
        noteId: foundNote,
        note: notes,
        appId,
        projectId,
        platform
      });

      return;
    }

    appCreateNoteMutation.mutate({
      note: notes,
      appId,
      projectId,
      platform
    });
  };

  const isError = appCreateNoteMutation.isError || appUpdateNoteMutation.isError;

  if (appStatus === FETCH_STATE.LOADING) {
    return (
      <Card>
        <CardContent>
          <Box mb={2}>
            <StyledNotes />
            <Typography title={t('components:AppHeader.Notes.a11y_title')}>
              {t('components:AppHeader.Notes.title')}
            </Typography>
            <Button disabled startIcon={<SaveIcon />} size="small" type="submit" variant="outlined">
              {t('common:save')}
            </Button>
          </Box>
          <Skeleton height="calc(100% - 48px)" maxWidth={'100%'} mb={1} />
          <Skeleton height={18} width={50} />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Box mb={2}>
          <StyledNotes />
          <Typography data-testid="notes-title" title={t('components:AppHeader.Notes.a11y_title')}>
            {t('components:AppHeader.Notes.title')}
          </Typography>
          <LoadingButton
            loading={isLoading}
            loadingPosition="start"
            onClick={handleSubmit}
            startIcon={<SaveIcon />}
            size="small"
            type="submit"
            variant="outlined"
            disabled={noChanges || isLoading}
          >
            {t('common:save')}
          </LoadingButton>
        </Box>
        <TextField
          disabled={isLoading}
          label={t('components:AppHeader.Notes.title')}
          multiline={true}
          onChange={handleChange}
          value={notes}
          rows={4}
          size="small"
          fullWidth
          error={isError}
          helperText={isError ? t('components:AppHeader.Notes.note_error') : null}
        />
      </CardContent>
    </Card>
  );
}

export default Notes;
