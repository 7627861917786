import React, { ElementType } from 'react';
import Skeleton from '@mui/material/Skeleton';
import InputLabel from '@mui/material/InputLabel';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
// eslint-disable-next-line import/named
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

// Constants
import { FETCH_STATE } from 'api/constants';

// Styles
import { FormControl } from './styled';

interface Props {
  id: string;
  options: Array<string | { value: string | number; label: string | React.ReactNode }>;
  value: string | number | Array<string | number>;
  renderValue?: (value: string | number | Array<string | number>) => React.ReactNode;
  onChange: (event: SelectChangeEvent<string | number | (string | number)[]>) => void;
  isCompact?: boolean;
  width?: number | string;
  label?: string | React.ReactNode;
  disabled?: boolean;
  multiple?: boolean;
  status?: string;
  className?: string;
  variant?: 'outlined' | 'filled' | 'standard' | undefined;
  displayEmpty?: boolean;
  name?: string;
  inputProps?: object;
  iconComponent?: ElementType;
}

/**
 * @name Select
 * @description Custom select for displaying app various options
 * @param  {String} id
 * @param  options
 * @param  {Number | String} value
 * @param  {Function} renderValue value to be displayed
 * @param  {Function} onChange
 * @param  {React.Node | String} label Optional
 * @param  {Boolean} isCompact Optional
 * @param  {Number} width
 * @param  {Number} status Optional
 * @param  {Boolean} multiple allows multi selection
 * @param  {Boolean} disabled
 * @param  {String} className
 * @param  {String} variant
 * @param {Boolean} displayEmpty
 * @param  {String} name
 * @param  {Object} inputProps
 * @param  {React.Node} iconComponent
 */
function Select({
  id,
  options,
  value,
  renderValue,
  onChange,
  isCompact = false,
  width,
  label = null,
  disabled = false,
  multiple = false,
  status,
  className,
  variant = 'outlined',
  displayEmpty = false,
  name,
  inputProps,
  iconComponent
}: Props) {
  // states for when we are fetching the options
  // LOADING
  if (status === FETCH_STATE.LOADING) {
    return (
      <React.Fragment>
        <Skeleton width={width} data-testid="skeleton-select-loading" />
      </React.Fragment>
    );
  }

  // ERROR
  if (status === FETCH_STATE.ERROR) {
    return (
      <FormControl compact={false} sx={{ width }} size="small" error disabled>
        <InputLabel color="secondary" id={`${id}select-label`}>
          <ErrorOutline />
        </InputLabel>
        <MuiSelect value="" />
      </FormControl>
    );
  }

  return (
    <FormControl
      className={className}
      size="small"
      width={width}
      compact={!!isCompact}
      disabled={disabled}
    >
      {label && (
        <InputLabel htmlFor={`label-${id}`} id={`label-${id}`}>
          {label}
        </InputLabel>
      )}
      <MuiSelect
        IconComponent={iconComponent || KeyboardArrowDown}
        variant={variant}
        displayEmpty={displayEmpty}
        labelId={`label-${id}`}
        id={id}
        value={value}
        renderValue={renderValue}
        onChange={onChange}
        label={label}
        multiple={multiple}
        name={name}
        inputProps={inputProps}
      >
        {options?.map((option) => {
          // Check if `options` array is an array of objects
          if (typeof option === 'object' && option !== null) {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            );
          }
          // Else `options` is an array of strings
          return (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </MuiSelect>
    </FormControl>
  );
}

export default Select;
