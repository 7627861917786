import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MuiBox from '@mui/material/Box';
import { customGray } from 'theme/colors';

export const Wrapper = styled(MuiBox)<{ color: string }>`
  background-color: ${({ theme }) => theme.palette.button.primary.disabled};
  border-radius: 10px;
  padding: 1px 10px 1px 10px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Relevant = styled(Wrapper)`
  background-color: ${({ theme }) => theme.palette.yellow.primary.ultraLight};
  color: ${({ theme }) => theme.palette.yellow.primary.main};
`;

export const ExtremelyRelevant = styled(Wrapper)`
  background-color: ${({ theme }) => theme.palette.blue.primary.ultraLight};
  color: ${({ theme }) => theme.palette.blue.primary.main};
`;

export const Starred = styled(Wrapper)`
  background-color: ${({ theme }) => theme.palette.primary.ultraLight};
  color: ${({ theme }) => theme.palette.primary.custom};
`;

export const Text = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  color: inherit;
  font-size: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Container = styled(Grid)`
  font-size: 10px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;

export const Translation = styled(Typography)`
  font-size: 10px;
  color: ${customGray[30]};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;
