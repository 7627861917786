import { useQuery } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Fetchers
import { getKeywordPopularityHistory, getKeywordRankHistory } from 'api/api';

// Constants
import { DATA_KEYS } from 'constants/constants';

// utils
const formatDate = (date: Dayjs | null) => (date ? date.format('YYYY-MM-DD') : '');

/**
 * @name useKeywordHistoricalData
 * @description fetches keyword popularity/rank or other historical data
 * */

interface Props {
  dataKey: string;
  projectId: string;
  appId: string;
  platform: string;
  kwId: number;
  start: Dayjs | null;
  end: Dayjs | null;
  isOpen: boolean;
}
function useKeywordHistoricalData({
  dataKey,
  projectId,
  appId,
  platform,
  kwId,
  start,
  end,
  isOpen
}: Props) {
  // enable refetch when all required params are present and dates are valid and modal isOpen
  const enabled =
    isOpen &&
    !!dataKey &&
    !!kwId &&
    !!start &&
    !!end &&
    end.isAfter(start) &&
    end.isBefore(dayjs().add(1, 'day'));

  let queryKey: string = '';
  interface QueryParams {
    projectId: number;
    appId: string;
    platform: string;
    kwId: number | string;
    start: string;
    end: string;
  }
  type QueryFn<QueryParams> = (params: QueryParams) => Promise<any>;
  let queryFn: QueryFn<any>;
  switch (dataKey) {
    case DATA_KEYS.RANK:
      queryKey = CACHE_KEYS.GET_KEYWORD_RANK_HISTORY;
      queryFn = getKeywordRankHistory;
      break;
    case DATA_KEYS.SCORE:
      queryKey = CACHE_KEYS.GET_KEYWORD_POPULARITY_HISTORY;
      queryFn = getKeywordPopularityHistory;
      break;
  }

  return useQuery({
    queryKey: [queryKey, { projectId, appId, platform, kwId, start, end }],
    queryFn: () =>
      queryFn({
        projectId,
        appId,
        platform,
        kwId,
        start: formatDate(start),
        end: formatDate(end)
      }),
    enabled: enabled,
    retry: false,
    refetchOnWindowFocus: false
  });
}

export default useKeywordHistoricalData;
