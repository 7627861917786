import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

// Components
import Select from 'components/Select';
import Modal from 'components/Modal';
import FeedbackToast from 'components/FeedbackToast';
import MenuItemLabel from './MenuItemLabel';
import ModalDataBody from './ModalDataBody';
import PrimaryButton from 'components/Button/Primary';

//constants
import { API, FETCH_STATE } from 'api';

// action type
const TYPES = {
  ADD_LIST: 'ADD_LIST',
  EDIT_LIST: 'EDIT_LIST',
  DELETE_LIST: 'DELETE_LIST'
};

const DEFAULT_MODAL_STATE = {
  show: false,
  title: '',
  body: '',
  type: ''
};
/**
 * @name KeywordlistsManager
 * @description shared component that allows user to create, edit and delete keyword lists.
 * @param {boolean} readonly : when true only allows user to switch between lists, when false allows user to CRUD lists.
 * @param {function} refetchTrackedKeywords : refetches kws when list changed
 * @param {string} status : status of the keyword lists
 * @param {array} availableLists : array of available lists
 * @param {object} listSelected : selected list
 * @param {function} setListSelected : sets selected list
 * @param {function} refetchLists : refetches lists
 * @param {function} resetSelection : resets selected kws
 *
 * */
function KeywordlistsManager({
  readonly,
  refetchTrackedKeywords,
  status,
  availableLists,
  listSelected,
  setListSelected,
  refetchLists,
  resetSelection
}) {
  const { projectId, appId, platform } = useParams();
  const { t } = useTranslation('components', { keyPrefix: 'KeywordlistsManager' });
  const [actionModalState, setActionModalState] = useState(DEFAULT_MODAL_STATE);
  const [payloadListName, setPayloadListName] = useState('');
  const [payloadListId, setPayloadListId] = useState(undefined);
  const [mutationStatus, setMutationStatus] = useState(FETCH_STATE.IDLE);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');

  const onSuccessfulMutation = () => {
    setMutationStatus(FETCH_STATE.SUCCESS);
    setFeedbackMessage(
      t('list_action_outcome', {
        action: t(actionModalState.type),
        outcome: FETCH_STATE.SUCCESS
      })
    );
    setActionModalState(DEFAULT_MODAL_STATE);
    setFeedbackOpen(true);
    resetPayload();
    refetchLists();
  };

  const onErrorMutation = () => {
    setMutationStatus(FETCH_STATE.ERROR);
    setFeedbackOpen(true);
  };

  const addListMutation = useMutation(
    (mutationData) => {
      return API.createKeywordList(mutationData);
    },
    {
      onSuccess: onSuccessfulMutation,
      onError: () => {
        onErrorMutation();
        setFeedbackMessage(t('error_creating_list'));
      }
    }
  );

  const editListMutation = useMutation(
    (mutationData) => {
      return API.updateKeywordList(mutationData);
    },
    {
      onSuccess: onSuccessfulMutation,
      onError: () => {
        onErrorMutation();
        setFeedbackMessage(t('error_updating_list'));
      }
    }
  );

  const deleteListMutation = useMutation(
    (mutationData) => {
      return API.deleteKeywordList(mutationData);
    },
    {
      onSuccess: onSuccessfulMutation,
      onError: () => {
        onErrorMutation();
        setFeedbackMessage(t('error_deleting_list'));
      }
    }
  );
  const onConfirmActionClick = () => {
    setMutationStatus(FETCH_STATE.LOADING);
    switch (actionModalState.type) {
      case TYPES.ADD_LIST:
        addListMutation.mutate({
          projectId,
          appId,
          platform,
          listName: payloadListName
        });
        return;
      case TYPES.EDIT_LIST:
        editListMutation.mutate({
          projectId,
          appId,
          platform,
          keywordListId: payloadListId,
          newListName: payloadListName
        });
        return;
      case TYPES.DELETE_LIST:
        deleteListMutation.mutate({
          projectId,
          appId,
          platform,
          keywordListId: payloadListId
        });
        return;
      default:
        console.log('no action');
    }
  };

  const resetPayload = () => {
    setPayloadListName('');
    setPayloadListId(undefined);
  };

  const toggleActionModal = () => {
    setActionModalState((prevState) => ({
      ...prevState,
      show: !prevState.show
    }));
  };

  const onFeedbackClose = () => {
    setFeedbackOpen(false);
    // reset mutation status after 1s as feedback toast changes color before it closes
    setTimeout(() => {
      setMutationStatus(FETCH_STATE.IDLE);
    }, 1000);
  };

  const onListSwitch = (newList) => {
    setListSelected(newList);
    resetSelection([]);
    refetchTrackedKeywords();
  };

  const onListAdd = () => {
    resetPayload();
    setActionModalState({
      show: true,
      title: t('add_list'),
      body: t('add_list_body'),
      type: TYPES.ADD_LIST
    });
  };

  const onListEdit = (e, listToEdit) => {
    e.stopPropagation();
    setPayloadListName(listToEdit.name);
    setPayloadListId(listToEdit.id);
    setActionModalState({
      show: true,
      title: t('edit_list'),
      body: t('edit_list_body', { listName: listToEdit.name }),
      type: TYPES.EDIT_LIST
    });
  };
  const onListDelete = (e, listToDelete) => {
    e.stopPropagation();
    setPayloadListId(listToDelete.id);
    setActionModalState({
      show: true,
      title: t('delete_list'),
      body: t('delete_list_body', { listName: listToDelete.name }),
      type: TYPES.DELETE_LIST
    });
  };

  // dynamically create options
  const options = availableLists.map(({ id, name }) => ({
    value: id,
    label: (
      <MenuItemLabel
        name={name}
        id={id}
        onEdit={onListEdit}
        onDelete={onListDelete}
        onListChange={onListSwitch}
        readonly={readonly}
        // allow delete list if more than one list exists
        allowDelete={availableLists.length > 1}
      />
    )
  }));

  // returns value to be displayed in select
  const renderValue = (listId) => {
    return availableLists.find((list) => list.id === listId)?.name;
  };

  const confirmButtonDisabled = () => {
    if (mutationStatus === FETCH_STATE.LOADING) return true;
    if (actionModalState.type === TYPES.ADD_LIST || actionModalState.type === TYPES.EDIT_LIST)
      return !payloadListName;
    return false;
  };

  return (
    <React.Fragment>
      <Stack direction="row" alignItems="center" spacing={4}>
        <Select
          id="keywordlistsManager"
          label={t('list')}
          value={listSelected.id}
          options={options}
          width={160}
          status={status}
          renderValue={renderValue}
        />
        <PrimaryButton onClick={onListAdd}>{t('create_new_list')}</PrimaryButton>
      </Stack>
      <Modal
        isOpen={actionModalState.show}
        setIsOpen={toggleActionModal}
        title={actionModalState.title}
        isComponent
        body={
          <ModalDataBody
            text={actionModalState.body}
            type={actionModalState.type}
            payloadListName={payloadListName}
            setPayloadListName={setPayloadListName}
            inputDisabled={mutationStatus === FETCH_STATE.LOADING}
            tooltipTitle={t('list_name_tooltip')}
          />
        }
        actionButton={
          <Button
            variant="contained"
            disabled={confirmButtonDisabled()}
            endIcon={mutationStatus === FETCH_STATE.LOADING && <CircularProgress size={20} />}
            onClick={onConfirmActionClick}
            color={actionModalState.type === TYPES.DELETE_LIST ? 'error' : 'primary'}
          >
            {t('confirm')}
          </Button>
        }
      />
      <FeedbackToast
        status={mutationStatus}
        message={feedbackMessage}
        open={feedbackOpen}
        onClose={onFeedbackClose}
      />
    </React.Fragment>
  );
}

KeywordlistsManager.propTypes = {
  readonly: PropTypes.bool,
  refetchTrackedKeywords: PropTypes.func,
  status: PropTypes.string,
  availableLists: PropTypes.array,
  listSelected: PropTypes.object,
  setListSelected: PropTypes.func,
  refetchLists: PropTypes.func,
  resetSelection: PropTypes.func
};
export default KeywordlistsManager;
