import { DataGridPro } from '@mui/x-data-grid-pro';

// Utils
import { COLUMNS } from './utils';

// Hooks
import useAIResearch from 'hooks/AI/useAIResearch';
import useSnackbar from 'hooks/useSnackbar';
import {
  AIReviewListStatus,
  ListStatus
} from 'api/queries/AI/KeywordResearch/useAIReviewListsStatusQuery';
import { useTranslation } from 'react-i18next';

function ReviewListStatus() {
  const { setSnackbar } = useSnackbar();
  const { reviewListStatus, setActiveTab } = useAIResearch();
  const { t } = useTranslation('pages', { keyPrefix: 'AILabs.status_table' });

  const handleClickRow = ({ row }: { row: AIReviewListStatus }) => {
    if (row.failed) {
      setSnackbar(true, t('error.failed'), 'error', 3000);
      return;
    }
    if (row.status !== ListStatus.TO_REVIEW) {
      setSnackbar(true, t('error.not_ready'), 'info', 3000);
      return;
    }

    if (row.status !== ListStatus.TO_REVIEW) {
      setSnackbar(true, t('error.already_reviewed'), 'info', 3000);
      return;
    }

    setActiveTab(1);
  };
  return (
    <>
      <DataGridPro
        columns={COLUMNS()}
        rows={reviewListStatus}
        getRowId={(row) => row.id}
        rowHeight={32}
        onRowClick={handleClickRow}
        columnHeaderHeight={32}
        autoHeight={true}
        disableRowSelectionOnClick
        sx={{
          width: '100%',
          margin: '16px auto 0 auto',
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: 'primary.main',
            fontSize: '10px'
          },
          '& .MuiDataGrid-row': {
            fontSize: '10px',
            cursor: 'pointer'
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none'
          }
        }}
        pageSizeOptions={[5, 10, 25, 50, 100, 200]}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
          sorting: {
            sortModel: [{ field: 'popularity_score', sort: 'desc' }]
          }
        }}
        pagination={true}
      />
    </>
  );
}

export default ReviewListStatus;
