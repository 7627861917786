import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import MuiGrid from '@mui/material/Grid';

// Components
import Accordion from 'components/Details';

export const Heading = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: 14px;
`;

export const Title = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: 16px;
`;

export const Details = styled(Accordion)`
  & .MuiAccordionSummary-root {
    width: fit-content;
    padding: 0;
  }
`;
