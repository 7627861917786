import { MissingData } from 'types/utils';

const UNRANKED_VALUE = 251;
const FETCHING_VALUE = -1;

/**
 * @name isEmpty
 * @description Checks if object is empty
 * @param  {object} obj
 * @returns {boolean} `true` if object is empty
 */
export const isEmpty = (obj: object) => {
  if (obj === undefined || obj === null) {
    return true;
  }
  return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
};

export const buildUrl = (urlWithVariables: string, params: object) => {
  if (isEmpty(params)) {
    return urlWithVariables;
  }

  if (typeof params !== 'object') {
    throw new Error('Argument `params` must be of type object');
  }
  return Object.entries(params).reduce((url, [key, value]) => {
    return url.replace(`:${key}`, value);
  }, urlWithVariables);
};

/**
 * @name sortBy
 * @description Sorts an array of objects based on the given attribute (word)
 * @param  {string} attributeName
 * @param  {boolean} isDescending, default true
 * */
export function sortBy(attributeName: string, isDescending = true) {
  return (a, b) => {
    const nameA = a[attributeName].toUpperCase(); // ignore upper and lowercase
    const nameB = b[attributeName].toUpperCase(); // ignore upper and lowercase
    if (isDescending ? nameA < nameB : nameB < nameA) {
      return -1;
    }
    if (isDescending ? nameA > nameB : nameB > nameA) {
      return 1;
    }

    // names must be equal
    return 0;
  };
}

/**
 * @name findAndUnshift
 * @description Sort the array by placing some of the elements on front (= unshift)
 * @param {Array} array
 * @param {Function} conditionFunction - function with one parameter being the array element. This function has to return true for elements that should be unshifted
 *
 * example usage:
 * findAndUnshift(["GE", "UH", "JP", "US", "EF"], (elem) => elem === "US")
 * -> ["US", "GE", "UH", "JP", "EF"]
 * @returns
 */
export function findAndUnshift<T>(array: T[], conditionFunction: (elem: any) => boolean) {
  const found: T[] = [];
  const other: T[] = [];
  array.forEach((elem) => {
    if (conditionFunction(elem)) {
      found.push(elem);
    } else {
      other.push(elem);
    }
  });

  return [...found, ...other];
}

/**
 * @name groupBy
 * @description Groups array by given key
 * @param {Array} array - elements to be grouped
 * @param {String} key - group elements by this object property
 * @param {Boolean} shouldReturnAsArray - (optional, default: true) return just the values or a grouped object with value for each key
 * @returns Object with unique key - Array properties
 */
export function groupBy<T extends object>(array: T[], key: keyof T, shouldReturnAsArray = true) {
  const groupedObject = array.reduce((acc, cur) => {
    // get the global_id of the current app
    const curId = cur[key];
    // if no app with this id was added create an empty array; add the current app
    acc[curId] = (acc[curId] || []).concat(cur);

    return acc;
  }, {});

  return shouldReturnAsArray ? Object.values(groupedObject) : groupedObject;
}

/**
 * @name sortByName
 * @description Sorts an array of objects based on their name attribute
 * */
export function sortByName(a: { name: string }, b: { name: string }): number {
  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
}

/**
 * @name getUniqueValues from array
 * @description for a given array and name/value args, extract new array of unique values
 * */

export function getUniqueValues<T>(array: T[], name: keyof T, value: keyof T) {
  if (array.length < 1) return [];
  const newOptions = new Set();
  array.forEach((element) => {
    const option = {
      name: element[name],
      value: element[value]
    };
    newOptions.add(JSON.stringify(option)); // we need this as the Set doesn't make objects unique
  });

  const uniqueArray = [...newOptions?.values()].map((item) => JSON.parse(item));
  return uniqueArray;
}

/**
 * @name getCookieValueFromName
 * @description returns the value of cookie by name
 * @param {string} name of cookie whose value needs to be returned
 * */
export function getCookieValueFromName(name: string) {
  // get cookies from document
  const value = `; ${document.cookie}`;
  // split the cookie string into two elements, the second one being a string starting with the value of the cookie you need
  const parts = value.split(`; ${name}=`);

  // keep the second element (which contains the value we need) - pop()
  // separate the value you need from the rest - split()
  // return the value - shift()
  return parts?.pop()?.split(';').shift();
}

/**
 * @name capitalizeFirstLetter
 * @description capitalizes the first letter of the provided string.
 * @param {string} string string to be capitalized
 * @returns {string} capitalized string
 * */
export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
/**
 * @name removeEmptyValues
 * @description This removes all empty and zero values from the object
 * @param  {Object} obj
 */
export function removeEmptyValues<T extends object>(obj: T) {
  for (let propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === 0 ||
      obj[propName] === '0' ||
      obj[propName] === '' ||
      (Array.isArray(obj[propName]) && obj[propName].length === 0)
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

/**
 * @name createOptionsFromObj
 * @description given an object, it returns an array with label/value fields
 * @param {object} object object with options
 * @param {string} labelKey key in object containing the label of the option
 * @param {function} sortFunction sort function - optional
 * @returns {array} of options
 * */
export function createOptionsFromObj(object, labelKey, sortFunction = null) {
  if (isEmpty(object)) return [];
  const entries = Object.entries(object);

  const options = entries.map((el) => {
    const value = el[0];
    const label = object[value][labelKey];
    return {
      value,
      label
    };
  });
  if (sortFunction) {
    return options.sort(sortFunction);
  }
  return options;
}

/**
 * Replaces all line breaks in the input string with an empty string, effectively removing it.
 * The regular expression (\r\n|\n|\r) matches any of the common line break characters
 * (\r\n for Windows-style line breaks, \n for Unix-style line breaks,
 * and \r for old Mac-style line breaks). The gm flags in the regular expression mean
 * that the matching should be global and case-insensitive.
 *
 * @param str string
 */
export const removeLineBreaks = (str: string) => str.replace(/(\r\n|\n|\r)/gm, '');

/**
 * Fallback for missing data in the UI
 *
 * @param value
 */
export const missingDataFallback = (value: number | string | null) => value ?? MissingData.NO_DATA;

/**
 * Checks if the value is equal to 251
 * Per our custom logic, the number 251 indicates that a keyword is unranked
 *
 * @param value
 */
export const isUnranked = (rank: number | null) => rank === UNRANKED_VALUE;

/**
 * Checks if the value is equal to -1
 * Per our custom logic, the number -1 indicates that a keyword is not yet fetched
 *
 * @param value
 */
export const isFetchingData = (rank: number | null) => rank === FETCHING_VALUE;

/**
 * Based on the rank value, renders the keyword rank, the unranked string fallback or the fetching data string fallback
 *
 * @param rank
 */
export const renderKWRank = (rank: number | null) =>
  isUnranked(rank) ? MissingData.UNRANKED : isFetchingData(rank) ? MissingData.FETCHING : rank;

/**
 * Renders the keyword rank with a fallback for missing data or out of range rank
 *
 * @param rank
 */
export const renderKWRankWithFallback = (rank: number | null) =>
  missingDataFallback(renderKWRank(rank));

export const renderAIKWRankWithFallback = (rank: number | null) => {
  switch (rank) {
    case -1:
      return 'Unranked';

    case null:
      return 'Fetching';

    default:
      return rank;
  }
};
