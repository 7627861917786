import Grid from '@mui/material/Grid';

// Styles
import { Checkbox, ListItemText } from './styled';

interface Props {
  name: string;
  checked: boolean;
}
function Label({ name, checked }: Props) {
  return (
    <Grid container alignItems="center">
      <Checkbox checked={checked} />
      <ListItemText primary={name} />
    </Grid>
  );
}

export default Label;
