import React from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Fetchers
import { getKeywordsCombinations } from 'api/api';

// Types
import { CombinationKeywordProps } from 'pages/app/Application/KeywordTrackingTable/AddKeywordTabs/AddKeywordShuffle/types';

interface KeywordsSuggestionsQueryProps {
  projectId: string | undefined;
  appId: string | undefined;
  platform: string | undefined;
  payload: {
    [key: string]: string | number;
  };
}
const useKeywordsSuggestionsQuery = ({
  projectId,
  appId,
  platform,
  payload
}: KeywordsSuggestionsQueryProps) => {
  const queryClient = useQueryClient();
  // args to be passed as dependencies and to api function only on button click
  const [queryArgs, setQueryArgs] = React.useState({
    words: '',
    nbCombination: payload.nbCombination
  });
  const [sortedData, setSortedData] = React.useState<CombinationKeywordProps[]>([]);

  const query = useQuery({
    queryKey: [CACHE_KEYS.GET_KEYWORDS_SUGGESTIONS, { projectId, appId, platform, ...queryArgs }],
    queryFn: () =>
      getKeywordsCombinations({
        projectId,
        appId,
        platform,
        ...queryArgs
      }),
    refetchOnWindowFocus: false,
    enabled: queryArgs.words !== '',
    keepPreviousData: true,
    onSuccess: (data) => {
      if (data.data.length !== 0) {
        const sorted = data.data.sort((a: CombinationKeywordProps, b: CombinationKeywordProps) => {
          const aPopularity = a.keyword_popularity ?? 0;
          const bPopularity = b.keyword_popularity ?? 0;
          return bPopularity - aPopularity;
        });
        setSortedData(sorted);
      }
    }
  });
  const resetData = () => {
    queryClient.resetQueries(CACHE_KEYS.GET_KEYWORDS_SUGGESTIONS);
    setQueryArgs({ words: '', nbCombination: 1 });
  };

  const fetchData = () => {
    setQueryArgs({
      nbCombination: payload.nbCombination,
      words: Object.entries(payload)
        .filter(([key, value]) => key !== 'nbCombination' && value !== '')
        .map(([_key, value]) => value)
        .join(',')
    });
  };

  const updateTrackedKeywords = (kw: string) => {
    queryClient.setQueryData(
      [CACHE_KEYS.GET_KEYWORDS_SUGGESTIONS, { projectId, appId, platform, ...queryArgs }],
      (oldData: { data?: CombinationKeywordProps[] }) => {
        return {
          data:
            oldData?.data?.map((item: CombinationKeywordProps) => {
              if (item.keyword === kw) {
                return { ...item, keyword_tracked: true };
              }
              return item;
            }) || []
        };
      }
    );
    query.refetch();
  };

  return { ...query, sortedData, resetData, fetchData, updateTrackedKeywords };
};

export default useKeywordsSuggestionsQuery;
