import React, { ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

interface Props {
  id: string;
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
  disabled?: boolean;
}

/**
 * Simple custom search input with optional search icon.
 * With icon allows for submit search,
 * Without icon only works as text input
 * @param {string} id - required
 * @param {string} placeholder - optional, default Search
 * @param {string} value - default ''
 * @param {boolean} disabled for both the text input and the submit button
 * @param {function} onChange
 * */

function SearchBarWithIcon({ id, onChange, value, placeholder = 'Search...', disabled }: Props) {
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <Box display="flex">
      <TextField
        sx={{ input: { padding: '11px' } }}
        fullWidth
        color="error"
        size="small"
        id={id}
        variant={'outlined'}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={onInputChange}
        autoComplete="off"
      />
    </Box>
  );
}

export default SearchBarWithIcon;
