import styled from '@emotion/styled';
import { rgba } from 'polished';
import Chip from '@mui/material/Chip';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MuiDivider from '@mui/material/Divider';
import { ReactNode } from 'react';
import { css } from '@emotion/react';

export const Item = styled(ListItemButton, { shouldForwardProp: (prop) => prop !== 'isActive' })<
  | {
      href?: string;
      activeclassname?: string;
      component?: string | ReactNode;
      isActive?: boolean;
      to?: string;
    }
  | {
      activeclassname?: string;
      component?: string | ReactNode;
      isActive?: boolean;
      to?: string;
    }
>`
  padding-top: ${(props) => props.theme.spacing(3)};
  padding-bottom: ${(props) => props.theme.spacing(3)};
  padding-left: ${(props) => props.theme.spacing(8)};
  padding-right: ${(props) => props.theme.spacing(7)};
  color: white;
  white-space: nowrap;

  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  svg {
    color: white;
    width: 20px;
    height: 20px;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.08);
    color: ${(props) => props.theme.sidebar.color};
  }
  &.${(props) => props.activeclassname} {
    span {
      color: ${(props) => props.theme.palette.primary.main};
    }
    svg {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      span {
        color: ${theme.palette.primary.main};
      }
      svg {
        color: ${theme.palette.primary.main};
      }
    `}
`;

export const Title = styled(ListItemText)`
  margin: 0;
  span {
    color: white;
    padding: 0 ${(props) => props.theme.spacing(4)};
    font-size: 14px;
    font-weight: 500;
  }
`;

export const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightSemiBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;
  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

export const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

export const ExpandMoreIcon = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

export const Divider = styled(MuiDivider)`
  background-color: white;
  margin-left: 32px;
  margin-right: 28px;
  border-bottom-width: 0.1px;
  border-color: rgba(0, 0, 0, 0.5);
`;
