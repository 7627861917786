export const ACTIONS = {
  RESET_KEYWORDS: 'RESET_KEYWORDS',
  SET_KEYWORDS: 'SET_KEYWORDS',
  SET_KEYWORDS_RANK_CHANGE: 'SET_KEYWORDS_RANK_CHANGE',
  SET_KEYWORDS_RANK: 'SET_KEYWORDS_RANK',
  SET_KEYWORDS_POPULARITY: 'SET_KEYWORDS_POPULARITY',
  SET_KEYWORDS_POPULARITY_CHANGE: 'SET_KEYWORDS_POPULARITY_CHANGE',
  SET_KEYWORDS_DOWNLOADS: 'SET_KEYWORDS_DOWNLOADS',
  SET_KEYWORDS_TOTAL_APPS: 'SET_KEYWORDS_TOTAL_APPS',
  UPDATE_KEYWORDS: 'UPDATE_KEYWORDS',
  DELETE_KEYWORDS: 'DELETE_KEYWORDS',
  CLEAR_FILTERS: 'CLEAR_FILTERS',
  ERROR: 'ERROR',
  SET_PAGE: 'SET_PAGE',
  SET_COLUMNS_VISIBILITY: 'SET_COLUMNS_VISIBILITY',
  SET_FILTERS: 'SET_FILTERS',
  SET_SELECTED: 'SET_SELECTED',
  SET_DENSITY: 'SET_DENSITY',
  SET_DESELECTED_ALL: 'SET_DESELECTED_ALL',
  SET_SORT_BY: 'SET_SORT_BY',
  SET_DATE_RANGE: 'SET_DATE_RANGE'
};
