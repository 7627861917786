import { useQuery } from '@tanstack/react-query';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Fetchers
import { getChangesBacklogExperiments } from 'api/api';

interface Props {
  projectId: string;
  appId: string;
  platform: string;
}

/**
 * Query function that returns the experiments for the given app
 *
 * @param projectId   Project id
 * @param appId       App id
 * @param platform    App platform
 */

function useExperimentsQuery({ projectId, appId, platform }: Props) {
  return useQuery({
    queryKey: [CACHE_KEYS.CHANGES_BACKLOG, { projectId, appId, platform }],
    queryFn: () => getChangesBacklogExperiments({ projectId, appId, platform })
  });
}

export default useExperimentsQuery;
