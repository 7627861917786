import { useMemo, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import i18n from 'i18next';

import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

// Components
import PageTitle from 'components/PageTitle';
import KeywordlistsManager from 'components/KeywordlistsManager';
import AddKeywordsTabs from './AddKeywordTabs';
import KeywordTrackingTableFilters from './KeywordTrackingTableFilters';
import KeywordTrackingTableToolbar from './KeywordTrackingTableToolbar';
import KeywordTrackingTableError from './KeywordTrackingTableErrorOverlay';
import KeywordTrackingTableSelection from './KeywordTrackingTableSelection';
import FeedbackToast from 'components/FeedbackToast';
import HistoricalDataGraphModal from 'components/HistoricalDataGraphModal';

// Hooks
import useKeywordTrackingTable from 'hooks/useKeywordTrackingTable';
import useKeywordHistoricalData from 'api/queries/Keywords/HistoricalData';
import useApp from 'hooks/useApp';
import usePermissions from 'hooks/usePermissions';

// Theme
import { customGray } from 'theme/colors';

// Styles
import { FlexBox, FlexItem } from './styled';

// Constants
import { keywordTrackingTableColumns } from 'contexts/KeywordTrackingTable';
import * as API from 'api/api';
import { FETCH_STATE, CACHE_KEYS } from 'api/constants';
import { ALLOWED_ROLES } from 'constants/constants';

const ROWS_PER_PAGE_OPTIONS = [10, 30, 40];

// initial historical data modal state (default 30 days range)
const hdModalInitialState = {
  // show/hide
  isOpen: false,
  // modal title
  title: '',
  // determines what data is fetched (rank/popularity) and key to use to read the data
  dataKey: '',
  // id of keyword to fetch data for
  kwId: undefined
};

function KeywordTrackingTable() {
  const {
    handlePageChange,
    page,
    keywords,
    filteredRows,
    handleColumnVisibilityChange,
    visibleColumns,
    queriesStatuses,
    isTableFetching,
    refetchKeywords,
    handleDeleteKeywords,
    handleUpdateKeywords,
    density,
    handleChangeDensity,
    handleSortChange,
    sort,
    selected,
    handleSelected,
    dateRange,
    handleDateRangeChange,
    // keywordlists related tools
    listsStatus,
    availableLists,
    listSelected,
    setListSelected,
    refetchLists
  } = useKeywordTrackingTable();

  const { translateKeywords } = useApp();
  const queryClient = useQueryClient();
  const { projectId, appId, platform } = useParams();

  // mutation-related state
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [mutationStatus, setMutationStatus] = useState(FETCH_STATE.IDLE);
  const [rowsToDelete, setRowsToDelete] = useState([]);

  // params needed for all mutations and queries (except currentListId is not needed for hd query)
  const commonMutationParams = {
    projectId,
    appId,
    platform,
    currentListId: listSelected.id
  };

  // historical data modal
  const [hdModalState, setHdModalState] = useState(hdModalInitialState);
  const setIsHdModalOpen = (isOpen) => {
    setHdModalState({ ...hdModalState, isOpen });
  };

  // historical data modal - on open
  const onHDOpen = (dataType, kwName, kwIdNew, dataKey) => {
    setHdModalState({
      ...hdModalState,
      isOpen: true,
      title: i18n.t('common:data_type_history_for_keyword', {
        dataType: dataType.toLowerCase(),
        kwName: kwName.toUpperCase()
      }),
      dataKey,
      kwId: kwIdNew
    });
  };

  // historical data query
  const { data: historyData, status: historyStatus } = useKeywordHistoricalData({
    ...commonMutationParams,
    dataKey: hdModalState.dataKey,
    start: dateRange[0],
    end: dateRange[1],
    kwId: hdModalState.kwId,
    isOpen: hdModalState.isOpen
  });

  // mutations //
  const onSuccessfulMutation = () => {
    setMutationStatus(FETCH_STATE.SUCCESS);
    setFeedbackOpen(true);
    setFeedbackMessage(t('common:success'));
  };

  const onErrorMutation = (err, _, context) => {
    setMutationStatus(FETCH_STATE.ERROR);
    setFeedbackOpen(true);
    setFeedbackMessage(t('common:error_request'));
    queryClient.setQueryData([CACHE_KEYS.APPS, projectId, appId, platform], context.previous);
  };

  //delete keywords mutation
  const deleteKeywordsMutation = useMutation(
    (mutationData) => {
      return API.deleteAppKeywordsFromList({ ...commonMutationParams, ...mutationData });
    },
    {
      onMutate: async (keyword) => {
        // Snapshot the previous value
        const previous = queryClient.getQueryData([
          CACHE_KEYS.GET_KEYWORDS_KEYWORD,
          appId,
          projectId,
          listSelected.id
        ]);

        // Optimistically update to the new value
        queryClient.setQueryData(
          [CACHE_KEYS.GET_KEYWORDS_KEYWORD, appId, projectId, listSelected.id],
          () => ({
            ...previous,
            keywords: previous.keywords.filter((kw) => Number(keyword.keywordIds) !== kw.keyword_id)
          })
        );

        return { previous };
      },
      onSuccess: (_resp, mutationData) => {
        handleDeleteKeywords(mutationData);
        onSuccessfulMutation();
      },
      onError: onErrorMutation
    }
  );

  // update keywords mutation
  const updateKeywordsMutation = useMutation(
    (mutationData) => {
      return API.updateAppKeywordsAttributes({ ...commonMutationParams, ...mutationData });
    },
    {
      onSuccess: (_resp, mutationData) => {
        // TODO consider refetching instead of mutating state (depending on response timing)
        handleUpdateKeywords(mutationData);
        handleSelected([]);
        onSuccessfulMutation();
      },
      onError: onErrorMutation
    }
  );

  // mutation feedback
  const onFeedbackClose = () => {
    setFeedbackOpen(false);
    // reset mutation status after 1s as feedback toast changes color before it closes
    setTimeout(() => {
      setMutationStatus(FETCH_STATE.IDLE);
    }, 1000);
  };

  // get values from object and make it array of strings
  // to add as dependency for columns memoization
  const queriesStatusesToString = Object.values(queriesStatuses).map((status) => status.toString());
  // when all the table queries are error, show error message
  const isTableError = Object.values(queriesStatuses).every(
    (status) => status === FETCH_STATE.ERROR
  );
  const hasPermission = usePermissions(ALLOWED_ROLES.GENERIC);

  // We memoize the keywordTrackingTableColumns and only change that if
  // the `density` or `count` changes.
  const COLUMNS = useMemo(
    () =>
      keywordTrackingTableColumns(
        density,
        filteredRows.length,
        deleteKeywordsMutation,
        updateKeywordsMutation,
        queriesStatuses,
        onHDOpen,
        translateKeywords,
        hasPermission,
        rowsToDelete,
        setRowsToDelete
      ),
    [
      density,
      filteredRows.length,
      deleteKeywordsMutation.status,
      updateKeywordsMutation.status,
      queriesStatusesToString,
      translateKeywords,
      hasPermission
    ]
  );
  const FILTER_COLUMNS = [...COLUMNS];

  // Correct table page
  const tablePage = page - 1;

  // Page size
  const [pageSize, setPageSize] = useState(ROWS_PER_PAGE_OPTIONS[1]);

  // The `keywords` sorting refers to the `name` column and we need to change it.
  if (sort[0].field === 'keywords') {
    sort[0].field = 'name';
  }

  const { t } = useTranslation('components', { keyPrefix: 'KeywordTrackingTable' });

  return (
    <Grid
      data-testid="app-keyword-tracking-table"
      container
      alignItems="stretch"
      spacing={6}
      mt={3}
    >
      <Grid item xs={12}>
        {/* page title and lists manager */}
        <Stack direction="row" alignItems="center" spacing={6} ml={2}>
          <PageTitle title={t('title')} />
          <KeywordlistsManager
            refetchTrackedKeywords={refetchKeywords}
            status={listsStatus}
            availableLists={availableLists}
            listSelected={listSelected}
            refetchLists={refetchLists}
            setListSelected={setListSelected}
            resetSelection={handleSelected}
          />
        </Stack>
        {/* add keywords options*/}
        <AddKeywordsTabs
          refetchKeywords={refetchKeywords}
          listSelectedId={listSelected.id}
          trackedKeywords={keywords}
        />
        <Card>
          <CardContent>
            <KeywordTrackingTableFilters columns={FILTER_COLUMNS} />
            <KeywordTrackingTableSelection
              selectOpen={!!selected.length}
              selectedKeywords={selected}
              allKeywords={keywords}
              listsStatus={listsStatus}
              availableLists={availableLists}
              currentListId={listSelected.id}
              deleteKeywordsMutation={deleteKeywordsMutation}
              updateKeywordsMutation={updateKeywordsMutation}
              onSuccessfulMutation={onSuccessfulMutation}
              onErrorMutation={onErrorMutation}
            />
            <FlexBox>
              <FlexItem>
                <DataGrid
                  // We want to keep the selection state in the KeywordTrackingTableContext
                  onSelectionModelChange={(newSelectionModel) => {
                    handleSelected(newSelectionModel);
                  }}
                  sortModel={sort}
                  onSortModelChange={(newSortModel) => {
                    handleSortChange(newSortModel);
                  }}
                  selectionModel={selected}
                  onColumnVisibilityModelChange={handleColumnVisibilityChange}
                  page={tablePage}
                  onPageChange={handlePageChange}
                  rowCount={filteredRows.length}
                  paginationMode="client"
                  loading={isTableFetching}
                  hideFooterSelectedRowCount
                  rowHeight={32}
                  headerHeight={32}
                  // Disable column based functions, as we keep them
                  // in the toolbar instead and use datagrid's `apiRef`
                  // to use them
                  disableColumnSelector
                  disableColumnFilter
                  error={isTableError || undefined}
                  components={{
                    LoadingOverlay: LinearProgress,
                    Toolbar: KeywordTrackingTableToolbar,
                    ErrorOverlay: KeywordTrackingTableError
                  }}
                  initialState={{
                    sorting: {
                      sortModel: sort
                    }
                  }}
                  sx={{
                    border: 'unset',
                    '& .MuiDataGrid-columnHeaderTitle': {
                      fontWeight: '500'
                    },
                    '& .MuiDataGrid-overlay': {
                      bgcolor: customGray[2]
                    },
                    '& .MuiDataGrid-columnHeaders': {
                      fontSize: '10px',
                      backgroundColor: 'primary.main'
                    },
                    '& .MuiDataGrid-row': {
                      fontSize: '10px'
                    }
                  }}
                  // We want to disable column virtualization as we only use around 10 columns
                  // We need to set `columnBuffer` and `columnThreshold` to value bigger
                  // than our columns length
                  columnBuffer={14}
                  columnThreshold={14}
                  columnVisibilityModel={visibleColumns}
                  rows={filteredRows || []}
                  getRowId={(row) => row.keyword_id}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                  onPageSizeChange={(newSize) => setPageSize(newSize)}
                  columns={COLUMNS}
                  pageSize={pageSize}
                  checkboxSelection
                  disableSelectionOnClick
                  onStateChange={(v) => {
                    // Listen for `density` change and set it accordingly in the
                    // context
                    density !== v.density.value && handleChangeDensity(v.density.value);
                  }}
                  // This enables auto height resizing when changing the density
                  autoHeight
                />
              </FlexItem>
            </FlexBox>
          </CardContent>
        </Card>
      </Grid>
      <FeedbackToast
        status={mutationStatus}
        message={feedbackMessage}
        open={feedbackOpen}
        onClose={onFeedbackClose}
      />
      <HistoricalDataGraphModal
        resData={historyData}
        status={historyStatus}
        {...hdModalState}
        setIsOpen={setIsHdModalOpen}
        dates={dateRange}
        setDates={handleDateRangeChange}
      />
    </Grid>
  );
}

export default KeywordTrackingTable;
