import CustomSelect from 'components/Select';
import styled from '@emotion/styled';

import { DateRangePicker as Dpicker } from '@mui/x-date-pickers-pro/DateRangePicker';

export const Select = styled(CustomSelect)`
  .MuiInputBase-input {
    padding: 8.5px 8px;
    font-size: 10px;
    height: 32px;
    box-sizing: border-box;
  }
`;

export const DateRangePicker = styled(Dpicker)`
  justify-content: space-between;
  & .MuiInputBase-input {
    height: 32px;
    font-size: 10px;
    box-sizing: border-box;
  }
`;
