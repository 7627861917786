import React, { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import IconButton from '@mui/material/IconButton';

// Styles
import { AccordionSummary, AccordionStyled } from './styled';

interface Props {
  title: string | ReactNode;
  titleVariant?: string;
  defaultExpanded?: boolean;
  content: string | ReactNode;
  expanded: boolean;
  setExpanded?: (expanded: boolean) => void;
  className?: string;
}

/**
 * @name Accordion
 * @description custom reusable accordion
 * @param  {String} title accordion content summery
 * @param  {String} titleVariant title variant, default is h5
 * @param  {boolean} defaultExpanded is expanded by default, default value false
 * @param  {String, node} content accordion content summery, can be a simple string or can be a child component
 * @param  {boolean} expanded is expanded or not
 * @param  {function} setExpanded set expanded state (whne passed, shows expand icon)
 * @param  {String} className className
 *
 * */
function Accordion({
  title,
  titleVariant = 'h5',
  defaultExpanded = false,
  content,
  expanded,
  setExpanded,
  className
}: Props) {
  return (
    <AccordionStyled defaultExpanded={defaultExpanded} expanded={expanded} className={className}>
      <AccordionSummary>
        <Typography variant={titleVariant}>{title}</Typography>
        {setExpanded && (
          <IconButton
            onClick={() => setExpanded(!expanded)}
            sx={{ position: 'absolute', right: 0, top: 0 }}
          >
            <OpenInFullIcon fontSize="small" />
          </IconButton>
        )}
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </AccordionStyled>
  );
}

export default Accordion;
