import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

// Components
import GlobalStyle from 'components/GlobalStyle';
import BrandIcon from 'components/BrandIcon';

// Styles
import { Root, Wrapper } from './styled';

/**
 * @name DefaultErrorBoundaryFallback
 * @description default error boundary fallback
 */
function DefaultErrorBoundaryFallback() {
  const { t } = useTranslation('components', {
    keyPrefix: 'ErrorBoundary.DefaultErrorBoundaryFallback'
  });
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <BrandIcon isPhiture size="md" />
      <Wrapper>
        <Helmet title="Error" />
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          {t('unexpected_error')}
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          {t('refresh_to_retry')}
        </Typography>
      </Wrapper>
    </Root>
  );
}

export default DefaultErrorBoundaryFallback;
