import { ChangeEvent, createContext, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

// Fetchers
import useExperimentsQuery from 'api/queries/ChangesBacklog/useExperimentsQuery';

// Types
import { Params } from 'types/App';
import { Experiment } from 'types/ChangesBacklog';

export interface ChangesBacklogContextProps {
  experiments: Experiment[];
  activeExperimentId: number | null;
  setActiveExperiment: (e: ChangeEvent<HTMLInputElement>) => void;
  activeExperiment: Experiment;
}
export const ChangesBacklogContext = createContext({} as ChangesBacklogContextProps);

function ChangesBacklogProvider({ children }: PropsWithChildren) {
  const { projectId, appId, platform } = useParams() as Params;
  const [activeExperimentId, setActiveExperiment] = useState<number | null>(null);
  const { data } = useExperimentsQuery({ projectId, appId, platform });

  const handleSetListSelected = (e: ChangeEvent<HTMLInputElement> | number) => {
    const value = typeof e === 'number' ? e : e.target.value;
    setActiveExperiment(Number(value));
  };

  useEffect(() => {
    if (data?.data && !activeExperimentId) {
      setActiveExperiment(data?.data[0]?.id);
    }
  }, [data?.data]);

  // Currently active experiment
  const activeExperiment = useMemo(
    () => data?.data.find((el: Experiment) => el.id === activeExperimentId),
    [data?.data, activeExperimentId]
  );

  return (
    <ChangesBacklogContext.Provider
      value={{
        experiments: data?.data,
        activeExperimentId,
        setActiveExperiment: handleSetListSelected,
        activeExperiment
      }}
    >
      {children}
    </ChangesBacklogContext.Provider>
  );
}

export default ChangesBacklogProvider;
