import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useTranslation } from 'react-i18next';
import './i18n';
import ReactGA from 'react-ga4';

// Theme
import createTheme from './theme';

// Constants
import routes from './routes';

// Hooks
import useTheme from 'hooks/useTheme';

// Utils
import createEmotionCache from 'utils/createEmotionCache';

// Contexts
import { AuthProvider } from 'contexts/Auth';
import AxiosProvider from 'contexts/Axios';
import { AlertProvider } from 'contexts/Alert';

// Components
import ErrorBoundary from 'components/ErrorBoundary';
import GlobalLoader from 'components/GlobalLoader';
import Snackbar from 'components/Snackbar';

// Context
import { SnackbarProvider } from 'contexts/Snackbar';

const clientSideEmotionCache = createEmotionCache();
const queryClient = new QueryClient();

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID as string);

/**
 * @name App
 * @description Main app endpoint
 */
function App() {
  const content = useRoutes(routes);
  const { theme } = useTheme();
  const { t } = useTranslation('components', {
    keyPrefix: 'App'
  });

  return (
    <QueryClientProvider client={queryClient}>
      <CacheProvider value={clientSideEmotionCache}>
        <HelmetProvider>
          <Helmet titleTemplate="%s | Toaster" defaultTitle={t('toaster_default_title')} />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MuiThemeProvider theme={createTheme(theme)}>
              <AlertProvider>
                <AuthProvider>
                  <AxiosProvider>
                    <Suspense fallback={<GlobalLoader />}>
                      <SnackbarProvider>
                        <ErrorBoundary location="app">{content}</ErrorBoundary>
                        <Snackbar />
                      </SnackbarProvider>
                    </Suspense>
                  </AxiosProvider>
                </AuthProvider>
              </AlertProvider>
            </MuiThemeProvider>
          </LocalizationProvider>
        </HelmetProvider>
      </CacheProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
