import styled from '@emotion/styled';
import MuiAlert from '@mui/material/Alert';
import MuiButton from '@mui/material/Button';
import MuiTextField from '@mui/material/TextField';

export const Alert = styled(MuiAlert)`
  margin-bottom: ${(props) => props.theme.spacing(1)};
  margin-top: ${(props) => props.theme.spacing(2)};
`;

export const TextField = styled(MuiTextField)`
  margin: ${(props) => props.theme.spacing(2)} 0;
`;

export const Form = styled.form`
  max-width: 327px;
`;

export const Button = styled(MuiButton)`
  height: 40px;
  margin: ${(props) => props.theme.spacing(1.5)} 0};
`;
