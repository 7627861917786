import React, { useContext } from 'react';
import i18n from 'i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import type { GridAlignment } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';

// Components
import Header from './Header';

// Contexts
import { CompetitorKeywordsContext } from 'contexts/Keywords/Competitor';

// Types
import { Params } from 'types/App';
import { CompetitorKeywords } from 'types/Competitors';

// Hooks
import useKeywordLists from 'hooks/useKeywordLists';
import useKeywordTrackingTable from 'hooks/useKeywordTrackingTable';

// Fetchers
import { useAddKeywordsMutation } from 'api/queries/Keywords/useAddKeywordsMutation';

// Constants
import { CACHE_KEYS, FETCH_STATE_ENUM, FetchState } from 'api/constants';

const translationPath = 'components:KeywordTrackingTable.AddRankedKeywordTabs';

const COLUMNS = (
  addKeywordsMutation: (kw: string) => void,
  variables: string | undefined,
  status: FetchState,
  allLoading: boolean
) => [
  {
    field: 'word',
    headerName: i18n.t(`${translationPath}.name`),
    minWidth: 200,
    maxWidth: 400
  },
  {
    field: 'popularity',
    headerName: i18n.t(`${translationPath}.SP`)
  },
  {
    field: 'rank',
    headerName: i18n.t(`${translationPath}.headerRank`),
    type: 'number'
  },
  {
    field: 'track',
    headerName: i18n.t(`${translationPath}.track`),
    editable: false,
    sortable: false,
    flex: 1,
    align: 'right' as GridAlignment,
    headerAlign: 'right' as GridAlignment,
    renderCell: ({ row }: { row: CompetitorKeywords }) => {
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          {allLoading || (variables === row.word && status === FETCH_STATE_ENUM.LOADING) ? (
            <Button>
              <CircularProgress size={14} />
            </Button>
          ) : (
            <Button onClick={() => addKeywordsMutation(row.word)}>
              <AddIcon />
            </Button>
          )}
        </Box>
      );
    }
  }
];

function AddCompetitorKeyword() {
  const queryClient = useQueryClient();
  const { projectId, appId, platform } = useParams() as Params;

  const { refetchKeywords } = useKeywordTrackingTable();
  const { listSelected } = useKeywordLists(projectId, Number(appId), platform);

  const { isAllLoading, keywords } = useContext(CompetitorKeywordsContext);

  const { mutate, variables, status } = useAddKeywordsMutation({
    projectId,
    appId,
    platform,
    keywordListIds: String(listSelected.id)
  });

  const handleAddKeyword = (kw: string) => {
    mutate(kw, {
      onSuccess: () => {
        queryClient.setQueryData(
          [
            CACHE_KEYS.COMPETITORS_KEYWORDS_RANK,
            {
              projectId: Number(projectId),
              appId: Number(appId),
              platform,
              requestAll: true,
              keywordIds: undefined,
              keywordStrings: undefined
            }
          ],
          ({ data }: { data: CompetitorKeywords[] }) => ({
            data: data?.filter((keyword) => keyword.word !== kw)
          })
        );
        refetchKeywords();
      }
    });
  };

  return (
    <>
      <Header />
      <DataGrid
        columns={COLUMNS(handleAddKeyword, variables, status, isAllLoading)}
        rows={keywords || []}
        getRowId={(row) => row.keyword_id}
        rowHeight={32}
        headerHeight={32}
        autoHeight={true}
        sx={{
          width: '70%',
          margin: '16px auto 0 auto',
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: 'primary.main',
            fontSize: '10px'
          },
          '& .MuiDataGrid-row': {
            fontSize: '10px'
          }
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'popularity', sort: 'desc' }]
          },
          pagination: { pageSize: 10 }
        }}
      />
    </>
  );
}

export default AddCompetitorKeyword;
