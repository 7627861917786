import { InputLabel, Typography } from './styled';

interface LabelProps {
  label: string;
  id: string;
  current: number;
  max: number;
}
/**
 * Custom label for title and subtitle inputs
 * */

function Label({ label, id, current, max }: LabelProps) {
  return (
    <InputLabel htmlFor={id}>
      <Typography>{label}</Typography>
      <Typography>
        {current}/{max}
      </Typography>
    </InputLabel>
  );
}

export default Label;
