import React from 'react';

// Styles
import { StyledSkeleton } from './styled';

interface Props {
  maxWidth?: number | string;
  height?: number | string;
  width?: number | string;
  mb?: number;
  borderRadius?: number;
}

/**
 * @name Skeleton
 * @description A complex Skeleton component that expands the default Material UI Skeleton implementation.
 * @param  {Number | String =} maxWidth Defaults to 160.
 * @param  {Number | String =} width Defaults to '100%'.
 * @param  {Number | String} height Defaults to 24.
 * @param  {Number =} mb In Material UI spacing style (0, 1, 2, 3, etc.).
 * @param  {Number =} borderRadius In Material UI spacing style (0, 1, 2, 3, etc.).
 */
function Skeleton({
  maxWidth = 160,
  width = '100%',
  height = 24,
  mb = 0,
  borderRadius = 1
}: Props) {
  return (
    <StyledSkeleton
      variant="rectangular"
      max-width={maxWidth}
      width={width}
      height={height}
      mb={mb}
      border-radius={borderRadius}
    >
      <span className="SrOnly">Loading</span>
    </StyledSkeleton>
  );
}

export default Skeleton;
