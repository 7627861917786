import React, { createContext, useEffect, useReducer } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

// Fetchers
import { API, CACHE_KEYS, FETCH_STATE } from 'api';

// Components
import GlobalLoader from 'components/GlobalLoader';

// Constants
import { URLS } from 'constants/URL';
import { ACTIONS } from 'constants/Auth';

// Utils
import { isValidToken } from 'utils/jwt';
import { isEmpty } from 'utils/utils';
import StorageService from 'utils/storage';

// Reducers
import { AuthReducer } from './reducer';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: {},
  accessToken: '',
  refreshToken: '',
  error: '',
  status: FETCH_STATE.IDLE
};

const PAGES_WITHOUT_AUTHENTICATION = [
  URLS.SIGN_IN,
  URLS.SIGN_UP,
  URLS.ACCOUNT_CONFIRMATION,
  URLS.RESET_PASSWORD
];

// Initial context state
const initialAuthContextState = {
  ...initialState,
  signIn: () => {},
  signOut: () => {},
  signUp: () => {},
  setErrorGoogle: () => {},
  setStateData: () => {}
};

const AuthContext = createContext(initialAuthContextState);

/**
 * @name AuthProvider
 * @description Context for user authentication. This wrapper will check on each App start if there are tokens in the localStorage. If there are, then it wil try to fetch the refreshed JWT tokens with current, fresh user object. If not, it will force user to log-in again.
 * @param  {object} props
 * @param  {React.ReactNode} props.children
 * @param  {Object} props.mockState Custom state value. Useful for tests and storybook. Defaults to `null`.
 */
function AuthProvider({ mockState = null, children }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // If the current page is not one of the `sign-in`, `sign-up`, `reset-password`
  // or `account-confirmation`, this will enable running the `refresh` api call
  // on app start. Otherwise we won't run `refresh` api call.
  const isAuthPage = !PAGES_WITHOUT_AUTHENTICATION.includes(pathname);
  // If mockState was passed, set it as a initial state. Otherwise set initial state to empty object.
  const isMockState = !isEmpty(mockState);
  const [state, dispatch] = useReducer(AuthReducer, isMockState ? mockState : initialState);
  const isLocalStorageRefreshToken = Boolean(StorageService.getLocalStorageRefreshToken());
  const isLocalStorageUser = Boolean(StorageService.getLocalStorageUser());
  const localRefreshToken =
    state.refreshToken ||
    StorageService.getSessionStorageRefreshToken() ||
    StorageService.getLocalStorageRefreshToken();

  useEffect(() => {
    // If refreshToken is missing or is expired
    // remove localStorageData and sessionStorageData
    // and set state as unauthenticated and initialized
    // This should only run once per app load.
    if (!mockState && !(localRefreshToken && Boolean(isValidToken(localRefreshToken)))) {
      StorageService.removeLocalStorageData();
      StorageService.removeSessionStorageData();
      dispatch({
        type: ACTIONS.INITIALIZE
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If the current page is one of the `sign-in`, `sign-up`, `reset-password`
    // or `account-confirmation`, set state as initialized
    // Do not run if the mockState is passed down.
    if (!isMockState && !isAuthPage) {
      dispatch({
        type: ACTIONS.INITIALIZE
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If there is no user and refreshToken is valid and the page is an authenticated page, refetch token
  // This means that the app is being loaded for the first time. This query
  // will also be disabled if `mockedState` is passed down.
  const enableUserRefreshQuery =
    !mockState && isEmpty(state.user) && Boolean(isValidToken(localRefreshToken)) && isAuthPage;

  const { refetch: refetchUser, isInitialLoading } = useQuery({
    queryKey: [CACHE_KEYS.AUTH_TOKEN_REFRESH],
    queryFn: () => API.userRefreshToken({ refreshToken: localRefreshToken }),
    enabled: !isMockState && enableUserRefreshQuery,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (responseData) => {
      const { access_token: accessToken, refresh_token: refreshToken, user } = responseData.data;
      // If `refreshToken` already was in the localStorage, update everything there
      if (isLocalStorageRefreshToken) {
        StorageService.setLocalStorageData({ user, refreshToken, accessToken });
      }
      StorageService.setSessionStorageData({ user, refreshToken, accessToken });

      dispatch({
        type: ACTIONS.INITIALIZE,
        payload: {
          isAuthenticated: true,
          user,
          accessToken,
          refreshToken
        }
      });
    },
    onError: (_error) => {
      dispatch({
        type: ACTIONS.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: {}
        }
      });
    }
  });

  // If state is mocked, use provided status.
  let status;
  if (isMockState) {
    status = state.status;
  } else {
    status = isInitialLoading ? FETCH_STATE.LOADING : FETCH_STATE.SUCCESS;
  }
  /**
   * handles the user sign through API. It provides `mutate` function
   * which needs `mutationData` to be passed to it. Mutation data is the same as the
   * arguments that API function expects.
   */
  const userSignInMutation = useMutation(
    ({ remember, ...mutationData }) => {
      return API.userSignIn(mutationData);
    },
    {
      onSuccess: async (responseData, query) => {
        const { access_token: accessToken, refresh_token: refreshToken, user } = responseData.data;
        // If sign in was successful and user choose `remember me`, we're setting the
        // sessionToken and user in the localstorage
        if (query.data.remember) {
          StorageService.setLocalStorageData({ user, accessToken, refreshToken });
        }
        StorageService.setSessionStorageData({ user, accessToken, refreshToken });

        dispatch({
          type: ACTIONS.SIGN_IN,
          payload: {
            user,
            accessToken,
            refreshToken
          }
        });
      },
      onError: (_error) => {
        dispatch({
          type: ACTIONS.ERROR
        });
      }
    }
  );

  /**
   * @param  {'LOCAL' | 'GOOGLE'} type
   * @param  {{username: string, password: string, remember: boolean} | {credential: string, remember: boolean}} data If type
   * is `GOOGLE` then we use payload with `credential`.
   */
  const signIn = (type, data) => {
    return userSignInMutation.mutateAsync({ type, data });
  };

  /**
   * handles the user creation through API. It provides `mutate` function
   * which needs `mutationData` to be passed to it. Mutation data is the same as the
   * arguments that API function expects.
   */
  const userSignUpMutation = useMutation(
    (mutationData) => {
      return API.userCreate(mutationData);
    },
    {
      onSuccess: async (_responseData) => {
        dispatch({
          type: ACTIONS.SIGN_UP
        });
      },
      onError: (_error) => {
        dispatch({
          type: ACTIONS.ERROR
        });
      }
    }
  );

  /**
   * @param  {{username: string, email: string, password: string}} data
   */
  const signUp = (data) => {
    return userSignUpMutation.mutateAsync({
      data
    });
  };

  /**
   * @name signOut
   * @description Removes user data from the `LocalStorage` and `SessionStorage`.
   * Also removes the `lastProjectId` key from the `LocalStorage`. After that,
   * user is redirected to the sign in page.
   */
  const signOut = () => {
    // Remove user data from `LocalStorage` and from `SessionStorage`
    StorageService.removeLocalStorageData();
    StorageService.removeSessionStorageData();

    dispatch({ type: ACTIONS.SIGN_OUT });
    navigate(`${URLS.SIGN_IN}`);
  };

  /**
   * @param  {string} error
   */
  const setErrorGoogle = (error) => {
    dispatch({
      type: ACTIONS.ERROR_GOOGLE,
      payload: {
        error
      }
    });
  };

  /**
   * handles the user creation through API. It provides `mutate` function
   * which needs `mutationData` to be passed to it. Mutation data is the same as the
   * arguments that API function expects.
   */
  const userPasswordResetMutation = useMutation(
    (mutationData) => {
      return API.userResetPassword(mutationData);
    },
    {
      onSuccess: async (_responseData) => {
        dispatch({
          type: ACTIONS.RESET_PASSWORD
        });
      },
      onError: (_error) => {
        dispatch({
          type: ACTIONS.ERROR
        });
      }
    }
  );

  /**
   * @param  {{email: string}} params
   */
  const passwordReset = (params) => {
    return userPasswordResetMutation.mutateAsync({
      params
    });
  };

  /**
   * @param  {{accessToken: string, refreshToken: string, user: object}} data
   */
  const setStateData = ({ accessToken, refreshToken, user }) => {
    dispatch({
      type: ACTIONS.SET_ACCESS_TOKEN,
      payload: {
        accessToken,
        refreshToken,
        user
      }
    });
  };

  /**
   * @name updateProjectsList
   * @description If the project update was successful, this function will run and update
   * the project name in the user projects list locally and if user is kept in the
   * localStorage, update it there. If the projectId is not in any of the lists, the
   * project will be added to an `owner` projects list.
   * @param  {String} projectId
   * @param  {String} projectName
   * @param  {Boolean} isShareProjectType Defaults to false. If true, add provided project to `share_projects` array.
   */
  const updateProjectsList = (projectId, projectName, isShareProjectType = false) => {
    const { user } = state;
    const stringifyProjectId = projectId.toString();

    const ownerProject = user.projects.owner_projects.find(
      (p) => p.project_id === stringifyProjectId
    );
    const shareProject = user.projects.share_projects.find(
      (p) => p.project_id === stringifyProjectId
    );

    // No project in any of the lists - add project to the
    // ownerProjects list with a `type` of `owner`
    if (!ownerProject && !shareProject) {
      user.projects.owner_projects = [
        ...user.projects.owner_projects,
        { project_id: projectId, account_name: projectName, type: 'owner' }
      ];
    }

    if (ownerProject) {
      user.projects.owner_projects = user.projects.owner_projects.map((p) =>
        p.project_id === stringifyProjectId ? { ...p, account_name: projectName } : p
      );
    }

    if (shareProject) {
      user.projects.share_projects = user.projects.owner_projects.map((p) =>
        p.project_id === stringifyProjectId ? { ...p, account_name: projectName } : p
      );
    }

    if (isShareProjectType) {
      user.projects.share_projects = [
        ...user.projects.share_projects,
        { project_id: projectId, account_name: projectName, type: 'share' }
      ];
    }

    if (isLocalStorageUser) {
      StorageService.setLocalStorageUser(user);
    }

    dispatch({
      type: ACTIONS.UPDATE_PROJECTS_LIST,
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signOut,
        signUp,
        setErrorGoogle,
        passwordReset,
        status,
        setStateData,
        refetchUser,
        updateProjectsList
      }}
    >
      {/* If user is being authenticated during app loading, display Loader, else display the App */}
      {status === FETCH_STATE.LOADING || !state.isInitialized ? (
        <GlobalLoader />
      ) : (
        <React.Fragment>{children}</React.Fragment>
      )}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node,
  mockState: PropTypes.object
};

export { AuthContext, AuthProvider };
