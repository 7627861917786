import { useParams } from 'react-router-dom';

// Styles
import { Wrapper, Grid, TrackedKeyword, Translation } from './styled';

// Fetchers
import { useOKSMetadataQuery } from 'api/queries/OKS/Metadata';
import { Params } from 'types/App';

export interface Metadata {
  title: string;
  subtitle: string;
  current: string;
  freeKeywords: string;
}

const getKeywordsFromMetadata = (metadata: Metadata) => {
  if (!metadata) return [];
  const { title, subtitle, current, freeKeywords } = metadata;

  // Split by comma and space
  const regex = /[ ,]+/;

  return [
    ...(title ? title.split(regex) : ''),
    ...(subtitle ? subtitle.split(regex) : ''),
    ...(current ? current.split(regex) : ''),
    ...(freeKeywords ? freeKeywords.split(regex) : '')
  ].map((keyword) => keyword.toLowerCase());
};

interface Props {
  keyword: string;
  translation: string;
  showTranslation: boolean;
}
/**
 * Renders the cell for the keyword with the translation
 *
 * @param keyword           Keyword
 * @param translation       Keyword translation
 * @param showTranslation   Determines whether to show the translation
 */
function KeywordCell({ keyword, translation, showTranslation }: Props) {
  const { projectId, appId, platform } = useParams() as Params;
  const { data: metaData } = useOKSMetadataQuery({ projectId, appId, platform });

  const allKeywords = getKeywordsFromMetadata(metaData?.data);
  const splitKeyword = keyword.split(' ');

  const selectedKeywords = splitKeyword.map((kw) =>
    allKeywords.includes(kw.toLowerCase()) ? (
      <TrackedKeyword key={kw}>{kw} </TrackedKeyword>
    ) : (
      kw + ' '
    )
  );

  const isTracked = selectedKeywords.every((kw) => typeof kw === 'object');

  return (
    <Grid>
      <Wrapper isTracked={isTracked}>{selectedKeywords}</Wrapper>
      <Translation>{showTranslation && translation}</Translation>
    </Grid>
  );
}

export default KeywordCell;
