import React from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';

// Components
import Header from './Header';

// Utils
import { COLUMNS } from './utils';

// Types
import { KeywordAttributes } from 'types/Keywords/AI';

// Hooks
import useAIResearch from 'hooks/AI/useAIResearch';
import useCSVFileName from 'hooks/useCSVFileName';

function AddKeywordAIGenerated() {
  const { reviewList, updateReviewKeyword, isLoadingReviewList } = useAIResearch();

  const fileName = useCSVFileName('AI_keyword_review_list');

  const handleUpdateKeyword = ({ keywordIds, relevancy, type }: KeywordAttributes) => {
    updateReviewKeyword({ keywordIds, relevancy, type });
  };
  return (
    <>
      <Header />
      <DataGridPro
        loading={isLoadingReviewList}
        columns={COLUMNS(handleUpdateKeyword)}
        rows={reviewList || []}
        rowHeight={32}
        getRowId={(row) => row.keyword_id}
        columnHeaderHeight={32}
        autoHeight={true}
        sx={{
          width: '100%',
          margin: '16px auto 0 auto',
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: 'primary.main',
            fontSize: '10px'
          },
          '& .MuiDataGrid-row': {
            fontSize: '10px'
          }
        }}
        pageSizeOptions={[5, 10, 25, 50, 100, 200]}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
          sorting: {
            sortModel: [{ field: 'popularity_score', sort: 'desc' }]
          }
        }}
        pagination={true}
        slots={{ toolbar: GridToolbar }}
        slotProps={{ toolbar: { csvOptions: { fileName } } }}
      />
    </>
  );
}

export default AddKeywordAIGenerated;
