import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';

// Styles
import {
  Typography,
  FilterWrapper,
  FilterInput
} from 'pages/app/Application/KeywordTrackingTable/styled';

/**
 * @name TextFieldFilter
 * @description Filter by the provided text.
 * @param  {String} label Header name of the corresponding column for this filter.
 * @param  {String} name Name of the corresponding column for this filter.
 * @param  {Function} onChange Function that sets up the filter for this component
 * @param  {Function} onBlur Formik onBlur handle
 * @param  {Array} value Current filter value
 * @param  {Number} width Current input width
 * @param  {'small' | 'medium' | 'large'} size Size of the input. Defaults to 'small'
 * @param  {String} className Current input className
 */
function TextFieldFilter({ label, size, name, width, onChange, onBlur, value, className }) {
  return (
    <FilterWrapper sx={{ width }} className={className}>
      <FormControl fullWidth>
        <Typography>{label}</Typography>
        <FilterInput
          onBlur={onBlur}
          onChange={onChange}
          name={name}
          fullWidth
          variant="outlined"
          placeholder={label}
          value={value}
          size={size}
          width={width}
        />
      </FormControl>
    </FilterWrapper>
  );
}

TextFieldFilter.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.string.isRequired,
  width: PropTypes.number
};

export default TextFieldFilter;
