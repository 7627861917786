import React, { useMemo } from 'react';
import {
  CartesianGrid,
  LineChart,
  Line,
  ResponsiveContainer,
  YAxis,
  XAxis,
  Legend,
  Tooltip
} from 'recharts';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HeartBrokenOutlinedIcon from '@mui/icons-material/HeartBrokenOutlined';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme, alpha } from '@mui/material/styles';
// Components
import HistoricalDataGraphTooltip from './HistoricalDataGraphTooltip';
// Style
import { Grid } from './styled';
// Constants
import { FETCH_STATE_ENUM } from 'api/constants';
import { DATA_KEYS } from 'constants/constants';
const ESTIMATED_DATA = 'estimated_data';

//utils
import { processDataForChart } from './utils';
// TODO: this will change when new response is implemented
/*
 ** RANK
 * 251 = unranked
 * -1 = missing data (error from fetcher)
 * -2 = missing data (waiting for data to be fetched)
 * */

interface ResultItem {
  date: string;
  [key: string]: number | string;
  results_number: string;
}

interface Props {
  width?: string | number;
  status: string;
  dataKey: string;
  resData: {
    data: {
      results: ResultItem[];
      last_record: {
        last_date: string;
        [key: string]: number | string;
        last_result_number: string;
      };
    };
  };
}

/**
 * @name HistoricalDataGraph
 * @description Reusable styled line chart to display historical data graph (rank or popularity or other)
 * with a loading state, an error state and a no data state
 * */
// width added for tests, as responsive container has no width
function HistoricalDataGraph({ status, resData, dataKey, width = '100%' }: Props) {
  const { t } = useTranslation('components', { keyPrefix: 'HistoricalDataGraph' });
  const theme = useTheme();
  const LINE_COLOR = theme.palette.secondary.main;
  const LINE_COLOR_LIGHT = alpha(theme.palette.secondary.main, 0.28);

  // Memoize the processed data using useMemo
  const processedChartData = useMemo(() => {
    return processDataForChart(
      resData?.data.results,
      dataKey,
      resData?.data.last_record[`last_${dataKey}`]
    );
  }, [JSON.stringify(resData), dataKey]);

  if (status === FETCH_STATE_ENUM.LOADING) {
    return (
      <Grid>
        <CircularProgress size={100} sx={{ color: LINE_COLOR }} />
      </Grid>
    );
  }

  if (status === FETCH_STATE_ENUM.ERROR) {
    return (
      <Grid flexDirection="column">
        <ErrorOutlineIcon fontSize="large" color="error" />
        <Typography variant="h5">{t('error_fetching_data')}</Typography>
      </Grid>
    );
  }

  if (!resData?.data.last_record[`last_${dataKey}`]) {
    return (
      <Grid flexDirection="column">
        <HeartBrokenOutlinedIcon fontSize="large" color="warning" />
        <Typography variant="h5">{t('no_data')}</Typography>
      </Grid>
    );
  }

  // min and max values for y-axis. Add more cases if needed
  let yMin = dataKey === DATA_KEYS.RANK ? 1 : 5;
  let yMax = dataKey === DATA_KEYS.RANK ? 250 : 100;

  // props that are the same for both real data and estimated data
  const lineSharedProps = {
    strokeWidth: 2,
    dot: false
  };

  return (
    <Grid data-testid="historical-data-graph-line-chart">
      <ResponsiveContainer width={width} height="100%" aspect={2.5}>
        <LineChart data={processedChartData}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="date"
            angle={-15}
            tickSize={4}
            tickFormatter={(date) => dayjs(date).format('DD MMM')}
            interval="preserveStartEnd"
            tick={{ fontSize: 11 }}
            tickMargin={10}
          />
          <YAxis
            allowDecimals={false}
            axisLine={false}
            interval={0}
            domain={[yMin, yMax]}
            reversed={dataKey === DATA_KEYS.RANK}
            tickCount={10}
            tick={{ fontSize: 11 }}
            tickLine={false}
          />
          <Tooltip filterNull={false} content={<HistoricalDataGraphTooltip dataKey={dataKey} />} />
          <Legend
            iconType="circle"
            wrapperStyle={{ paddingTop: 20 }}
            payload={[
              { value: dataKey, id: dataKey, color: LINE_COLOR },
              { value: t('estimated', { dataKey }), id: ESTIMATED_DATA, color: LINE_COLOR_LIGHT }
            ]}
          />
          <Line {...lineSharedProps} dataKey={dataKey} stroke={LINE_COLOR} />
          <Line
            {...lineSharedProps}
            dataKey={ESTIMATED_DATA}
            stroke={LINE_COLOR_LIGHT}
            strokeDasharray="4 1"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Grid>
  );
}

export default HistoricalDataGraph;
