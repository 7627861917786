import { useMutation, useQueryClient } from '@tanstack/react-query';

// Fetchers
import { updateExperimentBacklog } from 'api/api';

// Types
import { Experiment, ExperimentBacklog } from 'types/ChangesBacklog';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Utils
import { validateLiveStatus } from 'pages/app/Application/ChangesBacklog/AddMetadata/utils';

interface Props {
  projectId: string;
  appId: string;
  platform: string;
  experimentId: number;
}

/**
 * Query function that updates an experiment backlog for the  app
 *
 * @param projectId       Project id
 * @param appId           App id
 * @param platform        App platform
 * @param experimentId    Experiment id
 */

function useExperimentBacklogUpdateMutation({ projectId, appId, platform, experimentId }: Props) {
  const queryClient = useQueryClient();
  return useMutation(
    (props: { backlogId: number; data: ExperimentBacklog; optimisticUpdate?: boolean }) =>
      updateExperimentBacklog({
        projectId,
        appId,
        platform,
        experimentId,
        backlogId: props.backlogId,
        data: props.data
      }),
    {
      onMutate: async (variables) => {
        if (variables.optimisticUpdate) {
          // Snapshot the previous value
          const previous = queryClient.getQueryData([
            CACHE_KEYS.CHANGES_BACKLOG,
            { projectId, appId, platform }
          ]);

          // Optimistically update to the new value
          queryClient.setQueryData<{ data: Experiment[] }>(
            [CACHE_KEYS.CHANGES_BACKLOG, { projectId, appId, platform }],
            (prev?: { data: Experiment[] }) => {
              return {
                data:
                  prev?.data.map((exp) =>
                    exp.id === variables.data.experiment
                      ? {
                          ...exp,
                          app_backlogs: exp.app_backlogs.map((backlog) =>
                            backlog.id === variables.data.id
                              ? variables.data
                              : { ...backlog, status: validateLiveStatus(variables.data, backlog) }
                          )
                        }
                      : exp
                  ) || []
              };
            }
          );

          return { previous };
        }
      },
      onError: (err, _, context) => {
        queryClient.setQueryData(
          [CACHE_KEYS.CHANGES_BACKLOG, { projectId, appId, platform }],
          context?.previous
        );
      }
    }
  );
}

export default useExperimentBacklogUpdateMutation;
