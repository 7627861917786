import React, { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

// Hooks
import useAuth from 'hooks/useAuth';

// Constants
import { URLS } from 'constants/URL';

/**
 * @name AuthGuard
 * @description Wrapper for routes that can only be accessed by authenticated users
 * @param  {object} props
 * @param  {React.ReactNode} props.children
 */
function AuthGuard({ children }: PropsWithChildren) {
  const { pathname: prevLocation } = useLocation();
  const { isAuthenticated, isInitialized } = useAuth();
  const redirectUrl = prevLocation ? `${URLS.SIGN_IN}?redirect_to=${prevLocation}` : URLS.SIGN_IN;

  // If user is initialized but not authenticated - redirect user to the sign in page.
  if (isInitialized && !isAuthenticated) {
    return <Navigate to={redirectUrl} />;
  }

  // else display the page content
  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
