export interface Experiment {
  id: number;
  name: string;
  created_at: string;
  app_localised_id: number;
  date: string;
  app_backlogs: ExperimentBacklog[];
}

export interface ExperimentBacklog {
  id: number;
  asset_type: AssetTypesKeys;
  comment: string;
  current_value: string;
  date: string;
  is_active: boolean;
  language: string;
  previous_value: string;
  result: string;
  status: StatusKeys;
  name?: string;
  experimentId?: number;
  experiment?: number;
}

export enum StatusKeys {
  DRAFT = 'draft',
  AWAITING_CLIENT_APPROVAL = 'awaiting',
  APPROVED = 'approved',
  LIVE = 'live'
}
export enum Status {
  DRAFT = 'Draft',
  AWAITING_CLIENT_APPROVAL = 'Awaiting',
  APPROVED = 'Approved',
  LIVE = 'Live'
}

export enum AssetTypesKeys {
  TITLE = 'title',
  SUBTITLE = 'subtitle',
  KEYWORD_SET = 'keywords'
}

export enum AssetTypes {
  TITLE = 'Title',
  SUBTITLE = 'Subtitle',
  KEYWORD_SET = 'Keyword set'
}

export enum ResultKeys {
  Tie = 'tie',
  Win = 'win',
  Loss = 'loss',
  Pending = 'pending'
}
