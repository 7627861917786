import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';

export const Removed = styled(Typography)`
  color: red;
  font-size: 10px;
  word-wrap: break-word;
  width: 75px;
`;

export const Added = styled(Typography)`
  color: green;
  font-size: 10px;
  word-wrap: break-word;
  width: 75px;
`;
