import { useQuery } from '@tanstack/react-query';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Fetchers
import { getCompetitorsSuggestions } from 'api/api';

interface Props {
  projectId: string;
  appId: string;
  platform: string;
}

/**
 * Query function that returns the competitors suggestions data
 *
 * @param projectId   Project id
 * @param appId       App id
 * @param platform    App platform
 */
function useCompetitorsSuggestionsQuery({ projectId, appId, platform }: Props) {
  return useQuery({
    queryKey: [CACHE_KEYS.COMPETITORS_SUGGESTIONS, { projectId, appId, platform }],
    queryFn: () => getCompetitorsSuggestions({ projectId, appId, platform }),
    enabled: false
  });
}

export default useCompetitorsSuggestionsQuery;
