import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

interface Props {
  onDelete: (id: number) => void;
  id: number;
  isDisabled: boolean;
}

function ActionsCell({ onDelete, id, isDisabled }: Props) {
  const [deleteRow, setDeleteRow] = useState(false);

  return (
    <Grid>
      {deleteRow ? (
        <>
          <IconButton onClick={() => onDelete(id)}>
            <CheckIcon color="success" />
          </IconButton>
          <IconButton
            onClick={() => {
              setDeleteRow(false);
            }}
          >
            <ClearIcon color="error" />
          </IconButton>
        </>
      ) : (
        <>
          <IconButton
            disabled={isDisabled}
            onClick={() => {
              setDeleteRow(true);
            }}
          >
            <DeleteIcon color={isDisabled ? 'disabled' : 'error'} />
          </IconButton>
        </>
      )}
    </Grid>
  );
}

export default ActionsCell;
