// eslint-disable-next-line import/named
import { useQuery, UseQueryResult } from '@tanstack/react-query';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Fetchers
import { getAIKeywordsData } from 'api/api';
import { PLATFORMS } from 'types/Platform';

interface Props {
  projectId: number;
  appId: number;
  platform: PLATFORMS;
}

export interface KeywordPhrase {
  estimated_max_downloads: number;
  keyword: string;
  impressions: number;
}

interface Response {
  data: {
    [key: string]: {
      total_impressions: number;
      keyword: string;
      total_estimated_max_downloads: number;
      keyword_phrases: KeywordPhrase[];
    };
  };
}

/**
 * Query function that returns the keywords data used for generating assets
 *
 * @param projectId   Project id
 * @param appId       App id
 * @param platform    App platform
 *
 * */
function useAIKeywordsData({ projectId, appId, platform }: Props): UseQueryResult<Response> {
  return useQuery<Response>({
    queryKey: [CACHE_KEYS.AI_KEYWORDS_DATA, { projectId, appId, platform }],
    queryFn: () => getAIKeywordsData({ projectId, appId, platform }),
    refetchOnWindowFocus: false
  });
}

export default useAIKeywordsData;
