import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
  details: string | React.ReactNode;
  summary: string | React.ReactNode;
  className?: string;
  defaultExpanded?: boolean;
}

/**
 * Renders a collapsable detail/accordion element
 *
 * @param {string | ReactNode} details   - Details text
 * @param {string | ReactNode} summary   - Summary text
 * @param {string}  className            - Classname
 * @param {boolean} defaultExpanded      - Whether the accordion is expanded by default
 */
function Details({ details, summary, className, defaultExpanded }: Props) {
  return (
    <Accordion className={className} defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{summary}</AccordionSummary>
      <AccordionDetails>{details}</AccordionDetails>
    </Accordion>
  );
}

export default Details;
