import 'react-app-polyfill/stable';

import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { ENVIRONMENT } from 'constants/Environment';
import { LicenseInfo } from '@mui/x-license-pro';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router-dom';
import App from './App';
import { ThemeProvider } from 'contexts/Theme';
import './i18n';
// CSS imports must be last
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-datepicker/dist/react-datepicker.css';

const CURRENT_ENVIRONMENT = process.env.APP_ENV || '';

// Sentry initialization. When APP_ENV is PRODUCTION or STAGING, the Sentry
// is enabled. Sentry also needs to be initialized with the correct
// DSN. We use staging environment to catch bugs before release, and production
// to catch bugs after release.
if (process.env.SENTRY_ENABLED === 'true') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: CURRENT_ENVIRONMENT === ENVIRONMENT.PRODUCTION ? 0.1 : 1.0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      new Sentry.Integrations.Breadcrumbs({ console: false }),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true
      })
    ],
    // We're adjusting this value in production
    tracesSampleRate: CURRENT_ENVIRONMENT === ENVIRONMENT.PRODUCTION ? 0.5 : 1.0,
    release: process.env.SENTRY_RELEASE,
    environment: CURRENT_ENVIRONMENT
  });
}

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);

LicenseInfo.setLicenseKey(process.env.MUI_LICENSE_KEY as string);

root.render(
  <BrowserRouter>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </BrowserRouter>
);
