import React, { createContext, PropsWithChildren, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

// Fetchers
import { API, CACHE_KEYS, FETCH_STATE } from 'api';
import { FETCH_STATE_ENUM } from 'api/constants';

// Types
import { Params, App, OtherAppMarket } from 'types/App';

export interface AppContextState {
  translateKeywords: boolean;
  handleToggleTranslation: () => void;
  app?: App;
  allTrackedLocales: { [x: string]: { label: string; value: number }[] };
  status: FETCH_STATE_ENUM;
}

const AppContext = createContext<AppContextState>({} as AppContextState);

/**
 * Maps the other app markets per country
 *
 * @param markets Other app markets
 */
export const getLocales = (markets: OtherAppMarket[]) =>
  markets.reduce((acc, { id, locale, country_code }) => {
    return {
      ...acc,
      [country_code]: [...(acc[country_code] || []), { value: id, label: locale }]
    };
  }, {} as { [x: string]: { label: string; value: number }[] });

/**
 * @name AppProvider
 * @description Context for app.
 * @param  {React.ReactNode} children
 */
function AppProvider({ children }: PropsWithChildren) {
  const { projectId, appId, platform } = useParams() as Params;

  const [translateKeywords, setTranslateKeywords] = useState(true);

  const { data: appData, isInitialLoading } = useQuery<{ data: App }>({
    queryKey: [CACHE_KEYS.APPS, projectId, appId, platform],
    queryFn: () => API.getProjectApp({ projectId, appId, platform }),
    refetchOnWindowFocus: false,
    retry: false
  });

  const allTrackedLocales = getLocales(appData?.data?.other_app_market || []);

  const status = isInitialLoading ? FETCH_STATE.LOADING : FETCH_STATE.SUCCESS;

  const handleToggleTranslation = () => {
    setTranslateKeywords((prev) => !prev);
  };

  return (
    <AppContext.Provider
      value={{
        handleToggleTranslation,
        translateKeywords,
        app: appData?.data,
        allTrackedLocales,
        status
      }}
    >
      <React.Fragment>{children}</React.Fragment>
    </AppContext.Provider>
  );
}

export { AppContext, AppProvider };
