// eslint-disable-next-line import/named
import { useQuery, UseQueryResult } from '@tanstack/react-query';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Fetchers
import { getUntrackedRankedKeywords } from 'api/api';

// Types
import { UntrackedRanked } from 'types/Keywords/UntrackedRanked';

interface Props {
  projectId: string;
  appId: string;
  platform: string;
  listId: number;
}

/**
 * Fetches untracked ranked keywords for an app
 *
 * @param projectId   Project id
 * @param appId       App id
 * @param platform    App platofm
 * @param listId      Keyword list id
 */
function useUntrackedRankedKeywords({
  projectId,
  appId,
  platform,
  listId
}: Props): UseQueryResult<{ data: UntrackedRanked[] }> {
  return useQuery({
    queryKey: [CACHE_KEYS.GET_UNTRACKED_RANKED_KEYWORDS, { projectId, appId, platform, listId }],
    queryFn: () => getUntrackedRankedKeywords({ projectId, appId, platform, listId })
  });
}

export default useUntrackedRankedKeywords;
