import React, { ChangeEvent, useContext, useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

// Components
import Label from './Label';
import Select from 'components/Select';

// Utils
import { COLUMNS, resultOptions } from './utils';
import {
  getPreviousValue,
  updateBacklog,
  getPreviousExperiment
} from 'pages/app/Application/ChangesBacklog/AddMetadata/utils';

// Hooks
import useApp from 'hooks/useApp';

// Types
import { Experiment, ExperimentBacklog, StatusKeys } from 'types/ChangesBacklog';
import { Params } from 'types/App';

// Fetchers
import useExperimentBacklogUpdateMutation from 'api/queries/ChangesBacklog/useExperimentBacklogUpdateMutation';
import useExperimentMutation from 'api/queries/ChangesBacklog/useExperimentsNameMutation';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Components
import { NoBorderSelect } from 'components/Select/variations';

// Contexts
import { ChangesBacklogContext } from 'contexts/ChangesBacklog';

// Styles
import { DatePicker } from './styled';

const today = dayjs().startOf('day');

function HistoricalMetadata() {
  const { t } = useTranslation('pages', { keyPrefix: 'ChangesBacklog' });
  const queryClient = useQueryClient();
  const { projectId, appId, platform } = useParams() as Params;
  const { app, allTrackedLocales } = useApp();
  const [date, setDate] = useState<Dayjs>(today);
  const [liveOnly, setLiveOnly] = useState(false);
  const [result, setResult] = useState('all');
  const [experimentsShown, setExperiments] = useState<[string | number]>(['All']);

  const countryOptions = allTrackedLocales[app.country]?.map((country) => country.label);
  const { activeExperimentId, experiments } = useContext(ChangesBacklogContext);

  const { mutate: updateExperiment } = useExperimentMutation({ projectId, appId, platform });
  const { mutate: updateExperimentBacklog } = useExperimentBacklogUpdateMutation({
    projectId,
    appId,
    platform,
    experimentId: activeExperimentId!
  });

  useEffect(() => {
    if (experimentsShown.length === 1) {
      const activeExperiment = experiments.find((exp) => exp.id === experimentsShown[0]);
      activeExperiment?.date && setDate(dayjs(activeExperiment?.date));
    }
  }, [experimentsShown]);

  const handleUpdateRow = (id: number, data: ExperimentBacklog) => {
    updateExperimentBacklog({ backlogId: id, data, optimisticUpdate: true });
  };

  const filteredRows = experiments
    ?.filter((row) => experimentsShown.includes('All') || experimentsShown.includes(row.id))
    ?.map((exp) =>
      exp?.app_backlogs?.map((row) => ({
        ...row,
        name: exp.name,
        date: exp.date,
        experimentId: exp.id
      }))
    )
    .flat()
    .filter(
      (row) =>
        row.is_active &&
        (liveOnly ? row.status === StatusKeys.LIVE : true) &&
        (result === 'all' ? true : row.result === result)
    );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as unknown as [string | number];
    setExperiments(value);
  };

  const renderValue = (selected: string[] & number[]) => {
    if (selected.includes('All')) {
      return 'All';
    }

    if (selected.length === 1) {
      return experiments.find((experiment: Experiment) => experiment.id === selected[0])?.name;
    }

    return `${selected.length} experiments`;
  };

  const options = [
    { label: <Label name="All" checked={experimentsShown.indexOf('All') > -1} />, value: 'All' },
    ...experiments.map((experiment: Experiment) => ({
      label: (
        <Label
          name={experiment.name}
          checked={
            experimentsShown.indexOf('All') > -1 || experimentsShown.indexOf(experiment.id) > -1
          }
        />
      ),
      value: experiment.id
    }))
  ];

  const handleDateChange = async (date: Dayjs) => {
    setDate(date);
    updateExperiment(
      { date: dayjs(date).format('YYYY-MM-DD'), experimentId: experimentsShown[0] as number },
      {
        onSuccess: (res) => {
          queryClient.setQueryData(
            [CACHE_KEYS.CHANGES_BACKLOG, { projectId, appId, platform }],
            (prev?: { data: Experiment[] }) =>
              updateBacklog(Number(experimentsShown[0]), dayjs(date).format('YYYY-MM-DD'), prev)
          );
        }
      }
    );

    const experimentActive = experiments.find((exp) => exp.id === experimentsShown[0]);

    const fetchers = experimentActive?.app_backlogs?.map((row: ExperimentBacklog) => {
      const previousExperiment = getPreviousExperiment(experimentActive.date, experiments);
      const previous = getPreviousValue(previousExperiment?.app_backlogs || [], row.asset_type);
      return updateExperimentBacklog(
        { backlogId: row.id, data: { ...row, previous_value: previous } },
        {
          onSuccess: () => {
            queryClient.setQueryData(
              [CACHE_KEYS.CHANGES_BACKLOG, { projectId, appId, platform }],
              (prev?: { data: Experiment[] }) => {
                return {
                  data:
                    prev?.data.map((exp) =>
                      exp.id === experimentActive.id
                        ? {
                            ...exp,
                            app_backlogs: [
                              ...exp.app_backlogs.map((backlog) => {
                                const previousExperiment = getPreviousExperiment(
                                  exp.date,
                                  experiments
                                );
                                const previous = getPreviousValue(
                                  previousExperiment?.app_backlogs || [],
                                  backlog.asset_type
                                );
                                return { ...backlog, previous_value: previous };
                              })
                            ]
                          }
                        : exp
                    ) || []
                };
              }
            );
          }
        }
      );
    });

    // Update all rows
    await Promise.all(fetchers);
  };

  const handleDateRowChange = async (date: Dayjs, experimentId: number) => {
    updateExperiment(
      { date: dayjs(date).format('YYYY-MM-DD'), experimentId },
      {
        onSuccess: () => {
          queryClient.setQueryData(
            [CACHE_KEYS.CHANGES_BACKLOG, { projectId, appId, platform }],
            (prev?: { data: Experiment[] }) =>
              updateBacklog(experimentId, dayjs(date).format('YYYY-MM-DD'), prev)
          );
        }
      }
    );

    const experimentActive = experiments.find((exp) => exp.id === experimentId);

    const fetchers = experimentActive?.app_backlogs?.map((row: ExperimentBacklog) => {
      const previousExperiment = getPreviousExperiment(experimentActive.date, experiments);
      const previous = getPreviousValue(previousExperiment?.app_backlogs || [], row.asset_type);
      return updateExperimentBacklog(
        { backlogId: row.id, data: { ...row, previous_value: previous } },
        {
          onSuccess: () => {
            queryClient.setQueryData(
              [CACHE_KEYS.CHANGES_BACKLOG, { projectId, appId, platform }],
              (prev?: { data: Experiment[] }) => {
                return {
                  data:
                    prev?.data.map((exp) =>
                      exp.id === experimentActive.id
                        ? {
                            ...exp,
                            app_backlogs: [
                              ...exp.app_backlogs.map((backlog) => {
                                const previousExperiment = getPreviousExperiment(
                                  exp.date,
                                  experiments
                                );
                                const previous = getPreviousValue(
                                  previousExperiment?.app_backlogs || [],
                                  backlog.asset_type
                                );
                                return { ...backlog, previous_value: previous };
                              })
                            ]
                          }
                        : exp
                    ) || []
                };
              }
            );
          }
        }
      );
    });

    // Update all rows
    await Promise.all(fetchers);
  };

  return (
    <>
      <Grid container alignItems="center">
        <Select
          multiple
          id="backlogs-"
          displayEmpty={true}
          options={options}
          value={experimentsShown}
          width={220}
          onChange={handleChange}
          renderValue={renderValue}
        />
        <DatePicker
          disabled={experimentsShown.includes('All') || experimentsShown.length != 1}
          value={date}
          onChange={handleDateChange}
          format="DD/MM/YYYY"
        />
        <Grid display="flex" alignItems="center" onClick={() => setLiveOnly(!liveOnly)}>
          <Checkbox checked={liveOnly} /> <Typography variant="body2">{t('live_only')}</Typography>
        </Grid>
        <Grid display="flex" alignItems="center">
          <NoBorderSelect
            id={'result'}
            options={[{ label: 'All', value: 'all' }, ...resultOptions]}
            value={result}
            onChange={(e) => {
              setResult(e.target.value);
            }}
            inputProps={{ IconComponent: () => null }}
            width={100}
          />
        </Grid>
      </Grid>
      <DataGrid
        onCellEditCommit={(params) => {
          handleUpdateRow(params.row.id, { ...params.row, [params.field]: params.value });
        }}
        getRowHeight={() => 'auto'}
        headerHeight={48}
        rows={filteredRows || []}
        getRowId={(row) => row?.id}
        columns={COLUMNS(countryOptions, handleUpdateRow, handleDateRowChange, experiments)}
        paginationMode="client"
        rowsPerPageOptions={[30]}
        pageSize={30}
        hideFooterPagination
        hideFooterSelectedRowCount
        sx={{
          height: '400px',
          marginTop: 5,
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '500',
            fontSize: '10px'
          },
          '& .MuiDataGrid-cellContent': {
            fontSize: '10px'
          }
        }}
      />
    </>
  );
}

export default HistoricalMetadata;
