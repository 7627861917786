// Types
import { MinMax } from 'contexts/Keywords/UntrackedRanked';
import { CompetitorKeywords } from 'types/Competitors';

/**
 * Returns filtered keywords based on rank and popularity score
 *
 * @param keywords
 * @param filterBy
 * @param rankMinMax
 * @param spMinMax
 */
export const getFilteredKeywords = (
  keywords: CompetitorKeywords[] | undefined,
  trackedKeywords: any[],
  filterBy: string,
  rankMinMax: MinMax,
  spMinMax: MinMax
) => {
  if (!keywords) return [];

  return keywords.filter((kw) => {
    const rank = kw.rank;
    const sp = kw.popularity;
    const name = kw.word.toLowerCase();
    const filter = filterBy.toLowerCase();

    return (
      rank >= rankMinMax.currentMin &&
      rank <= rankMinMax.currentMax &&
      sp >= spMinMax.currentMin &&
      sp <= spMinMax.currentMax &&
      name.includes(filter) &&
      !trackedKeywords.includes(name)
    );
  });
};
