import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';

// Hooks
import useMetadataGeneratorAI from 'hooks/AI/useMetadataGeneratorAI';

// Fetchers
import useMainAIListQuery from 'api/queries/AI/KeywordResearch/useMainAIListQuery';

// Components
import AssetRow from './AssetRow';
import PromptEditor from './PromptEditor';
import OKS from './OKS';
import Keywords from './Keywords';

// Styles
import { Title, Details, Heading } from './styled';

// Types
import { Params } from 'types/App';

/**
 * Display of AI generated asset options
 * */
function Assets() {
  const { t } = useTranslation('pages', {
    keyPrefix: 'AI.MetadataGenerator.Assets'
  });

  const { projectId, appId, platform } = useParams() as Params;

  const {
    handleGenerateAssets,
    isGeneratingAssets,
    assets,
    prompt,
    isLoadingAssets,
    keywordsData,
    allPrompts,
    generateAIOKS
  } = useMetadataGeneratorAI();

  const { data, isLoading } = useMainAIListQuery({
    projectId: Number(projectId),
    appId: Number(appId),
    platform
  });

  const mainList = data?.data?.keywords || [];

  if (isLoadingAssets) {
    return (
      <Box paddingBottom={4} paddingTop={34} sx={{ width: '100%' }}>
        <Box display="flex" justifyContent="center">
          <SmartToyOutlinedIcon color="primary" />
          <Typography variant="h5" gutterBottom sx={{ marginLeft: 2, marginRight: 2 }}>
            {t('loading')}
          </Typography>
          <SmartToyOutlinedIcon color="primary" />
        </Box>
        <LinearProgress />
        <Box paddingTop={2} display="flex" justifyContent="center"></Box>
      </Box>
    );
  }

  const editablePrompt = allPrompts.find((p) => p.id === prompt?.id)?.prompt;

  const handleGenerateAIOKS = (title: string, subtitle: string) => {
    generateAIOKS({ title, subtitle });
  };

  return (
    <Box paddingBottom={4} mt={4}>
      <Title mb={2}>Metadata Generator</Title>
      <Details
        details={<PromptEditor />}
        summary={<Heading>{t('titles.prompt_editor')}</Heading>}
      />
      <Details details={<Keywords />} summary={<Heading>{t('titles.keywords')}</Heading>} />
      <Divider />

      <Grid container justifyContent={'space-between'} alignItems={'center'} mt={4}>
        <Heading>{t('titles.AI_assets')}</Heading>
        <Tooltip
          title={
            prompt?.prompt !== editablePrompt
              ? t('disabled_message')
              : isLoading
              ? t('loading_main_list')
              : !mainList.length
              ? t('no_main_list')
              : !keywordsData.length
              ? 'Waiting for keywords data'
              : ''
          }
        >
          <span>
            <LoadingButton
              variant="contained"
              color="secondary"
              onClick={handleGenerateAssets}
              loading={isGeneratingAssets || isLoading || !keywordsData.length}
              endIcon={<AddIcon />}
              loadingPosition="end"
              disabled={prompt?.prompt !== editablePrompt || !mainList.length}
            >
              {t('generate_assets')}
            </LoadingButton>
          </span>
        </Tooltip>
      </Grid>
      <Grid container mt={4}>
        <Grid>
          {assets?.map((asset, index) => (
            <AssetRow asset={asset} key={index} onSubmit={handleGenerateAIOKS} />
          ))}
        </Grid>
        {assets.length > 0 && <OKS />}
      </Grid>
    </Box>
  );
}

export default Assets;
