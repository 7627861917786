import React, {
  createContext,
  useState,
  PropsWithChildren,
  useEffect,
  Dispatch,
  SetStateAction
} from 'react';
import { useParams } from 'react-router-dom';

// Hooks
import useKeywordsLists from 'hooks/useKeywordLists';

// Fetchers
import useUntrackedRankedKeywords from 'api/queries/Keywords/Ranks/useUntrackedRankedKeywords';

// Types
import { UntrackedRanked } from 'types/Keywords/UntrackedRanked';
import { Params } from 'types/App';

// Utils
import { getFilteredKeywords } from 'contexts/Keywords/UntrackedRanked/utils';

export interface MinMax {
  min: number;
  max: number;
  currentMin: number;
  currentMax: number;
}

interface UntrackedRankedKeywordsContextProps {
  filter: string;
  setFilter: (value: string) => void;
  keywords?: UntrackedRanked[];
  spMinMax: MinMax;
  rankMinMax: MinMax;
  setSPMinMax: Dispatch<SetStateAction<MinMax>>;
  setRankMinMax: Dispatch<SetStateAction<MinMax>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isAllLoading: boolean;
}
export const UntrackedRankedKeywordsContext = createContext<UntrackedRankedKeywordsContextProps>(
  {} as UntrackedRankedKeywordsContextProps
);

function UntrackedRankedKeywordsProvider({ children }: PropsWithChildren) {
  const [isAllLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [spMinMax, setSPMinMax] = useState<MinMax>({
    min: 0,
    max: 0,
    currentMin: 0,
    currentMax: 0
  });
  const [rankMinMax, setRankMinMax] = useState<MinMax>({
    min: 0,
    max: 0,
    currentMin: 0,
    currentMax: 0
  });

  const { projectId, appId, platform } = useParams() as Params;
  const { listSelected } = useKeywordsLists(projectId, Number(appId), platform);

  const { data: keywords, isLoading } = useUntrackedRankedKeywords({
    projectId,
    appId,
    platform,
    listId: listSelected.id
  });

  // Set min and max values for rank and sp
  useEffect(() => {
    if (keywords?.data) {
      const spValues = keywords?.data.map((kw) => kw.popularity_score);
      const rankValues = keywords?.data.map((kw) => kw.rank);

      const rankMin = Math.min(...rankValues);
      const rankMax = Math.max(...rankValues);
      const spMin = Math.min(...spValues);
      const spMax = Math.max(...spValues);

      setSPMinMax({
        min: spMin,
        max: spMax,
        currentMax: spMax,
        currentMin: spMin
      });

      setRankMinMax({
        min: rankMin,
        max: rankMax,
        currentMax: rankMax,
        currentMin: rankMin
      });
    }
  }, [keywords?.data]);

  const filteredKeywords = getFilteredKeywords(keywords?.data, filter, rankMinMax, spMinMax);

  return (
    <UntrackedRankedKeywordsContext.Provider
      value={{
        filter,
        keywords: filteredKeywords,
        spMinMax,
        rankMinMax,
        setSPMinMax,
        setRankMinMax,
        setFilter,
        isLoading,
        isAllLoading,
        setIsLoading
      }}
    >
      <React.Fragment>{children}</React.Fragment>
    </UntrackedRankedKeywordsContext.Provider>
  );
}

export default UntrackedRankedKeywordsProvider;
