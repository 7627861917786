import { useContext } from 'react';

// Contexts
import { SnackbarContext } from 'contexts/Snackbar';

/**
 * Custom hook to use the snackbar context
 */
export default function useSnackbar() {
  return useContext(SnackbarContext);
}
