import { useMutation, useQueryClient } from '@tanstack/react-query';

// Fetchers
import { updateAppKeywordsAttributes } from 'api/api';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Types
import { AIReviewList, KeywordAttributes } from 'types/Keywords/AI';

interface Props {
  projectId: number;
  appId: number;
  platform: string;
  reviewListId: number;
}

export default function useAIReviewKeywordMutation({
  projectId,
  appId,
  platform,
  reviewListId
}: Props) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ keywordIds, relevancy, type }: KeywordAttributes) =>
      updateAppKeywordsAttributes({
        projectId,
        appId,
        platform,
        currentListId: reviewListId,
        keywordIds,
        relevancy,
        type
      }),
    {
      onMutate: async ({ keywordIds, relevancy, type }) => {
        // Snapshot the previous value
        const previous: { data: AIReviewList[] } | undefined = queryClient.getQueryData([
          CACHE_KEYS.AI_REVIEW_LISTS,
          { projectId, appId, platform, reviewListId }
        ]);

        // Update relevancy or type for a keyword
        queryClient.setQueryData(
          [CACHE_KEYS.AI_REVIEW_LISTS, { projectId, appId, platform, reviewListId }],
          {
            data: previous?.data.map((kw) =>
              kw.keyword_id === Number(keywordIds)
                ? {
                    ...kw,
                    keyword_relevancy_id: relevancy ? relevancy : kw.keyword_relevancy_id,
                    keyword_type_id: type ? type : kw.keyword_type_id
                  }
                : kw
            )
          }
        );

        // Fallback
        return { previous };
      },
      onError: (err, _, context) => {
        queryClient.setQueryData(
          [CACHE_KEYS.AI_REVIEW_LISTS, { projectId, appId, platform, reviewListId }],
          context?.previous
        );
      }
    }
  );
}
