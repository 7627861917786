import React, { ChangeEvent, useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

// Components
import { NoBorderSelect } from 'components/Select/variations';

// Styles
import { AddIcon, AddRowButton, InputText, Select } from './styled';
// Types
import { AssetTypesKeys, ResultKeys, StatusKeys } from 'types/ChangesBacklog';
import { Params } from 'types/App';

// Hooks
import useApp from 'hooks/useApp';

// Fetchers
import useExperimentBacklogMutation from 'api/queries/ChangesBacklog/useExperimentBacklogMutation';

// Contexts
import { ChangesBacklogContext } from 'contexts/ChangesBacklog';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Utils
import {
  assetOptions,
  getPreviousValue,
  statusOptions,
  getPreviousExperiment
} from 'pages/app/Application/ChangesBacklog/AddMetadata/utils';

/**
 * Table footer component for adding experiment rows to the table
 * @constructor
 */
function TableFooter() {
  const { t } = useTranslation('pages', {
    keyPrefix: 'ChangesBacklog.TableColumns'
  });
  const queryClient = useQueryClient();
  const { activeExperimentId, activeExperiment, experiments } = useContext(ChangesBacklogContext);
  const { projectId, appId, platform } = useParams() as Params;
  const { app, allTrackedLocales } = useApp();

  const countryOptions = useMemo(
    () => allTrackedLocales[app?.country]?.map((country) => country.label),
    [allTrackedLocales, app?.country]
  );

  const [form, setForm] = useState<{
    status: StatusKeys;
    language: string;
    assetType: AssetTypesKeys;
    current: string;
    comment: string;
  }>({
    status: StatusKeys.DRAFT,
    language: '',
    assetType: AssetTypesKeys.TITLE,
    current: '',
    comment: ''
  });

  useEffect(() => {
    if (countryOptions?.length) {
      setForm({
        ...form,
        language: countryOptions[0]
      });
    }
  }, [countryOptions]);

  const { mutate } = useExperimentBacklogMutation({
    projectId,
    appId,
    platform,
    experimentId: activeExperimentId!
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  // Adds a row to the new metadata table
  const handleAddRow = () => {
    const previousExperiment = getPreviousExperiment(activeExperiment.date, experiments);
    const previous = getPreviousValue(previousExperiment?.app_backlogs || [], form.assetType);
    mutate(
      { ...form, previous },
      {
        onSuccess: (res) => {
          queryClient.setQueryData(
            [CACHE_KEYS.CHANGES_BACKLOG, { projectId, appId, platform }],
            (oldData) => {
              return {
                data: oldData.data.map((exp) =>
                  exp.id === activeExperimentId
                    ? {
                        ...exp,
                        app_backlogs: [
                          ...exp.app_backlogs,
                          { ...res.data, result: ResultKeys.Pending }
                        ]
                      }
                    : exp
                )
              };
            }
          );
        }
      }
    );
  };

  return (
    <>
      <Divider />
      <Grid mt={4} container alignItems="center">
        <NoBorderSelect
          width={100}
          id="draft"
          options={statusOptions}
          name="status"
          value={form.status}
          onChange={handleChange}
          inputProps={{ IconComponent: () => null }}
        />
        <Select
          width={100}
          id="language"
          options={countryOptions}
          label={t('language')}
          name="language"
          value={form.language}
          onChange={handleChange}
        />
        <Select
          width={100}
          id="asset-type"
          name="assetType"
          options={assetOptions}
          label={t('asset_type')}
          value={form.assetType}
          onChange={handleChange}
        />
        <InputText
          placeholder={t('new')}
          name="current"
          value={form.current}
          handleChange={handleChange}
          sx={{
            '& input::placeholder': {
              color: 'text.primary',
              opacity: '1'
            }
          }}
        />
        <InputText
          placeholder={t('comments')}
          name="comment"
          value={form.comment}
          handleChange={handleChange}
          sx={{
            '& input::placeholder': {
              color: 'text.primary',
              opacity: '1'
            }
          }}
        />
      </Grid>
      <Grid mt={4}>
        <AddRowButton variant="contained" color="secondary" onClick={handleAddRow}>
          <AddIcon /> {t('add_row')}
        </AddRowButton>
      </Grid>
    </>
  );
}

export default TableFooter;
