import styled from '@emotion/styled';
import TextInput from 'components/InputText';
import MuiBox from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MuiButton from '@mui/material/Button';

export const InputText = styled(TextInput)`
  width: 190px;
  & .MuiInputBase-input,
  .MuiFormControl-root {
    width: 100%;
    font-size: 10px;
  }

  .MuiInputBase-root {
    padding: 0;
  }
`;

export const ScoresContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ScoresLabel = styled(MuiBox)`
  font-size: 10px;
`;

export const ScoresWrapper = styled(MuiBox)<{ color: string }>`
  background-color: ${({ color }) => color};
  border-radius: 10px;
  padding: 1px 10px 1px 10px;
  width: 45px;
  display: flex;
  justify-content: center;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: 10px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const Button = styled(MuiButton)`
  height: 28px;
`;
