export const PLATFORMS = {
  IPHONE: 'iphone',
  IPAD: 'ipad',
  IOS: 'ios',
  ANDROID: 'android'
};

export const ALLOWED_ROLES = {
  GENERIC: ['Standard (phiture)', 'Admin'],
  DELETE_KEYWORD: ['Standard (phiture)', 'Admin'],
  EDIT_KEYWORD: ['Standard (phiture)', 'Admin', 'Owner', 'Editor']
};

export const PROJECT_ROLES = [
  {
    id: '1',
    role: 'Admin'
  },
  {
    id: '2',
    role: 'Owner'
  },
  {
    id: '3',
    role: 'Editor'
  },
  {
    id: '4',
    role: 'Client(External to Phiture)'
  },
  { id: '5', role: 'Freelancer Research + Relevancy(external)' },
  {
    id: '6',
    role: 'Freelancer(Optimization)'
  },
  {
    id: '7',
    role: 'Freelancer Analysis'
  },
  { id: '8', role: 'Localization' }
];

export const KEYWORD_TYPE_OPTIONS = [
  { label: 'None', value: '' },
  { label: 'Brand', value: 1 },
  { label: 'Competition', value: 2 },
  { label: 'Generic', value: 3 }
];

export const KEYWORD_RELEVANCY_OPTIONS = [
  { label: 'None', value: '' },
  { label: 'Irrelevant', value: 1 },
  { label: 'Relevant', value: 2 },
  { label: 'Extremely relevant', value: 3 },
  { label: 'Starred', value: 4 }
];

export const SELECT_FILTER_OPTIONS = {
  relevancy_label: {
    1: 'Irrelevant',
    2: 'Relevant',
    3: 'Extremely relevant',
    4: 'Starred'
  },
  type_id: {
    1: 'Brand',
    2: 'Competition',
    3: 'Generic'
  }
};

export const DATE_RANGE_OPTIONS = [
  { label: 'last_week', value: 7 },
  { label: 'last_30_days', value: 30 },
  { label: 'last_90_days', value: 90 },
  { label: 'last_year', value: 365 }
];

export const APP_PAGES = {
  TRACKING_TABLE: 'keywords_tracking',
  OKS: 'optimal_keyword_set',
  CHANGES_BACKLOG: 'changes_backlog',
  KEYWORDS_ANALYSIS: 'keyword_analysis',
  SEQUENTIAL_ANALYSIS: 'sequential_analysis',
  AI_LABS: 'ai_labs_research',
  AI_RESEARCH: 'ai_research',
  AI_METADATA: 'ai_metadata_generator'
};

export const IOS_VERSION = 7;

// Static Priority (tier) options
export const PRIORITY_OPTIONS = [
  { value: '1', label: 'components:AppHeader.Details.priority_count' },
  { value: '2', label: 'components:AppHeader.Details.priority_count' },
  { value: '3', label: 'components:AppHeader.Details.priority_count' }
];

// Static Platform options
export const PLATFORM_OPTIONS = [
  {
    value: PLATFORMS.IPHONE,
    label: 'iPhone'
  },
  {
    value: PLATFORMS.ANDROID,
    label: 'Android'
  },
  {
    value: PLATFORMS.IPAD,
    label: 'iPad'
  }
];

// dataKey
export const DATA_KEYS = {
  RANK: 'rank',
  SCORE: 'score'
};

// Password must be at least 8 characters, contain one uppercase letter, one lowercase letter, one number, one special character among !@#$%^&*
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/;
