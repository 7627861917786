// eslint-disable-next-line import/named
import { useQuery, UseQueryResult } from '@tanstack/react-query';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Fetchers
import { getAIMainList } from 'api/api';

//Types
import { AIMainKeywordList } from 'types/Keywords/AI';

interface Props {
  projectId: number;
  appId: number;
  platform: string;
}

/**
 * Query function that returns the master AI keyword list. to be displayed in the AI tracked table
 * @param projectId   Project id
 * @param appId       App id
 * @param platform    App platform
 */

function useMainAIListQuery({
  projectId,
  appId,
  platform
}: Props): UseQueryResult<{ data: AIMainKeywordList }> {
  return useQuery({
    queryKey: [CACHE_KEYS.AI_MAIN_LIST, { projectId, appId, platform }],
    queryFn: () => getAIMainList({ projectId, appId, platform }),
    retry: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
}

export default useMainAIListQuery;
