/**
 * Formats the number into a string
 *
 * Example: 100000 = 100K
 *          1000000 = 1M
 *
 * @param num Number to format
 */
export function nFormatter(num: number) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value);

  return item ? (Number(num) / item.value).toFixed(1).replace(rx, '$1') + item.symbol : '0';
}
