import React, { useState, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import { typePrimaryColor } from 'theme/colors';

// Components
import { SelectedTab } from 'pages/app/Application/KeywordTrackingTable/AddKeywordTabs/styled';
import AddKeywordPanel from 'pages/app/Application/KeywordTrackingTable/AddKeywordTabs/AddKeywordPanel';
import Accordion from 'components/Accordion';
import Manual from './Manual';
import ReviewList from './ReviewList';
import ReviewListStatus from 'pages/app/Application/AI/Research/Review/Status';

// Hooks
import useAIResearch from 'hooks/AI/useAIResearch';

// Styles
import { StyledAccordion } from './styled';
/**
 * AddKeywords accordion component allowing AI project user to add kws manually or validate and add AI generated kws
 * */

function Review() {
  const { t } = useTranslation('components', {
    keyPrefix: 'ResearchAI.AddKeywords'
  });

  const { mainList, isLoadingMainList, activeTab, setActiveTab } = useAIResearch();

  const handleChangeActiveTab = (_e: SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const [expanded, setExpanded] = useState(true);
  const handleExpand = (selected: number) => {
    if (activeTab === selected) {
      setExpanded((prevExpanded) => !prevExpanded);
      return;
    }
    setExpanded(true);
  };
  // context that handles generation of AI keywords
  return (
    <Box>
      <StyledAccordion
        expanded={expanded}
        title={
          <Box display="flex" alignItems="baseline">
            <Tabs
              value={activeTab}
              onChange={handleChangeActiveTab}
              aria-label="add keywords Research AI tabs"
              sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: typePrimaryColor,
                  height: '1px'
                },
                width: 'fit-content'
              }}
            >
              <SelectedTab
                label={t('status')}
                id="add-keywords-tab-0"
                aria-controls="add-keywords-tabpanel-0"
                key="add_keywords"
                onClick={() => handleExpand(0)}
              />
              <SelectedTab
                label={t('review_list')}
                id="add-keywords-tab-1"
                aria-controls="add-keywords-tabpanel-1"
                key="review_ai_generated"
                onClick={() => handleExpand(1)}
              />
              <SelectedTab
                disabled={isLoadingMainList && !mainList.length}
                label={t('manual')}
                id="add-keywords-tab-2"
                aria-controls="add-keywords-tabpanel-2"
                key="add_keywords"
                onClick={() => handleExpand(2)}
              />
            </Tabs>
          </Box>
        }
        content={
          <Box sx={{ width: '100%' }}>
            <AddKeywordPanel value={activeTab} index={0} key="ai_generated">
              <ReviewListStatus />
            </AddKeywordPanel>
            <AddKeywordPanel value={activeTab} index={1} key="ai_generated">
              <ReviewList />
            </AddKeywordPanel>
            <AddKeywordPanel value={activeTab} index={2} key="manual">
              <Manual />
            </AddKeywordPanel>
          </Box>
        }
      />
    </Box>
  );
}

export default Review;
