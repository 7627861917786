import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

// Components
import Loader from 'components/Loader';
import BrandIcon from 'components/BrandIcon';
import NewPasswordFromReset from 'pages/auth/ResetPassword/NewPasswordFromReset';

// Fetchers
import { API, CACHE_KEYS } from 'api/index';

// Constants
import { URLS } from 'constants/URL';

// Types
import { FETCH_STATE_ENUM } from 'api/constants';

// Styles
import { Wrapper } from './styled';

enum TranslationKey {
  AccountConfirmation = 'AccountConfirmation',
  ResetPasswordConfirm = 'ResetPasswordConfirm'
}

interface Props {
  translationKey: TranslationKey;
}

/**
 * @name ConfirmationError
 * @description Displays error message
 */
function ConfirmationError({ translationKey }: Props) {
  const { t } = useTranslation('pages', {
    keyPrefix: translationKey
  });
  return (
    <Wrapper>
      <Helmet title={t('error_confirming')} />
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        {t('error_confirming')}
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        <Trans
          i18nKey={`pages:${translationKey}.confirmation_error_information`}
          components={{ a: <a href="mailto:support@toaster.com" /> }}
        />
      </Typography>
    </Wrapper>
  );
}

/**
 * @name ConfirmationMessage
 * @description Displays information about successful account confirmation/reset password
 */
function ConfirmationMessage({ translationKey }: Props) {
  const { t } = useTranslation('pages', {
    keyPrefix: translationKey
  });

  return (
    <Wrapper>
      <Helmet title={t('confirmed')} />
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        {t('all_set')}
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        {t('shortly_redirected')}
      </Typography>
    </Wrapper>
  );
}

/**
 * @name Confirmation
 * @description This page is used to check verify a new account, or reset a password. If
 * successful, the user is redirected to the login page after 5 seconds
 * @param isResetPassword
 */
function Confirmation({ isResetPassword }: { isResetPassword: boolean }) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const verificationCode = searchParams.get('verification_code') ?? '';
  const verificationType = searchParams.get('verification_type') ?? '';
  const translationKey = isResetPassword
    ? TranslationKey.ResetPasswordConfirm
    : TranslationKey.AccountConfirmation;

  const verifyEmailQuery = useQuery({
    queryKey: [CACHE_KEYS.USER_VERIFY_EMAIL],
    queryFn: () => API.userVerifyEmail({ verificationCode, verificationType }),
    refetchOnWindowFocus: false,
    enabled: !isResetPassword
  });

  const verifyEmailForResetPasswordMutation = useMutation(
    ({ password, confirmPassword }: { password: string; confirmPassword: string }) => {
      return API.userVerifyEmailForPasswordReset({
        verificationCode,
        verificationType,
        password,
        confirmPassword
      });
    }
  );

  // Access query information
  const isAccountConfirmed =
    verifyEmailQuery.isSuccess || verifyEmailForResetPasswordMutation.isSuccess;

  useEffect(() => {
    // If account is confirmed, redirect user to the sign-in page
    if (isAccountConfirmed) {
      const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
        navigate({ pathname: URLS.SIGN_IN, search: '?account_confirmed=true' }, { replace: true });
      }, 5000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountConfirmed]);

  const isLoading =
    verifyEmailQuery.isInitialLoading || verifyEmailForResetPasswordMutation.isLoading;

  const isError = verifyEmailQuery.isError || verifyEmailForResetPasswordMutation.isError;

  const handleSubmit = ({
    password,
    confirmPassword
  }: {
    password: string;
    confirmPassword: string;
  }) => {
    verifyEmailForResetPasswordMutation.mutate({ password, confirmPassword });
  };

  if (isLoading) {
    return (
      <React.Fragment>
        <BrandIcon isPhiture={true} size="md" />
        <Loader />
      </React.Fragment>
    );
  }

  if (isError) {
    return (
      <React.Fragment>
        <BrandIcon isPhiture size="md" />
        <ConfirmationError translationKey={translationKey} />
      </React.Fragment>
    );
  }

  if (isResetPassword && verifyEmailForResetPasswordMutation.status === FETCH_STATE_ENUM.IDLE) {
    return (
      <React.Fragment>
        <BrandIcon isPhiture size="md" />
        <NewPasswordFromReset handleOnSubmit={handleSubmit} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <BrandIcon isPhiture size="md" />
      <ConfirmationMessage translationKey={translationKey} />
    </React.Fragment>
  );
}

export default Confirmation;
