import { DATA_KEYS } from 'constants/constants';

export const getTextValue = (
  dataKey: string,
  originalSeries: number | string | null,
  integratedSeries: number | string | null,
  t: any
): string => {
  if (dataKey === DATA_KEYS.RANK) {
    return originalSeries === null
      ? integratedSeries !== null
        ? t('dataKey_based_on_previous', { value: integratedSeries })
        : t('unranked')
      : originalSeries;
  } else {
    return originalSeries === null
      ? t('dataKey_based_on_previous', { value: integratedSeries })
      : originalSeries;
  }
};
