import { ChangeEvent } from 'react';
import i18n from 'i18next';

// Components
import { NoBorderSelect } from 'components/Select/variations';

// Types
import { AIReviewList, KeywordAttributes } from 'types/Keywords/AI';

// Utils
import { reducedRelevancyOptions, reducedTypeOptions } from 'contexts/KeywordTrackingTable/columns';
import { renderAIKWRankWithFallback } from 'utils/utils';
import { SELECT_FILTER_OPTIONS } from 'constants/constants';

const translationPath = 'pages:AILabs.keyword_table.header';

export const COLUMNS = (handleUpdateKeyword: (data: KeywordAttributes) => void) => [
  {
    field: 'word',
    headerName: i18n.t(`${translationPath}.keyword`),
    minWidth: 150,
    flex: 1
  },
  {
    field: 'popularity_score',
    headerName: i18n.t(`${translationPath}.sp`),
    flex: 1
  },
  {
    field: 'rank',
    headerName: i18n.t(`${translationPath}.rank`),
    flex: 1,
    valueGetter: ({ row }: { row: AIReviewList }) => renderAIKWRankWithFallback(row.rank)
  },
  { field: 'top_app_title', headerName: i18n.t(`${translationPath}.top_ranked_app`), flex: 1 },
  {
    field: 'top_app_developer_name',
    headerName: i18n.t(`${translationPath}.app_developer`),
    flex: 1
  },
  {
    field: 'keyword_type_id',
    headerName: i18n.t(`${translationPath}.type`),
    minWidth: 100,
    valueFormatter: ({ value }: { value: 1 | 2 | 3 }) => SELECT_FILTER_OPTIONS.type_id[value],
    flex: 1,
    renderCell: ({ row }: { row: AIReviewList }) => {
      const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        handleUpdateKeyword({
          keywordIds: String(row.keyword_id),
          type: Number(event.target.value)
        });
      };
      return (
        <NoBorderSelect
          width={125}
          value={row.keyword_type_id}
          options={reducedTypeOptions()}
          id={'type-options'}
          inputProps={{ IconComponent: () => null }}
          onChange={handleChange}
        />
      );
    }
  },
  // {
  //   field: 'confidence_type',
  //   headerName: i18n.t(`${translationPath}.confidence_type`),
  //   flex: 1
  // },
  {
    field: 'keyword_relevancy_id',
    headerName: i18n.t(`${translationPath}.relevancy`),
    minWidth: 100,
    flex: 1,
    valueFormatter: ({ value }: { value: 1 | 2 | 3 | 4 }) =>
      SELECT_FILTER_OPTIONS.relevancy_label[value],
    renderCell: ({ row }: { row: AIReviewList }) => {
      const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        handleUpdateKeyword({
          keywordIds: String(row.keyword_id),
          relevancy: Number(event.target.value)
        });
      };
      return (
        <NoBorderSelect
          onChange={handleChange}
          width={125}
          inputProps={{ IconComponent: () => null }}
          value={row.keyword_relevancy_id}
          options={reducedRelevancyOptions()}
          id={'relevancy-options'}
        />
      );
    }
  },
  {
    field: 'classification_rule',
    headerName: i18n.t(`${translationPath}.classification_rule`),
    flex: 1
  },
  { field: 'note', headerName: i18n.t(`${translationPath}.classification_note`), flex: 1 }
  // {
  //   field: 'confidence_relevancy',
  //   headerName: i18n.t(`${translationPath}.confidence_relevancy`),
  //   flex: 1,
  //   minWidth: 125
  // }
];
