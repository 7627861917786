import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

// Components
import Select from 'components/Select';

// Styles
import { TextArea, Text, SaveButton } from './styled';

// Hooks
import useMetadataGeneratorAI from 'hooks/AI/useMetadataGeneratorAI';

function PromptEditor() {
  const { t } = useTranslation('pages', {
    keyPrefix: 'AI.MetadataGenerator.Assets'
  });
  const {
    allPrompts,
    handleSetActivePrompt,
    handleSetPrompt,
    prompt,
    isSavingPrompt,
    handleCreatePrompt
  } = useMetadataGeneratorAI();

  const options = allPrompts.map((prompt) => ({
    label: dayjs(prompt.created_at).format('MM/DD/YY, h:mm A'),
    value: prompt.id
  }));

  const isDisabled =
    prompt?.prompt === allPrompts.find((p) => p.id === prompt?.id)?.prompt || isSavingPrompt;

  return (
    <>
      <Grid container>
        <TextArea
          multiline
          value={prompt?.prompt || ''}
          handleChange={(e) => handleSetPrompt(e.target.value)}
        />
        <Grid
          container
          alignItems={'center'}
          ml={6}
          sx={{ width: 'fit-content', height: 'fit-content' }}
        >
          <Text>{t('prompt_history')}</Text>
          <Select
            width={170}
            id={'prompts'}
            options={options}
            value={prompt?.id || ''}
            onChange={(e) => handleSetActivePrompt(e.target.value)}
          />
        </Grid>
      </Grid>
      <SaveButton
        variant="contained"
        color="secondary"
        onClick={handleCreatePrompt}
        disabled={isDisabled}
      >
        {t('save')}
      </SaveButton>
    </>
  );
}

export default PromptEditor;
