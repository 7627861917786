import React from 'react';
import { useLocation } from 'react-router-dom';

// Utils
import reduceChildRoutes from 'components/Sidebar/reduceChildRoutes';

// Types
import { Page } from 'components/Sidebar/Navigation/sidebarUtilsItems';

interface Props {
  pages: Page[];
  depth: number;
}

const SidebarNavList = ({ pages, depth }: Props) => {
  const router = useLocation();
  const currentRoute = router.pathname;

  const childRoutes = pages.reduce(
    (items, page) => reduceChildRoutes({ items, page, currentRoute, depth }),
    []
  );

  return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;
