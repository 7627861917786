// Styles
import { ListItemText, Grid, Checkbox, Image } from './styled';

interface Props {
  icon: string;
  name: string;
  checked: boolean;
}

function Label({ icon, name, checked }: Props) {
  return (
    <Grid container alignItems="center">
      <Checkbox checked={checked} />
      <Image src={icon} height={8} width={8} />
      <ListItemText ml={4} primary={name} />
    </Grid>
  );
}

export default Label;
