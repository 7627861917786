import { Suspense } from 'react';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';

// Components
import OKSMetadata from './Metadata';
import OKSTable from './Table';
import OKSHeader from './Header';

// Context
import { OKSProvider } from 'contexts/OKS';

// Styles
import { LoadingWrapper } from './styled';

// Utils
import withAndroidCheck from 'utils/withAndroidCheck';

/**
 * Renders the Optimal Keyword Set Page.
 */
function OKSPage() {
  return (
    <Suspense
      fallback={
        <LoadingWrapper container justifyContent="center" alignItems="center">
          <CircularProgress />
        </LoadingWrapper>
      }
    >
      <OKSProvider>
        <OKSMetadata />
        <Divider />
        <OKSHeader />
        <OKSTable />
      </OKSProvider>
    </Suspense>
  );
}

export default withAndroidCheck(OKSPage);
