import React, { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';

// Components
import AppHeader from './AppHeader';

/**
 * @name AppPage
 * @description Displays information the current selected app
 */
function AppPage({ children }: PropsWithChildren) {
  return (
    <React.Fragment>
      <Helmet title="AppPage" />
      <AppHeader />
      {children}
    </React.Fragment>
  );
}

export default AppPage;
