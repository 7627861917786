import React, { lazy } from 'react';
// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from 'layouts/Auth';
import MainLayout from 'layouts/Main';
import PresentationLayout from 'layouts/Presentation';

// Guards
import AuthGuard from 'components/guards/AuthGuard';
import ProjectGuard from 'components/guards/ProjectGuard';

// Contexts
import { AppProvider } from 'contexts/App';
import { LocalesListProvider } from 'contexts/LocalesList';
import { ProjectAppsProvider } from 'contexts/ProjectApps';
import { KeywordTrackingTableProvider } from 'contexts/KeywordTrackingTable';
import KeywordsListsProvider from 'contexts/Keywords/KeywordLists';
import SharingCenterProvider from 'contexts/SharingCenter';

// Wrappers
import ErrorBoundary from 'components/ErrorBoundary';
import LandingGuard from 'components/guards/LandingGuard';
import SuspenseWrapper from 'components/SuspenseWrapper';

// Toaster components
import KeywordTrackingTable from 'pages/app/Application/KeywordTrackingTable';

// Toaster pages - those components are the finalized Toaster pages
import SignUp from 'pages/auth/SignUp';
import ResetPassword from 'pages/auth/ResetPassword';
import Confirmation from 'pages/auth/Confirmation';
import AppPage from 'pages/app/Application';
import CreateProject from 'pages/app/CreateProject';
import ProjectSettings from 'pages/app/ProjectSettings';
import UserSettings from 'pages/app/UserSettings';
import SignInLanding from 'pages/auth/SignIn';
import Page404 from 'pages/auth/Page404';
import OKSPage from 'pages/app/Application/OKS';
import ChangesBacklog from 'pages/app/Application/ChangesBacklog';
import MetadataGeneratorAI from 'pages/app/Application/AI/MetadataGenerator';
import ResearchAI from 'pages/app/Application/AI/Research';
import MetadataGeneratorAIProvider from 'contexts/AI/MetadataGeneratorAI';
const UserDashboard = lazy(() => import('pages/app/Dashboard'));

const routes = [
  {
    path: '/',
    element: <LandingGuard />
  },
  /** start of Toaster **/
  {
    path: 'app',
    children: [
      {
        path: 'sign-in',
        element: (
          <PresentationLayout>
            <ErrorBoundary location="app/sign-in">
              <SignInLanding />
            </ErrorBoundary>
          </PresentationLayout>
        )
      },
      {
        path: 'sign-up',
        element: (
          <AuthLayout>
            <ErrorBoundary location="app/sign-up">
              <SignUp />
            </ErrorBoundary>
          </AuthLayout>
        )
      },
      {
        path: 'account-confirmation',
        element: (
          <AuthLayout>
            <ErrorBoundary location="app/account-confirmation">
              <Confirmation isResetPassword={false} />
            </ErrorBoundary>
          </AuthLayout>
        )
      },
      {
        path: 'reset-password-confirmation',
        element: (
          <AuthLayout>
            <ErrorBoundary location="reset-password-confirmation">
              <Confirmation isResetPassword={true} />
            </ErrorBoundary>
          </AuthLayout>
        )
      },
      {
        path: 'reset-password',
        element: (
          <AuthLayout>
            <ErrorBoundary location="app/reset-password">
              <ResetPassword />
            </ErrorBoundary>
          </AuthLayout>
        )
      },
      {
        path: 'user-settings',
        element: (
          <AuthGuard>
            <SharingCenterProvider>
              <MainLayout>
                <ErrorBoundary location="app/user-settings">
                  <UserSettings />
                </ErrorBoundary>
              </MainLayout>
            </SharingCenterProvider>
          </AuthGuard>
        )
      },
      {
        path: 'dashboard',
        element: (
          <AuthGuard>
            <MainLayout>
              <ProjectGuard />
            </MainLayout>
          </AuthGuard>
        )
      },
      {
        path: 'project/:projectId/app/:appId/platform/:platform',
        element: (
          <AuthGuard>
            <ProjectGuard>
              <LocalesListProvider>
                <KeywordsListsProvider>
                  <AppProvider>
                    <SharingCenterProvider>
                      <MainLayout>
                        <ErrorBoundary location="app/project/:projectId/app/:appId">
                          <AppPage />
                        </ErrorBoundary>
                      </MainLayout>
                    </SharingCenterProvider>
                  </AppProvider>
                </KeywordsListsProvider>
              </LocalesListProvider>
            </ProjectGuard>
          </AuthGuard>
        ),
        children: [
          {
            path: 'keyword-tracking',
            element: (
              <KeywordTrackingTableProvider>
                <KeywordTrackingTable />
              </KeywordTrackingTableProvider>
            )
          },
          {
            path: 'keyword-tracking-ai',
            element: <ResearchAI />
          },
          {
            path: 'optimal-keyword-set',
            element: <OKSPage />
          },
          {
            path: 'optimal-keyword-set-ai',
            element: (
              <MetadataGeneratorAIProvider>
                <MetadataGeneratorAI />
              </MetadataGeneratorAIProvider>
            )
          },
          {
            path: 'changes-backlog',
            element: <ChangesBacklog />
          }
        ]
      },
      {
        path: 'project/:projectId',
        element: (
          <AuthGuard>
            <ProjectGuard>
              <ProjectAppsProvider>
                <SharingCenterProvider>
                  <MainLayout />
                </SharingCenterProvider>
              </ProjectAppsProvider>
            </ProjectGuard>
          </AuthGuard>
        ),
        children: [
          {
            index: true,
            element: (
              <ErrorBoundary location="app/project/:projectId">
                <LocalesListProvider>
                  <SuspenseWrapper>
                    <UserDashboard />
                  </SuspenseWrapper>
                </LocalesListProvider>
              </ErrorBoundary>
            )
          },
          {
            path: 'settings',
            element: (
              <ErrorBoundary location="app/project/:projectId/settings">
                <SuspenseWrapper>
                  <ProjectSettings />
                </SuspenseWrapper>
              </ErrorBoundary>
            )
          }
        ]
      },
      {
        path: 'project/empty',
        element: (
          <MainLayout>
            <SharingCenterProvider>
              <UserDashboard />
            </SharingCenterProvider>
          </MainLayout>
        )
      },
      {
        path: 'project/create',
        element: (
          <AuthGuard>
            <SharingCenterProvider>
              <MainLayout>
                <ErrorBoundary location="app/project/create">
                  <CreateProject />
                </ErrorBoundary>
              </MainLayout>
            </SharingCenterProvider>
          </AuthGuard>
        )
      }
    ]
  },
  /**end of Toaster **/
  {
    path: '*',
    element: <AuthLayout />,
    children: [
      {
        path: '*',
        element: <Page404 />
      }
    ]
  }
];

export default routes;
