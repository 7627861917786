import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

// Fetchers
import { API, CACHE_KEYS, FETCH_STATE } from 'api';

// Constants
import { PLATFORMS } from 'constants/constants';

// Hooks
import useLocalesList from 'hooks/useLocalesList';

// Utils
import { createOptionsFromObj, findAndUnshift } from 'utils/utils';

/**
 * @name useSearchApps
 * @description custom hook to handle search apps api mutation resp
 * @param searchParams {object}
 * @param onParamInput {function}
 * @param initialParams {object}
 * @param projectId {string}
 * */
function useSearchApps(searchParams, onParamInput, initialParams, projectId) {
  // status of options being fetched
  const [optionsStatus, setOptionsStatus] = useState(FETCH_STATE.LOADING);
  // countries dropdown - changes between ios and android
  const [countryOptions, setCountryOptions] = useState([]);
  // locales dropdown - changes between ios and android
  const [localeOptions, setLocaleOptions] = useState([]);
  // supplies all countries and locales for both android and ios
  const {
    iosComboStatus,
    iosCombo,
    iosCountryOptions,
    androidCountriesStatus,
    androidLanguagesStatus,
    androidCountryOptions,
    androidLanguageOptions
  } = useLocalesList();

  const [searchResults, setSearchResults] = useState([]);

  const setIosLangs = () => {
    const langs = createOptionsFromObj(iosCombo[searchParams.country].langs, 'name');

    let unshiftedLangs = langs;
    // for countries !== us and gb, with more than 1 language, put english at the bottom of the options
    if (langs.length > 1 && searchParams.country !== 'US' && searchParams.country !== 'GB') {
      unshiftedLangs = findAndUnshift(langs, (el) => el.value !== 'en_us' && el.value !== 'en_gb');
    }

    setLocaleOptions(unshiftedLangs);
    onParamInput('locale', unshiftedLangs[0].value);
  };

  // set optionsStatus
  useEffect(() => {
    const allStatus = [iosComboStatus, androidCountriesStatus, androidLanguagesStatus];

    const isSuccess = allStatus.every((stat) => stat === FETCH_STATE.SUCCESS);
    if (isSuccess) {
      setOptionsStatus(FETCH_STATE.SUCCESS);
      return;
    }
    const isError = allStatus.some((stat) => stat === FETCH_STATE.ERROR);
    if (isError) {
      setOptionsStatus(FETCH_STATE.ERROR);
      return;
    }
    const isLoading = allStatus.some((stat) => stat === FETCH_STATE.LOADING);
    if (isLoading) {
      setOptionsStatus(FETCH_STATE.LOADING);
    }
  }, [iosComboStatus, androidCountriesStatus, androidLanguagesStatus]);

  // on platform change
  useEffect(() => {
    if (optionsStatus !== FETCH_STATE.SUCCESS) return;
    onParamInput('country', initialParams.country);
    // when platform === android set countries and languages
    if (searchParams.platform === PLATFORMS.ANDROID) {
      setCountryOptions(androidCountryOptions);
      setLocaleOptions(androidLanguageOptions);
      onParamInput('locale', initialParams.locale);
    }
    // when platform === ios set countries
    if (
      searchParams.platform !== PLATFORMS.ANDROID &&
      iosCountryOptions &&
      iosCombo[searchParams.country]
    ) {
      setCountryOptions(iosCountryOptions);
      setIosLangs();
    }
    // eslint-disable-next-line
  }, [searchParams.platform, optionsStatus, iosCountryOptions]);

  // on country change
  useEffect(() => {
    if (optionsStatus !== FETCH_STATE.SUCCESS) return;
    // if platform is android no need to re-set language options
    if (searchParams.platform !== PLATFORMS.ANDROID && iosCombo[searchParams.country]) {
      setIosLangs();
    }
    // eslint-disable-next-line
  }, [searchParams.country, iosCountryOptions]);

  const {
    status: searchAppsStatus,
    mutate: onSubmitSearch,
    reset: resetSearch
  } = useMutation(
    [CACHE_KEYS.APPS_SEARCH_AUTOCOMPLETE],
    () => API.appSearch({ ...searchParams, projectId }),
    {
      onSuccess: (res) => {
        setSearchResults(res.data);
      }
    }
  );

  return {
    searchAppsStatus,
    onSubmitSearch,
    searchResults,
    resetSearch,
    countryOptions,
    localeOptions,
    optionsStatus
  };
}

export default useSearchApps;
