import { useTranslation } from 'react-i18next';

// Hooks
import useApp from 'hooks/useApp';

/**
 * Composes a file name from the app details for CSV export
 * @param name - File name
 */
function useCSVFileName(name: string) {
  const { app } = useApp();
  const { t } = useTranslation('components', {
    keyPrefix: 'KeywordTrackingTable.KeywordTrackingTableToolbar'
  });

  const date = new Date();

  if (app) {
    const fileName = `${name}_global_id_${app.global_id}_id_${app.id}_${app.platform}_${
      app.country
    }_${app.locale}_${app.title}_${t('priority')}_${app.tier}_${date.toISOString()}`;

    return fileName;
  }
}

export default useCSVFileName;
