import styled from '@emotion/styled';
import MuiGrid from '@mui/material/Grid';
import MuiDivider from '@mui/material/Divider';

export const Divider = styled(MuiDivider)`
  background-color: white;
  margin-left: 32px;
  margin-right: 28px;
  border-bottom-width: 0.05px;
  border-color: rgba(0, 0, 0, 0.3);
`;

export const MenuWrapper = styled(MuiGrid)`
  position: absolute;
  left: 240px;
  width: 240px;
  top: -50px;
  background-color: ${({ theme }) => theme.sidebar.project};
  color: white;
`;
