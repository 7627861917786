import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

// Styles
import { Alert, TextField, Form } from 'pages/auth/styled';

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().max(255).email('common:email_valid').required('common:email_required')
});

interface Props {
  handleOnSubmit: (values: { email: string }) => void;
}

/**
 * @name ResetPassword
 * @description This is a ResetPassword form
 * @param  {object} props
 * @param  {function} props.handleOnSubmit
 */
function ResetPassword({ handleOnSubmit }: Props) {
  // We do not specify namespace and keyPrefix, as we need also to translate schema. It does not work otherwise.
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { handleSubmit, errors, values, touched, handleBlur, handleChange, isSubmitting } =
    useFormik({
      initialValues: {
        email: '',
        submit: false
      },
      validationSchema: ResetPasswordSchema,
      onSubmit: handleOnSubmit
    });

  return (
    <Form noValidate onSubmit={handleSubmit}>
      {errors.submit && (
        <Alert mt={2} mb={1} severity="warning">
          {errors.submit}
        </Alert>
      )}
      <Grid container>
        <Grid item xs={12} my={4}>
          <TextField
            type="email"
            name="email"
            label={t('common:email_address')}
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && t(errors.email)}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} my={2}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {t('common:reset_password')}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
}

export default ResetPassword;
