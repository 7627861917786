import apiError from 'api/api-error';
import { buildUrl, removeEmptyValues } from 'utils/utils';
import { ApiInvalidPayload } from './api-error/errors';
import { api, API_URL, AUTH_URL } from './config';
import { API_URLS, AUTH_URLS } from './constants';

//////// API FUNCTIONS ////////
/**
 * @name getApi
 * @description Reusable function for calling GET API
 * @param  {string} endpoint API endpoint
 * @param  {object} params query params
 * @param  {object} options
 * @param  {boolean} options.withAuthorization
 */
export const getApi = async (
  endpoint,
  query = {},
  options = {
    withAuthorization: true
  }
) => {
  try {
    api.interceptors.request.use((config) => {
      return config;
    });

    const response = await api.request(endpoint, {
      method: 'GET',
      params: {
        ...query
      },
      withAuthorization: options.withAuthorization
    });

    return response.data;
  } catch (error) {
    return apiError(error);
  }
};

/**
 * Reusable function for calling POST API
 * @param  {string} endpoint API endpoint
 * @param  {object} body
 * @param  {object} options
 * @param  {boolean} options.withAuthorization
 */
export const postApi = async (
  endpoint,
  body,
  options = {
    withAuthorization: true
  },
  method = 'POST'
) => {
  try {
    const response = await api.request(endpoint, {
      method,
      data: {
        ...body
      },
      withAuthorization: options.withAuthorization
    });

    return response.data;
  } catch (error) {
    apiError(error);
  }
};

/**
 * Reusable function for calling PUT API
 * @param  {string} endpoint API endpoint
 * @param  {object} body
 * @param  {object} options
 * @param  {boolean} options.withAuthorization
 */
export const putApi = async (
  endpoint,
  body,
  options = {
    withAuthorization: true
  },
  method = 'PUT'
) => {
  try {
    const response = await api.request(endpoint, {
      method,
      data: {
        ...body
      },
      withAuthorization: options.withAuthorization
    });

    return response.data;
  } catch (error) {
    apiError(error);
  }
};

/**
 * Reusable function for calling DELETE API
 * @param  {string} endpoint API endpoint
 * @param  {object} body
 * @param  {object} options
 * @param  {boolean} options.withAuthorization
 */
export const deleteApi = async (
  endpoint,
  body,
  options = {
    withAuthorization: true
  },
  method = 'DELETE'
) => {
  try {
    const response = await api.request(endpoint, {
      method,
      data: {
        ...body
      },
      withAuthorization: options.withAuthorization
    });

    return response.data;
  } catch (error) {
    apiError(error);
  }
};

/**
 * Reusable function for calling PATCH API
 * @param  {string} endpoint API endpoint
 * @param  {object} body
 * @param  {object} options
 * @param  {boolean} options.withAuthorization
 */
export const patchApi = async (
  endpoint,
  body,
  options = {
    withAuthorization: true
  }
) => {
  try {
    const response = await api.request(endpoint, {
      method: 'PATCH',
      data: {
        ...body
      },
      withAuthorization: options.withAuthorization
    });

    return response.data;
  } catch (error) {
    apiError(error);
  }
};

//////// USER ////////
/**
 * @param  {object} data post body
 * @param  {string} data.username
 * @param  {string} data.email
 * @param  {string} data.password
 */
export const userCreate = async ({ data }) => {
  if (!(data.username && data.email && data.password)) {
    throw new ApiInvalidPayload();
  }
  const response = await postApi(
    `${API_URL}/${API_URLS.USERS}`,
    {
      username: data.username,
      email: data.email,
      password: data.password
    },
    {
      withAuthorization: false
    }
  );

  return response;
};

/**
 * @param  {object} params query params
 * @param  {string} params.email
 */
export const userResetPassword = async ({ params }) => {
  if (!params.email) {
    throw new ApiInvalidPayload();
  }
  const response = await getApi(
    `${API_URL}/${API_URLS.USER_RESET_PASSWORD}`,
    {
      email: params.email
    },
    { withAuthorization: false }
  );

  return response;
};

/**
 * @param  {object} data
 * @param  {'GOOGLE' | 'LOCAL' } data.type decides where the login should be sent
 * @param  {{username: string,  password: string} | {credential: string}} data.data post body
 */
export const userSignIn = async ({ type, data }) => {
  let response;
  if (type === 'GOOGLE') {
    if (!data.credential) {
      throw new ApiInvalidPayload();
    }
    // TODO: Change payload structure
    response = await postApi(
      `${AUTH_URL}/${AUTH_URLS.AUTH_SIGN_IN_GOOGLE}`,
      {
        credential: data.credential
      },
      { withAuthorization: false }
    );
  } else {
    if (!(data.username && data.password)) {
      throw new ApiInvalidPayload();
    }
    // TODO: Change payload structure
    response = await postApi(
      `${AUTH_URL}/${AUTH_URLS.AUTH_SIGN_IN}`,
      {
        username: data.username,
        password: data.password
      },
      { withAuthorization: false }
    );
  }
  return response;
};

/**
 * @param  {{verificationCode: string, verificationType: string}} query query params
 */
export const userVerifyEmail = async ({ verificationCode, verificationType }) =>
  await getApi(
    `${API_URL}/${API_URLS.USER_VERIFY_EMAIL}`,
    {
      verification_code: verificationCode,
      verification_type: verificationType
    },
    { withAuthorization: false }
  );

/**
 * @param  {{verificationCode: string, verificationType: string, password: string, confirmPassword: string }} request body
 */
export const userVerifyEmailForPasswordReset = async ({
  verificationCode,
  verificationType,
  password,
  confirmPassword
}) =>
  await postApi(
    `${API_URL}/${API_URLS.USER_VERIFY_EMAIL}`,
    {
      verification_code: verificationCode,
      verification_type: verificationType,
      new_password: password,
      new_password_confirmation: confirmPassword
    },
    { withAuthorization: false }
  );

export const userCurrent = async () => await getApi(`${API_URL}/${API_URLS.USER_CURRENT}`);

/**
 * @param  {{refreshToken: string}} post body
 */
export const userRefreshToken = async ({ refreshToken }) => {
  return await postApi(
    `${AUTH_URL}/${AUTH_URLS.AUTH_TOKEN_REFRESH}`,
    {
      refresh: refreshToken
    },
    { withAuthorization: false }
  );
};

/**
 * @description edit user with the provided data
 * @param  {{username: String, currentPassword: String, password: String}} data post body
 */
export const editUser = async ({ username, currentPassword, password }) => {
  if (!(username || currentPassword)) {
    throw new ApiInvalidPayload();
  }
  // User can't change currentPassword without providing new one
  if (currentPassword && !password) {
    throw new ApiInvalidPayload();
  }
  const response = await patchApi(`${API_URL}/${API_URLS.USER_CURRENT}`, {
    username,
    password,
    current_password: currentPassword
  });

  return response;
};

//////// PROJECT ////////
/**
 * @description creates new project for authenticated user
 * @param  {object} data post body
 * @param  {{name: string, reporting: boolean, isPrivate: boolean, AI: boolean}} data post body
 */
export const projectCreate = async ({ name, reporting, isPrivate, AI }) => {
  if (!name) {
    throw new ApiInvalidPayload();
  }
  const response = await postApi(
    `${API_URL}/${API_URLS.PROJECTS}`,
    {
      name: name,
      reporting: reporting,
      is_private: isPrivate,
      ai_project: AI
    },
    { withAuthorization: true }
  );

  return response;
};

/**
 * @description returns a project based on the provided id
 * @param  {{projectId: String}} query query params
 */
export const getProject = async ({ projectId }) =>
  await getApi(buildUrl(`${API_URL}/${API_URLS.PROJECT}/`, { projectId }));

/**
 * @description returns all projects for the authenticated user
 * @param  {{projectId: String}} query query params
 */
export const listProjects = async () => await getApi(`${API_URL}/${API_URLS.PROJECTS}/`);

/**
 * @description edit project with the provided data
 * @param  {{projectId: String}} query query params
 * @param  {{projectOwner: String, projectName: String, projectReporting: Boolean}} data post body
 */
export const editProject = async (
  { projectId },
  { projectOwner, projectName, projectReporting, projectIsPrivate }
) => {
  if (!projectId) {
    throw new ApiInvalidPayload();
  }
  const response = await patchApi(buildUrl(`${API_URL}/${API_URLS.PROJECT}/`, { projectId }), {
    name: projectName,
    email: projectOwner,
    reporting: projectReporting,
    is_private: projectIsPrivate
  });

  return response;
};

/**
 * @description shares project with the provided user email
 * @param  {{projectId: String}} query query params
 * @param  {{email: string, roleId: integer}} data post body
 */
export const shareProject = async ({ projectId }, { userEmail, userRoleId }) => {
  if (!(projectId && userEmail && userRoleId)) {
    throw new ApiInvalidPayload();
  }
  const response = await postApi(buildUrl(`${API_URL}/${API_URLS.SHARE_PROJECT}/`, { projectId }), {
    email: userEmail,
    role_id: userRoleId
  });

  return response;
};

/**
 * @description updates user type within the project
 * @param  {{projectId: String, userId: String}} query query params
 * @param  {{roleId: integer}} data put body
 */
export const updateSharedUser = async ({ projectId, userId }, { userRoleId }) => {
  if (!(projectId && userId && userRoleId)) {
    throw new ApiInvalidPayload();
  }
  const response = await putApi(
    buildUrl(`${API_URL}/${API_URLS.EDIT_SHARE_USER}/`, { projectId, userId }),
    {
      role_id: userRoleId
    }
  );

  return response;
};

/**
 * @description deletes user type within the project
 * @param  {{projectId: String, userId: String}} query query params
 */
export const deleteSharedUser = async ({ projectId, userId }) => {
  if (!(projectId && userId)) {
    throw new ApiInvalidPayload();
  }
  const response = await deleteApi(
    buildUrl(`${API_URL}/${API_URLS.REMOVE_SHARE_USER}/`, { projectId, userId })
  );

  return response;
};

/**
 * @description returns all projects.
 * Only accessible for Phiturians - users with `@phiture.com` email
 */
export const getProjectsAccess = async () => await getApi(`${API_URL}/${API_URLS.PROJECTS_ACCESS}`);

/**
 * @description joins provided project
 * Only accessible for Phiturians - users with `@phiture.com` email
 * @param  {{projectId: String}} post body
 */
export const joinProjectsAccess = async ({ projectId }) => {
  return await postApi(`${API_URL}/${API_URLS.PROJECTS_ACCESS}`, {
    project_id: projectId
  });
};

//////// PROJECT APPS ////////
/**
 * @description returns all apps tracked in a project
 * @param  {{projectId: string}} query query params
 */
export const listProjectApps = async ({ projectId }) =>
  await getApi(`${API_URL}/${API_URLS.PROJECTS}${projectId}/apps/`);

/**
 * @description returns app details
 * @param  {{projectId: String, appId: String, platform: String}} query query params
 */
export const getProjectApp = async ({ projectId, appId, platform }) =>
  await getApi(buildUrl(`${API_URL}/${API_URLS.APP}`, { projectId, appId, platform }));
/**
 * @description creates app note
 * @param  {{projectId: String, appId: String, note: String}} post body
 */
export const createProjectAppNote = async ({ projectId, appId, platform, note }) => {
  return await postApi(buildUrl(`${API_URL}/${API_URLS.NOTES}`, { projectId, appId, platform }), {
    note
  });
};

/**
 * @description get app note
 * @param  {{projectId: String, appId: String, platform: String}}
 */
export const getProjectAppNote = async ({ projectId, appId, platform }) => {
  return await getApi(buildUrl(`${API_URL}/${API_URLS.NOTES}`, { projectId, appId, platform }));
};

/**
 * @description updates app note
 * @param  {{projectId: String, appId: String, noteId: String, note: String}} post body
 */
export const updateProjectAppNote = async ({ projectId, appId, noteId, platform, note }) => {
  return await patchApi(
    buildUrl(`${API_URL}/${API_URLS.NOTE}`, { projectId, appId, platform, noteId }),
    {
      note
    }
  );
};

/**
 * @description adds app to project
 * @param  {{projectId: String, locale: String, country: String, app: Number, platform: String}} post body
 */
export const addAppToProject = async ({ projectId, locale, country, app, platform }) => {
  return await postApi(buildUrl(`${API_URL}/${API_URLS.APPS}`, { projectId }), {
    locale,
    country,
    app,
    platform
  });
};

/**
 * Updates the tier (priority) of an app in a project
 *
 * @param projectId  Project ID
 * @param appId      App ID
 * @param platform   App platform
 * @param tier       Priority tier
 */
export const updateAppTier = async ({ projectId, appId, platform, tier }) => {
  return await postApi(
    buildUrl(`${API_URL}/${API_URLS.APP_TIER}`, { projectId, appId, platform }),
    { tier }
  );
};

//////// PROJECT APPS SEARCH ////////
/**
 * @description searches for an specific app
 * @param  {{search: String, locale: String, country: String, platform: String, projectId: String}} query query params, all required
 */
export const appSearch = async ({ searchId, platform, country, locale, projectId }) => {
  if (!(searchId && platform && country && locale)) {
    throw new ApiInvalidPayload();
  }
  return await getApi(buildUrl(`${API_URL}/${API_URLS.APPS_SEARCH}`, { projectId }), {
    search: searchId,
    platform,
    country,
    locale
  });
};

/**
 * Links two apps from different platforms
 *
 * @param projectId         Project ID
 * @param appId             App ID
 * @param platform          App platform
 * @param newAppId          New app ID
 * @param applePlatform     Apple platform
 */
export const linkApps = async ({ projectId, appId, platform, newAppId, applePlatform }) => {
  return await postApi(
    buildUrl(`${API_URL}/${API_URLS.LINK_APPS}`, { projectId, appId, platform }),
    {
      app_id: newAppId,
      apple_platform: applePlatform
    }
  );
};

//////// COUNTRIES AND LANGUAGES ////////
/**
 * @description get array of countries and respective locales
 * @param {{projectId: String}}
 */
export const countryLangCombo = async ({ projectId }) => {
  return await getApi(buildUrl(`${API_URL}/${API_URLS.APPS_COUNTRY_LANG_COMBO}`, { projectId }));
};

/**
 * @description get array of countries
 * @param {{projectId: String}}
 */
export const countries = async ({ projectId }) => {
  return await getApi(buildUrl(`${API_URL}/${API_URLS.APPS_COUNTRIES}`, { projectId }));
};

/**
 * @description get array of languages
 * @param {{projectId: String}}
 */
export const languages = async ({ projectId }) => {
  return await getApi(buildUrl(`${API_URL}/${API_URLS.APPS_LANGUAGES}`, { projectId }));
};

////////// KEYWORD LIST ////////

/**
 * @description fetch keyword lists
 * @param  {{projectId: Number, appId: Number, platform: String}}
 * (platform is one of iphone, ipad, android)
 */
export const getKeywordLists = async ({ projectId, appId, platform }) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.KEYWORD_LISTS}`, { projectId, appId, platform })
  );
};

/**
 * @description create keyword list
 * @param  {{projectId: Number, appId: Number, platform: String, listName: String}}
 * (platform is one of iphone, ipad, android)
 */

export const createKeywordList = async ({ projectId, appId, platform, listName }) => {
  return await postApi(
    buildUrl(`${API_URL}/${API_URLS.KEYWORD_LISTS}`, { projectId, appId, platform }),
    {
      keyword_list_name: listName
    }
  );
};

/**
 * @description update keyword list
 * @param  {{projectId: Number, appId: Number, platform: String, keywordListId: Number, newListName: String}}
 * (platform is one of iphone, ipad, android)
 */
export const updateKeywordList = async ({
  projectId,
  appId,
  platform,
  keywordListId,
  newListName
}) => {
  return await patchApi(
    buildUrl(`${API_URL}/${API_URLS.KEYWORD_LISTS}`, { projectId, appId, platform }),
    {
      keyword_list_id: keywordListId,
      keyword_list_name: newListName
    }
  );
};

/**
 * @description delete keyword list
 * @param  {{projectId: Number, appId: Number, platform: String, keywordListId: Number}}
 * (platform is one of iphone, ipad, android)
 */
export const deleteKeywordList = async ({ projectId, appId, platform, keywordListId }) => {
  return await deleteApi(
    buildUrl(`${API_URL}/${API_URLS.KEYWORD_LISTS}`, { projectId, appId, platform }),
    {
      keyword_list_id: keywordListId
    }
  );
};

//////// APP KEYWORDS ////////
/**
 * @description adds keyword(s) to app's one or more keyword lists. if addIndividualTerms true > if keyword is a string made up
 * of more than one word, it will also add the words individually
 * for example 'this is a long keyword' will also add 'this' 'is' 'a' 'long' 'keyword'
 * @param {{projectId: String, appId: String, platform: String,keywords: String, keywordListIds: String, addIndividualTerms: Boolean  }} path params
 */
export const addKeywordsToAppKeywordLists = async ({
  projectId,
  appId,
  platform,
  keywords,
  keywordListIds,
  addIndividualTerms = false
}) => {
  return await postApi(
    buildUrl(`${API_URL}/${API_URLS.KEYWORDS_ADD}`, { projectId, appId, platform }),
    {
      keywords,
      keyword_list_ids: keywordListIds,
      should_split_long_tail: addIndividualTerms
    }
  );
};

/**
 * @description gets app keywords
 * @param {{projectId: String, appId: String, platform: String, listId: Number,  iosVersion: Number  }} path params
 *
 * This api fetches keyword main attributes
 */
export const getAppKeywords = async ({ projectId, appId, platform, iosVersion, listId }) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.KEYWORDS_GET}`, {
      projectId,
      appId,
      platform,
      keywordListId: listId
    }),
    {
      ios_version: iosVersion
    }
  );
};

/**
 * @description gets app keywords rank
 * @param {{projectId: String, appId: String, platform: String, listId: Number, iosVersion: Number }} path params
 *
 * This api fetches keyword rank related attributes
 */
export const getAppKeywordsRank = async ({ projectId, appId, platform, iosVersion, listId }) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.KEYWORDS_GET_RANK}`, {
      projectId,
      appId,
      platform,
      keywordListId: listId
    }),
    {
      ios_version: iosVersion
    }
  );
};

/**
 * @description gets app keywords rank delta between two dates
 * @param {{projectId: String, appId: String, platform: String, listId: Number }} path params
 * @param {{ keyword_ids?: String, start: String, end: String }} query params (optional)
 */

export const getAppKeywordsRankChange = async ({
  projectId,
  appId,
  platform,
  listId,
  keywordIds,
  start,
  end
}) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.KEYWORDS_GET_RANK_CHANGE}`, {
      projectId,
      appId,
      platform,
      keywordListId: listId
    }),
    {
      keywords_ids: keywordIds,
      start,
      end
    }
  );
};

/**
 * @description gets app keywords popularity
 * @param {{projectId: String, appId: String, platform: String, listId: Number }} path params
 * @param {{ iosVersion: Number }} query params
 *
 * This api fetches keyword popularity related attributes
 */
export const getAppKeywordsPopularity = async ({
  projectId,
  appId,
  platform,
  iosVersion,
  listId
}) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.KEYWORDS_GET_POPULARITY}`, {
      projectId,
      appId,
      platform,
      keywordListId: listId
    }),
    {
      ios_version: iosVersion
    }
  );
};

/**
 * @description gets app keywords popularity delta between two dates
 * @param {{projectId: String, appId: String, platform: String, listId: Number }} path params
 * @param {{ keyword_ids?: String, start: String, end: String }} query params (optional)
 */

export const getAppKeywordsPopularityChange = async ({
  projectId,
  appId,
  platform,
  listId,
  start,
  end,
  keywordIds
}) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.KEYWORDS_GET_POPULARITY_CHANGE}`, {
      projectId,
      appId,
      platform,
      keywordListId: listId
    }),
    {
      keyword_ids: keywordIds,
      start,
      end
    }
  );
};

/**
 * @description gets app keywords total-apps
 * @param {{projectId: String, appId: String, platform: String, listId: Number }} path params
 * @param {{ iosVersion: Number }} query params
 *
 * This api fetches keyword total-apps related attributes
 */
export const getAppKeywordsTotalApps = async ({
  projectId,
  appId,
  platform,
  iosVersion,
  listId
}) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.KEYWORDS_GET_TOTAL_APPS}`, {
      projectId,
      appId,
      platform,
      keywordListId: listId
    }),
    {
      ios_version: iosVersion
    }
  );
};

/**
 * @description gets app keywords downloads
 * @param {{projectId: String, appId: String, platform: String, listId: Number }} path params
 * @param {{ iosVersion: Number }} query params
 *
 * This api fetches keyword downloads related attributes
 */
export const getAppKeywordsDownloads = async ({
  projectId,
  appId,
  platform,
  iosVersion,
  listId
}) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.KEYWORDS_GET_DOWNLOADS}`, {
      projectId,
      appId,
      platform,
      keywordListId: listId
    }),
    {
      ios_version: iosVersion
    }
  );
};

/**
 * @description updated keywords type and relevancy
 * @param {{ projectId: Number, appId: Number, platform: String
 *           currentListId: Number, type?: Number, relevancy?: Number, keywordIds: String }}
 */
export const updateAppKeywordsAttributes = async ({
  projectId,
  appId,
  platform,
  currentListId,
  type,
  relevancy,
  keywordIds
}) => {
  return await postApi(
    buildUrl(`${API_URL}/${API_URLS.KEYWORDS_UPDATE}`, { projectId, appId, platform }),
    {
      keyword_ids: keywordIds,
      keyword_list_id: currentListId,
      type_id: type,
      relevancy_id: relevancy
    }
  );
};

/**
 * @description deleted keywords from app
 * @param {{projectId: Number, appId: Number, platform: String, currentListId: Number, keywordIds: String }}
 */

export const deleteAppKeywordsFromList = async ({
  projectId,
  appId,
  platform,
  currentListId,
  keywordIds
}) => {
  return await deleteApi(
    buildUrl(`${API_URL}/${API_URLS.KEYWORDS_DELETE}`, { projectId, appId, platform }),
    {
      keyword_ids: keywordIds,
      keyword_list_id: currentListId
    }
  );
};

/**
 * @description gets keywords combinations for give list
 * @param {{projectId, appId, platform, words, nbCombination }} params
 *
 * This api fetches keyword downloads related attributes
 */
export const getKeywordsCombinations = async ({
  projectId,
  appId,
  platform,
  words,
  nbCombination
}) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.KEYWORDS_SUGGESTIONS}`, {
      projectId,
      appId,
      platform
    }),
    {
      words,
      nb_combination: nbCombination
    }
  );
};

/**
 * @description gets keyword rank history
 * @param {{projectId: String, appId: String, platform: String, kwId:Number, start: String, end: String }}
 *
 * This api fetches keyword main attributes
 */
export const getKeywordRankHistory = async ({ projectId, appId, platform, kwId, start, end }) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.KEYWORD_RANK_HISTORY}`, {
      projectId,
      appId,
      platform,
      kwId
    }),
    {
      start,
      end
    }
  );
};

/**
 * @description gets keyword popularity history
 * @param {{projectId: String, appId: String, platform: String, kwId:Number, start: String, end: String }}
 *
 * This api fetches keyword main attributes
 */
export const getKeywordPopularityHistory = async ({
  projectId,
  appId,
  platform,
  kwId,
  start,
  end
}) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.KEYWORD_POPULARITY_HISTORY}`, {
      projectId,
      appId,
      platform,
      kwId
    }),
    {
      start,
      end
    }
  );
};

/**
 * Returns the list of selected competitors
 *
 * @param projectId     Project id
 * @param appId         App id
 * @param platform      App platform
 */
export const getCompetitors = async ({ projectId, appId, platform }) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.COMPETITORS}`, { projectId, appId, platform })
  );
};

/**
 * Returns a list of potential competitors
 *
 * @param projectId     Project id
 * @param appId         App id
 * @param platform      App platform
 */
export const getCompetitorsSuggestions = async ({ projectId, appId, platform }) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.COMPETITORS_SUGGESTIONS}`, { projectId, appId, platform })
  );
};

/**
 * Adds/tracks a competitor for an app
 *
 * @param projectId     Project id
 * @param appId         App id
 * @param platform      App platform
 * @param competitorId  Id of the competitor to track
 * */
export const addCompetitor = async ({ projectId, appId, platform, competitorId }) => {
  return await postApi(
    buildUrl(`${API_URL}/${API_URLS.COMPETITORS}`, { projectId, appId, platform }),
    {
      competitor_id: competitorId
    }
  );
};

/**
 * Fetches the ranking of a competitor app for the given keywords
 *
 * @param projectId         Project id
 * @param appId             App id
 * @param platform          App platform
 * @param keywordIds        Array of keyword ids
 * @param keywordStrings    Array of keywords
 */
export const getCompetitorKeywordsRanking = async ({
  projectId,
  appId,
  platform,
  keywordIds,
  keywordStrings
}) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.COMPETITORS_KEYWORDS_RANK}`, { projectId, appId, platform }),
    {
      keywords_ids: keywordIds,
      keywords_str: keywordStrings
    }
  );
};

/**
 * Returns the OKS Metadata for the app
 *
 * @param projectId     Project id
 * @param appId         App id
 * @param platform      App platform
 */
export const getOKSMetadata = async ({ projectId, appId, platform }) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.OKS_METADATA}`, { projectId, appId, platform })
  );
};

export const updateOKSMetadata = async ({ projectId, appId, platform, data }) => {
  return await postApi(
    buildUrl(`${API_URL}/${API_URLS.OKS_METADATA}`, { projectId, appId, platform }),
    { ...data }
  );
};

export const getOKSKeywords = async ({ projectId, appId, platform, iosVersion, listId }) => {
  return await postApi(
    buildUrl(`${API_URL}/${API_URLS.OKS_GENERATOR}`, {
      projectId,
      appId,
      platform,
      keywordListId: listId
    }),
    {
      ios_version: iosVersion
    }
  );
};

/**
 * Fetches untracked ranked keywords for an app
 *
 * @param projectId   Project id
 * @param appId       App id
 * @param platform    App platofm
 * @param listId      Keyword list id
 */
export const getUntrackedRankedKeywords = async ({ projectId, appId, platform, listId }) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.KEYWORDS_GET_UNTRACKED_RANKED}`, {
      projectId,
      appId,
      platform,
      keywordListId: listId
    })
  );
};

/**
 * Fetches experiments for an app
 * @param projectId
 * @param appId
 * @param platform
 */
export const getChangesBacklogExperiments = async ({ projectId, appId, platform }) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.CHANGES_BACKLOG}`, { projectId, appId, platform })
  );
};

/**
 * Creates an experiment for an app
 *
 * @param projectId   Project id
 * @param appId       App id
 * @param platform    App platform
 * @param name        Experiment name
 * @param date        Experiment date
 */
export const createChangesBacklogExperiment = async ({
  projectId,
  appId,
  platform,
  name,
  date
}) => {
  return await postApi(
    buildUrl(`${API_URL}/${API_URLS.CHANGES_BACKLOG}`, { projectId, appId, platform }),
    { name, date }
  );
};

/**
 * Updates name for an experiment
 *
 * @param projectId     Project id
 * @param appId         App id
 * @param platform      App platform
 * @param name          Experiment name
 * @param experimentId  Experiment id
 */
export const updateChangesBacklogExperimentName = async ({
  projectId,
  appId,
  platform,
  date,
  experimentId,
  name
}) => {
  return await patchApi(
    buildUrl(`${API_URL}/${API_URLS.CHANGES_BACKLOG_EXPERIMENT}`, {
      projectId,
      appId,
      platform,
      experimentId
    }),
    { date, name }
  );
};

/**
 * Fetches backlog of an experiment
 *
 * @param projectId
 * @param appId
 * @param platform
 * @param experimentId
 */
export const getExperimentBacklog = async ({ projectId, appId, platform, experimentId }) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.EXPERIMENT_BACKLOG}`, {
      projectId,
      appId,
      platform,
      experimentId
    })
  );
};

/**
 * Creates a backlog for an experiment
 *
 * @param projectId     Project id
 * @param appId         App id
 * @param platform      App platform
 * @param name          Experiment name
 * @param experimentId  Experiment id
 * @param status        Status
 * @param assetType     Asset type
 * @param current       Current value
 * @param comment       Comment
 * @param language      Language
 * @param previous      Previous value
 *
 */
export const createExperimentBacklog = async ({
  projectId,
  appId,
  platform,
  experimentId,
  status,
  assetType,
  current,
  comment,
  language,
  previous
}) => {
  return await postApi(
    buildUrl(`${API_URL}/${API_URLS.EXPERIMENT_BACKLOG}`, {
      projectId,
      appId,
      platform,
      experimentId,
      previous
    }),
    {
      status,
      asset_type: assetType,
      current_value: current,
      comment,
      language,
      previous_value: previous
    }
  );
};

/**
 * Deletes a backlog for an experiment
 *
 * @param projectId     Project id
 * @param appId         App id
 * @param platform      App platform
 * @param experimentId  Experiment id
 *
 */
export const deleteExperimentBacklog = async ({
  projectId,
  appId,
  platform,
  experimentId,
  backlogId
}) => {
  return await deleteApi(
    buildUrl(`${API_URL}/${API_URLS.EXPERIMENT_BACKLOG_ITEM}`, {
      projectId,
      appId,
      platform,
      experimentId,
      backlogId
    })
  );
};

/**
 * Deletes a backlog for an experiment
 *
 * @param projectId     Project id
 * @param appId         App id
 * @param platform      App platform
 * @param experimentId  Experiment id
 *
 */
export const updateExperimentBacklog = async ({
  projectId,
  appId,
  platform,
  experimentId,
  backlogId,
  data
}) => {
  return await patchApi(
    buildUrl(`${API_URL}/${API_URLS.EXPERIMENT_BACKLOG_ITEM}`, {
      projectId,
      appId,
      platform,
      experimentId,
      backlogId
    }),
    { ...data }
  );
};

export const generateAIResearchKeywords = async ({ projectId, appId, platform, model }) => {
  return await postApi(
    buildUrl(`${API_URL}/${API_URLS.AI_RESEARCH_KEYWORDS}`, {
      projectId,
      appId,
      platform
    }),
    {
      model
    }
  );
};

export const getAIKeywordLists = async ({ projectId, appId, platform }) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.AI_KEYWORD_LISTS}`, {
      projectId,
      appId,
      platform
    })
  );
};

export const getAIKeywordListById = async ({ projectId, appId, platform, generationId }) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.AI_KEYWORD_LIST}`, {
      projectId,
      appId,
      platform,
      generationId
    })
  );
};

export const getAIKeywordListsToReview = async ({ projectId, appId, platform }) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.AI_REVIEW_LISTS}`, {
      projectId,
      appId,
      platform
    })
  );
};

export const getAIKeywordListByIdToReview = async ({
  projectId,
  appId,
  platform,
  reviewListId
}) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.AI_REVIEW_LISTS_BY_ID}`, {
      projectId,
      appId,
      platform,
      reviewListId
    })
  );
};

export const validateAIReviewList = async ({ projectId, appId, platform, reviewListId }) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.AI_VALIDATE_REVIEW_LISTS_BY_ID}`, {
      projectId,
      appId,
      platform,
      reviewListId
    })
  );
};

export const getAIMainList = async ({ projectId, appId, platform }) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.AI_MAIN_LIST}`, {
      projectId,
      appId,
      platform
    })
  );
};

// AI Assets Generator
export const getAIAssetsGenerated = async ({ projectId, appId, platform }) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.AI_ASSETS_GENERATED}`, {
      projectId,
      appId,
      platform
    })
  );
};

export const generateAIAssets = async ({ projectId, appId, platform, promptId, keywordsData }) => {
  return await postApi(
    buildUrl(`${API_URL}/${API_URLS.AI_ASSETS_GENERATOR}`, {
      projectId,
      appId,
      platform
    }),
    {
      prompt_id: promptId,
      keyword_data: keywordsData
    }
  );
};

export const postAIAssetsGeneratorRecalculate = async ({
  projectId,
  appId,
  platform,
  updatedOption
}) => {
  return await postApi(
    buildUrl(`${API_URL}/${API_URLS.AI_ASSETS_GENERATOR_RECALCULATE}`, {
      projectId,
      appId,
      platform,
      optionId: updatedOption.id
    }),
    { title: updatedOption.title, subtitle: updatedOption.subtitle }
  );
};

export const getAppDescription = async ({ projectId, appId, platform }) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.APP_DESCRIPTION}`, {
      projectId,
      appId,
      platform
    })
  );
};

export const getAIKeywordsData = async ({ projectId, appId, platform }) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.AI_KEYWORDS_DATA}`, {
      projectId,
      appId,
      platform
    })
  );
};

export const getPrompts = async ({ projectId, appId, platform, promptType }) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.PROMPTS}`, {
      projectId,
      appId,
      platform
    }),
    {
      'generation-type': promptType
    }
  );
};

export const createPrompt = async ({ projectId, appId, platform, promptType, prompt }) => {
  return await postApi(
    buildUrl(`${API_URL}/${API_URLS.PROMPTS}`, {
      projectId,
      appId,
      platform
    }),
    {
      generation_type: promptType,
      prompt
    }
  );
};

/**
 * Fetches status of AI Review Lists
 *
 * @param projectId
 * @param appId
 * @param platform
 * @returns {Promise<any|Error|undefined>}
 */
export const getAIReviewListsStatus = async ({ projectId, appId, platform }) => {
  return await getApi(
    buildUrl(`${API_URL}/${API_URLS.AI_REVIEW_LISTS_STATUS}`, {
      projectId,
      appId,
      platform
    })
  );
};

export const generateAIOKS = async ({ projectId, appId, platform, title, subtitle }) => {
  return await postApi(
    buildUrl(`${API_URL}/${API_URLS.AI_OKS_GENERATOR}`, { projectId, appId, platform }),
    {
      title,
      subtitle
    }
  );
};
