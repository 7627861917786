import styled from '@emotion/styled';

// Components
import Accordion from 'components/Accordion';

export const StyledAccordion = styled(Accordion)`
  & .MuiAccordionSummary-root {
    padding: 0;
  }

  & .MuiAccordionDetails-root {
    padding: 0;
  }
`;
