import styled from '@emotion/styled';
import MuiBox from '@mui/material/Box';
import MuiGrid from '@mui/material/Grid';

export const Box = styled(MuiBox)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 10px;
`;

export const Translation = styled(Box)`
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;

export const Grid = styled(MuiGrid)`
  overflow: hidden;
`;

export const TrackedKeyword = styled(Box)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  display: inline;
`;

export const Wrapper = styled(Box, { shouldForwardProp: (prop) => prop !== 'isTracked' })`
  background-color: ${({ theme, isTracked }) =>
    isTracked ? theme.palette.primary.light : theme.palette.secondary.light};
  height: 100%;
`;
