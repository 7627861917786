import { useQuery } from '@tanstack/react-query';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Fetchers
import { getAppKeywordsRank } from 'api/api';

interface Props {
  projectId: string;
  appId: string;
  platform: string;
  iosVersion: number;
  listId: number;
}
function useKeywordsRanksQuery({ projectId, appId, platform, iosVersion, listId }: Props) {
  return useQuery({
    queryKey: [CACHE_KEYS.GET_KEYWORDS_RANK, { projectId, appId, platform, iosVersion, listId }],
    queryFn: () => getAppKeywordsRank({ projectId, appId, platform, iosVersion, listId }),
    enabled: !!listId
  });
}

export default useKeywordsRanksQuery;
