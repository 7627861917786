import useAuth from 'hooks/useAuth';

/**
 * @name usePermissions
 * @description A hook that checks if the user has permission to access a feature
 * @param {string[]} allowedRoles - An array of roles that are allowed to access the feature
 * @returns {boolean} hasPermission - A boolean indicating if the user has permission to access the feature
 *
 * Roles currently available: 'Standard (phiture)', 'Admin'.
 * There will be more roles and roles in the future and this hook will be updated accordingly.
 * */
const usePermissions = (allowedRoles: string[]): boolean => {
  const { user }: any = useAuth();
  const { group } = user;
  return allowedRoles.includes(group);
};

export default usePermissions;
