import { useMutation } from '@tanstack/react-query';

// Fetchers
import { updateChangesBacklogExperimentName } from 'api/api';

interface Props {
  projectId: string;
  appId: string;
  platform: string;
}

/**
 * Update experiment name or date mutation
 *
 * @param projectId   Project id
 * @param appId       App id
 * @param platform    App platform
 * @param experimentId    Experiment id
 */
function useExperimentMutation({ projectId, appId, platform }: Props) {
  return useMutation((props: { date?: string; experimentId: number; name?: string }) =>
    updateChangesBacklogExperimentName({
      projectId,
      appId,
      platform,
      date: props.date,
      experimentId: props.experimentId,
      name: props.name
    })
  );
}

export default useExperimentMutation;
