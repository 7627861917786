// eslint-disable-next-line import/named
import { useQuery, UseQueryResult } from '@tanstack/react-query';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Types
import { PLATFORMS } from 'types/Platform';

// Fetchers
import { getAIReviewListsStatus } from 'api/api';

interface Props {
  projectId: number;
  appId: number;
  platform: PLATFORMS;
}

export enum ListStatus {
  INITIALIZING = 'initializing',
  GENERATING = 'generating',
  AWAITING_DATA = 'awaiting_data',
  RELEVANCY_BRAND_LABELLING = 'relevancy_brand_labeling',
  TO_REVIEW = 'to_review',
  REVIEWED = 'reviewed'
}

export interface AIReviewListStatus {
  const: number;
  created_at: string;
  duration: number;
  failed: boolean;
  id: number;
  model: string;
  status: ListStatus;
  token_used: number;
  total_unique_keywords: number;
  updated_at: string;
}

interface Response {
  data: AIReviewListStatus[];
}
function useAIReviewListsStatusQuery({
  projectId,
  appId,
  platform
}: Props): UseQueryResult<Response> {
  return useQuery({
    queryKey: [CACHE_KEYS.AI_REVIEW_LISTS_STATUS, { projectId, appId, platform }],
    queryFn: () => getAIReviewListsStatus({ projectId, appId, platform }),
    refetchInterval: 60 * 1000
  });
}

export default useAIReviewListsStatusQuery;
