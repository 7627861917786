import styled from '@emotion/styled';
import MuiBox from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DatePicker as DPicker } from '@mui/x-date-pickers-pro';
import MuiGrid from '@mui/material/Grid';

export const CurrentCell = styled(MuiGrid)`
  font-size: 10px;
  width: 100px;
  word-wrap: break-word;
`;

export const CommentCell = styled(MuiGrid)`
  font-size: 10px;
  white-space: pre-wrap;
`;

export const Wrapper = styled(MuiBox)<{ color: string }>`
  background-color: ${({ color }) => color};
  border-radius: 10px;
  padding: 1px 10px 1px 10px;
  width: fit-content;
`;

export const Text = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: 10px;
`;

export const DatePicker = styled(DPicker)`
  & .MuiInputBase-root {
    height: 36px;
    margin-left: ${({ theme }) => theme.spacing(2)};
  }
`;

export const DatePickerCell = styled(DPicker)`
  & .MuiInputBase-root {
    height: 36px;
    margin-left: ${({ theme }) => theme.spacing(2)};
    font-size: 10px;
  }

  outline: none;
  border: none;

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiButtonBase-root {
    padding: 0px;
    margin-right: 0px;
  }

  & .MuiInputBase-input {
    padding: 0;
  }

  & .MuiSvgIcon-root {
    height: 16px;
  }
`;
