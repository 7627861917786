import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

// Constants
import { DATE_RANGE_OPTIONS } from 'constants/constants';

// Hooks
import useKeywordTrackingTable from 'hooks/useKeywordTrackingTable';

// Components
import DateRangePickerWithShortcuts from 'components/DateRangePickerWithShortcuts';
import DateRangeFilter from './DateRangeFilter';
import FilterChips from './FilterChips';
import PrimaryButton from 'components/Button/Primary';

// Styles
import {
  Box,
  AccordionSummary,
  ButtonsBox,
  KeywordTrackingTableFiltersWrapper,
  DatePickerWrapper,
  SelectFilter,
  TextFieldFilter,
  RangeFilter
} from './styled';

// Constants
import {
  FILTER_TYPES,
  KEYWORD_TRACKING_TABLE_FILTERS,
  KEYWORD_TRACKING_TABLE_FILTERS_SCHEMA
} from 'contexts/KeywordTrackingTable';

const INPUT_WIDTH = 120;

function KeywordTrackingTableFilters({ columns, size = 'small' }) {
  const { t } = useTranslation('components', {
    keyPrefix: 'KeywordTrackingTable'
  });
  const {
    filters,
    queryFilters,
    handleFiltersSubmit,
    handleFiltersReset,
    dateRange,
    handleDateRangeChange
  } = useKeywordTrackingTable();

  const formattedValues = {
    ...filters,
    ...queryFilters
  };

  const {
    handleSubmit,
    isValid,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    values,
    setValues
  } = useFormik({
    initialValues: {
      ...formattedValues
    },
    validateOnChange: false,
    validationSchema: KEYWORD_TRACKING_TABLE_FILTERS_SCHEMA,
    onSubmit: (values) => {
      // We don't reset the form, so we need to update the values in the form
      // and in the context
      // We are providing entire values object in order to make sure that Formik
      // won't remove a empty property - otherwise the form will break.
      setValues({ ...filters, ...values });
      handleFiltersSubmit({ ...filters, ...values });
    }
  });

  const handleReset = () => {
    handleFiltersReset();
    setValues(KEYWORD_TRACKING_TABLE_FILTERS);
  };

  return (
    <Accordion disableGutters defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{t('KeywordTrackingTableFilters.filters')}</Typography>
        <FilterChips filters={filters} columns={columns} />
      </AccordionSummary>
      <AccordionDetails>
        <KeywordTrackingTableFiltersWrapper
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit}
          onReset={handleReset}
        >
          <Box>
            {columns.map((column) => {
              if (column.filterType === FILTER_TYPES.TEXT) {
                return (
                  <TextFieldFilter
                    key={column.headerName}
                    label={column.headerName}
                    name={column.field}
                    value={values[column.field]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size={size}
                    width={INPUT_WIDTH}
                  />
                );
              }
              if (column.filterType === FILTER_TYPES.DATE_RANGE) {
                return (
                  <DateRangeFilter
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    width={INPUT_WIDTH}
                    key={column.headerName}
                    label={column.headerName}
                    name={column.field}
                    size={size}
                    valueStart={values['date_range_start']}
                    valueEnd={values['date_range_end']}
                  />
                );
              }
              if (column.filterType === FILTER_TYPES.RANGE) {
                return (
                  <>
                    <RangeFilter
                      key={column.headerName}
                      label={column.headerName}
                      name={column.field}
                      // If filtersFilters are `null`, default array values to `EMPTY_VALUE`
                      value={[values[`${column.field}_min`], values[`${column.field}_max`]]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size={size}
                      width={INPUT_WIDTH}
                    />
                  </>
                );
              }
              if (column.filterType === FILTER_TYPES.MULTISELECT) {
                return (
                  <SelectFilter
                    key={column.headerName}
                    label={column.headerName}
                    name={column.field}
                    // If filtersFilters are `null`, default to `EMPTY_ARRAY`
                    value={values[column.field]}
                    options={column.filterOptions}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size={size}
                    width={INPUT_WIDTH}
                    multiple
                  />
                );
              }

              if (column.filterType === FILTER_TYPES.SELECT) {
                return (
                  <SelectFilter
                    key={column.headerName}
                    label={column.headerName}
                    name={column.field}
                    // If filtersFilters are `null`, default to `EMPTY_VALUE`
                    value={values[column.field]}
                    options={column.filterOptions}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size={size}
                    width={INPUT_WIDTH}
                  />
                );
              }

              // If column does not have a `filterType`, return nothing.
              return null;
            })}

            {/*DateRangePicker controls range of popularity_change and rank_change columns*/}
            {/*also changes and it's changed by the date range inside pop and rank historical data modal*/}
            <DatePickerWrapper>
              <Typography sx={{ fontWeight: 500, fontSize: '10px' }} align="center" gutterBottom>
                {t('KeywordTrackingTableFilters.pop_rank_change')}
              </Typography>
              <DateRangePickerWithShortcuts
                dates={dateRange}
                setDates={handleDateRangeChange}
                shortcutOptions={DATE_RANGE_OPTIONS}
              />
            </DatePickerWrapper>
            <ButtonsBox>
              <PrimaryButton
                type="submit"
                disabled={!isValid}
                // If any filter is invalid, disable filter submission
                variant="contained"
              >
                {t('KeywordTrackingTableFilters.filter')}
              </PrimaryButton>
              <Button color="warning" type="reset" variant="outlined">
                {t('KeywordTrackingTableFilters.clear_filters')}
              </Button>
            </ButtonsBox>
          </Box>
        </KeywordTrackingTableFiltersWrapper>
      </AccordionDetails>
    </Accordion>
  );
}

KeywordTrackingTableFilters.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  columns: PropTypes.array.isRequired
};

export default KeywordTrackingTableFilters;
