import React from 'react';
import Grid from '@mui/material/Grid';
import i18n from 'i18next';

// Utils
import { reducedRelevancyOptions, reducedTypeOptions } from 'contexts/KeywordTrackingTable/columns';
import {
  Filters,
  FilterTypes,
  getFilter
} from 'pages/app/Application/AI/Research/TableFilters/utils';

// Styles
import { Wrapper } from './styled';

// Hooks
import useAIResearch from 'hooks/AI/useAIResearch';

const FILTERS = () => [
  {
    name: Filters.Word,
    type: FilterTypes.Text,
    label: i18n.t('pages:AI.filters.keyword'),
    placeholder: i18n.t('pages:AI.filters.keyword')
  },
  {
    name: Filters.Type,
    type: FilterTypes.MultiSelect,
    options: reducedTypeOptions(),
    label: i18n.t('pages:AI.filters.type'),
    placeholder: i18n.t('pages:AI.filters.type')
  },
  {
    name: Filters.Relevancy,
    type: FilterTypes.MultiSelect,
    options: reducedRelevancyOptions(),
    label: i18n.t('pages:AI.filters.relevancy'),
    placeholder: i18n.t('pages:AI.filters.relevancy')
  },
  {
    name: Filters.Popularity,
    type: FilterTypes.Range,
    label: i18n.t('pages:AI.filters.popularity'),
    min: 0,
    max: 100
  },
  {
    name: Filters.TotalApps,
    label: i18n.t('pages:AI.filters.total_apps'),
    type: FilterTypes.Range,
    min: 0,
    max: 100
  },
  {
    name: Filters.Rank,
    type: FilterTypes.Range,
    label: i18n.t('pages:AI.filters.rank'),
    min: 0,
    max: 100
  },

  {
    name: Filters.EstimatedDownloads,
    type: FilterTypes.Range,
    label: i18n.t('pages:AI.filters.estimated_downloads'),
    min: 0,
    max: 100
  }
];

function TableFilters() {
  const { filters, setFilters } = useAIResearch();

  return (
    <Grid container alignItems={'center'} my={2}>
      {FILTERS().map((filter, i) => (
        <Wrapper component={'span'} key={i}>
          {getFilter(filter, filters, setFilters)}
        </Wrapper>
      ))}
    </Grid>
  );
}

export default TableFilters;
