import React, { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';

// Constants
import { THEMES } from 'constants/Theme';

// Theme
import createTheme from 'theme';

// Components
import GlobalStyle from 'components/GlobalStyle';

// Styles
import { Root, AppContent } from './styled';

/**
 * @description Layout for presentation
 * @param  {React.ReactNode} children
 */
const Presentation = ({ children }: PropsWithChildren) => {
  return (
    <MuiThemeProvider theme={createTheme(THEMES.DEFAULT)}>
      <Root>
        <CssBaseline />
        <GlobalStyle />
        <AppContent>
          {children}
          <Outlet />
        </AppContent>
      </Root>
    </MuiThemeProvider>
  );
};

export default Presentation;
