import { useMutation } from '@tanstack/react-query';

// Fetchers
import { addKeywordsToAppKeywordLists } from 'api/api';

interface Props {
  projectId: string;
  appId: string;
  platform: string;
  keywordListIds: string;
}

export function useAddKeywordsMutation({ projectId, appId, platform, keywordListIds }: Props) {
  return useMutation((keywords: string) =>
    addKeywordsToAppKeywordLists({
      projectId,
      appId,
      platform,
      keywords,
      keywordListIds,
      addIndividualTerms: false
    })
  );
}
