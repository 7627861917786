import { useParams } from 'react-router-dom';
import { createContext, PropsWithChildren, SyntheticEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

// Fetchers
import useAIReviewListsQuery from 'api/queries/AI/KeywordResearch/useAIReviewListsQuery';
import useAIReviewListByIdQuery from 'api/queries/AI/KeywordResearch/useAIReviewListByIdQuery';
import useMainAIListQuery from 'api/queries/AI/KeywordResearch/useMainAIListQuery';
import useAIReviewKeywordMutation from 'api/queries/AI/KeywordResearch/useAIReviewKeywordMutation';
import useGenerateAIKeywordsQuery from 'api/queries/AI/KeywordResearch/useGenerateAIKeywordsQuery';
import useValidateAIReviewList from 'api/queries/AI/KeywordResearch/useAIReviewListMutation';
import useAIMainListKeywordMutation from 'api/queries/AI/KeywordResearch/useAIMainListKeywordMutation';
import useDeleteAIMainListKeyword from 'api/queries/AI/KeywordResearch/useDeleteAIMainListKeyword';
import useAIReviewListsStatusQuery, {
  AIReviewListStatus
} from 'api/queries/AI/KeywordResearch/useAIReviewListsStatusQuery';

// Types
import { Params } from 'types/App';
import { AIMainListKeyword, AIReviewList } from 'types/Keywords/AI';
import { Models } from 'types/AILabs';

// Hooks
import useSnackbar from 'hooks/useSnackbar';

// Constants
import { CACHE_KEYS } from 'api/constants';

/**
 * Filter rows based on form values
 *
 * @param data      Data to filter
 * @param filters   Filters
 */
export const getFilteredRows = (data, filters) => {
  if (!data) return [];

  const keys = Object.keys(filters);

  return data.filter((item) => {
    return keys.every((key) => {
      if (typeof filters[key] === 'string') {
        return item[key].toLowerCase().includes(filters[key].toLowerCase());
      }

      if (Array.isArray(filters[key])) {
        return filters[key].length === 0 || filters[key].includes(item[key]);
      }

      return item[key] >= filters[key].min && item[key] <= filters[key].max;
    });
  });
};

export interface AIResearchContextState {
  mainList: AIMainListKeyword[];
  filteredMainList: AIMainListKeyword[];
  setFilters: (form: typeof initialFormState) => void;
  filters: typeof initialFormState;
  reviewList: AIReviewList[];
  handleGenerateKeywords: () => void;
  model: Models;
  setModel: (model: Models) => void;
  updateReviewKeyword: (data: { keywordIds: string; relevancy?: number; type?: number }) => void;
  isLoadingReviewList: boolean;
  validateReviewList: () => void;
  updateMainAIListKeyword: (data: {
    keywordIds: string;
    relevancy?: number;
    type?: number;
  }) => void;
  handleDeleteKeyword: (data: { keywordId: number }) => void;
  isLoadingMainList: boolean;
  mainListId?: number;
  isValidating: boolean;
  isGeneratingKeywords: boolean;
  reviewListStatus: AIReviewListStatus[];
  activeTab: number;
  setActiveTab: (newValue: number) => void;
}

const initialFormState = {
  word: '',
  keyword_type_id: [],
  relevancy: [],
  popularity_score: { min: -1, max: 100 },
  total_apps: { min: -1, max: 251 },
  rank: { min: -1, max: 251 },
  estimated_downloads: { min: -1, max: Infinity }
};

export const AIResearchContext = createContext({} as AIResearchContextState);

function AIResearchProvider({ children }: PropsWithChildren) {
  const queryClient = useQueryClient();
  const { projectId, appId, platform } = useParams() as Params;
  const { setSnackbar } = useSnackbar();
  const { t } = useTranslation('pages', { keyPrefix: 'AI' });

  const [activeTab, setActiveTab] = useState(0);

  const [filters, setFilters] = useState(initialFormState);
  const [selectedModel, setSelectedModel] = useState<Models>(Models.GPT_4);

  const { data: mainList, isInitialLoading: isLoadingMainList } = useMainAIListQuery({
    projectId: Number(projectId),
    appId: Number(appId),
    platform
  });

  const { data: reviewLists, isInitialLoading: isLoadingReviewLists } = useAIReviewListsQuery({
    projectId: Number(projectId),
    appId: Number(appId),
    platform
  });

  const { data: reviewList, isInitialLoading: isLoadingReviewList } = useAIReviewListByIdQuery({
    projectId: Number(projectId),
    appId: Number(appId),
    platform,
    reviewListId: reviewLists?.data.keyword_list_ids[0]!
  });

  /**
   * Request to generate a new AI keyword list
   */
  const { mutateAsync: generateAIKeywords, isLoading: isGeneratingKeywords } =
    useGenerateAIKeywordsQuery(
      {
        projectId: Number(projectId),
        appId: Number(appId),
        platform,
        model: selectedModel
      },
      {
        onSuccess: () => {
          return queryClient.invalidateQueries([
            CACHE_KEYS.AI_REVIEW_LISTS_STATUS,
            { projectId: Number(projectId), appId: Number(appId), platform }
          ]);
        }
      }
    );

  const { mutate: updateReviewKeyword } = useAIReviewKeywordMutation({
    projectId: Number(projectId),
    appId: Number(appId),
    platform,
    reviewListId: reviewLists?.data.keyword_list_ids[0]!
  });

  const { mutate: updateMainAIListKeyword } = useAIMainListKeywordMutation({
    projectId: Number(projectId),
    appId: Number(appId),
    platform,
    listId: mainList?.data.keyword_list_id!
  });

  const { refetch: validateReviewList, isFetching: isValidating } = useValidateAIReviewList(
    {
      projectId: Number(projectId),
      appId: Number(appId),
      platform,
      reviewListId: reviewLists?.data.keyword_list_ids[0]!
    },
    {
      onSuccess: () => {
        setSnackbar(true, t('validate_success'), 'success', 3000);

        // Upon validation, remove the review list from the cache
        queryClient.setQueryData(
          [
            CACHE_KEYS.AI_REVIEW_LISTS,
            {
              projectId: Number(projectId),
              appId: Number(appId),
              platform,
              reviewListId: reviewLists?.data.keyword_list_ids[0]!
            }
          ],
          () => {
            return { data: [] };
          }
        );

        // The data is now in the main list, so we need to invalidate the main list cache
        return queryClient.invalidateQueries([
          CACHE_KEYS.AI_MAIN_LIST,
          { projectId: Number(projectId), appId: Number(appId), platform }
        ]);
      },
      onError: () => {
        setSnackbar(true, t('validate_error'), 'error', 3000);
      }
    }
  );

  const handleGenerateKeywords = async () => {
    try {
      await generateAIKeywords();
      setSnackbar(true, t('ai_generation_request_success'), 'success', 3000);
    } catch (error) {
      setSnackbar(true, t('ai_generation_request_fail'), 'error', 3000);
    }
  };

  const { mutate: handleDeleteKeyword } = useDeleteAIMainListKeyword({
    projectId: Number(projectId),
    appId: Number(appId),
    platform,
    listId: mainList?.data.keyword_list_id!
  });

  const { data: reviewListStatus } = useAIReviewListsStatusQuery({
    projectId: Number(projectId),
    appId: Number(appId),
    platform
  });

  // Filtered main AI list table rows
  const filteredMainList = useMemo(() => {
    return getFilteredRows(mainList?.data.keywords || [], filters);
  }, [mainList?.data, filters]);

  return (
    <AIResearchContext.Provider
      value={{
        filters,
        setFilters,
        mainList: mainList?.data?.keywords || [],
        filteredMainList,
        reviewList: reviewList?.data || [],
        handleGenerateKeywords,
        model: selectedModel,
        setModel: setSelectedModel,
        updateReviewKeyword,
        isLoadingReviewList: isLoadingReviewList || isLoadingReviewLists,
        isLoadingMainList,
        validateReviewList,
        updateMainAIListKeyword,
        handleDeleteKeyword,
        mainListId: mainList?.data.keyword_list_id,
        isValidating,
        isGeneratingKeywords,
        reviewListStatus: reviewListStatus?.data || [],
        activeTab,
        setActiveTab
      }}
    >
      {children}
    </AIResearchContext.Provider>
  );
}

export default AIResearchProvider;
