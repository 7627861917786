import { useMutation } from '@tanstack/react-query';

// Fetchers
import { generateAIResearchKeywords } from 'api/api';

// Types
import { Models } from 'types/AILabs';

interface Props {
  projectId: number;
  appId: number;
  platform: string;
  model: Models;
}

/**
 * Query function that returns the keywords generated by AI
 *
 * @param projectId   Project id
 * @param appId       App id
 * @param platform    App platform
 * @param model       AI model
 * @param onSuccess   On success callback
 */
function useGenerateAIKeywordsQuery(
  { projectId, appId, platform, model }: Props,
  { onSuccess }: { onSuccess: () => void }
) {
  return useMutation(() => generateAIResearchKeywords({ projectId, appId, platform, model }), {
    onSuccess
  });
}

export default useGenerateAIKeywordsQuery;
