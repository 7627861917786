import { useMutation } from '@tanstack/react-query';

// Fetchers
import { deleteExperimentBacklog } from 'api/api';

interface Props {
  projectId: string;
  appId: string;
  platform: string;
}

/**
 * Delete experiment backlog mutation
 *
 * @param projectId   Project id
 * @param appId       App id
 * @param platform    App platform
 * @param experimentId    Experiment id
 */
function useExperimentBacklogDeleteMutation({ projectId, appId, platform }: Props) {
  return useMutation((props: { backlogId: number; experimentId: number }) =>
    deleteExperimentBacklog({
      projectId,
      appId,
      platform,
      experimentId: props.experimentId,
      backlogId: props.backlogId
    })
  );
}

export default useExperimentBacklogDeleteMutation;
