import styled from '@emotion/styled';
import MuiButton from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MuiBox from '@mui/material/Box';

// Components
import TextInput from 'components/InputText';

export const InputText = styled(TextInput)`
  margin-left: ${({ theme }) => theme.spacing(2)};
  & .MuiInputBase-root {
    height: 24px;
    font-size: 10px;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  }
`;

export const Button = styled(MuiButton)`
  height: 24px;
  font-size: 10px;
`;

export const Box = styled(MuiBox)`
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GridContainer = styled(Grid)`
  margin-left: ${({ theme }) => theme.spacing(2)};
  width: 42px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;

  font-size: 10px;
`;
