import { useTranslation, Trans } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';

// Styles
import { Grid } from './styled';

/**
 * Position column cell component with tooltip text explaining the OKS methodology
 */
function PositionColumnCell() {
  const { t } = useTranslation('components', {
    keyPrefix: 'OptimalKeywordsPage.tableSection.tableColumns'
  });

  return (
    <Tooltip
      title={
        <Trans i18nKey={'components:OptimalKeywordsPage.tableSection.tableColumns.tooltipText'} />
      }
    >
      <Grid>{t('position')}</Grid>
    </Tooltip>
  );
}

export default PositionColumnCell;
