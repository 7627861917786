import { useQuery } from '@tanstack/react-query';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Fetchers
import { getAppKeywordsPopularity } from 'api/api';

interface Props {
  projectId: string;
  appId: string;
  platform: string;
  iosVersion: number;
  listId: number;
}
function useKeywordsPopularityQuery({ projectId, appId, platform, iosVersion, listId }: Props) {
  return useQuery({
    queryKey: [
      CACHE_KEYS.GET_KEYWORDS_POPULARITY,
      { projectId, appId, platform, iosVersion, listId }
    ],
    queryFn: () => getAppKeywordsPopularity({ projectId, appId, platform, iosVersion, listId }),
    enabled: !!listId
  });
}

export default useKeywordsPopularityQuery;
