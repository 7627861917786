import { useParams } from 'react-router-dom';
import { useContext, useMemo } from 'react';

// Contexts
import { OKSContext } from 'contexts/OKS';

// Constants
import { FILTER_OPTIONS } from 'constants/OKS';

// Utils
import { getOKSTableColumns, getOKSTableRows } from './utils';

// Hooks
import useApp from 'hooks/useApp';
import useKeywordsLists from 'hooks/useKeywordLists';

// Fetchers
import { useOKSMetadataQuery } from 'api/queries/OKS/Metadata';
import useKeywordsQuery from 'api/queries/Keywords/useKeywordsQuery';
import useKeywordsRanksQuery from 'api/queries/Keywords/Ranks/useKeywordsRanksQuery';
import useKeywordsPopularityQuery from 'api/queries/Keywords/Popularity';
import useOKSGenerator from 'api/queries/OKS/Generator';
import useCompetitorsQuery from 'api/queries/Competitors/useCompetitors';
import useCompetitorsKeywordsRankQuery from 'api/queries/Competitors/useCompetitorsKeywordsRank';

/**
 * Returns the OKS context and calculates the rows and columns for the OKS table
 */
const useOKSTable = () => {
  const { state, dispatch } = useContext(OKSContext);
  const { filterKeyword, filterByGroup, recommendedKeyword, competitors } = state;

  const { translateKeywords } = useApp();
  const { projectId, appId, platform } = useParams();
  const { listSelected } = useKeywordsLists(projectId, appId, platform);

  const { data: metaData } = useOKSMetadataQuery({ projectId, appId, platform });
  const { data: keywordData } = useKeywordsQuery({
    projectId,
    appId,
    platform,
    listId: listSelected?.id
  });
  const { data: rankData } = useKeywordsRanksQuery({
    projectId,
    appId,
    platform,
    listId: listSelected?.id
  });
  const { data: popularityData } = useKeywordsPopularityQuery({
    projectId,
    appId,
    platform,
    listId: listSelected?.id
  });
  const { data: oksGenerated } = useOKSGenerator({
    projectId,
    appId,
    platform,
    listId: listSelected?.id
  });

  const { data: competitorsData } = useCompetitorsQuery({ appId, platform, projectId });

  const keywords = useMemo(() => {
    return keywordData?.keywords?.map((kw, i) => ({
      ...kw,
      ...popularityData?.keywords[i],
      ...rankData?.keywords[i],
      length: kw.name.length
    }));
  }, [keywordData?.keywords, popularityData?.keywords, rankData?.keywords]);

  /**
   * Returns the related search terms for the recommended keyword
   */
  const getRelatedSearchTerms = useMemo(
    () =>
      oksGenerated?.data?.find((kw) => kw?.name === recommendedKeyword?.name)
        ?.related_search_terms || [],
    [recommendedKeyword, oksGenerated?.data]
  );

  /**
   * Determines which keyword set to render for the OKS table
   */
  const activeKeywordSet = useMemo(() => {
    return recommendedKeyword
      ? getRelatedSearchTerms
      : filterByGroup === FILTER_OPTIONS.SET
      ? oksGenerated?.data || []
      : keywords;
  }, [recommendedKeyword, filterByGroup, keywords, oksGenerated?.data]);

  const competitorKeywords = useMemo(() => {
    return activeKeywordSet?.reduce(
      (acc, kw) => {
        if (kw?.keyword_id) {
          return { ...acc, keywordIds: [...acc.keywordIds, kw.keyword_id] };
        }
        return { ...acc, keywordStrings: [...acc?.keywordStrings, kw?.name] };
      },
      { keywordIds: [], keywordStrings: [] }
    );
  }, [activeKeywordSet]);

  const { data: competKws, isLoading } = useCompetitorsKeywordsRankQuery({
    appId,
    platform,
    projectId,
    keywordIds: competitorKeywords?.keywordIds?.join(','),
    keywordStrings: competitorKeywords?.keywordStrings?.join(',')
  });

  /**
   * Returns more data about the selected competitors for the OKS table
   */
  const selectedCompetitors = useMemo(
    () =>
      competitors
        .map((comp) => competitorsData?.data?.find((allComp) => comp === allComp.app_localised_id))
        .filter((comp) => !!comp),
    [competitors, competitorsData?.data]
  );

  /**
   * Returns the columns for the OKS table
   */
  const tableColumns = useMemo(() => {
    return getOKSTableColumns(selectedCompetitors, translateKeywords);
  }, [selectedCompetitors, translateKeywords]);

  const keywordsWithCompetitors = useMemo(() => {
    // Filter out competitor keywords which are not active currently
    const selectedCompetitorKeywords = competKws?.data?.filter((comp) =>
      competitors?.includes(comp?.competitor_localised_id)
    );

    return activeKeywordSet?.map((kw) => {
      const competitorRanks = competitors?.reduce((acc, comp) => {
        const competitorKeyword = selectedCompetitorKeywords?.find(
          (compKw) =>
            (compKw?.keyword_id === kw?.keyword_id || compKw?.word === kw?.name) &&
            compKw?.competitor_localised_id === comp
        );
        return { ...acc, [comp]: competitorKeyword?.rank || null };
      }, {});

      return {
        ...kw,
        ...competitorRanks,
        isLoading
      };
    });
  }, [activeKeywordSet, competKws?.data, competitors, isLoading]);

  /**
   * Returns the rows for the OKS table
   */
  const tableRows = useMemo(() => {
    return getOKSTableRows(
      selectedCompetitors,
      keywordsWithCompetitors,
      filterKeyword,
      filterByGroup,
      translateKeywords,
      metaData?.data?.title || '',
      metaData?.data?.subtitle || ''
    );
  }, [
    selectedCompetitors,
    keywordsWithCompetitors,
    filterKeyword,
    filterByGroup,
    translateKeywords,
    metaData?.data?.title,
    metaData?.data?.subtitle,
    recommendedKeyword
  ]);

  return {
    state: {
      ...state,
      tableRows,
      tableColumns
    },
    dispatch
  };
};

export default useOKSTable;
