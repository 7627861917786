import styled from '@emotion/styled';
import MuiAccordionSummery from '@mui/material/AccordionSummary';
import MuiAccordion from '@mui/material/Accordion';

export const AccordionStyled = styled(MuiAccordion)`
  margin: 10px 0;
`;
// summery container: place arrow on the left and rotate when expanded
export const AccordionSummary = styled(MuiAccordionSummery)`
  flex-direction: row-reverse;
  & .muiaccordionsummary-expandiconwrapper.mui-expanded: {
    transform: rotate(180deg);
  }
`;
