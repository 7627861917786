import React, { ChangeEvent } from 'react';
import i18n from 'i18next';
import dayjs, { Dayjs } from 'dayjs';

// Components
import ActionsCell from 'pages/app/Application/ChangesBacklog/AddMetadata/ActionsCell';
import Diff from 'pages/app/Application/ChangesBacklog/Diff';

// Components
import { NoBorderSelect } from 'components/Select/variations';

// Types
import {
  Experiment,
  Status,
  AssetTypes,
  ExperimentBacklog,
  StatusKeys,
  AssetTypesKeys
} from 'types/ChangesBacklog';

// Styles
import {
  CommentCell,
  CurrentCell,
  Text,
  Wrapper
} from 'pages/app/Application/ChangesBacklog/HistoricalMetadata/styled';

export const statusOptions = [
  {
    value: StatusKeys.APPROVED,
    label: (
      <Wrapper color={'#eafef4'}>
        <Text color={'#03aa58'}>{Status.APPROVED}</Text>
      </Wrapper>
    )
  },
  {
    value: StatusKeys.LIVE,
    label: (
      <Wrapper color={'#eafef4'}>
        <Text color={'#03aa58'}>{Status.LIVE}</Text>
      </Wrapper>
    )
  },
  {
    value: StatusKeys.DRAFT,
    label: (
      <Wrapper color={'#fff8e8'}>
        <Text color={'#fabc1b'}>{Status.DRAFT}</Text>
      </Wrapper>
    )
  },
  {
    value: StatusKeys.AWAITING_CLIENT_APPROVAL,
    label: (
      <Wrapper color={'#eafef4'}>
        <Text>{Status.AWAITING_CLIENT_APPROVAL}</Text>
      </Wrapper>
    )
  }
];

export const assetOptions = [
  { value: AssetTypesKeys.TITLE, label: AssetTypes.TITLE },
  { value: AssetTypesKeys.SUBTITLE, label: AssetTypes.SUBTITLE },
  { value: AssetTypesKeys.KEYWORD_SET, label: AssetTypes.KEYWORD_SET }
];

const translationPath = 'pages:ChangesBacklog.TableColumns';

export const COLUMNS = (
  languageOptions: { value: number; label: string }[],
  onDeleteRow: (id: number) => void,
  onUpdateRow: (id: number, row: ExperimentBacklog) => void,
  allRows: ExperimentBacklog[],
  experiments: Experiment[]
) => [
  {
    field: 'status',
    align: 'left',
    minWidth: 111,
    headerName: i18n.t(`${translationPath}.status`),
    editable: true,
    renderCell: ({ row }: { row: ExperimentBacklog }) => {
      const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onUpdateRow(row.id, { ...row, [e.target.name]: e.target.value });
      };
      return (
        <NoBorderSelect
          name={'status'}
          id={'status'}
          options={statusOptions}
          value={row.status}
          onChange={handleChange}
          width={100}
          inputProps={{ IconComponent: () => null }}
        />
      );
    }
  },
  {
    field: 'language',
    headerName: i18n.t(`${translationPath}.language`),
    minWidth: 111,
    renderCell: ({ row }: { row: ExperimentBacklog }) => {
      const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onUpdateRow(row.id, { ...row, [e.target.name]: e.target.value });
      };
      return (
        <NoBorderSelect
          id={'language'}
          name={'language'}
          options={languageOptions}
          value={row.language}
          onChange={handleChange}
          width={100}
        />
      );
    }
  },
  {
    field: 'asset_type',
    headerName: i18n.t(`${translationPath}.asset_type`),
    minWidth: 111,
    renderCell: ({ row }: { row: ExperimentBacklog }) => {
      const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const assetType = e.target.value as AssetTypesKeys;
        const previousExperiment = getPreviousExperiment(row.date, experiments);
        const previous = getPreviousValue(previousExperiment?.app_backlogs || [], assetType);
        onUpdateRow(row.id, {
          ...row,
          asset_type: assetType,
          previous_value: previous
        });
      };
      return (
        <NoBorderSelect
          id={'asset_type'}
          options={assetOptions}
          value={row.asset_type}
          name={'asset_type'}
          onChange={handleChange}
          width={100}
        />
      );
    }
  },
  {
    field: 'previous_value',
    headerName: i18n.t(`${translationPath}.before`),
    minWidth: 100
  },
  {
    field: 'beforeLength',
    minWidth: 50,
    align: 'center',
    headerName: i18n.t(`${translationPath}.length`),
    valueGetter: ({ row }: { row: ExperimentBacklog }) => row?.previous_value?.length
  },
  {
    field: 'current_value',
    headerName: i18n.t(`${translationPath}.new`),
    editable: true,
    minWidth: 100,
    renderCell: ({ row }: { row: ExperimentBacklog }) => (
      <CurrentCell>{row?.current_value}</CurrentCell>
    )
  },
  {
    field: 'currentLength',
    width: 50,
    align: 'center',
    headerName: i18n.t(`${translationPath}.length`),
    valueGetter: ({ row }: { row: ExperimentBacklog }) => row?.current_value?.length
  },
  {
    field: 'changes',
    headerName: i18n.t(`${translationPath}.changes`),
    renderCell: ({ row }: { row: ExperimentBacklog }) => (
      <Diff current={row.current_value} previous={row.previous_value} />
    )
  },
  {
    field: 'comment',
    headerName: i18n.t(`${translationPath}.comments`),
    editable: true,
    flex: 1,
    renderCell: ({ row }: { row: ExperimentBacklog }) => <CommentCell>{row?.comment}</CommentCell>
  },
  {
    field: 'actions',
    align: 'center',
    editable: false,
    headerName: '',
    renderCell: ({ row }: { row: ExperimentBacklog }) => (
      <ActionsCell
        id={row.id}
        onDelete={onDeleteRow}
        isDisabled={allRows.filter((r) => r.asset_type === row.asset_type).length <= 1}
      />
    )
  }
];

/**
 * Updates the experiment with the new date
 *
 * @param prev                  Previous data
 * @param activeExperimentId    Active experiment id
 * @param date                  New date
 */
export const updateBacklog = (
  activeExperimentId: number,
  date: string,
  prev?: { data: Experiment[] }
) => {
  return {
    ...prev,
    data:
      prev?.data.map((exp: Experiment) => {
        if (exp.id === activeExperimentId) {
          return {
            ...exp,
            date
          };
        }
        return exp;
      }) || []
  };
};

/**
 * Returns the current value from a live experiment
 *
 * @param currentLive   Live experiment
 * @param assetType     Asset type
 */
export const getPreviousValue = (currentLive: ExperimentBacklog[], assetType: AssetTypesKeys) =>
  currentLive?.find((exp) => exp?.asset_type === assetType)?.current_value || '';

export const validateLiveStatus = (resData: ExperimentBacklog, backlog: ExperimentBacklog) => {
  if (resData.status === StatusKeys.LIVE) {
    const otherBacklog =
      backlog.asset_type === resData.asset_type && backlog.status === StatusKeys.LIVE;
    if (otherBacklog) {
      return StatusKeys.DRAFT;
    }
  }
  return backlog.status;
};

export function compareDates(a: Dayjs, b: Dayjs) {
  if (a.isBefore(b)) {
    return -1;
  } else if (a.isAfter(b)) {
    return 1;
  } else {
    return 0;
  }
}

export function getPreviousExperiment(date: string, experiments: Experiment[]) {
  const targetDayjs = dayjs(date);
  const datesDayjs = experiments.map((exp) => dayjs(exp.date));

  const closestPreviousDates = datesDayjs
    .filter((date) => date.isBefore(targetDayjs))
    .sort(compareDates);

  const closestDate = closestPreviousDates[closestPreviousDates.length - 1];
  const previous = experiments.find((exp) => exp.date === closestDate?.format('YYYY-MM-DD'));

  return previous;
}
