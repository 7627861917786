import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';

// Components
import Competitor from './Competitor';
import AppModal from 'components/AppModal';

// Styles
import { CompetitorContainer, AddButton } from './styled';

// Constants
import { PLATFORMS } from 'constants/constants';

// Hooks
import useApp from 'hooks/useApp';
import useTrackStatus from 'hooks/useTrackStatus';

// Fetchers
import useCompetitorsSuggestionsQuery from 'api/queries/Competitors/useCompetitorsSuggestionsQuery';
import useCompetitorsMutation from 'api/queries/Competitors/useCompetitorsMutation';
import useCompetitorsQuery from 'api/queries/Competitors/useCompetitors';

// Types
import { CACHE_KEYS, FETCH_STATE_ENUM } from 'api/constants';
import { Params } from 'types/App';

const platformOptions = [
  {
    value: PLATFORMS.IPHONE,
    label: 'iPhone'
  },
  {
    value: PLATFORMS.ANDROID,
    label: 'Android'
  },
  {
    value: PLATFORMS.IPAD,
    label: 'iPad'
  }
];

/**
 * Renders the list of the competitors and the button to add more competitors.
 */
function Competitors() {
  const queryClient = useQueryClient();

  const { t } = useTranslation('components', { keyPrefix: 'AppHeader.Competitors' });
  const [isOpen, setIsOpen] = useState(false);
  const { projectId, appId, platform } = useParams() as Params;
  const { app } = useApp();

  const { data: competitors, isLoading } = useCompetitorsQuery({ appId, platform, projectId });

  // move this into another component, prefetch it actually on button hover
  const {
    data: suggestions,
    refetch,
    isInitialLoading
  } = useCompetitorsSuggestionsQuery({
    appId,
    platform,
    projectId
  });

  const addCompetitor = useCompetitorsMutation({ appId, platform, projectId });

  const { activeApp, setActiveApp } = useTrackStatus({ status: addCompetitor.status });

  const handleOpenModal = () => {
    refetch();
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const currentPlatform = useMemo(() => {
    return platformOptions.filter((option) => option.value === app?.platform);
  }, [app?.platform]);

  const modalInitialParams = {
    platform: app?.platform,
    country: app?.country,
    locale: app?.locale,
    searchId: ''
  };

  /**
   * Adds a competitor to the list of tracked competitors for an app.
   * @param app  Competitor app
   */
  const handleSubmit = (app) => {
    const competitorId = app.app.id || app.app.app_id;

    addCompetitor.mutate(
      {
        competitorId
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            CACHE_KEYS.COMPETITORS_KEYWORDS_RANK,
            {
              appId: Number(appId),
              platform,
              projectId: Number(projectId),
              requestAll: true,
              keywordIds: undefined,
              keywordStrings: undefined
            }
          ]);
        }
      }
    );
    setActiveApp(() => ({ [competitorId]: FETCH_STATE_ENUM.LOADING }));
  };

  /**
   * Checks if the app is already tracked.
   * @param app  Competitor app
   */
  const isTracked = (app) => {
    return competitors?.data.some(
      (competitor) => competitor.app_id === app.id || competitor.app_id === app.app_id
    );
  };

  // check if the competitors are more than 14
  const isLessThan15 = competitors?.data.lenght <= 14;

  if (isLoading)
    return (
      <Card>
        <CardContent>
          <Typography component="h2" variant="appH2" gutterBottom marginTop="0.3rem">
            {t('competitors')}
          </Typography>
          <CompetitorContainer container mt={4}>
            {Array.from(Array(10).keys()).map((_, i) => {
              return (
                <Grid key={i}>
                  <Skeleton key={i} width={24} height={40} />
                </Grid>
              );
            })}
          </CompetitorContainer>
        </CardContent>
      </Card>
    );

  return (
    <>
      <Card>
        <CardContent>
          <Typography component="h2" variant="appH2" gutterBottom marginTop="0.3rem">
            {t('competitors')}
          </Typography>
          <CompetitorContainer container mt={4}>
            {isLessThan15
              ? competitors?.data.map((competitor) => (
                  <Competitor
                    key={competitor.app_id}
                    name={competitor.name}
                    icon={competitor.icon}
                  />
                ))
              : // only display the first 14 competitors
                competitors?.data.slice(0, 14).map((competitor, i) => (
                  <Competitor
                    key={competitor.app_id}
                    name={competitor.name}
                    icon={competitor.icon}
                    // display the rest of the competitors in a tooltip on hover on the last visible competitor
                    tooltipTitle={
                      i === 13 && (
                        <Grid spacing={2} display="flex" justifyContent="space-between" container>
                          {competitors?.data
                            .slice(14, competitors?.data.length)
                            .map((competitor) => (
                              <Grid key={competitor.app_id} item>
                                <Competitor
                                  key={competitor.app_id}
                                  name={competitor.name}
                                  icon={competitor.icon}
                                />
                              </Grid>
                            ))}
                        </Grid>
                      )
                    }
                  />
                ))}
            <AddButton disabled={!modalInitialParams.platform} handleClick={handleOpenModal} />
          </CompetitorContainer>
        </CardContent>
      </Card>
      {modalInitialParams.platform && (
        <AppModal
          initialParams={modalInitialParams}
          isOpen={isOpen}
          onClose={handleCloseModal}
          platformOptions={currentPlatform}
          disableSelection={true}
          title={t('track_competitor')}
          buttonText={t('track')}
          suggestions={suggestions?.data || []}
          onSubmit={handleSubmit}
          isTracked={isTracked}
          status={activeApp}
          isLoadingSuggestions={isInitialLoading}
        />
      )}
    </>
  );
}

export default Competitors;
