import React from 'react';
import SnackBar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// Hooks
import useSnackbar from 'hooks/useSnackbar';

const Alert = React.forwardRef<HTMLDivElement, any>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Snackbar() {
  const { isOpen, handleClose, message, severity, duration } = useSnackbar();

  return (
    <SnackBar
      open={isOpen}
      autoHideDuration={duration}
      ClickAwayListenerProps={{ onClickAway: () => null }}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </SnackBar>
  );
}

export default Snackbar;
