import { useMutation } from '@tanstack/react-query';

// Fetchers
import { createPrompt } from 'api/api';

// Types
import { Prompt, PromptType } from 'api/queries/AI/Prompts/usePromptsQuery';

interface Props {
  projectId: number;
  appId: number;
  platform: string;
  promptType: PromptType;
}

/**
 * Mutation to create a prompt
 *
 * */
function usePromptMutation(
  { projectId, appId, platform, promptType }: Props,
  {
    onSuccess,
    onError
  }: { onSuccess: (responseData: { data: Prompt }) => void; onError: () => void }
) {
  return useMutation(
    ({ prompt }: { prompt: string }) =>
      createPrompt({ projectId, appId, platform, promptType, prompt }),
    {
      onSuccess,
      onError
    }
  );
}

export default usePromptMutation;
