import i18n from 'i18next';
import dayjs from 'dayjs';
import {
  AIReviewListStatus,
  ListStatus
} from 'api/queries/AI/KeywordResearch/useAIReviewListsStatusQuery';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';

const translationPath = 'pages:AILabs.status_table';

export const COLUMNS = () => [
  {
    field: 'created_at',
    headerName: i18n.t(`${translationPath}.header.created_at`),
    minWidth: 120,
    maxWidth: 120,
    valueGetter: ({ row }: { row: AIReviewListStatus }) =>
      dayjs(row.created_at).format('MM/DD/YY, h:mm A')
  },
  {
    field: 'updated_at',
    minWidth: 120,
    maxWidth: 120,
    headerName: i18n.t(`${translationPath}.header.updated_at`),
    valueGetter: ({ row }: { row: AIReviewListStatus }) =>
      dayjs(row.updated_at).format('MM/DD/YY, h:mm A')
  },
  {
    field: 'status',
    headerName: i18n.t(`${translationPath}.header.step`),
    minWidth: 180,
    maxWidth: 180,
    valueGetter: ({ row }: { row: AIReviewListStatus }) =>
      i18n.t(`${translationPath}.status.${row.status}`)
  },
  {
    field: 'failed',
    headerName: i18n.t(`${translationPath}.header.status`),
    flex: 1,
    renderCell: ({ row }: { row: AIReviewListStatus }) =>
      row.failed ? (
        <ErrorIcon color={'error'} />
      ) : row.status === ListStatus.TO_REVIEW || row.status === ListStatus.REVIEWED ? (
        <CheckCircleIcon color={'success'} />
      ) : (
        <PendingIcon color={'action'} />
      )
  },
  {
    field: 'token_used',
    headerName: i18n.t(`${translationPath}.header.tokens`),
    flex: 1
  },
  {
    field: 'total_unique_keywords',
    headerName: i18n.t(`${translationPath}.header.total_unique_keywords`),
    flex: 1
  },
  {
    field: 'model',
    headerName: i18n.t(`${translationPath}.header.model`),
    flex: 1
  },
  {
    field: 'cost',
    headerName: i18n.t(`${translationPath}.header.cost`),
    flex: 1
  },
  {
    field: 'duration',
    headerName: i18n.t(`${translationPath}.header.duration`),
    flex: 1
  }
];
