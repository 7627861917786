import React, { Suspense, PropsWithChildren } from 'react';

// Components
import Loader from 'components/Loader';

/**
 * @name SuspenseWrapper
 * @description Wrapper for the React.Suspense element
 * @param  {React.ReactNode} children
 */
function SuspenseWrapper({ children }: PropsWithChildren) {
  return <Suspense fallback={<Loader />}>{children}</Suspense>;
}

export default SuspenseWrapper;
