import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';

export const GridJustifySpaceAroundAfter = styled(Grid)`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  :after {
    content: '';
    flex: auto;
  }
  flex-wrap: wrap;
`;

export const TextFieldMarginRight = styled(TextField)`
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const FormLabelPaddingTB = styled(FormLabel)`
  margin-top: 20px;
  margin-bottom: 10px;
`;
