import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

// Styles
import { TextInput } from 'pages/app/Application/ChangesBacklog/AddMetadata/styled';

interface Props {
  name: string;
  onChange: (name: string) => void;
}

/**
 * Modal for creating a new experiment
 *
 * @param name        Experiment name
 * @param onChange    Function to handle name change
 * @constructor
 */
function AddListModal({ name, onChange }: Props) {
  const { t } = useTranslation('pages', {
    keyPrefix: 'ChangesBacklog'
  });

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <Grid>
      <TextInput placeholder={t('label')} value={name} handleChange={handleChangeName} />
    </Grid>
  );
}

export default AddListModal;
