import { ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';

// Styles
import { CompetitorIcon } from './styled';

interface Props {
  name: string;
  icon: string;
  tooltipTitle?: string | ReactNode;
}

/**
 * Renders the competitor icon.
 *
 * @param name   Competitor name
 * @param icon   Competitor icon
 * @param tooltipTitle Competitor tootlip tile if any (used to display remaining competitors in box)
 */
function Competitor({ name, icon, tooltipTitle = '' }: Props) {
  return (
    <Tooltip title={tooltipTitle}>
      <Grid>
        <CompetitorIcon src={icon} alt={name} />
      </Grid>
    </Tooltip>
  );
}

export default Competitor;
