import { DATA_KEYS } from 'constants/constants';
interface DataItem {
  date: string;
  [key: string]: number | string | null;
  estimated_data: number | string | null;
}
interface ResultItem {
  date: string;
  [key: string]: number | string;
  results_number: string;
}
const MISSING_DATA = 'missing data';
export const processDataForChart = (
  originalData?: ResultItem[],
  dataKey?: string,
  lastKnownValue?: string | number | null
) => {
  let dataArray: DataItem[] = [];
  // if lastKnownValue === null it means that data for that kw is missing
  if (!originalData || lastKnownValue === null || !dataKey) {
    return dataArray;
  }

  // loop through data to adapt to chart format
  // the combination of two lines (estimated_data and [dataKey])  is a workaround to have a dashed line when there is missing data
  let lastValue = lastKnownValue;
  for (let i = 0; i < originalData.length; i++) {
    const item = originalData[i];
    const itemDataKey = item[dataKey];
    // check that data is not missing (and in case of rank that it is not unranked>>251)
    const isMissingData =
      (dataKey === DATA_KEYS.RANK && (itemDataKey === MISSING_DATA || itemDataKey === 251)) ||
      itemDataKey === MISSING_DATA;

    // if data is not missing or null, we assign it to lastValue, in order to always have the most recent value
    if (!isMissingData && itemDataKey !== null) {
      lastValue = itemDataKey;
    }

    // create the item for the chart
    const dataItem = {
      date: item.date,
      // this is the line that has been integrated with estimated data when actual data is missing
      estimated_data: dataKey === DATA_KEYS.RANK && itemDataKey === 251 ? null : lastValue,
      // this is the actual data that has null whenever the data is missing
      [dataKey]: isMissingData ? null : itemDataKey
    };

    dataArray.push(dataItem as DataItem);
  }

  return dataArray;
};
