import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

// Components
import Accordion from 'components/Accordion';
import AddKeywordManual from './AddKeywordManual';
import AddKeywordShuffle from 'pages/app/Application/KeywordTrackingTable/AddKeywordTabs/AddKeywordShuffle';
import AddKeywordPanel from './AddKeywordPanel';
import FeedbackToast from 'components/FeedbackToast';
import ComingSoonMessage from 'components/ComingSoonMessage';
import AddRankedKeyword from 'pages/app/Application/KeywordTrackingTable/AddKeywordTabs/AddRankedKeyword';
import AddCompetitorKeyword from 'pages/app/Application/KeywordTrackingTable/AddKeywordTabs/AddCompetitorKeyword';

// Style
import { SelectedTab } from './styled';
import { typePrimaryColor } from 'theme/colors';

// Hooks
import useApp from 'hooks/useApp';
import useFilters from 'hooks/useFilters';

// Fetchers
import { addKeywordsToAppKeywordLists } from 'api/api';

// Constants
import { FETCH_STATE } from 'api';

// Contexts
import UntrackedRankedKeywordsProvider from 'contexts/Keywords/UntrackedRanked';
import CompetitorKeywordsProvider from 'contexts/Keywords/Competitor';

const TABS = [
  {
    index: 0,
    label: 'manual',
    content: <AddKeywordManual />
  },
  {
    index: 1,
    label: 'ranked',
    content: (
      <UntrackedRankedKeywordsProvider>
        <AddRankedKeyword />
      </UntrackedRankedKeywordsProvider>
    )
  },
  {
    index: 2,
    label: 'competitor',
    content: (
      <CompetitorKeywordsProvider>
        <AddCompetitorKeyword />
      </CompetitorKeywordsProvider>
    )
  },
  {
    index: 3,
    label: 'shuffle',
    content: <AddKeywordShuffle />
  },
  {
    index: 4,
    label: 'android',
    content: <ComingSoonMessage />,
    disabled: true
  }
];

const tabProps = (index) => {
  return {
    id: `add-keywords-tab-${index}`,
    'aria-controls': `add-keywords-tabpanel-${index}`
  };
};

/**
 * @name AddKeywordsTabs
 * @description tab groups containing the various tables to add keywords to tracked table (only to currently displayed list
 * @param {function} refetchKeywords re-fetches tracked table keywords
 * @param {Number} listSelectedId keyword list id to add keywords to
 * @param {Array} trackedKeywords keywords currently in tracked table
 * */

function AddKeywordsTabs({ refetchKeywords, listSelectedId, trackedKeywords }) {
  const { t } = useTranslation('components', { keyPrefix: 'KeywordTrackingTable.AddKeywordTabs' });
  // manage tab change
  const [tabIndex, setTabIndex] = useState(0);
  const handleChange = (_e, newValue) => {
    setTabIndex(newValue);
  };

  // manage feedback modal state
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const onFeedbackClose = () => {
    setFeedbackOpen(false);
  };

  // project and app details for api
  const { projectId, appId, platform } = useParams();
  const { status: appDetailsStatus, app } = useApp();

  // add keywords payload
  const initialPayload = {
    addIndividualTerms: false,
    keywords: ''
  };

  const [payload, setPayload, resetPayload] = useFilters(initialPayload);

  const {
    status: addKeywordsStatus,
    mutateAsync: addKeywords,
    variables: addKwVariables
  } = useMutation(
    (mutationData) => {
      return addKeywordsToAppKeywordLists({
        projectId,
        appId,
        platform: app.platform,
        keywordListIds: listSelectedId.toString(),
        ...payload,
        ...mutationData
      });
    },
    {
      onSuccess: async (responseData) => {
        // get the count of keywords added from the resp message
        // (if you try to add a keywords that already existed, that won't be included in the count)
        const count = parseInt(responseData.msg[0]);
        setFeedbackOpen(true);
        setFeedbackMessage(t('keywords_added_successfully'));
        refetchKeywords();
        resetPayload();
      },
      onError: () => {
        setFeedbackOpen(true);
        setFeedbackMessage(t('error_adding_keywords'));
      }
    }
  );

  // disable add keywords button when
  // app is details are still fetching,
  // adding keyword loading
  // there are no keywords in payload
  const isAddButtonDisabled =
    [appDetailsStatus, addKeywordsStatus].some((el) => el === FETCH_STATE.LOADING) ||
    payload.keywords === '';

  const onAddKeywordsClick = async (mutationData, cb) => {
    await addKeywords(mutationData, {
      onSuccess: () => {
        if (cb) {
          cb();
        }
      }
    });
    // when 'add as csv' ready, add if statement here
  };

  const [expanded, setExpanded] = useState(false);
  const handleExpand = (selected) => {
    if (tabIndex === selected) {
      setExpanded((prevExpanded) => !prevExpanded);
      return;
    }
    setExpanded(true);
  };

  return (
    <Box mt={5}>
      <Typography variant="subtitle2" gutterBottom ml={2}>
        {t('add_keywords')}
      </Typography>
      <Accordion
        expanded={expanded}
        title={
          /*tabs Navbar*/
          <Box>
            <Tabs
              value={tabIndex}
              onChange={handleChange}
              aria-label="add keywords tabs"
              sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: typePrimaryColor,
                  height: '1px'
                },
                width: 'fit-content'
              }}
            >
              {TABS.map(({ label, index, disabled }) => (
                <SelectedTab
                  label={t(label)}
                  {...tabProps(index)}
                  key={label}
                  onClick={() => handleExpand(index)}
                  disabled={disabled}
                />
              ))}
            </Tabs>
          </Box>
        }
        content={
          /*tabs panels content*/
          <Box sx={{ width: '100%' }}>
            {TABS.map(({ label, index, content }) => (
              <AddKeywordPanel value={tabIndex} index={index} key={label}>
                {/* allows to pass props to child component */}
                {React.cloneElement(content, {
                  payload,
                  setPayload,
                  status: addKeywordsStatus,
                  isDisabled: isAddButtonDisabled,
                  onClick: onAddKeywordsClick,
                  buttonText: t('add_keywords'),
                  addKwVariables,
                  trackedKeywords
                })}
              </AddKeywordPanel>
            ))}
            {/*feedback snackbar*/}
            <FeedbackToast
              open={feedbackOpen}
              onClose={onFeedbackClose}
              message={feedbackMessage}
              status={addKeywordsStatus}
            />
          </Box>
        }
      />
    </Box>
  );
}

AddKeywordsTabs.propTypes = {
  refetchKeywords: PropTypes.func,
  listSelectedId: PropTypes.number,
  trackedKeywords: PropTypes.array
};
export default AddKeywordsTabs;
