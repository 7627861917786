// Components
import RecommendedKeyword from './RecommendedKeyword';

// Hooks
import useOKSTable from 'hooks/useOKSTable';
import useApp from 'hooks/useApp';

// Constants
import { ACTIONS, FILTER_OPTIONS } from 'constants/OKS';

// Types
import { RecommendedKeyword as Keyword } from 'types/OKS/Metadata';

interface Props {
  keywords: Keyword[];
}

/**
 * Renders the list of recommended keywords and handles active state
 *
 * @param keywords   List of keywords
 */
function RecommendedKeywords({ keywords }: Props) {
  const {
    state: { recommendedKeyword },
    dispatch
  } = useOKSTable();

  const { translateKeywords } = useApp();
  const handleActive = (keyword: Keyword) => {
    dispatch({ type: ACTIONS.SET_RECOMMENDED_KEYWORD, payload: keyword });
    dispatch({ type: ACTIONS.SET_FILTER, payload: FILTER_OPTIONS.ALL });
  };

  return (
    <>
      {keywords.map((keyword) => (
        <RecommendedKeyword
          showTranslation={translateKeywords}
          name={keyword.name}
          onClick={() => handleActive(keyword)}
          isActive={keyword.name === recommendedKeyword?.name}
          translation={keyword.en_translation}
          popularityScore={Number(keyword.search_popularity)}
          rank={keyword.rank}
          key={keyword.name}
        />
      ))}
    </>
  );
}

export default RecommendedKeywords;
