import { useMutation } from '@tanstack/react-query';

// Fetchers
import { generateAIAssets } from 'api/api';

interface Props {
  projectId: number;
  appId: number;
  platform: string;
  promptId: number;
  keywordsData: { [key: string]: {} };
}

/**
 * Fetcher to generate AI assets
 * */

function useGenerateAIAssets(
  { projectId, appId, platform, promptId, keywordsData }: Props,
  { onSuccess, onError }: { onSuccess: () => void; onError: () => void }
) {
  return useMutation(
    () => generateAIAssets({ projectId, appId, platform, promptId, keywordsData }),
    {
      onSuccess,
      onError
    }
  );
}

export default useGenerateAIAssets;
