import React, { PropsWithChildren } from 'react';
import CssBaseline from '@mui/material/CssBaseline';

// Components
import GlobalStyle from 'components/GlobalStyle';

// Styles
import { Root } from './styled';

/**
 * @description Layout for loader
 * @param  {React.ReactNode} children
 */
const Loader = ({ children }: PropsWithChildren) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Root>
  );
};

export default Loader;
