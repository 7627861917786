import styled from '@emotion/styled';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import MuiAvatar from '@mui/material/Avatar';

export const Footer = styled.div`
  background-color: ${(props) => props.theme.sidebar.footer.background} !important;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  padding: ${(props) => props.theme.spacing(3)} ${(props) => props.theme.spacing(7)}
    ${(props) => props.theme.spacing(6)} ${(props) => props.theme.spacing(8)};
`;

export const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};

  span {
    background-color: ${(props) => props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }

  & .MuiAvatar-root {
    height: ${(props) => props.theme.spacing(9)};
    width: ${(props) => props.theme.spacing(9)};
  }
`;

export const LogoutButton = styled(Button)`
  color: ${(props) => props.theme.palette.text.primary};
  background-color: ${(props) => props.theme.footer.background};
  height: 32px;
  font-size: 14px;
`;

export const Avatar = styled(MuiAvatar)`
  cursor: pointer;
`;
