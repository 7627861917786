import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

/**
 * @name MenuItemLabel
 * @description Custom MenuItem for list manager label, containing edit and delete buttons
 * @param {number} id
 * @param {string} name
 * @param {function} onEdit
 * @param {function} onDelete
 * @param {function} onListChange
 * @param {boolean} readonly
 * @param {boolean} allowDelete
 */

const DEFAULT = 'DEFAULT';
function MenuItemLabel({ id, name, onEdit, onDelete, onListChange, readonly, allowDelete }) {
  const isDefault = name.toUpperCase() === DEFAULT;
  return (
    <Stack
      direction="row"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      key={id}
      onClick={() => onListChange({ id, name })}
    >
      <Tooltip title={name} arrow>
        <Box sx={{ width: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</Box>
      </Tooltip>
      {/* when readonly or Default list, hide edit and delete functions*/}
      {!readonly && !isDefault && (
        <Box>
          <IconButton onClick={(e) => onEdit(e, { id, name })} size="small">
            <EditIcon color="info" />
          </IconButton>
          {/* when only one list available don't allow deletion*/}
          {allowDelete && (
            <IconButton onClick={(e) => onDelete(e, { id, name })} size="small">
              <DeleteIcon color="error" />
            </IconButton>
          )}
        </Box>
      )}
    </Stack>
  );
}

MenuItemLabel.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onListChange: PropTypes.func,
  readonly: PropTypes.bool,
  allowDelete: PropTypes.bool
};
export default MenuItemLabel;
