import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

// Styles
import { Root } from './styled';

interface Props {
  color?: 'secondary' | 'inherit' | 'primary' | 'error' | 'info' | 'success' | 'warning';
  size?: number;
}

function Loader({ color = 'secondary', size = 40 }: Props) {
  return (
    <Root>
      <CircularProgress size={size} color={color} />
    </Root>
  );
}

export default Loader;
