import React, { ForwardedRef, forwardRef } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';

// Utils
import { isEmpty, buildUrl } from 'utils/utils';

// Hooks
import useApp from 'hooks/useApp';

// Types
import { Pages } from 'components/Sidebar/Navigation/sidebarUtilsItems';

// Styles
import { Item, ExpandLessIcon, Badge, Title, ExpandMoreIcon, Divider } from './styled';

// Components
import ReportBugItem from 'components/Sidebar/Items/ReportBug';
import SharingCenterItem from 'components/Sidebar/Items/SharingCenter';
import ProjectItem from 'components/Sidebar/Items/Project';

// Types
import { PLATFORMS } from 'types/Platform';

// Constants
import { URLS } from 'constants/URL';

const CustomRouterLink = forwardRef((props: any, ref: ForwardedRef<HTMLDivElement>) => (
  <div ref={ref}>
    <NavLink {...props} />
  </div>
));

CustomRouterLink.displayName = 'CustomRouterLink';

const SidebarNavListItem = ({
  title: titleProp,
  href,
  children,
  icon: Icon,
  badge,
  type
}: {
  title: string;
  href: string;
  children?: React.ReactNode;
  icon: any;
  badge?: string;
  type: Pages;
}) => {
  const { t } = useTranslation('common');
  const title = t(titleProp);

  switch (type) {
    case Pages.REPORT_A_BUG:
      return <ReportBugItem icon={Icon} title={title} href={href} />;

    case Pages.OPEN_SHARING_CENTER:
      return <SharingCenterItem icon={Icon} title={title} />;

    case Pages.QUICK_ACCESS:
      return (
        <NestedListItem title={title} icon={Icon}>
          {children}
        </NestedListItem>
      );

    case Pages.PROJECTS:
      return <ProjectItem icon={Icon} title={title} />;

    case Pages.DASHBOARD:
    case Pages.CREATE_NEW_PROJECT:
    case Pages.USER_SETTINGS:
    case Pages.TRACKING_TABLE:
    case Pages.OKS:
    case Pages.CHANGES_BACKLOG:
    case Pages.AI_LABS:
      return <ListItem href={href} title={title} icon={Icon} badge={badge} />;

    default:
      return null;
  }
};

export default SidebarNavListItem;

export function ListItem({
  title,
  href,
  icon: Icon,
  badge
}: {
  title: string;
  href: string;
  icon: any;
  badge?: string;
}) {
  const { projectId, appId, platform } = useParams();

  return (
    <Item
      component={CustomRouterLink}
      to={buildUrl(href, { projectId, appId, platform })}
      activeclassname="active"
      disabled={(href === URLS.OKS || href === URLS.AI_LABS) && platform === PLATFORMS.ANDROID}
    >
      <Icon />
      <Title>
        {title}
        {badge && <Badge label={badge} />}
      </Title>
    </Item>
  );
}

export function NestedListItem({
  title,
  children,
  icon: Icon
}: {
  title: string;
  children: React.ReactNode;
  icon: any;
}) {
  const { app } = useApp();
  const [open, setOpen] = React.useState(false);

  const hasApp = !isEmpty(app || {});

  const handleToggle = () => {
    setOpen((state) => !state);
  };

  return (
    <React.Fragment>
      {(open || hasApp) && <Divider />}
      <Item isActive={open} onClick={handleToggle}>
        <Icon />
        <Title>{title}</Title>
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Item>
      <Collapse in={open}>{children}</Collapse>
      {(open || hasApp) && <Divider />}
    </React.Fragment>
  );
}
