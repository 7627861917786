import React, { ChangeEvent } from 'react';
import i18n from 'i18next';
import dayjs, { Dayjs } from 'dayjs';

// Types
import {
  AssetTypesKeys,
  Experiment,
  ExperimentBacklog,
  ResultKeys,
  StatusKeys
} from 'types/ChangesBacklog';

// Components
import Diff from 'pages/app/Application/ChangesBacklog/Diff';
import { NoBorderSelect } from 'components/Select/variations';

// Styles
import {
  Wrapper,
  Text,
  DatePickerCell,
  CurrentCell,
  CommentCell
} from 'pages/app/Application/ChangesBacklog/HistoricalMetadata/styled';

// Utils
import {
  assetOptions,
  getPreviousValue,
  statusOptions,
  getPreviousExperiment
} from 'pages/app/Application/ChangesBacklog/AddMetadata/utils';

export const resultOptions = [
  {
    value: ResultKeys.Win,
    label: (
      <Wrapper color={'#eafef4'}>
        <Text color={'#03aa58'}>Win</Text>
      </Wrapper>
    )
  },
  {
    value: ResultKeys.Loss,
    label: (
      <Wrapper color={'#ffecec'}>
        <Text color={'#fc4440'}>Loss</Text>
      </Wrapper>
    )
  },
  {
    value: ResultKeys.Pending,
    label: (
      <Wrapper color={'#eafef4'}>
        <Text>Pending</Text>
      </Wrapper>
    )
  },
  {
    value: ResultKeys.Tie,
    label: (
      <Wrapper color={'#f0f0f0'}>
        <Text color={'#686666'}>Tie</Text>
      </Wrapper>
    )
  }
];

const translationPath = 'pages:ChangesBacklog.TableColumns';

export const COLUMNS = (
  languageOptions: { value: number; label: string }[],
  onUpdateRow: (id: number, row: ExperimentBacklog) => void,
  updateDate: (date: Dayjs, experimentId: number) => void,
  experiments: Experiment[]
) => [
  {
    field: 'name',
    headerName: i18n.t(`${translationPath}.experiment_name`)
  },
  {
    field: 'result',
    headerName: i18n.t(`${translationPath}.results`),
    renderCell: ({ row }: { row: ExperimentBacklog }) => {
      const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onUpdateRow(row.id, { ...row, result: e.target.value });
      };
      return (
        <NoBorderSelect
          value={row.result}
          id={'results'}
          options={resultOptions}
          onChange={handleChange}
          inputProps={{ IconComponent: () => null }}
          width={100}
        />
      );
    }
  },
  {
    field: 'date',
    align: 'left',
    headerName: i18n.t(`${translationPath}.date`),
    minWidth: 120,
    renderCell: ({ row }: { row: ExperimentBacklog }) => {
      const date = dayjs(row.date, 'YYYY-MM-DD');
      const handleDateChange = (d: Dayjs) => {
        updateDate(d, row?.experimentId!);
      };

      return <DatePickerCell value={date} format="DD/MM/YYYY" onChange={handleDateChange} />;
    }
  },
  {
    field: 'status',
    minWidth: 100,
    align: 'left',
    headerName: i18n.t(`${translationPath}.status`),
    renderCell: ({ row }: { row: ExperimentBacklog }) => {
      const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value as StatusKeys;
        onUpdateRow(row.id, { ...row, status: value });
      };
      return (
        <NoBorderSelect
          id={'status'}
          options={statusOptions}
          value={row.status}
          onChange={handleChange}
          inputProps={{ IconComponent: () => null }}
          width={100}
        />
      );
    }
  },
  {
    field: 'language',
    headerName: i18n.t(`${translationPath}.language`),
    minWidth: 95,
    renderCell: ({ row }: { row: ExperimentBacklog }) => {
      const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onUpdateRow(row.id, { ...row, language: e.target.value });
      };
      return (
        <NoBorderSelect
          id={'language'}
          options={languageOptions}
          value={row.language}
          onChange={handleChange}
          width={100}
        />
      );
    }
  },
  {
    field: 'asset_type',
    headerName: i18n.t(`${translationPath}.asset_type`),
    minWidth: 95,
    renderCell: ({ row }: { row: ExperimentBacklog }) => {
      const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const assetType = e.target.value as AssetTypesKeys;
        const previousExperiment = getPreviousExperiment(row.date, experiments);
        const previous = getPreviousValue(previousExperiment?.app_backlogs || [], assetType);

        onUpdateRow(row.id, {
          ...row,
          asset_type: assetType,
          previous_value: previous
        });
      };

      return (
        <NoBorderSelect
          id={'asset_type'}
          options={assetOptions}
          value={row.asset_type}
          name={'asset_type'}
          onChange={handleChange}
          width={100}
        />
      );
    }
  },
  {
    field: 'previous_value',
    minWidth: 100,
    headerName: i18n.t(`${translationPath}.before`)
  },
  {
    field: 'beforeLength',
    width: 50,
    align: 'center',
    headerName: i18n.t(`${translationPath}.length`),
    valueGetter: ({ row }: { row: ExperimentBacklog }) => row?.previous_value?.length || 0
  },
  {
    field: 'current_value',
    headerName: i18n.t(`${translationPath}.new`),
    editable: true,
    minWidth: 100,
    renderCell: ({ row }: { row: ExperimentBacklog }) => (
      <CurrentCell>{row?.current_value}</CurrentCell>
    )
  },
  {
    field: 'currentLength',
    align: 'center',
    width: 50,
    headerName: i18n.t(`${translationPath}.length`),
    valueGetter: ({ row }: { row: ExperimentBacklog }) => row?.current_value?.length || 0
  },
  {
    field: 'changes',
    headerName: i18n.t(`${translationPath}.changes`),
    renderCell: ({ row }: { row: ExperimentBacklog }) => (
      <Diff current={row.current_value} previous={row.previous_value} />
    )
  },
  {
    field: 'comment',
    headerName: i18n.t(`${translationPath}.comments`),
    editable: true,
    flex: 1,
    renderCell: ({ row }: { row: ExperimentBacklog }) => <CommentCell>{row?.comment}</CommentCell>
  }
];
