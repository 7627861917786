import { useContext } from 'react';

// Contexts
import { AIResearchContext } from 'contexts/AI/Research';

function useAIResearch() {
  return useContext(AIResearchContext);
}

export default useAIResearch;
