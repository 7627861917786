import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import { useParams } from 'react-router-dom';
// Fetchers
import useCompetitorsKeywordsRankQuery from 'api/queries/Competitors/useCompetitorsKeywordsRank';

// Types
import { Params } from 'types/App';
import { CompetitorKeywords } from 'types/Competitors';

// Utils
import { getFilteredKeywords } from 'contexts/Keywords/Competitor/utils';

// Hooks
import useKeywordTrackingTable from 'hooks/useKeywordTrackingTable';

export const bue = 'asd';

export interface MinMax {
  min: number;
  max: number;
  currentMin: number;
  currentMax: number;
}

interface CompetitorKeywordsContextProps {
  filter: string;
  setFilter: (value: string) => void;
  keywords?: CompetitorKeywords[];
  spMinMax: MinMax;
  rankMinMax: MinMax;
  setSPMinMax: Dispatch<SetStateAction<MinMax>>;
  setRankMinMax: Dispatch<SetStateAction<MinMax>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isAllLoading: boolean;
}
export const CompetitorKeywordsContext = createContext<CompetitorKeywordsContextProps>(
  {} as CompetitorKeywordsContextProps
);

function CompetitorKeywordsProvider({ children }: PropsWithChildren) {
  const [isAllLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [spMinMax, setSPMinMax] = useState<MinMax>({
    min: 0,
    max: 0,
    currentMin: 0,
    currentMax: 0
  });
  const [rankMinMax, setRankMinMax] = useState<MinMax>({
    min: 0,
    max: 0,
    currentMin: 0,
    currentMax: 0
  });

  const { projectId, appId, platform } = useParams() as Params;

  const { keywords: trackedKeywords } = useKeywordTrackingTable();

  const { data: keywords, isLoading } = useCompetitorsKeywordsRankQuery({
    projectId: Number(projectId),
    appId: Number(appId),
    platform,
    requestAll: true
  });

  // Set min and max values for rank and sp
  useEffect(() => {
    if (keywords?.data) {
      const spValues = keywords?.data.map((kw) => kw.popularity);
      const rankValues = keywords?.data.map((kw) => kw.rank);

      const rankMin = Math.min(...rankValues);
      const rankMax = Math.max(...rankValues);
      const spMin = Math.min(...spValues);
      const spMax = Math.max(...spValues);

      setSPMinMax({
        min: spMin,
        max: spMax,
        currentMax: spMax,
        currentMin: spMin
      });

      setRankMinMax({
        min: rankMin,
        max: rankMax,
        currentMax: rankMax,
        currentMin: rankMin
      });
    }
  }, [keywords?.data]);

  // Filter keywords which are inside the rank and popularity range
  const filteredKeywords = getFilteredKeywords(
    keywords?.data,
    trackedKeywords.map((kw) => kw.name),
    filter,
    rankMinMax,
    spMinMax
  );

  // Filter out duplicate keywords
  const unique = filteredKeywords.filter(
    (obj, index) => filteredKeywords.findIndex((item) => item.word === obj.word) === index
  );
  return (
    <CompetitorKeywordsContext.Provider
      value={{
        filter,
        keywords: unique,
        spMinMax,
        rankMinMax,
        setSPMinMax,
        setRankMinMax,
        setFilter,
        isLoading,
        isAllLoading,
        setIsLoading
      }}
    >
      <React.Fragment>{children}</React.Fragment>
    </CompetitorKeywordsContext.Provider>
  );
}

export default CompetitorKeywordsProvider;
