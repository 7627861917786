import styled from '@emotion/styled';
import MuiButton from '@mui/material/Button';
import { DatePicker as DPicker } from '@mui/x-date-pickers-pro';

// Components
import InputText from 'components/InputText';

export const TextInput = styled(InputText)`
  display: flex;
  flex-direction: column;
  height: 37px;
  width: 289px;
  color: ${({ theme }) => theme.palette.secondary.secondary};
  font-size: 10px;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  & .MuiInputBase-input {
    color: ${({ theme }) => theme.palette.secondary.secondary};
    font-size: 10px;
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
    height: 37px;
    width: 289px;
    box-sizing: border-box;
  }
`;

export const Button = styled(MuiButton)`
  background-color: ${({ theme }) => theme.palette.secondary.secondary};
  color: white;
  font-size: 10px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  height: 32px;
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export const FinishButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const DatePicker = styled(DPicker)`
  & .MuiInputBase-root {
    height: 36px;
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;
