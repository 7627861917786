// eslint-disable-next-line import/named
import { useQuery, UseQueryResult } from '@tanstack/react-query';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Fetchers
import { getAppDescription } from 'api/api';

interface Props {
  projectId: number;
  appId: number;
  platform: string;
}

export interface AppDescription {
  description: string;
  app_id: number;
  app_localised_id: number;
}

interface Response {
  data: AppDescription;
}

/**
 * Query function that returns the app description from a store
 *
 * @param projectId   Project id
 * @param appId       App id
 * @param platform    App platform
 *
 * */

function useAppDescriptionQuery({ projectId, appId, platform }: Props): UseQueryResult<Response> {
  return useQuery<Response>({
    queryKey: [CACHE_KEYS.APP_DESCRIPTION, { projectId, appId, platform }],
    queryFn: () => getAppDescription({ projectId, appId, platform })
  });
}

export default useAppDescriptionQuery;
