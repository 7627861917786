import { useMutation } from '@tanstack/react-query';

// Fetchers
import { createChangesBacklogExperiment } from 'api/api';

interface Props {
  projectId: string;
  appId: string;
  platform: string;
}

/**
 * Crete experiment mutation
 *
 * @param projectId   Project id
 * @param appId       App id
 * @param platform    App platform
 */
function useExperimentsMutation({ projectId, appId, platform }: Props) {
  return useMutation(({ name, date }: { name: string; date: string }) =>
    createChangesBacklogExperiment({ projectId, appId, platform, name, date })
  );
}

export default useExperimentsMutation;
