import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Constants
import { STATUS } from 'constants/Auth';

// Hooks
import useAuth from 'hooks/useAuth';

// Styles
import { GoogleWrapper } from './styled';

const GOOGLE_BUTTON_VARIANTS = {
  SIGN_UP: 'signup_with',
  SIGN_IN: 'signin_with'
};

interface Props {
  setSignInStatus: (status: string) => void;
  setIsGoogleSubmitting: (status: boolean) => void;
  variant?: string;
}

/**
 * @description Renders Google OAuth button with provided variant text.
 * @param  {function} setSignInStatus if set to either 'GOOGLE_SIGN_IN' or 'GOOGLE_SIGN_UP' depending on provided variant
 * hides form and displays next screen
 * @param  {function} setIsGoogleSubmitting Disables global form submit button when user is using Google OAuth
 * @param  {'SIGN_UP' | 'SIGN_IN'} variant Type of text to be displayed on the button
 */
function GoogleButton({ setSignInStatus, setIsGoogleSubmitting, variant = 'SIGN_UP' }: Props) {
  const { t } = useTranslation('components', {
    keyPrefix: 'GoogleButton'
  });
  const { signIn, setErrorGoogle } = useAuth();
  const divRef = useRef(null);
  const googleOAuthClientId = process.env.GOOGLE_OAUTH_CLIENT_ID;

  useEffect(() => {
    // If window, google object or div is not defined
    // don't initialize Google accounts script
    if (typeof window === 'undefined' || !window?.google || !divRef.current) {
      return;
    }

    try {
      window.google.accounts.id.initialize({
        client_id: googleOAuthClientId,
        callback: async (response) => {
          const { credential } = response;
          // Disable sign up button to avoid confusion
          setIsGoogleSubmitting(true);
          try {
            // Set the `remember` user to true for Google OAuth.
            await signIn('GOOGLE', { credential, remember: true });
            // If the Google OAuth was done from SignUp or SignIn form.
            setSignInStatus(variant === 'SIGN_UP' ? STATUS.GOOGLE_SIGN_UP : STATUS.GOOGLE_SIGN_IN);
          } catch (error) {
            // Display error if there was a problem during Sign In or Sign In
            setErrorGoogle(t('error_during_google_sign_in'));
          } finally {
            setIsGoogleSubmitting(false);
          }
        }
      });
      // Render button with provided text
      window.google.accounts.id.renderButton(divRef.current, {
        theme: 'outline',
        // Same width as the entire SignUp / SignIn wrapper
        width: 327,
        text: GOOGLE_BUTTON_VARIANTS[variant]
      });
    } catch (error) {
      // Display error if there was a problem loading the component
      setErrorGoogle(t('error_loading_google_sign_in'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleOAuthClientId, window.google, divRef.current]);

  return <GoogleWrapper ref={divRef} />;
}

export default GoogleButton;
