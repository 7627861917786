import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';

// Components
import Button from 'components/AddButton';

export const CompetitorContainer = styled(Grid)`
  display: grid;
  grid-template-columns: 28px 28px 28px 28px 28px;
  grid-template-rows: 28px 28px 28px;
  max-height: 84px;
  overflow: hidden;
`;

export const AddButton = styled(Button)`
  border: 1px solid #d3d3d3;
  height: 24px;
  width: 24px;
  border-radius: 4.5px;
  background-color: ${({ theme }) => theme.palette.text.primary};
  &&:hover {
    background-color: ${({ theme }) => theme.palette.text.primary};
  }
  // style icon inside
  & > svg {
    font-size: 10px;
  }
`;
