import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// Components
import InputText from 'components/InputText';

export const TextArea = styled(InputText)`
  width: 70%;
  & .MuiInputBase-root {
    padding: ${({ theme }) => theme.spacing(2)};
  }
  & .MuiFormControl-root {
    width: 100%;
  }
`;

export const Text = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export const SaveButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;
