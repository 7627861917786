import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Types
import { PLATFORMS } from 'types/Platform';

/**
 * HOC that checks if the current platform is Android and renders a message if so.
 *
 * @param Component React component
 */
function withAndroidCheck(Component: () => JSX.Element) {
  // eslint-disable-next-line react/display-name
  return (props: any) => {
    const { t } = useTranslation('common');
    const { platform } = useParams();

    if (platform === PLATFORMS.ANDROID) {
      return (
        <Grid mt={4} display="flex" justifyContent="center">
          <Typography variant="h6">{t('only_for_ios')}</Typography>
        </Grid>
      );
    }
    return <Component {...props} />;
  };
}

export default withAndroidCheck;
