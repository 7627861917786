import React, { PropsWithChildren, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';

// Components
import GlobalStyle from 'components/GlobalStyle';
import Sidebar from 'components/Sidebar';
import Footer from 'components/Footer';

// Utils
import { appItems, appItemsAI } from 'components/Sidebar/Navigation/sidebarUtilsItems';

// Styles
import { Root, MainContent, AppContent, DrawerContainer, drawerWidth, IconButton } from './styled';

// Hooks
import useProject from 'hooks/useProject';

/**
 * @name Layout
 * @description Main layout for Toaster app pages
 *
 * @param  {React.ReactNode} children
 */
const Layout = ({ children }: PropsWithChildren) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const { project } = useProject();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const appRoutes = project?.ai_project ? appItemsAI : appItems;
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <DrawerContainer>
        <Grid item sx={{ display: { xs: 'block', md: 'none' }, position: { xs: 'absolute' } }}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerToggle}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        </Grid>
        {/* mobile */}
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth, overflow: 'visible' } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={appRoutes}
          />
        </Box>
        {/* desktop */}
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth, overflow: 'visible' } }}
            items={appRoutes}
          />
        </Box>
      </DrawerContainer>
      <AppContent>
        <MainContent>
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
};

export default Layout;
