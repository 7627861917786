import MuiButton from '@mui/material/Button';
import styled from '@emotion/styled';

export const Button = styled(MuiButton)`
  background-color: ${({ theme }) => theme.palette.button.primary.default};
  &:disabled {
    background-color: ${({ theme }) => theme.palette.button.primary.disabled};
  }
  &:hover {
    background-color: ${({ theme }) => theme.palette.button.primary.hover};
  }
`;
