import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ClearIcon from '@mui/icons-material/Clear';

// Hooks
import useFilters from 'hooks/useFilters';
import useSearchApps from 'hooks/useSearchApps';

// Components
import SearchBarWithIcon from 'components/SearchBarWithIcon';
import FetchStatesIndicator from 'components/FetchStatesIndicator';
import AddAppRow from './AddAppRow';
import AddAppResultsRowLoading from 'components/AddAppResultsRow/AddAppResultsRowLoading';
import Select from 'components/Select';

// Types
import { PLATFORMS } from 'types/Platform';
import { Competitor } from 'types/Competitors';
import { FETCH_STATE_ENUM } from 'api/constants';

// Styles
import {
  DialogContent,
  ResultsContainer,
  SelectsContainer,
  CollapsableAlert,
  InfoDetails
} from './styled';

const SELECT_WITH = 170;
const SKELETON_LENGTH = 3;

const params = {
  platform: PLATFORMS.IPHONE,
  country: 'US',
  locale: 'en_us',
  searchId: ''
};

const infoDetails = [
  {
    details: (
      <Typography>
        <Trans i18nKey={'components:DashboardAddApp.info.summaryIOS'} />
      </Typography>
    ),
    summary: (
      <Typography>
        <Trans i18nKey={'components:DashboardAddApp.info.iOS'} />
      </Typography>
    )
  },
  {
    details: (
      <Typography>
        <Trans i18nKey={'components:DashboardAddApp.info.summaryAndroid'} />
      </Typography>
    ),
    summary: (
      <Typography>
        <Trans i18nKey={'components:DashboardAddApp.info.android'} />
      </Typography>
    )
  }
];

export interface SubmitData {
  app: any;
  country: string;
  locale: string;
  projectId?: string;
  platform: PLATFORMS;
}

interface Props {
  initialParams?: {
    platform: PLATFORMS;
    country: string;
    locale: string;
    searchId: string;
  };
  onSubmit: (data: SubmitData) => void;
  isOpen: boolean;
  handleOpen?: () => void;
  onClose: () => void;
  status: { [id: string]: FETCH_STATE_ENUM };
  title: string;
  buttonText: string;
  isTracked: ({
    id,
    global_id,
    locale,
    country,
    platform
  }: {
    id: number;
    global_id: number;
    locale: string;
    country: string;
    platform: string;
  }) => boolean;
  platformOptions: { value: string; label: string }[];
  disableSelection?: boolean;
  suggestions?: Competitor[];
  isLoadingSuggestions?: boolean;
}

function AppModal({
  initialParams = params,
  onSubmit,
  isOpen,
  onClose,
  status,
  title,
  buttonText,
  isTracked,
  platformOptions,
  disableSelection,
  suggestions = [],
  isLoadingSuggestions
}: Props) {
  const { t } = useTranslation('components');
  const { projectId } = useParams() as { projectId: string };

  const [isAlertOpen, setAlertOpen] = useState(true);

  const [searchParams, onParamInput, resetParams] = useFilters<typeof initialParams, string>(
    initialParams
  );

  useEffect(() => {
    if (initialParams?.platform) {
      onParamChange('platform', initialParams?.platform);
    }
  }, [initialParams?.platform]);

  const {
    searchAppsStatus,
    onSubmitSearch,
    searchResults,
    resetSearch,
    countryOptions,
    localeOptions,
    optionsStatus
  } = useSearchApps(searchParams, onParamInput, initialParams, projectId);

  const handleAddAppClose = () => {
    onClose();
    resetParams();
    resetSearch();
  };

  const onParamChange = (key: keyof typeof initialParams, value: string) => {
    onParamInput(key, value);
    resetSearch();
  };

  const onResetFilters = () => {
    resetParams();
    resetSearch();
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmitSearch();
  };

  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleAddAppClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box onSubmit={handleSubmit} component="form">
            <SelectsContainer>
              <Select
                id="platform"
                label={t('common:platform')}
                value={searchParams.platform}
                options={platformOptions}
                onChange={(ev) => onParamChange('platform', ev.target.value)}
                width={SELECT_WITH}
                disabled={disableSelection}
              />
              <Select
                id="country"
                label={t('common:country')}
                value={searchParams.country}
                options={countryOptions}
                onChange={(ev) => onParamChange('country', ev.target.value)}
                status={optionsStatus}
                width={SELECT_WITH}
                disabled={disableSelection}
              />
              <Select
                id="locale"
                label={t('common:locale')}
                value={searchParams.locale}
                options={localeOptions}
                onChange={(ev) => onParamChange('locale', ev.target.value)}
                status={optionsStatus}
                width={SELECT_WITH}
                disabled={disableSelection}
              />
            </SelectsContainer>
            <SearchBarWithIcon
              onChange={(value) => onParamChange('searchId', value)}
              value={searchParams.searchId}
              id="dashboard-add-an-app"
            />
            <CollapsableAlert isOpen={isAlertOpen} severity="info" handleClose={handleCloseAlert}>
              <Typography variant="h6" gutterBottom>
                {t('DashboardAddApp.info.title')}
              </Typography>
              {infoDetails.map(({ summary, details }, i) => (
                <InfoDetails key={i} details={details} summary={summary} />
              ))}
            </CollapsableAlert>
            <Button
              type="submit"
              variant="outlined"
              disabled={searchParams.searchId === ''}
              sx={{ margin: '20px 0', width: '100%' }}
            >
              {t('DashboardAddApp.search_apps')}
            </Button>
          </Box>
          <Box sx={{ textAlign: 'center', height: '25px' }}>
            {searchAppsStatus !== FETCH_STATE_ENUM.IDLE && (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {searchAppsStatus === FETCH_STATE_ENUM.SUCCESS && searchResults.length > 0 && (
                  <Typography variant="h4">
                    {t('DashboardAddApp.resultsWithCount', { count: searchResults.length })}
                  </Typography>
                )}
                <Tooltip title={t('DashboardAddApp.reset_search')}>
                  <IconButton type="submit" aria-label="submit" onClick={onResetFilters}>
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
          {searchAppsStatus === FETCH_STATE_ENUM.SUCCESS && searchResults.length > 0 ? (
            <ResultsContainer justifyContent="flex-start">
              <Grid sx={{ textAlign: 'center' }}>
                {searchResults.map((app) => (
                  <AddAppRow
                    app={app}
                    key={app.global_id || app.app_id}
                    country={searchParams.country}
                    locale={searchParams.locale}
                    platform={searchParams.platform}
                    onSubmit={onSubmit}
                    status={status}
                    buttonText={buttonText}
                    isTracked={isTracked({
                      id: app.id || app.app_id,
                      global_id: app.global_id,
                      locale: searchParams.locale,
                      country: searchParams.country,
                      platform: searchParams.platform
                    })}
                  />
                ))}
              </Grid>
            </ResultsContainer>
          ) : searchAppsStatus === FETCH_STATE_ENUM.IDLE && suggestions.length > 0 ? (
            <ResultsContainer justifyContent="flex-start">
              <Grid sx={{ textAlign: 'center' }}>
                {suggestions.map((app) => (
                  <AddAppRow
                    app={app}
                    key={app.global_id || app.app_id}
                    country={searchParams.country}
                    locale={searchParams.locale}
                    platform={searchParams.platform}
                    onSubmit={onSubmit}
                    status={status}
                    buttonText={buttonText}
                    isTracked={isTracked({
                      id: app.id || app.app_id,
                      global_id: app?.global_id,
                      locale: searchParams.locale,
                      country: searchParams.country,
                      platform: searchParams.platform
                    })}
                  />
                ))}
              </Grid>
            </ResultsContainer>
          ) : (
            <ResultsContainer justifyContent="center">
              <FetchStatesIndicator
                status={isLoadingSuggestions ? FETCH_STATE_ENUM.LOADING : searchAppsStatus}
                idleText={t('DashboardAddApp.search_apps')}
                loadingChild={
                  <>
                    {Array.from(Array(SKELETON_LENGTH).keys()).map((el) => (
                      <AddAppResultsRowLoading key={el} />
                    ))}
                  </>
                }
                errorText={t('DashboardAddApp.error_searching_apps')}
                successEmptyText={t('DashboardAddApp.no_apps_found')}
                resultsNumber={searchResults?.length}
              />
            </ResultsContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddAppClose}>{t('DashboardAddApp.close')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default AppModal;
