import { useQuery } from '@tanstack/react-query';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Fetchers
import { getCompetitorKeywordsRanking } from 'api/api';

// Types
import { AppParams } from 'types/App';

interface Props extends AppParams {
  keywordIds?: string;
  keywordStrings?: string;
  requestAll?: boolean;
}

/**
 * Query function that returns the competitors keywords ranking
 *
 * @param projectId   Project id
 * @param appId       App id
 * @param platform    App platform
 */
function useCompetitorsKeywordsRankQuery({
  projectId,
  appId,
  platform,
  keywordIds,
  keywordStrings,
  requestAll = false
}: Props) {
  return useQuery({
    queryKey: [
      CACHE_KEYS.COMPETITORS_KEYWORDS_RANK,
      { projectId, appId, platform, keywordIds, keywordStrings, requestAll }
    ],
    queryFn: () =>
      getCompetitorKeywordsRanking({ projectId, appId, platform, keywordIds, keywordStrings }),
    enabled:
      (keywordIds?.length && keywordIds?.length > 0) ||
      (keywordStrings?.length && keywordStrings?.length > 0) ||
      requestAll
  });
}

export default useCompetitorsKeywordsRankQuery;
