import { createContext, useState } from 'react';
import { useQueries, useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Fetchers
import { API, CACHE_KEYS, FETCH_STATE } from 'api';

// Utils
import { createOptionsFromObj, sortBy } from 'utils/utils';

export const initialLocalesListState = {
  iosCombo: [],
  iosCountryOptions: [],
  iosComboStatus: FETCH_STATE.LOADING,
  androidCountryOptions: [],
  androidLanguageOptions: [],
  androidCountriesStatus: FETCH_STATE.LOADING,
  androidLanguagesStatus: FETCH_STATE.LOADING
};
const LocalesListContext = createContext(initialLocalesListState);

/**
 * @name LocalesListProvider
 * @description Context for countries and languages for android and ios.
 * Fetches list of country + locales combo and list of countries and list of languages
 * @param  {object} props
 * @param  {React.ReactNode} props.children
 * @param  {Object} props.mockState Custom state value. Useful for tests and storybook. Defaults to `null`.
 */

function LocalesListProvider({ children }) {
  const { projectId } = useParams();
  // Combo status
  const [iosCombo, setIosCombo] = useState([]);
  const [iosCountryOptions, setIosCountryOptions] = useState([]);
  const [androidCountryOptions, setAndroidCountryOptions] = useState([]);
  const [androidLanguageOptions, setAndroidLanguageOptions] = useState([]);

  // COUNTRIES AND LANGUAGES
  // IOS
  const { status: iosComboStatus } = useQuery({
    queryKey: [CACHE_KEYS.APPS_COUNTRY_LANG_COMBO],
    queryFn: () => API.countryLangCombo({ projectId }),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (responseData) => {
      const data = responseData.data;
      setIosCombo(data);
      const countryOpts = createOptionsFromObj(data, 'name');
      setIosCountryOptions(countryOpts);
    }
  });

  // ANDROID
  const [{ status: androidCountriesStatus }, { status: androidLanguagesStatus }] = useQueries({
    queries: [
      {
        queryKey: [CACHE_KEYS.APPS_COUNTRIES],
        queryFn: () => API.countries({ projectId }),
        refetchOnWindowFocus: false,
        onSuccess: (responseData) => {
          setAndroidCountryOptions(createOptionsFromObj(responseData.data, 'name'));
        }
      },
      {
        queryKey: [CACHE_KEYS.APPS_LANGUAGES],
        queryFn: () => API.languages({ projectId }),
        refetchOnWindowFocus: false,
        onSuccess: (responseData) => {
          setAndroidLanguageOptions(
            createOptionsFromObj(responseData.data, 'name', sortBy('label'))
          );
        }
      }
    ]
  });

  const LocalesListProviderValue = {
    iosCombo,
    iosCountryOptions,
    iosComboStatus,
    androidCountryOptions,
    androidLanguageOptions,
    androidCountriesStatus,
    androidLanguagesStatus
  };

  return (
    <LocalesListContext.Provider value={LocalesListProviderValue}>
      {children}
    </LocalesListContext.Provider>
  );
}

LocalesListProvider.propTypes = {
  children: PropTypes.node,
  mockState: PropTypes.object
};

export { LocalesListContext, LocalesListProvider };
