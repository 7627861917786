import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import i18n from 'i18next';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

// Components
import SharedUsersList from './SharedUsersList';
import ButtonModal from 'components/ButtonModal';

// Constants
import { PROJECT_ROLES } from 'constants/constants';
import { CACHE_KEYS } from 'api/constants';

// Fetchers
import { API, ERROR } from 'api';

// Hooks
import useProject from 'hooks/useProject';
import useAlert from 'hooks/useAlert';

// Styles
import { FormControl, Card, Button, LoadingButton, ButtonsWrapper, TextField } from './styled';

const SharingCenterSchema = () =>
  Yup.object().shape({
    userEmail: Yup.string().email().required(i18n.t('common:email_required')),
    userRoleId: Yup.string().required(i18n.t('shared:SharingCenter.role_required'))
  });

interface Props {
  noOverflow: boolean;
  isComponent: boolean;
}

/**
 * @name SharingCenter
 * @description Reusable Sharing Center component
 * @param  isComponent If this is `true` that means the SharingCenter is
 * displayed inside a modal, so we are hiding the SharingCenter title and disabling
 * bottom margin.
 * @param  noOverflow If this is passed, then the `SharedUsersList` will
 * not have a scrollbar displayed. Useful, when trying to display SharingCenter inside
 * smaller container (like modal). This prop is passed down to the `SharedUsersList` component.
 */
function SharingCenter({ noOverflow, isComponent }: Props) {
  // We do not specify namespace and keyPrefix, as we need also to translate schema. It does not work otherwise.
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { project } = useProject();
  const { projectId } = useParams();
  const memoSharingCenterSchema = useMemo(() => SharingCenterSchema(), []);

  /**
   * handles sharing the project with the user through the API. It provides `mutate` function
   * which needs `mutationData` to be passed to it. Mutation data is the same as the
   * arguments that API function expects.
   */
  const shareProjectMutation = useMutation(
    (mutationData) => {
      return API.shareProject({ projectId: project.id }, mutationData);
    },
    {
      onSuccess: async (responseData, mutationData) => {
        const { data } = responseData;
        const { user_project_share: userProjectShare } = data;
        const { user_id: userId } = userProjectShare;
        const { userEmail, userRoleId } = mutationData;
        queryClient.setQueryData(
          [CACHE_KEYS.PROJECTS, { projectId: Number(projectId) }],
          (oldData) => {
            return {
              data: {
                ...oldData.data,
                project_users: [
                  ...oldData.data.project_users,
                  { user_id: userId, user_email: userEmail, user_role_id: userRoleId }
                ]
              }
            };
          }
        );

        setAlert(t('shared:SharingCenter.user_shared_successfully'));
      }
    }
  );

  /**
   * @name handleOnSubmit
   * @description manages Formik submit function
   */
  const handleOnSubmit = async (values, { errors, setErrors, setStatus, setSubmitting }) => {
    try {
      await shareProjectMutation.mutateAsync({
        userEmail: values.userEmail,
        userRoleId: values.userRoleId
      });
      setStatus({ success: true });
    } catch (error) {
      let tempErrors = {};
      // If error is raised because of invalid payload
      // print returned errors from the API on the UI
      if (error instanceof ERROR.ApiResponseBadRequest) {
        const { errorData } = error;
        delete Object.assign(errorData, { submit: errorData['non_field_errors'] })[
          'non_field_errors'
        ];

        tempErrors = { ...errorData };
      } else {
        const message = error.message || t('shared:SharingCenter.something_went_wrong');
        tempErrors = { submit: [message] };
        // If this is a general error, display snackbar.
        setAlert(message);
      }

      setErrors(tempErrors);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  const {
    handleSubmit,
    errors,
    values,
    touched,
    status,
    handleBlur,
    handleChange,
    resetForm,
    isValid,
    dirty,
    isSubmitting
  } = useFormik({
    initialValues: {
      userEmail: '',
      userRoleId: '',
      submit: false
    },
    validationSchema: memoSharingCenterSchema,
    onSubmit: handleOnSubmit
  });

  const { userEmail } = values;
  const permissionLevel =
    PROJECT_ROLES.find((e) => e.id === parseInt(values.userRoleId, 10))?.userRoleId || null;

  /**
   * @name handleExit
   * @description this function is invoked when the Modal or Dialog ends its transition.
   * This is very useful, as it enables to trigger change after Modal is unmounted.
   */
  const handleExit = () => {
    resetForm();
  };

  return (
    <Card mb={isComponent ? 6 : 0}>
      <CardContent>
        {isComponent && (
          <Typography component="h1" title={t('shared:SharingCenter.title')} variant="appH1" mb={4}>
            {t('shared:SharingCenter.title')}
          </Typography>
        )}
        <Typography
          component="p"
          title={t('shared:SharingCenter.general_settings')}
          variant="subtitle1"
          mb={2}
        >
          {t('shared:SharingCenter.description')}
        </Typography>
        <form noValidate onSubmit={handleSubmit} id="sharingCenterForm">
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} my={2}>
              <TextField
                fullWidth
                type="email"
                name="userEmail"
                label={t('shared:SharingCenter.add_email')}
                value={userEmail}
                error={Boolean(touched.userEmail && errors.userEmail)}
                helperText={touched.userEmail && errors.userEmail}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4} my={2}>
              <FormControl fullWidth error={Boolean(touched.userRoleId && errors.userRoleId)}>
                <InputLabel id="userRoleId-label">{t('shared:SharingCenter.role')}</InputLabel>
                <Select
                  labelId="userRoleId-label"
                  name="userRoleId"
                  id="userRoleId"
                  label={t('shared:SharingCenter.role')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.userRoleId}
                >
                  {PROJECT_ROLES.map((e) => (
                    <MenuItem key={e.id} value={e.id}>
                      {e.role}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{touched.userRoleId && errors.userRoleId}</FormHelperText>
              </FormControl>
            </Grid>
            <ButtonsWrapper item>
              <ButtonModal
                openModalButtonText={t('shared:SharingCenter.share')}
                handleExit={handleExit}
                title={t('shared:SharingCenter.are_you_sure')}
                isLoading={isSubmitting}
                disabled={!(isValid && dirty)}
                actionButton={
                  <LoadingButton
                    loading={isSubmitting}
                    role="button"
                    disabled={isSubmitting || status?.success}
                    type="submit"
                    form="sharingCenterForm"
                    variant="contained"
                    color="primary"
                    autoFocus
                  >
                    {t('shared:SharingCenter.share')}
                  </LoadingButton>
                }
                body={
                  <Trans
                    i18nKey="shared:SharingCenter.you_will_share_project"
                    userEmail={userEmail}
                    permissionLevel={permissionLevel}
                  >
                    You will share this project with <b>{{ userEmail }}</b> with{' '}
                    <b>{{ permissionLevel }}</b> permissions level.
                  </Trans>
                }
              />
              <Button role="button" type="reset" onClick={resetForm} color="secondary">
                {t('shared:SharingCenter.cancel')}
              </Button>
            </ButtonsWrapper>
          </Grid>
        </form>
        <SharedUsersList noOverflow={noOverflow} />
      </CardContent>
    </Card>
  );
}

export default SharingCenter;
