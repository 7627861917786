import styled from '@emotion/styled';
import Tab from '@mui/material/Tab';

// Theme
import { typePrimaryColor, typeSecondaryColor } from 'theme/colors';

export const SelectedTab = styled(Tab)`
  color: ${typeSecondaryColor};
  border-bottom: 1px solid ${typeSecondaryColor};
  &.Mui-selected {
    color: ${typePrimaryColor};
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-bottom: 1px solid ${typePrimaryColor};
  }
`;
