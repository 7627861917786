import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';

// Styles
import { IconButton } from './styled';

interface Props {
  className?: string;
  disabled?: boolean;
  handleClick: () => void;
}

/**
 *
 * @param className     Styles
 * @param disabled      Is button disabled
 * @param handleClick   CLick handler for the button
 */
function AddButton({ className, disabled, handleClick }: Props) {
  return (
    <IconButton onClick={handleClick} className={className} disabled={disabled}>
      <AddCircleOutlinedIcon fontSize="24px" />
    </IconButton>
  );
}

export default AddButton;
