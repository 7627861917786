import styled from '@emotion/styled';
import MuiBox from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export const RangeInputBox = styled(MuiBox)<{ width: number }>`
  width: ${(props) => props.width}px;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const RangeFilterWrapper = styled(MuiBox)`
  display: flex;
  gap: ${(props) => props.theme.spacing(0.5)};
`;

export const RangeFilterInput = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding: 8.5px 8px;
    height: 32px;
    box-sizing: border-box;
    font-size: 10px;
  }
`;
