import { useContext } from 'react';

// Contexts
import { ProjectAppsContext } from 'contexts/ProjectApps';

const useProjectApps = () => {
  const context = useContext(ProjectAppsContext);

  if (!context) throw new Error('ProjectAppsContext must be placed within ProjectAppsProvider');

  return context;
};

export default useProjectApps;
