import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

// Types
import { Params } from 'types/App';
import { Experiment } from 'types/ChangesBacklog';

// Fetchers
import useExperimentsMutation from 'api/queries/ChangesBacklog/useExperimentsMutation';

// Styles
import { Button, Grid, Title } from './styled';

// Components
import Modal from 'components/Modal';
import AddExperimentModal from 'pages/app/Application/ChangesBacklog/AddMetadata/AddExperimentModal';

// Constants
import { CACHE_KEYS } from 'api/constants';

function CreateExperiment() {
  const queryClient = useQueryClient();
  const { projectId, appId, platform } = useParams() as Params;
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState('');

  const { t } = useTranslation('pages', {
    keyPrefix: 'ChangesBacklog'
  });
  const { mutate, isLoading } = useExperimentsMutation({ projectId, appId, platform });

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleAddName = (name: string) => {
    setName(name);
  };

  // Create a new experiment
  const handleAddList = () => {
    const date = dayjs(new Date()).format('YYYY-MM-DD');
    mutate(
      { name, date },
      {
        onSuccess: (res) => {
          setOpenModal(false);
          queryClient.setQueryData(
            [CACHE_KEYS.CHANGES_BACKLOG, { projectId, appId, platform }],
            (prev?: { data: Experiment[] }) => {
              return {
                data: [...(prev?.data || []), res.data]
              };
            }
          );
        }
      }
    );
  };
  return (
    <Grid container alignItems="center" justifyContent="center" flexDirection="column">
      <Title component="h1">{t('create_first_experiment')}</Title>
      <Button onClick={handleOpenModal} variant="contained" color="primary">
        {t('add_new_list')}
      </Button>
      <Modal
        isLoading={false}
        isOpen={openModal}
        setIsOpen={setOpenModal}
        isComponent
        body={<AddExperimentModal name={name} onChange={handleAddName} />}
        title={t('add_new_list')}
        fullWidth={false}
        actionButton={
          <Button variant="contained" color="primary" onClick={handleAddList} disabled={isLoading}>
            {t('add')}
          </Button>
        }
      />
    </Grid>
  );
}

export default CreateExperiment;
