import { useQuery } from '@tanstack/react-query';
// Fetchers
import { validateAIReviewList } from 'api/api';

// Constants
import { CACHE_KEYS } from 'api/constants';

interface Props {
  projectId: number;
  appId: number;
  platform: string;
  reviewListId: number;
}

/**
 * Handles validating an AI Keyword list
 * */

function useValidateAIReviewList(
  { projectId, appId, platform, reviewListId }: Props,
  { onSuccess, onError }: { onSuccess: () => void; onError: () => void }
) {
  return useQuery({
    queryKey: [CACHE_KEYS.AI_VALIDATE_REVIEW_LIST, { projectId, appId, platform, reviewListId }],
    queryFn: () => validateAIReviewList({ projectId, appId, platform, reviewListId }),
    enabled: false,
    onSuccess,
    onError
  });
}

export default useValidateAIReviewList;
