import { PropsWithChildren } from 'react';

// Styles
import { Button } from './styled';

interface Props {
  onClick: () => void;
  className?: string;
  variant?: 'outlined' | 'text' | 'contained' | undefined;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}
function PrimaryButton({
  children,
  onClick,
  className,
  variant = 'contained',
  disabled = false,
  type = 'button'
}: PropsWithChildren<Props>) {
  return (
    <Button
      disabled={disabled}
      className={className}
      onClick={onClick}
      variant={variant}
      type={type}
    >
      {children}
    </Button>
  );
}

export default PrimaryButton;
