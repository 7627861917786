import React from 'react';
import Grid from '@mui/material/Grid';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

// Components
import Modal from 'components/Modal';
import HistoricalDataGraph from 'components/HistoricalDataGraph';
import DateRangePickerWithShortcuts from 'components/DateRangePickerWithShortcuts';

// Constants
import { DATE_RANGE_OPTIONS } from 'constants/constants';
import { FETCH_STATE_ENUM } from 'api/constants';

interface ResultItem {
  date: string;
  [key: string]: number | string;
  results_number: string;
}
interface Props {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  status: string;
  dates: [Dayjs | null, Dayjs | null];
  setDates: (dates: [Dayjs | null, Dayjs | null]) => void;
  title: string;
  resData: {
    data: {
      results: ResultItem[];
      last_record: {
        last_date: string;
        [key: string]: number | string;
        last_result_number: string;
      };
    };
  };
  dataKey: string;
}

/**
 * @name HistoricalDataGraphModal
 * @description reusable modal to display historical data chart
 * */

function HistoricalDataGraphModal({
  isOpen,
  setIsOpen,
  status,
  dates,
  setDates,
  title,
  resData,
  dataKey
}: Props) {
  const { t } = useTranslation('components', { keyPrefix: 'HistoricalDataGraphModal' });

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isComponent
      maxWidth="md"
      title={title}
      titleTextAlign="center"
      body={<HistoricalDataGraph resData={resData} status={status} dataKey={dataKey} />}
      actionButton={
        <Grid item xs={11} height={80} display="flex" alignItems="center" justifyContent="start">
          <DateRangePickerWithShortcuts
            dates={dates}
            setDates={setDates}
            label={t('choose_range')}
            shortcutOptions={DATE_RANGE_OPTIONS}
            loading={status === FETCH_STATE_ENUM.LOADING}
            disabled={status === FETCH_STATE_ENUM.LOADING}
          />
        </Grid>
      }
    />
  );
}

export default HistoricalDataGraphModal;
