// Styles
import { InputLabel, Typography } from './styled';

interface Props {
  label: string;
  id: string;
  current: number;
  max: number;
}

/**
 * Renders an input label with current and maximum allowed length
 *
 * @param label     Input label
 * @param id        Input id
 * @param current   Current input length
 * @param max       Maximum length allowed
 */
function Label({ label, id, current, max }: Props) {
  return (
    <InputLabel htmlFor={id}>
      <Typography>{label}</Typography>
      <Typography>
        {current}/{max}
      </Typography>
    </InputLabel>
  );
}

export default Label;
