import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import List from '@mui/material/List';

// Components
import SidebarNavSection from '../NavSection';

// Styles
import { Items } from './styled';

// Types
import { AppItems } from 'components/Sidebar/Navigation/sidebarUtilsItems';

interface Props {
  items: AppItems;
}

const AppNav = ({ items }: Props) => {
  const { t } = useTranslation();
  const { appId } = useParams();

  return (
    <>
      {appId && (
        <>
          <List disablePadding>
            <Items>
              {items &&
                items.map((item, i) => (
                  <SidebarNavSection
                    component="div"
                    key={`sidebarnavsection-${i}`}
                    pages={item.pages}
                  />
                ))}
            </Items>
          </List>
        </>
      )}
    </>
  );
};

export default AppNav;
