// eslint-disable-next-line import/named
import { useQuery, UseQueryResult } from '@tanstack/react-query';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Fetchers
import { getProjectAppNote } from 'api/api';

// Types
import { AppNote } from 'types/App';

interface Props {
  projectId: string;
  appId: string;
  platform: string;
}

/**
 * Query function that returns the app notes
 *
 * @param projectId   Project id
 * @param appId       App id
 * @param platform    App platform
 */
function useAppNotesQuery({
  projectId,
  appId,
  platform
}: Props): UseQueryResult<{ data: AppNote }> {
  return useQuery({
    queryKey: [CACHE_KEYS.APP_NOTE, { projectId, appId, platform }],
    queryFn: () => getProjectAppNote({ projectId, appId, platform })
  });
}

export default useAppNotesQuery;
