import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Hooks
import useProject from 'hooks/useProject';

// Styles
import { Title } from './styled';

// Utils
import withAndroidCheck from 'utils/withAndroidCheck';

// Components
import Review from './Review';
import AITable from './Table';
import TableFilters from './TableFilters';

// Contexts
import AIResearchProvider from 'contexts/AI/Research';

function ResearchAI() {
  const { project } = useProject();
  const { t } = useTranslation('pages', {
    keyPrefix: 'AI'
  });

  if (!project.ai_project) {
    return (
      <Grid mt={2} display="flex" justifyContent="center">
        <Typography variant="h6">{t('not_available')}</Typography>
      </Grid>
    );
  }

  return (
    <AIResearchProvider>
      <Title>{t('review')}</Title>
      <Review />
      <Title>{t('main_list')}</Title>
      <TableFilters />
      <AITable />
    </AIResearchProvider>
  );
}

export default withAndroidCheck(ResearchAI);
