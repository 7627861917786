import { useState, useEffect, useRef, FC, MouseEventHandler } from 'react';
import Collapse from '@mui/material/Collapse';

// Styles
import { ExpandLessIcon, ExpandMoreIcon, Item, Title } from 'components/Sidebar/NavListItem/styled';

// Components
import Menu from '../../Menu';

interface Props {
  title: string;
  icon: FC;
}
function ProjectItem({ title, icon: Icon }: Props) {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      // Close the menu if the user clicks outside of it or the Projects item
      if (
        open &&
        ref.current &&
        !ref.current.contains(e.target as Node) &&
        !itemRef.current?.contains(e.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [open]);

  /**
   * Toggle the menu open and closed
   */
  const handleToggle = () => {
    setOpen((state) => !state);
  };

  return (
    <>
      <Item ref={itemRef} isActive={open} onClick={handleToggle}>
        <Icon />
        <Title>{title}</Title>
        {open ? (
          <ExpandMoreIcon sx={{ transform: 'rotate(90deg)' }} />
        ) : (
          <ExpandLessIcon sx={{ transform: 'rotate(90deg)' }} />
        )}
      </Item>
      <Collapse ref={ref} in={open}>
        <Menu />
      </Collapse>
    </>
  );
}

export default ProjectItem;
