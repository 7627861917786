import { useContext } from 'react';

// Contexts
import { MetadataGeneratorAIContext } from 'contexts/AI/MetadataGeneratorAI';

const useMetadataGeneratorAI = () => {
  const context = useContext(MetadataGeneratorAIContext);

  if (!context) {
    throw new Error('useMetadataGeneratorAI must be used within a MetadataGeneratorAIProvider');
  }

  return context;
};

export default useMetadataGeneratorAI;
