import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import i18n from 'i18next';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Fetchers
import { API, ERROR } from 'api';

// Hooks
import useAuth from 'hooks/useAuth';
import useAlert from 'hooks/useAlert';

// Styles
import {
  Card,
  Button,
  ButtonsWrapper,
  LoadingButton,
  TextField
} from 'pages/app/UserSettings/styled';

const UserChangePasswordSchema = () =>
  Yup.object().shape({
    currentPassword: Yup.string().required(
      i18n.t('components:UserChangePasswordForm.current_password_required')
    ),
    password: Yup.string()
      .notOneOf(
        [Yup.ref('currentPassword'), null],
        i18n.t('components:UserChangePasswordForm.not_match_passwords')
      )
      .min(8, i18n.t('components:UserChangePasswordForm.password_min_length'))
      .max(255)
      .required(i18n.t('components:UserChangePasswordForm.password_required')),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('password'), null],
        i18n.t('components:UserChangePasswordForm.match_passwords')
      )
      .required(i18n.t('components:UserChangePasswordForm.confirm_password_required'))
  });

/**
 * @name UserChangePasswordForm
 * @description Component which enables user to change:
 * - current password
 */
function UserChangePasswordForm() {
  const { user } = useAuth();
  const { username } = user;
  // We do not specify namespace and keyPrefix, as we need also to translate schema. It does not work otherwise.
  const { t } = useTranslation();
  const memoUserChangePasswordSchema = useMemo(() => UserChangePasswordSchema(), []);
  const { setAlert } = useAlert();

  /**
   * handles editing the user through the API. It provides `mutate` function
   * which needs `mutationData` to be passed to it. Mutation data is the same as the
   * arguments that API function expects.
   */
  const editUserMutation = useMutation(
    (mutationData) => {
      return API.editUser({ ...mutationData, username });
    },
    {
      onSuccess: async () => {
        setAlert(t('components:UserChangePasswordForm.password_changed_successfully'));
      }
    }
  );

  /**
   * @name handleOnSubmit
   * @description manages Formik submit function
   */
  const handleOnSubmit = async (values, { errors, setErrors, setStatus, setSubmitting }) => {
    try {
      const { password, currentPassword } = values;
      await editUserMutation.mutateAsync({
        password,
        currentPassword
      });
      setStatus({ success: true });
    } catch (error) {
      let tempErrors = {};
      // If error is raised because of invalid payload
      // print returned errors from the API on the UI
      if (error instanceof ERROR.ApiResponseBadRequest) {
        const { errorData } = error;
        delete Object.assign(errorData, { submit: errorData['non_field_errors'] })[
          'non_field_errors'
        ];

        tempErrors = { ...errorData };
      } else {
        const message =
          error.message || t('components:UserChangePasswordForm.something_went_wrong');
        tempErrors = { submit: [message] };
        // If this is a general error, display snackbar.
        setAlert(message);
      }

      setErrors(tempErrors);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  const {
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    resetForm,
    isValid,
    dirty,
    isSubmitting,
    touched,
    errors
  } = useFormik({
    initialValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
      submit: false
    },
    validationSchema: memoUserChangePasswordSchema,
    onSubmit: handleOnSubmit
  });

  return (
    <Card mb={6}>
      <CardContent>
        <Typography
          component="h1"
          title={t('components:UserChangePasswordForm.title')}
          variant="appH1"
          mb={4}
        >
          {t('components:UserChangePasswordForm.title')}
        </Typography>
        <Typography
          component="p"
          title={t('components:UserChangePasswordForm.general_settings')}
          variant="subtitle1"
          mb={2}
        >
          {t('components:UserChangePasswordForm.description')}
        </Typography>

        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12} my={2}>
              <TextField
                id="currentPassword"
                name="currentPassword"
                label={t('components:UserChangePasswordForm.current_password')}
                type="password"
                variant="outlined"
                value={values.currentPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                error={Boolean(touched.currentPassword && errors.currentPassword)}
                helperText={touched.currentPassword && errors.currentPassword}
              />
            </Grid>
            <Grid item xs={12} md={6} my={2}>
              <TextField
                id="password"
                name="password"
                label={t('components:UserChangePasswordForm.password')}
                type="password"
                variant="outlined"
                fullWidth
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </Grid>
            <Grid item xs={12} md={6} my={2}>
              <TextField
                id="confirmPassword"
                name="confirmPassword"
                label={t('components:UserChangePasswordForm.confirm_password')}
                type="password"
                variant="outlined"
                fullWidth
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                helperText={touched.confirmPassword && errors.confirmPassword}
              />
            </Grid>
            <ButtonsWrapper item>
              <LoadingButton
                loading={isSubmitting}
                role="button"
                disabled={!(isValid && dirty) || isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
                autoFocus
              >
                {t('components:UserChangePasswordForm.save')}
              </LoadingButton>
              <Button role="button" onClick={resetForm} type="reset" color="secondary">
                {t('components:UserChangePasswordForm.cancel')}
              </Button>
            </ButtonsWrapper>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}

export default UserChangePasswordForm;
