export const customBlue = {
  10: '#EAF6FF',
  50: '#A3D3F5',
  100: '#2FA8FC',
  200: '#a3c1ed',
  300: '#7ea8e5',
  400: '#6395e0',
  500: '#4782da',
  600: '#407ad6',
  700: '#376fd0',
  800: '#2f65cb',
  900: '#2052c2 '
};

export const customGreen = {
  100: '#28EF8F',
  80: '#03aa58',
  60: '#7EF5BC',
  40: '#A9F9D2',
  30: '#EFFDF4',
  10: '#EAFEF4'
};

export const customGray = {
  110: '#646464',
  100: '#272525',
  95: '#323030',
  90: '#504F4F',
  70: '#686666',
  50: '#929191',
  40: '#f5f5f5',
  30: '#BEBEBE',
  20: '#D4D3D3',
  10: '#E9E9E9',
  6: '#F2F2F2',
  2: '#FBFBFB',
  0: '#FFFFFF'
};

export const typePrimaryColor = '#323030';

export const typeSecondaryColor = '#686666';

export const inputBorderColor = '#BEBEBE';

export const inputHoverBorderColor = '#323030';

export const successColor = '#28EF8F';
