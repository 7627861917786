import { useMutation, useQueryClient } from '@tanstack/react-query';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Fetcher
import { deleteAppKeywordsFromList } from 'api/api';

interface Props {
  appId: number;
  projectId: number;
  platform: string;
  listId: number;
}

function useDeleteAIMainListKeyword({ appId, projectId, platform, listId }: Props) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ keywordId }: { keywordId: number }) =>
      deleteAppKeywordsFromList({
        projectId,
        appId,
        platform,
        currentListId: listId,
        keywordIds: String(keywordId)
      }),
    {
      onMutate: async ({ keywordId }) => {
        const previous = queryClient.getQueryData([
          CACHE_KEYS.AI_MAIN_LIST,
          { projectId, appId, platform }
        ]);

        queryClient.setQueryData([CACHE_KEYS.AI_MAIN_LIST, { projectId, appId, platform }], {
          data: {
            ...previous?.data,
            keywords: previous?.data.keywords.filter((kw) => kw.keyword_id !== Number(keywordId))
          }
        });

        return { previous };
      },

      onError: (err, _, context) => {
        queryClient.setQueryData(
          [CACHE_KEYS.AI_MAIN_LIST, { projectId, appId, platform }],
          context?.previous
        );
      }
    }
  );
}

export default useDeleteAIMainListKeyword;
