// Utility type to replace a subset of properties of a type with another type
export type Modify<T, R> = Omit<T, keyof R> & R;

export enum MissingData {
  NO_DATA = 'N/D',

  UNRANKED = 'N/R',

  FETCHING = 'Fetching'
}
