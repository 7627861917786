import styled from '@emotion/styled';
import { alpha } from '@mui/material/styles';

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  font-size: 11px;
  font-family: 'Roboto', serif;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 5px;
  // create a shadow effect on the right bottom of the div
  box-shadow: 2px 2px 2px ${({ theme }) => alpha(theme.palette.primary.main, 0.25)};
`;

export const DateSpan = styled.span`
  background-color: ${({ theme }) => alpha(theme.palette.primary.main, 1)};
  width: 100%;
  text-align: center;
  // make top left and right corners rounded
  border-radius: 5px 5px 0 0;
`;

export const ValueSpan = styled.span`
  padding: 5px;
`;
