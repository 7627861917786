import styled from '@emotion/styled';
import MuiMenuItem from '@mui/material/MenuItem';

// Components
import TextInput from 'components/InputText';

export const InputText = styled(TextInput)`
  margin-left: ${({ theme }) => theme.spacing(2)};
  & .MuiInputBase-root {
    height: 24px;
    font-size: 10px;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  }
`;

export const MenuItem = styled(MuiMenuItem)`
  font-size: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  & .MuiBox-root {
    display: flex;
    flex-direction: column;
  }

  cursor: auto;

  &:hover {
    background-color: white;
  }
`;
