import React from 'react';
import * as Sentry from '@sentry/react';

// Hooks
import useAuth from 'hooks/useAuth';

// Components
import DefaultErrorBoundary from './DefaultErrorBoundary';

interface Props {
  location: string;
  children: React.ReactNode;
  customFallback?: React.ReactElement | null;
}

/**
 * @name ErrorBoundary
 * @description This is a custom error boundary that will catch any errors that occur in the application.
 * This will try to get authContext information and pass it to the Sentry.
 * This error boundary will also display a fallback UI if there is an error and custom user form to get
 * more information regarding the issue.
 * @param  {string} location where in the app this error was raised
 * @param  {React.ReactNode} children
 * @param  {React.ReactElement | null} customFallback defaults to null
 */
function ErrorBoundary({ location, children, customFallback = null }: Props) {
  const { user } = useAuth();

  Sentry.configureScope((scope) => {
    if (location) {
      // if there is location available, set the corresponding sentry tag
      scope.setTag('location', location);
    }
    if (user) {
      // if there is user available, set the specific sentry tags
      scope.setTags({
        username: user.username,
        email: user.email
      });
    }
  });

  return (
    <Sentry.ErrorBoundary fallback={customFallback || DefaultErrorBoundary} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  );
}

export default ErrorBoundary;
