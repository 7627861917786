import styled from '@emotion/styled';
import MuiListItemText from '@mui/material/ListItemText';
import MuiCheckbox from '@mui/material/Checkbox';

export const ListItemText = styled(MuiListItemText)`
  margin-left: ${({ theme }) => theme.spacing(2)};
`;

export const Checkbox = styled(MuiCheckbox)`
  padding: 0;
  & .MuiSvgIcon-root {
    height: 15px;
    width: 15px;
  }

  & .PrivateSwitchBase-input {
    height: 15px;
    width: 15px;
  }
`;
