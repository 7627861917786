import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';

// Styles
import { Alert, TextField, Form, Button } from './styled';

// Types
import { CreateProjectProps } from 'pages/app/CreateProject';

const CreateProjectSchema = () =>
  Yup.object().shape({
    name: Yup.string().max(255).required(i18n.t('common:name_required'))
  });

interface Props {
  handleOnSubmit: (values: CreateProjectProps) => void;
  isLoading: boolean;
  isError: boolean;
}

/**
 * @description Craete Project Form
 *
 * @param {function} handleOnSubmit - function to handle form submit
 * @param {boolean} isLoading - loading state
 */
function CreateProjectForm({ handleOnSubmit, isLoading, isError }: Props) {
  // We do not specify keyPrefix, as we use multiple namespaces
  const { t } = useTranslation();
  const memoCreateProjectSchema = useMemo(() => CreateProjectSchema(), []);
  const { handleSubmit, errors, values, touched, handleBlur, handleChange } = useFormik({
    initialValues: {
      name: '',
      reporting: false,
      isPrivate: false,
      AI: false
    },
    validationSchema: memoCreateProjectSchema,
    onSubmit: handleOnSubmit
  });

  return (
    <Form noValidate onSubmit={handleSubmit}>
      {/* create project form and api error */}
      {isError && <Alert severity="warning">{t('pages:CreateProject.something_went_wrong')}</Alert>}
      <TextField
        type="text"
        name="name"
        label={t('common:name')}
        value={values.name}
        error={Boolean(touched.name && errors.name)}
        fullWidth
        helperText={touched.name && errors.name}
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={isLoading}
      />
      <FormControlLabel
        control={
          <Checkbox
            name="reporting"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.reporting}
            color="primary"
            disabled={isLoading}
          />
        }
        label={t('components:CreateProject.reporting')}
      />
      <FormControlLabel
        control={
          <Checkbox
            name="isPrivate"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.isPrivate}
            color="primary"
            disabled={isLoading}
          />
        }
        label={t('components:CreateProject.isPrivate')}
      />
      <FormControlLabel
        control={
          <Checkbox
            name="AI"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.AI}
            color="primary"
            disabled={isLoading}
          />
        }
        label={t('components:CreateProject.AI')}
      />
      <Button
        role="button"
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress color="secondary" size={30} />
        ) : (
          t('components:CreateProject.create_project')
        )}
      </Button>
    </Form>
  );
}

export default CreateProjectForm;
