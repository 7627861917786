import styled from '@emotion/styled';
import MuiTypography from '@mui/material/Typography';
import MuiGrid from '@mui/material/Grid';
import MuiBox from '@mui/material/Box';

// Components
import CustomSelect from 'components/Select';
import RecommendedKeywordComponent from 'pages/app/Application/OKS/Metadata/RecommendedKeywords/RecommendedKeyword';
import TextInput from 'components/InputText';
import PrimaryButton from 'components/Button/Primary';

export const ExportButton = styled(PrimaryButton)`
  margin-left: ${({ theme }) => theme.spacing(2)};
  height: 24px;
  font-size: 10px;
`;

export const SelectCustom = styled(CustomSelect)`
  margin-left: ${({ theme }) => theme.spacing(2)};
  & > .MuiInputBase-root {
    padding-top: 0;
    height: 24px;
    font-size: 10px;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  }

  & .MuiSelect-select {
    display: flex;
    align-items: center;
    padding: ${({ theme }) => theme.spacing(1.5)} ${({ theme }) => theme.spacing(2)};
    height: 24px;
    box-sizing: border-box;
  }
`;

export const Typography = styled(MuiTypography)`
  white-space: nowrap;
  margin-right: ${({ theme }) => theme.spacing(11.5)};
  font-size: 16px;
`;

export const RecommendedKeyword = styled(RecommendedKeywordComponent)`
  width: 150px;
`;

export const Image = styled.img`
  height: 16px;
  width: 16px;
  margin-left: ${({ theme }) => theme.spacing(0.5)};
`;
export const InputText = styled(TextInput)`
  margin-left: ${({ theme }) => theme.spacing(2)};
  & .MuiInputBase-root {
    height: 24px;
    font-size: 10px;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  }
`;

export const Competitor = styled(MuiTypography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  margin-left: ${({ theme }) => theme.spacing(0.5)};
  margin-right: ${({ theme }) => theme.spacing(5.5)};
  font-size: 10px;
`;

export const Container = styled(MuiGrid)`
  display: flex;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  // TODO create a custom style for hiding scrollbar
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const Box = styled(MuiBox)`
  height: 12px;
  font-size: 10px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;
