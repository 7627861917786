import Grid from '@mui/material/Grid';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import CircularProgress from '@mui/material/CircularProgress';

// Hooks
import useMetadataGeneratorAI from 'hooks/AI/useMetadataGeneratorAI';
import useCSVFileName from 'hooks/useCSVFileName';

// Utils
import { COLUMNS, groupingColDef, groupRows } from './utils';

function Keywords() {
  const { keywordsData, isLoadingKeywords } = useMetadataGeneratorAI();

  if (isLoadingKeywords) {
    return (
      <Grid container justifyContent={'center'}>
        <CircularProgress />
      </Grid>
    );
  }

  const fileName = useCSVFileName('AI_Keyword_Estimated_Downloads');

  const groupedRows = groupRows(keywordsData);

  return (
    <Grid container justifyContent={'center'}>
      <Grid sx={{ width: '100%' }}>
        <DataGridPro
          treeData={true}
          disableChildrenSorting
          columnHeaderHeight={32}
          autoHeight={true}
          disableRowSelectionOnClick
          rowHeight={32}
          columns={COLUMNS()}
          rows={groupedRows}
          getTreeDataPath={(row) => row.hierarchy}
          sx={{
            width: '100%',
            margin: '16px auto 0 auto',
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: 'primary.main',
              fontSize: '10px'
            },
            '& .MuiDataGrid-row': {
              fontSize: '10px',
              cursor: 'pointer'
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none'
            }
          }}
          pageSizeOptions={[5, 10, 25, 50, 100, 200]}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
            sorting: {
              sortModel: [{ field: 'total_estimated_max_downloads', sort: 'desc' }]
            }
          }}
          groupingColDef={groupingColDef(groupedRows)}
          pagination={true}
          slots={{ toolbar: GridToolbar }}
          slotProps={{ toolbar: { csvOptions: { fileName } } }}
        />
      </Grid>
    </Grid>
  );
}
export default Keywords;
