import { PropsWithChildren, useContext, useEffect } from 'react';

// Constants
import { API_URLS, AUTH_URLS } from 'api/constants';
import { api, API_URL, AUTH_URL } from 'api/config';

// Contexts
import { AuthContext } from 'contexts/Auth';

// Utils
import StorageService from 'utils/storage';

// Fetchers
import { API } from 'api';

/**
 * @name AxiosProvider
 * @description This is a wrapper component which enables passing context data to the axios.
 * It is necessary in order to fetch data when user did not wanted to `remember` the login.
 * This component is also responsible for refreshing `access_token` when it expires.
 * @param  {object} props
 * @param  {React.ReactNode} props.children
 */
function AxiosProvider({ children }: PropsWithChildren) {
  const {
    localAccessToken: stateAccessToken,
    localRefreshToken: stateRefreshToken,
    setStateData
  } = useContext(AuthContext);

  const isLocalStorageRefreshToken = Boolean(StorageService.getLocalStorageRefreshToken());
  const localAccessToken =
    stateAccessToken ||
    StorageService.getSessionStorageAccessToken() ||
    StorageService.getLocalStorageAccessToken();

  const localRefreshToken =
    stateRefreshToken ||
    StorageService.getSessionStorageRefreshToken() ||
    StorageService.getLocalStorageRefreshToken();

  // We will be updating those values later.
  let currentAccessToken = localAccessToken;
  let currentRefreshToken = localRefreshToken;

  // This is a requestResponseInterceptor which will be later passed to the api instance.
  // We're accessing the api's axios config to set the authorization header.
  const requestResponseInterceptor = (config) => {
    if (currentAccessToken && config.withAuthorization) {
      // If token exists and there is `withAuthorization` argument then
      // set the `currentAccessToken` as Authorization header
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${currentAccessToken}`;
    } else {
      delete config.headers.Authorization;
    }

    return config;
  };

  const requestErrorInterceptor = (error) => {
    return Promise.reject(error);
  };

  // This is a responseResponseInterceptor - it catches the response object's response and passes it to the return function.
  const responseResponseInterceptor = (response) => {
    return response;
  };

  // This is a responseErrorInterceptor. We use this to catch api's errors and try to refresh the token if the error
  // informs that its unauthorized.
  const responseErrorInterceptor = async (error) => {
    const originalConfig = error.config;
    if (
      originalConfig &&
      // Provide API URLS for which the retry should not be ran
      originalConfig.url !== `${AUTH_URL}/${AUTH_URLS.AUTH_SIGN_IN}` &&
      originalConfig.url !== `${API_URL}/${API_URLS.USER_VERIFY_EMAIL}` &&
      error.response
    ) {
      // If `axios` returned error with those codes, this should mean that
      // the `localAccessToken` expired
      if (
        error.response.status === 401 ||
        (error.response.status === 403 && !originalConfig._retry)
      ) {
        // Retry previous API call after this
        originalConfig._retry = true;
        try {
          // Fetch new `localAccessToken` using `currentRefreshToken`
          const responseData = await API.userRefreshToken({
            refreshToken: currentRefreshToken
          });
          const {
            access_token: newAccessToken,
            refresh_token: newRefreshToken,
            user
          } = responseData.data;

          // If `localRefreshToken` already was in the localStorage, update everything there
          if (isLocalStorageRefreshToken) {
            StorageService.setLocalStorageData({
              user,
              accessToken: newAccessToken,
              refreshToken: newRefreshToken
            });
          }
          // Update sessionStorage
          StorageService.setSessionStorageData({
            user,
            accessToken: newAccessToken,
            refreshToken: newRefreshToken
          });
          // Update state data
          setStateData({
            accessToken: newAccessToken,
            refreshToken: newRefreshToken,
            user
          });
          currentAccessToken = newAccessToken;
          currentRefreshToken = newRefreshToken;

          return api(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(error);
  };

  // Setup interceptors
  const requestInterceptor = api.interceptors.request.use(
    requestResponseInterceptor,
    requestErrorInterceptor
  );
  const responseInterceptor = api.interceptors.response.use(
    responseResponseInterceptor,
    responseErrorInterceptor
  );

  useEffect(() => {
    return () => {
      // On cleanup we are removing interceptors.
      api.interceptors.request.eject(requestInterceptor);
      api.interceptors.response.eject(responseInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return children;
}

export default AxiosProvider;
