// Cache keys which are being used by react-query to reference the cached data.
export const CACHE_KEYS = {
  // USERS
  USERS: 'USERS',
  USER_CURRENT: 'USER_CURRENT',
  USER_VERIFY_EMAIL: 'USER_VERIFY_EMAIL',
  USER_RESET_PASSWORD: 'USER_RESET_PASSWORD',
  USER: 'USER',

  // AUTH
  AUTH_TOKEN_REFRESH: 'AUTH_TOKEN_REFRESH',

  // PROJECTS
  PROJECTS: 'PROJECTS',
  PROJECTS_ACCESS: 'PROJECTS_ACCESS',
  PROJECT: 'PROJECT',
  SHARE_PROJECT: 'SHARE_PROJECT',
  REMOVE_SHARE_USER: 'REMOVE_USER',
  EDIT_SHARE_USER: 'EDIT_SHARE_USER',

  // APPS:
  APPS: 'APPS',
  APP: 'APP',
  APPS_SEARCH_AUTOCOMPLETE: 'APPS_SEARCH_AUTOCOMPLETE',
  APP_NOTE: 'APP_NOTE',

  APPS_COUNTRY_LANG_COMBO: 'APPS_COUNTRY_LANG_COMBO',
  APPS_COUNTRIES: 'APPS_COUNTRIES',
  APPS_LANGUAGES: 'APPS_LANGUAGES',

  GET_KEYWORD_LISTS: 'GET_KEYWORD_LISTS',

  GET_KEYWORDS_KEYWORD: 'GET_KEYWORDS_KEYWORD', // requestType: keyword
  GET_KEYWORDS_POPULARITY: 'GET_KEYWORDS_POPULARITY', // requestType: popularity
  GET_KEYWORDS_POPULARITY_CHANGE: 'GET_KEYWORDS_POPULARITY_CHANGE',
  GET_KEYWORDS_RANK: 'GET_KEYWORDS_RANK', // requestType: rank
  GET_KEYWORDS_RANK_CHANGE: 'GET_KEYWORDS_RANK_CHANGE',
  GET_KEYWORDS_TOTAL_APPS: 'GET_KEYWORDS_TOTAL_APPS', // requestType: total-apps
  GET_KEYWORDS_DOWNLOADS: 'GET_KEYWORDS_DOWNLOADS', // requestType: downloads,
  GET_UNTRACKED_RANKED_KEYWORDS: 'GET_UNTRACKED_RANKED_KEYWORDS',

  GET_KEYWORD_RANK_HISTORY: 'GET_KEYWORD_RANK_HISTORY',
  GET_KEYWORD_POPULARITY_HISTORY: 'GET_KEYWORD_POPULARITY_HISTORY',

  GET_KEYWORDS_SUGGESTIONS: 'GET_KEYWORDS_SUGGESTIONS',
  // NOTES:
  NOTES: 'NOTES',
  NOTE: 'NOTE',

  // COMPETITORS
  COMPETITORS: 'COMPETITORS',
  COMPETITORS_KEYWORDS_RANK: 'COMPETITORS_KEYWORDS_RANK',
  COMPETITORS_SUGGESTIONS: 'COMPETITORS_SUGGESTIONS',

  // OKS
  OKS_METADATA: 'OKS_METADATA',
  OKS_GENERATOR: 'OKS_GENERATOR',

  // CHANGES BACKLOG
  CHANGES_BACKLOG: 'CHANGES_BACKLOG',
  EXPERIMENT_BACKLOG: 'EXPERIMENT_BACKLOG',
  CURRENT_METADATA: 'CURRENT_METADATA',

  // AI Research
  AI_REVIEW_LISTS: 'AI_REVIEW_LISTS',
  AI_MAIN_LIST: 'AI_MAIN_LIST',
  AI_VALIDATE_REVIEW_LIST: 'AI_VALIDATE_REVIEW_LIST',
  AI_REVIEW_LISTS_STATUS: 'AI_REVIEW_LISTS_STATUS',

  // AI Assets Generator
  AI_ASSETS_GENERATED: 'AI_ASSETS_GENERATED',
  AI_ASSETS_GENERATOR: 'AI_ASSETS_GENERATOR',
  AI_PROMPTS: 'AI_PROMPTS',
  AI_ASSET_PROMPT_BY_ID: 'AI_ASSET_PROMPT_BY_ID',

  // App description
  APP_DESCRIPTION: 'APP_DESCRIPTION',
  AI_KEYWORDS_DATA: 'AI_KEYWORDS_DATA'
};

// List of API endpoints
export const API_URLS = {
  USERS: 'users/',
  USER_CURRENT: 'users/me/',
  USER_VERIFY_EMAIL: 'users/verify-email/',
  USER_RESET_PASSWORD: 'users/reset-password/',

  PROJECTS: 'projects/',
  PROJECTS_ACCESS: 'projects/access/',
  PROJECT: 'projects/:projectId',
  SHARE_PROJECT: 'projects/:projectId/users',
  EDIT_SHARE_USER: 'projects/:projectId/users/:userId',
  REMOVE_SHARE_USER: 'projects/:projectId/users/:userId',

  APPS: 'projects/:projectId/apps/',
  APP: `projects/:projectId/apps/:appId/:platform/`,

  KEYWORDS: 'projects/:projectId/apps/:appId/keywords/',
  KEYWORDS_ADD: 'projects/:projectId/apps/:appId/:platform/add-keywords/',
  KEYWORDS_GET: 'projects/:projectId/apps/:appId/:platform/get-keywords/:keywordListId/',
  KEYWORDS_GET_POPULARITY:
    'projects/:projectId/apps/:appId/:platform/get-keywords-popularity/:keywordListId/',
  KEYWORDS_GET_POPULARITY_CHANGE:
    'projects/:projectId/apps/:appId/:platform/get-popularity-change/:keywordListId/',
  KEYWORDS_GET_RANK: 'projects/:projectId/apps/:appId/:platform/get-keywords-ranks/:keywordListId/',
  KEYWORDS_GET_RANK_CHANGE:
    'projects/:projectId/apps/:appId/:platform/get-rank-change/:keywordListId/',
  KEYWORDS_GET_UNTRACKED_RANKED:
    'projects/:projectId/apps/:appId/:platform/get-untracked-keywords/:keywordListId/',

  KEYWORDS_GET_DOWNLOADS:
    'projects/:projectId/apps/:appId/:platform/get-keywords-downloads/:keywordListId/',
  KEYWORDS_GET_TOTAL_APPS:
    'projects/:projectId/apps/:appId/:platform/get-keywords-total-apps/:keywordListId/',
  KEYWORDS_DELETE: 'projects/:projectId/apps/:appId/:platform/keywords/remove/',
  KEYWORDS_UPDATE: 'projects/:projectId/apps/:appId/:platform/keywords/update-attributes/',
  KEYWORD_LISTS: 'projects/:projectId/apps/:appId/:platform/keyword-lists/',

  KEYWORD_RANK_HISTORY: 'projects/:projectId/apps/:appId/:platform/keywords/:kwId/get-rank/',
  KEYWORD_POPULARITY_HISTORY: 'projects/:projectId/apps/:appId/keywords/:kwId/get-popularity/',

  KEYWORDS_SUGGESTIONS: 'projects/:projectId/apps/:appId/:platform/keywords/suggestions/',

  NOTES: 'projects/:projectId/apps/:appId/:platform/notes/',
  NOTE: 'projects/:projectId/apps/:appId/:platform/notes/:noteId/',

  APPS_SEARCH: 'projects/:projectId/apps/search/',

  APPS_COUNTRY_LANG_COMBO: 'projects/:projectId/apps/ioscomb/',
  APPS_COUNTRIES: 'projects/:projectId/apps/app-countries/',
  APPS_LANGUAGES: 'projects/:projectId/apps/app-languages/',
  APP_TIER: `projects/:projectId/apps/:appId/:platform/change-app-tier/`,
  LINK_APPS: 'projects/:projectId/apps/:appId/:platform/link-apps/',

  COMPETITORS: 'projects/:projectId/apps/:appId/:platform/competitors/',
  COMPETITORS_KEYWORDS_RANK: 'projects/:projectId/apps/:appId/:platform/competitors/keywords/',
  COMPETITORS_SUGGESTIONS: 'projects/:projectId/apps/:appId/:platform/competitors/suggestions/',

  OKS_METADATA: 'projects/:projectId/apps/:appId/:platform/oks-metadata/',
  OKS_GENERATOR: 'projects/:projectId/apps/:appId/:platform/keywords/:keywordListId/oks-generator/',

  CHANGES_BACKLOG: 'projects/:projectId/apps/:appId/:platform/experiments/',
  CHANGES_BACKLOG_EXPERIMENT:
    'projects/:projectId/apps/:appId/:platform/experiments/:experimentId/',
  EXPERIMENT_BACKLOG:
    'projects/:projectId/apps/:appId/:platform/experiments/:experimentId/backlog/',
  EXPERIMENT_BACKLOG_ITEM:
    'projects/:projectId/apps/:appId/:platform/experiments/:experimentId/backlog/:backlogId/',
  CURRENT_LIVE_METADATA: 'projects/:projectId/apps/:appId/:platform/experiments/live-backlogs/',

  AI_RESEARCH_KEYWORDS: 'projects/:projectId/apps/:appId/:platform/ai/keywords/',
  AI_KEYWORD_LISTS: 'projects/:projectId/apps/:appId/:platform/ai/generations/',
  AI_KEYWORD_LIST: 'projects/:projectId/apps/:appId/:platform/ai/generations/:generationId/',

  AI_REVIEW_LISTS: 'projects/:projectId/apps/:appId/:platform/ai/review-lists/',
  AI_REVIEW_LISTS_STATUS: 'projects/:projectId/apps/:appId/:platform/ai/lists/',
  AI_REVIEW_LISTS_BY_ID: 'projects/:projectId/apps/:appId/:platform/ai/review-lists/:reviewListId/',
  AI_VALIDATE_REVIEW_LISTS_BY_ID:
    'projects/:projectId/apps/:appId/:platform/ai/review-lists/:reviewListId/validate/',
  AI_MAIN_LIST: 'projects/:projectId/apps/:appId/:platform/ai/main-list/',

  AI_ASSETS_GENERATED: 'projects/:projectId/apps/:appId/:platform/ai/assets-generated/',
  AI_ASSETS_GENERATOR: 'projects/:projectId/apps/:appId/:platform/ai/generate-assets/',
  AI_ASSETS_GENERATOR_RECALCULATE:
    '/projects/:projectId/apps/:appId/:platform/ai/assets-generator/recalculate/:optionId/',

  APP_DESCRIPTION: 'projects/:projectId/apps/:appId/:platform/app-description/',
  AI_OKS_GENERATOR: 'projects/:projectId/apps/:appId/:platform/ai/oks-generator/',
  AI_KEYWORDS_DATA: 'projects/:projectId/apps/:appId/:platform/ai/keywords-data/',

  // Prompts
  PROMPTS: 'projects/:projectId/apps/:appId/:platform/ai/prompts/'
};

// List of endpoints related to user authentication
export const AUTH_URLS = {
  AUTH_SIGN_IN: 'login/',
  AUTH_SIGN_IN_GOOGLE: 'login/google/',
  AUTH_TOKEN_REFRESH: 'login/refresh/'
};

// List of query states. This is used throughout the app to determine the state of the query.
export const FETCH_STATE = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
  EMPTY: 'empty'
};

export enum FETCH_STATE_ENUM {
  LOADING = 'loading',
  IDLE = 'idle',
  SUCCESS = 'success',
  ERROR = 'error',
  EMPTY = 'empty'
}

export type FetchState = 'loading' | 'idle' | 'success' | 'error' | 'empty';

export const STALE_TIME = {
  INFINITY: Infinity,
  TEN_MINUTES: 10 * 60 * 1000
};

export const GET_KEYWORDS_REQ_TYPE = {
  KEYWORD: 'KEYWORD',
  RANK: 'RANK',
  RANK_CHANGE: 'RANK_CHANGE',
  POPULARITY: 'POPULARITY',
  POPULARITY_CHANGE: 'POPULARITY_CHANGE',
  DOWNLOADS: 'DOWNLOADS',
  TOTAL_APPS: 'TOTAL_APPS'
};
