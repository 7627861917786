import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HeartBrokenOutlinedIcon from '@mui/icons-material/HeartBrokenOutlined';
import Typography from '@mui/material/Typography';

//Components
import CombinationKeyword from './CombinationKeyword';
import TooltipWithIcon from 'components/TooltipWithIcon';
//Styled
import { FormLabelPaddingTB, GridJustifySpaceAroundAfter, TextFieldMarginRight } from './styled';

// Hooks
import useKeywordsSuggestionsQuery from 'api/queries/Keywords/Shuffle';

//Constants
import { FETCH_STATE_ENUM } from 'api/constants';
import { AddKeywordShuffleProps, CombinationKeywordProps } from './types';
const INITIAL_FORM_STATE: { [key: string]: number | string } = {
  nbCombination: 1,
  '#1': '',
  '#2': '',
  '#3': '',
  '#4': '',
  '#5': ''
};

const inputs = ['#1', '#2', '#3', '#4', '#5'].map((label) => ({ label }));
const radios = ['one', 'up_to_two', 'up_to_three', 'up_to_four', 'up_to_five'].map((label, i) => ({
  label,
  value: i + 1
}));

function AddKeywordShuffle({
  onClick: addKeywords,
  status: addKeywordsStatus,
  addKwVariables
}: AddKeywordShuffleProps) {
  const { t } = useTranslation('components', {
    keyPrefix: 'KeywordTrackingTable.AddKeywordShuffle'
  });
  const { projectId, appId, platform } = useParams();

  const [formState, setFormState] = React.useState(INITIAL_FORM_STATE);
  const { sortedData, status, fetchData, resetData, updateTrackedKeywords } =
    useKeywordsSuggestionsQuery({
      projectId,
      appId,
      platform,
      payload: formState
    });

  const resetForm = useCallback(() => {
    setFormState(INITIAL_FORM_STATE);
    resetData();
  }, [resetData]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  }, []);

  // disable button if less than 2 text fields are filled
  const isShuffleDisabled =
    Object.entries(formState)
      .filter(([key, value]) => key !== 'nbCombination' && value !== '')
      .map(([_key, value]) => value).length < 2;

  return (
    <Grid container display="flex" flexDirection="column">
      {/*form container*/}
      <FormControl>
        {/*inputs*/}
        <span>{t('choose_between_2_5_one_word_kws')}</span>
        <GridJustifySpaceAroundAfter>
          {inputs.map((keyword: { label: string }) => (
            <TextFieldMarginRight
              size="small"
              key={keyword.label}
              name={keyword.label}
              label={keyword.label}
              placeholder={keyword.label}
              value={formState[keyword.label]}
              inputProps={{ maxLength: 50 }}
              onKeyDown={(event) => {
                if ([' ', ','].includes(event.key)) event.preventDefault();
              }}
              onChange={handleChange}
            />
          ))}
        </GridJustifySpaceAroundAfter>
        <FormLabelPaddingTB id="nb-combinations-radio-group-label">
          {t('choose_how_many_words_per_combination')}
        </FormLabelPaddingTB>
        <RadioGroup
          row
          aria-labelledby="nb-combinations-radio-group-label"
          name="nb-combinations-radio-group"
        >
          {radios.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={t(option.label)}
              checked={formState.nbCombination === option.value}
              onChange={() => {
                setFormState({ ...formState, nbCombination: option.value });
              }}
            />
          ))}
        </RadioGroup>
        <Grid mt={2} display="flex" justifyContent="flex-start" alignItems="center">
          <Button
            color="secondary"
            variant="outlined"
            disabled={isShuffleDisabled}
            onClick={fetchData}
          >
            {t('shuffle')}
          </Button>
          <Button color="warning" variant="outlined" onClick={resetForm} sx={{ marginLeft: 5 }}>
            {t('reset')}
          </Button>
          {status === FETCH_STATE_ENUM.SUCCESS && (
            <Grid ml={5}>
              <TooltipWithIcon
                title={
                  <Grid
                    sx={{
                      // make new line when \n is found
                      whiteSpace: 'pre-line'
                    }}
                  >
                    {t('tracked_means_any_list_add_to_current_list')}
                  </Grid>
                }
              />
            </Grid>
          )}
        </Grid>
      </FormControl>
      {/*results container*/}
      <GridJustifySpaceAroundAfter>
        {status === FETCH_STATE_ENUM.SUCCESS &&
          (sortedData.length ? (
            sortedData.map((combination: CombinationKeywordProps, index: number) => {
              return (
                <CombinationKeyword
                  {...combination}
                  key={index}
                  addKeywords={addKeywords}
                  addKeywordsStatus={addKeywordsStatus}
                  addKwVariables={addKwVariables}
                  updateTrackedKeywords={updateTrackedKeywords}
                />
              );
            })
          ) : (
            <Grid sx={{ width: '100%', textAlign: 'center' }}>
              <Typography variant="subtitle1" gutterBottom>
                {t('no_combinations_found')}
              </Typography>
              <HeartBrokenOutlinedIcon color="warning" />
            </Grid>
          ))}
        {status === FETCH_STATE_ENUM.LOADING &&
          Array.from(Array(14).keys()).map((_, i) => {
            return (
              <Grid
                key={i}
                sx={{
                  marginRight: 2
                }}
              >
                <Skeleton key={i} width={150} height={40} data-testid="loading-skeleton" />
              </Grid>
            );
          })}
        {status === FETCH_STATE_ENUM.ERROR && (
          <Grid sx={{ width: '100%', textAlign: 'center' }}>
            <Typography variant="subtitle1" gutterBottom>
              {t('error_shuffling_kws')}
            </Typography>
            <ErrorOutlineIcon color="error" />
          </Grid>
        )}
      </GridJustifySpaceAroundAfter>
    </Grid>
  );
}

export default AddKeywordShuffle;
