import React, { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

// Hooks
import useSnackbar from 'hooks/useSnackbar';

// Fetchers
import useAIAssetsGeneratorQuery, {
  GeneratedAsset
} from 'api/queries/AI/Assets/useAIAssetsGeneratorQuery';
import useGenerateAIAssets from 'api/queries/AI/Assets/useGenerateAIAssets';
import useAppDescriptionQuery, { AppDescription } from 'api/queries/AI/useAppDescriptionQuery';
import usePromptsQuery, { Prompt, PromptType } from 'api/queries/AI/Prompts/usePromptsQuery';
import usePromptMutation from 'api/queries/AI/Prompts/usePromptMutation';
import useAIOKSGeneratorQuery from 'api/queries/AI/Assets/useAIOKSGenerator';
import useAIKeywordsData, { KeywordPhrase } from 'api/queries/AI/Assets/useAIKeywordsData';

// Types
import { Params } from 'types/App';
import { RecommendedKeyword } from 'types/OKS/Metadata';

// Constants
import { CACHE_KEYS } from 'api/constants';

export interface KeywordsData {
  total_impressions: number;
  keyword: string;
  total_estimated_max_downloads: number;
  keyword_phrases: KeywordPhrase[];
  name: string;
}

export interface MetadataGeneratorAIContextProps {
  isLoadingAssets: boolean;
  handleGenerateAssets: () => void;
  isGeneratingAssets: boolean;
  assets: GeneratedAsset[];
  appDescription?: AppDescription;
  allPrompts: Prompt[];
  handleSetActivePrompt: (promptId: number) => void;
  handleSetPrompt: (prompt: string) => void;
  prompt?: Prompt;
  handleCreatePrompt: () => void;
  isSavingPrompt: boolean;
  generateAIOKS: ({ title, subtitle }: { title: string; subtitle: string }) => void;
  optimalKeywordSet: RecommendedKeyword[];
  isGeneratingOKS: boolean;
  keywordsData: KeywordsData[];
  isLoadingKeywords: boolean;
}
export const MetadataGeneratorAIContext = createContext({} as MetadataGeneratorAIContextProps);

/**
 * Context provider for the Metadata Generator AI.
 * Allows user to trigger the generation of metadata.
 * fetches the status of the generation [for the second release will implement SSE ].
 * Provides the generated metadata once ready.
 */
function MetadataGeneratorAIProvider({ children }: PropsWithChildren) {
  const queryClient = useQueryClient();
  const { projectId, appId, platform } = useParams() as Params;
  const { setSnackbar } = useSnackbar();
  const { t } = useTranslation('pages', { keyPrefix: 'AI.MetadataGenerator' });

  const [activePrompt, setActivePrompt] = useState({
    id: 1,
    prompt: '',
    promptType: PromptType.AssetGeneration,
    created_at: ''
  });

  const {
    data,
    isInitialLoading: isLoadingAssets,
    refetch: refetchAssets
  } = useAIAssetsGeneratorQuery({
    projectId: Number(projectId),
    appId: Number(appId),
    platform
  });

  const { data: kwsData, isLoading: isLoadingKeywords } = useAIKeywordsData({
    projectId: Number(projectId),
    appId: Number(appId),
    platform
  });

  // Format keywords data
  const keywordsData = Object.keys(kwsData?.data || {}).length
    ? Object.entries(kwsData?.data || []).map(([key, { keyword_phrases, ...rest }]) => ({
        name: key,
        keyword_phrases: keyword_phrases.sort((a, b) => b.impressions - a.impressions),
        ...rest
      }))
    : [];

  const sortedKeywordsData = keywordsData.sort((a, b) => b.total_impressions - a.total_impressions);

  const { isLoading: isGeneratingAssets, mutate: handleGenerateAssets } = useGenerateAIAssets(
    {
      projectId: Number(projectId),
      appId: Number(appId),
      platform,
      promptId: activePrompt.id,
      keywordsData: kwsData?.data!
    },
    {
      onSuccess: () => {
        setSnackbar(true, t('success'), 'success', 3000);
        return refetchAssets();
      },
      onError: () => {
        setSnackbar(true, t('error'), 'error', 3000);
      }
    }
  );

  const { data: appDescription } = useAppDescriptionQuery({
    projectId: Number(projectId),
    appId: Number(appId),
    platform
  });

  const { data: allPrompts } = usePromptsQuery({
    projectId: Number(projectId),
    appId: Number(appId),
    platform,
    promptType: PromptType.AssetGeneration
  });

  const { mutate, isLoading } = usePromptMutation(
    {
      projectId: Number(projectId),
      appId: Number(appId),
      platform,
      promptType: PromptType.AssetGeneration
    },
    {
      onSuccess: (res: { data: Prompt }) => {
        queryClient.setQueryData(
          [
            CACHE_KEYS.AI_PROMPTS,
            {
              projectId: Number(projectId),
              appId: Number(appId),
              platform,
              promptType: PromptType.AssetGeneration
            }
          ],
          (prev?: { data: Prompt[] }) => {
            return {
              data: [...(prev?.data || []), res.data]
            };
          }
        );
        setActivePrompt(res.data);
        // setActivePrompt(res.data.id);
        setSnackbar(true, t('prompt_success'), 'success', 3000);
      },
      onError: () => {
        setSnackbar(true, t('prompt_error'), 'error', 3000);
      }
    }
  );

  const {
    mutate: generateAIOKS,
    data: oks,
    isLoading: isGeneratingOKS
  } = useAIOKSGeneratorQuery({
    projectId: Number(projectId),
    appId: Number(appId),
    platform
  });

  const handleSetActivePrompt = (promptId: number) => {
    setActivePrompt(allPrompts?.data.find((prompt) => prompt.id === promptId)!);
  };

  const handleSetPrompt = (prompt: string) => {
    setActivePrompt((test) => ({ ...test, prompt }));
  };

  const handleCreatePrompt = () => {
    mutate({ prompt: activePrompt.prompt });
  };

  useEffect(() => {
    if (allPrompts?.data.length) {
      const lastPrompt = allPrompts?.data[allPrompts?.data.length - 1];
      setActivePrompt(lastPrompt);
    }
  }, [allPrompts?.data]);

  // Sort by sum of total impressions
  const sortedAssets = data?.data.sort(
    (a, b) =>
      b.title_total_impressions +
      b.subtitle_total_impressions -
      (a.title_total_impressions + a.subtitle_total_impressions)
  );

  return (
    <MetadataGeneratorAIContext.Provider
      value={{
        isLoadingAssets,
        handleGenerateAssets,
        isGeneratingAssets,
        assets: sortedAssets || [],
        appDescription: appDescription?.data,
        allPrompts: allPrompts?.data || [],
        handleSetActivePrompt,
        handleSetPrompt,
        prompt: activePrompt,
        handleCreatePrompt,
        isSavingPrompt: isLoading,
        generateAIOKS,
        optimalKeywordSet: oks?.data || [],
        isGeneratingOKS,
        keywordsData: sortedKeywordsData,
        isLoadingKeywords
      }}
    >
      {children}
    </MetadataGeneratorAIContext.Provider>
  );
}

export default MetadataGeneratorAIProvider;
