import React, { ReactNode } from 'react';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

/**
 * @name TooltipWithIcon
 * @description custom tooltip with question mark icon as child
 * @param {string | node} title
 * @param {string} size
 * */

interface Props {
  title: string | ReactNode;
  size?: 'small' | 'medium' | 'large';
}
function TooltipWithIcon({ title, size = 'medium' }: Props) {
  return (
    <Tooltip title={title} arrow>
      <HelpOutlineIcon color="secondary" cursor="pointer" fontSize={size} />
    </Tooltip>
  );
}

export default TooltipWithIcon;
