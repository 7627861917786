import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '@mui/material/Modal';

// Assets
import NotesIcon from 'assets/notes.svg';

// Styles
import { Button, NotesInput, Grid } from './styled';

interface Props {
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
}

function Notes({ value, handleChange, handleBlur }: Props) {
  const { t } = useTranslation('components', {
    keyPrefix: 'OptimalKeywordsPage.metadataSection.form'
  });
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Button onClick={handleClick}>
        <NotesIcon height={12} width={12} />
        {t('addNotes')}
      </Button>
      <Modal
        onClose={handleClick}
        open={isOpen}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Grid>
          <NotesInput
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={value}
            name="notes"
            placeholder={t('labelNotes')}
            id="notes"
            multiline={true}
          />
        </Grid>
      </Modal>
    </>
  );
}
export default Notes;
