import React, { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import { useQueryClient } from '@tanstack/react-query';

// Fetchers
import { CACHE_KEYS, FETCH_STATE } from 'api/constants';

// Components
import TooltipWithIcon from 'components/TooltipWithIcon';
import PrimaryButton from 'components/Button/Primary';

// Styles
import { GridItem, TextField, Typography, CSVTypography, FileDropBox } from './styled';

// Hooks
import useFilters from 'hooks/useFilters';
import useAIResearch from 'hooks/AI/useAIResearch';
import useSnackbar from 'hooks/useSnackbar';

// Fetchers
import { useAddKeywordsMutation } from 'api/queries/Keywords/useAddKeywordsMutation';

// Types
import { Params } from 'types/App';

// Types of files accepted by uploader
const FILE_TYPES: string[] = ['CSV'];

// Add keywords payload
const initialPayload = {
  addIndividualTerms: false,
  keywords: ''
};

function AddKeywordManual() {
  const queryClient = useQueryClient();
  const { setSnackbar } = useSnackbar();

  const { t } = useTranslation('components', {
    keyPrefix: 'KeywordTrackingTable.AddKeywordManual'
  });

  const { projectId, appId, platform } = useParams() as Params;

  const { mainListId } = useAIResearch();

  const [payload, setPayload, resetPayload] = useFilters(initialPayload);

  const { mutateAsync, variables, status } = useAddKeywordsMutation({
    projectId,
    appId,
    platform,
    keywordListIds: String(mainListId),
    addIndividualTerms: payload.addIndividualTerms
  });

  // Add keywords via input
  const onInputDown = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPayload('keywords', e.target.value);
  };

  // WIP Add keywords as CSV
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const handleFileUpload = (file: File) => {
    setCsvFile(file);
  };

  // Toggle long tail
  const onSwitchToggle = () => {
    setPayload('addIndividualTerms', !payload.addIndividualTerms);
  };

  const onAddKeywordsClick = async () => {
    await mutateAsync(payload.keywords, {
      onSuccess: () => {
        resetPayload();
        setSnackbar(true, t('keywords_added'), 'success', 3000);

        queryClient.invalidateQueries([
          CACHE_KEYS.AI_MAIN_LIST,
          { projectId: Number(projectId), appId: Number(appId), platform }
        ]);
      }
    });
  };

  return (
    <>
      <Grid
        container
        display="flex"
        alignItems="start"
        justifyContent="space-between"
        sx={{ height: '100%' }}
      >
        {/* Manually add keywords in input*/}
        <GridItem item md={12} lg={6}>
          {/* Manually add keywords in input, title*/}
          <Typography variant="subtitle2" gutterBottom>
            {t('add_separate_comma')}
            <TooltipWithIcon title={<Box>{t('keywords_added_generic_relevant')}</Box>} />
          </Typography>
          {/* Manually add keywords in input, main component*/}
          <TextField
            value={payload.keywords}
            variant="outlined"
            onChange={onInputDown}
            fullWidth
            disabled={status === FETCH_STATE.LOADING}
            label={t('enter_keywords')}
            multiline
            rows={2}
          />
          {/* Toggle long tail*/}
          <Box display="flex" flexDirection="column">
            {/* Toggle long tail, title*/}
            <Typography variant="subtitle2" sx={{ alignItems: 'center !important' }} gutterBottom>
              {t('add_individual_terms')}
              <TooltipWithIcon
                title={<Box sx={{ whiteSpace: 'pre-line' }}>{t('individual_terms_tooltip')}</Box>}
              />
            </Typography>
            {/* Toggle long tail, main component*/}
            <Switch
              checked={payload.addIndividualTerms}
              onChange={onSwitchToggle}
              inputProps={{ 'aria-label': 'addIndividualTerms' }}
            />
          </Box>
          <Box sx={{ p: 3 }}>
            <PrimaryButton
              onClick={onAddKeywordsClick}
              disabled={status === FETCH_STATE.LOADING}
              endIcon={
                status === FETCH_STATE.LOADING ? <CircularProgress size={20} /> : <AddIcon />
              }
            >
              {t('add')}
            </PrimaryButton>
          </Box>
        </GridItem>
        {/* Import CSV*/}
        <GridItem item md={12} lg={5} sx={{ height: '100%' }}>
          {/* Import CSV, title*/}
          <Typography variant="subtitle2" gutterBottom>
            {t('import_from_csv')}
            <TooltipWithIcon
              title={<Box sx={{ whiteSpace: 'pre-line' }}>{t('csv_requirements')}</Box>}
            />
          </Typography>
          {/*import csv, main component*/}
          <FileUploader handleChange={handleFileUpload} name="file" types={FILE_TYPES}>
            <FileDropBox variant="outlined">
              {/*import csv, main component, container*/}
              <Box display="flex" flexDirection="column" alignItems="center">
                {/*import csv, main component, container, icon*/}
                <FileUploadIcon fontSize="large" color="secondary" />
                {/*import csv, main component, container, text with tooltip*/}
                <Tooltip title={csvFile ? csvFile.name : t('choose_file')}>
                  <CSVTypography
                    sx={{ color: csvFile ? 'primary.main' : 'secondary.main' }}
                    variant="subtitle2"
                    mr={2}
                    gutterBottom
                  >
                    {csvFile ? csvFile.name : t('choose_file')}
                  </CSVTypography>
                </Tooltip>
              </Box>
            </FileDropBox>
          </FileUploader>
          <Alert severity="warning">Feature not yet available</Alert>
        </GridItem>
      </Grid>
    </>
  );
}

export default AddKeywordManual;
