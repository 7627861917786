import styled from '@emotion/styled';
import MuiAddIcon from '@mui/icons-material/Add';
import MuiBox from '@mui/material/Box';

// Components
import SelectCustom from 'components/Select';
import TextInput from 'components/InputText';
import MuiButton from '@mui/material/Button';

export const AddIcon = styled(MuiAddIcon)`
  color: white;
  background: white;
  border-radius: 50%;
  fill: ${({ theme }) => theme.palette.secondary.secondary};
  height: 9px;
  width: 9px;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const Select = styled(SelectCustom)`
  margin-left: ${({ theme }) => theme.spacing(2)};
`;

export const InputText = styled(TextInput)`
  margin-left: ${({ theme }) => theme.spacing(2)};
`;

export const AddRowButton = styled(MuiButton)`
  background-color: ${({ theme }) => theme.palette.secondary.secondary};
  color: white;
  font-size: 10px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  height: 32px;
  margin-right: ${({ theme }) => theme.spacing(2)};
  margin-left: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
