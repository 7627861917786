import React from 'react';
import styled from '@emotion/styled';
import i18n from 'i18next';
import Typography from '@mui/material/Typography';

import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';

// Constants
import { DATA_KEYS } from 'constants/constants';
import { FETCH_STATE, GET_KEYWORDS_REQ_TYPE } from 'api/constants';

// Components
import ConfirmCancelButton from 'components/ConfirmCancelButton';
import TableCellWithStates from 'components/TableCellWithStates';
import { NoBorderSelect } from 'components/Select/variations';

// Styles
import {
  Container,
  Translation,
  Wrapper,
  Text,
  Relevant,
  ExtremelyRelevant,
  Starred
} from './styled';

export const DATE_STRING_FORMAT = 'YYYY-MM-DD';

export const typeOptions = () => [
  {
    value: 1,
    label: (
      <Wrapper>
        <Text>{i18n.t('common:brand')}</Text>
      </Wrapper>
    )
  },
  {
    value: 2,
    label: (
      <Wrapper>
        <Text>{i18n.t('common:competitor')}</Text>
      </Wrapper>
    )
  },
  {
    value: 3,
    label: (
      <Wrapper>
        <Text>{i18n.t('common:generic')}</Text>
      </Wrapper>
    )
  }
];

export const reducedTypeOptions = () => [
  {
    value: 1,
    label: (
      <Wrapper>
        <Text>{i18n.t('common:brand')}</Text>
      </Wrapper>
    )
  },
  {
    value: 3,
    label: (
      <Wrapper>
        <Text>{i18n.t('common:generic')}</Text>
      </Wrapper>
    )
  }
];
export const relevancyOptions = () => [
  {
    value: 4,
    label: (
      <Starred>
        <Text>{i18n.t('common:starred')}</Text>
      </Starred>
    )
  },
  {
    value: 3,
    label: (
      <ExtremelyRelevant>
        <Text>{i18n.t('common:extremely_relevant')}</Text>
      </ExtremelyRelevant>
    )
  },
  {
    value: 2,
    label: (
      <Relevant>
        <Text>{i18n.t('common:relevant')}</Text>
      </Relevant>
    )
  },
  {
    value: 1,
    label: (
      <Wrapper>
        <Text>{i18n.t('common:irrelevant')}</Text>
      </Wrapper>
    )
  }
];

export const reducedRelevancyOptions = () => [
  {
    value: 3,
    label: (
      <ExtremelyRelevant>
        <Text>{i18n.t('common:extremely_relevant')}</Text>
      </ExtremelyRelevant>
    )
  },
  {
    value: 2,
    label: (
      <Relevant>
        <Text>{i18n.t('common:relevant')}</Text>
      </Relevant>
    )
  },
  {
    value: 1,
    label: (
      <Wrapper>
        <Text>{i18n.t('common:irrelevant')}</Text>
      </Wrapper>
    )
  }
];

export const relevancyOptionsWithNone = () => [
  {
    value: '',
    label: (
      <Wrapper>
        <Text>{i18n.t('common:none')}</Text>
      </Wrapper>
    )
  },
  ...relevancyOptions()
];

export const typeOptionsWithNone = () => [
  {
    value: '',
    label: (
      <Wrapper>
        <Text>{i18n.t('common:none')}</Text>
      </Wrapper>
    )
  },
  ...typeOptions()
];

export const DEFAULT_SORT = [{ field: 'popularity_score', sort: 'desc' }];
export const DEFAULT_DENSITY = 'standard';
export const FILTER_TYPES = {
  RANGE: 'RANGE',
  TEXT: 'TEXT',
  MULTISELECT: 'MULTISELECT',
  SELECT: 'SELECT',
  DATE_RANGE: 'DATE_RANGE'
};

// This is important. If you want to add a new SELECT TYPE filter, add a corresponding
// options list here. currently we have: relevancy_label, type_id
export const SELECT_FILTER_OPTIONS = {
  relevancy_label: ['1', '2', '3', '4'],
  type_id: ['1', '2', '3']
};

const KeywordsHeaderTitle = styled(Typography)`
  font-weight: 500;
  font-size: 10px;
`;

const rankCellValues = (value) => {
  switch (value) {
    case 251:
      return 'Unranked';
    case -1:
      return 'Waiting';
    case -2:
      return 'Fetching';
    default:
      return value;
  }
};

/**
 * @name keywordTrackingTableColumns
 * @description Columns to be displayed inside KeywordTrackingTable component.
 * @param  {'standard' | 'compact' | 'comfortable'} density Current density of the table.
 * If the density is `compact` then we change some cells styling
 * @param  {Number} count Number of total keywords to be displayed in the header of the `name` column.
 * @param deleteKeywordMutation
 * @param updateKeywordMutation
 * @param queriesStatuses
 * @param onHDOpen
 * @param translateKeywords
 * @param hasPermission
 * @return  {{
 *  field: String,
 *  renderHeader: (props) => JSX.Element,
 *  headerClassName: String,
 *  headerName: String,
 *  width: Number,
 *  renderCell: (props) => JSX.Element,
 *  filterType: 'RANGE' | 'SELECT' | 'MULTISELECT' | "TEXT"
 * }}
 */
export const keywordTrackingTableColumns = (
  density = 'standard',
  count = 0,
  deleteKeywordMutation,
  updateKeywordMutation,
  queriesStatuses,
  onHDOpen,
  translateKeywords,
  hasPermission,
  rowsToDelete,
  setRowsToDelete
) => {
  return [
    {
      field: 'name',
      renderHeader: () => {
        return (
          <KeywordsHeaderTitle data-testid="keyword-tracking-table-count">
            {i18n.t('components:KeywordTrackingTable.columns.keywords', { count })}
          </KeywordsHeaderTitle>
        );
      },
      headerClassName: 'MuiDataGrid-columnHeaderTitle',
      headerName: i18n.t('components:KeywordTrackingTable.columns.keywords_header_name'),
      minWidth: 170,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Tooltip title={row.name} arrow>
            <Container container flexDirection="column">
              {row.name}
              {translateKeywords && <Translation>{row.en_translation}</Translation>}
            </Container>
          </Tooltip>
        );
      },
      filterType: FILTER_TYPES.TEXT
    },
    {
      field: 'type_id',
      headerName: i18n.t('components:KeywordTrackingTable.columns.type_header_name'),
      minWidth: 125,
      flex: 1,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <NoBorderSelect
            id="type"
            value={row.type_id}
            options={typeOptions()}
            isCompact={density === 'compact'}
            width={125}
            onChange={(e) =>
              updateKeywordMutation.mutate({
                keywordIds: row.keyword_id.toString(),
                type: e.target.value,
                type_name: e.target.label
              })
            }
            inputProps={{ IconComponent: () => null }}
            //add loading state only for the row being updated
            status={
              updateKeywordMutation?.variables?.keywordIds.includes(row.keyword_id.toString()) &&
              updateKeywordMutation?.variables?.type
                ? updateKeywordMutation?.status
                : queriesStatuses[GET_KEYWORDS_REQ_TYPE.KEYWORD]
            }
          />
        );
      },
      filterType: FILTER_TYPES.MULTISELECT,
      filterOptions: typeOptions()
    },
    {
      field: 'relevancy_label',
      headerName: i18n.t('components:KeywordTrackingTable.columns.relevancy_header_name'),
      minWidth: 125,
      flex: 1,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <NoBorderSelect
            id="relevancy"
            value={row.relevancy_label}
            options={relevancyOptions()}
            isCompact={density === 'compact'}
            inputProps={{ IconComponent: () => null }}
            width={125}
            onChange={(e) =>
              updateKeywordMutation.mutate({
                keywordIds: row.keyword_id.toString(),
                relevancy: e.target.value,
                relevancy_name: e.target.label
              })
            }
            //add loading state only for the row being updated
            status={
              updateKeywordMutation?.variables?.keywordIds.includes(row.keyword_id.toString()) &&
              updateKeywordMutation?.variables?.relevancy
                ? updateKeywordMutation?.status
                : queriesStatuses[GET_KEYWORDS_REQ_TYPE.KEYWORD]
            }
          />
        );
      },
      filterType: FILTER_TYPES.MULTISELECT,
      filterOptions: relevancyOptions()
    },
    {
      field: 'popularity_score',
      headerName: i18n.t('components:KeywordTrackingTable.columns.popularity_score_header_name'),
      type: 'string',
      flex: 0.75,
      editable: false,
      filterType: FILTER_TYPES.RANGE,
      renderCell: (props) => (
        <TableCellWithStates
          justifyContent="end"
          status={queriesStatuses[GET_KEYWORDS_REQ_TYPE.POPULARITY]}
        >
          <Box display="flex" alignItems="center">
            {rankCellValues(props.value)}
            <IconButton
              onClick={() =>
                onHDOpen(
                  GET_KEYWORDS_REQ_TYPE.POPULARITY,
                  props.row.name,
                  props.row.keyword_id,
                  DATA_KEYS.SCORE
                )
              }
            >
              <TimelineOutlinedIcon sx={{ fontSize: '11px' }} />
            </IconButton>
          </Box>
        </TableCellWithStates>
      )
    },
    {
      field: 'popularity_change',
      headerName: i18n.t(
        'components:KeywordTrackingTable.columns.popularity_score_change_header_name'
      ),
      type: 'number',
      flex: 0.75,
      editable: false,
      filterType: FILTER_TYPES.RANGE,
      disableExport: true,
      renderCell: (props) => {
        const color =
          props.value > 0 ? 'success.main' : props.value === 0 ? 'secondary.main' : 'error.main';
        const sign = props.value > 0 ? '+' : '';
        return (
          <TableCellWithStates status={queriesStatuses[GET_KEYWORDS_REQ_TYPE.POPULARITY_CHANGE]}>
            <Box sx={{ color }}>{`${sign}${props.value}`}</Box>
          </TableCellWithStates>
        );
      }
    },
    {
      field: 'max_results',
      headerName: i18n.t('components:KeywordTrackingTable.columns.total_apps_header_name'),
      type: 'string',
      flex: 0.75,
      editable: false,
      filterType: FILTER_TYPES.RANGE,
      renderCell: (props) => (
        <TableCellWithStates status={queriesStatuses[GET_KEYWORDS_REQ_TYPE.TOTAL_APPS]}>
          {props.value}
        </TableCellWithStates>
      )
    },
    {
      field: 'relevancy',
      headerName: i18n.t('components:KeywordTrackingTable.columns.competition_header_name'),
      type: 'number',
      flex: 0.75,
      editable: false,
      // TODO check if we need this filter
      //filterType: FILTER_TYPES.RANGE,
      renderCell: (props) => (
        <TableCellWithStates status={queriesStatuses[GET_KEYWORDS_REQ_TYPE.KEYWORD]}>
          {!!props.value
            ? 'N/D'
            : `${props.row.relevancy_competitor}/${props.row.competitors_count} (${props.value}%)`}
        </TableCellWithStates>
      )
    },
    {
      field: 'rank',
      headerName: i18n.t('components:KeywordTrackingTable.columns.rank_header_name'),
      type: 'string',
      flex: 0.75,
      editable: false,
      filterType: FILTER_TYPES.RANGE,
      renderCell: (props) => (
        <TableCellWithStates status={queriesStatuses[GET_KEYWORDS_REQ_TYPE.RANK]}>
          <Box display="flex" alignItems="center">
            {rankCellValues(props.value)}
            <IconButton
              onClick={() =>
                onHDOpen(
                  GET_KEYWORDS_REQ_TYPE.RANK,
                  props.row.name,
                  props.row.keyword_id,
                  DATA_KEYS.RANK
                )
              }
            >
              <TimelineOutlinedIcon sx={{ fontSize: '11px' }} />
            </IconButton>
          </Box>
        </TableCellWithStates>
      )
    },
    {
      field: 'rank_change',
      headerName: i18n.t('components:KeywordTrackingTable.columns.rank_change_header_name'),
      type: 'string',
      disableExport: true,
      flex: 0.75,
      editable: false,
      filterType: FILTER_TYPES.RANGE,
      renderCell: (props) => {
        const color =
          typeof props.value === 'number' && props.value > 0
            ? 'success.main'
            : props.value < 0
            ? 'error.main'
            : 'secondary.main';
        return (
          <TableCellWithStates status={queriesStatuses[GET_KEYWORDS_REQ_TYPE.RANK_CHANGE]}>
            <Box sx={{ color }}>{props.value}</Box>
          </TableCellWithStates>
        );
      }
    },
    {
      field: 'download_score',
      headerName: i18n.t('components:KeywordTrackingTable.columns.download_score_header_name'),
      type: 'number',
      flex: 0.85,
      editable: false,
      filterType: FILTER_TYPES.RANGE,
      renderCell: (props) => (
        <TableCellWithStates status={queriesStatuses[GET_KEYWORDS_REQ_TYPE.DOWNLOADS]}>
          {/*show only one decimal*/}
          {props.value?.toFixed(1) || 'N/D'}
        </TableCellWithStates>
      )
    },
    {
      field: 'keyword_id',
      headerName: i18n.t('components:KeywordTrackingTable.columns.delete_header_name'),
      flex: 0.75,
      // Disable default Material UI datagrid hooks
      editable: false,
      disableExport: true,
      sortable: false,
      renderCell: (props) => {
        const isConfirmed = rowsToDelete.includes(props.value);

        const handleConfirm = () => {
          setRowsToDelete([...rowsToDelete, props.value]);
        };

        const handleUnconfirm = () => {
          setRowsToDelete(rowsToDelete.filter((item) => item !== props.value));
        };

        if (!hasPermission) return <DoNotDisturbAltIcon />;
        return (
          <Box display="flex" sx={{ width: '80px' }} justifyContent="center">
            {deleteKeywordMutation?.variables?.keywordIds.includes(props.value.toString()) &&
            deleteKeywordMutation.status === FETCH_STATE.LOADING ? (
              <ConfirmCancelButton type="loading" />
            ) : (
              <>
                <ConfirmCancelButton
                  type={isConfirmed ? 'confirm' : 'delete'}
                  action={() =>
                    isConfirmed
                      ? deleteKeywordMutation.mutate({ keywordIds: props.value.toString() })
                      : handleConfirm(props.value)
                  }
                />
                {isConfirmed && (
                  <ConfirmCancelButton type="cancel" action={() => handleUnconfirm(props.value)} />
                )}
              </>
            )}
          </Box>
        );
      }
    }
  ];
};
