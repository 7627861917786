// Utils
import { KEYWORD_TRACKING_TABLE_FILTERS, updateKeywords } from '../config';

// Constants
import { ACTIONS } from 'constants/KeywordTrackingTable';
const NONE = 'None';

export const KeywordTrackingTableReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_FILTERS:
      // Also remove the current selected keywords when changing the filters!
      return {
        ...state,
        selected: [],
        filters: {
          ...KEYWORD_TRACKING_TABLE_FILTERS,
          ...action.payload.filters
        }
      };
    case ACTIONS.CLEAR_FILTERS:
      return {
        ...state,
        filters: action.payload.filters
      };
    case ACTIONS.SET_SELECTED:
      return {
        ...state,
        selected: action.payload.selected
      };
    case ACTIONS.ERROR:
      return {
        ...state,
        error: action.payload.error
      };
    case ACTIONS.SET_DENSITY:
      return {
        ...state,
        density: action.payload.density
      };
    case ACTIONS.SET_SORT_BY:
      return {
        ...state,
        sort: action.payload.sort,
        page: action.payload.page ? action.payload.sort : state.page
      };
    case ACTIONS.SET_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload.dateRange
      };

    case ACTIONS.SET_COLUMNS_VISIBILITY:
      return {
        ...state,
        visibleColumns: action.payload.visibleColumns
      };
    case ACTIONS.SET_PAGE:
      return {
        ...state,
        page: action.payload.page
      };
    case ACTIONS.RESET_KEYWORDS:
      return {
        ...state,
        keywords: []
      };
    case ACTIONS.SET_KEYWORDS:
    case ACTIONS.SET_KEYWORDS_RANK:
    case ACTIONS.SET_KEYWORDS_RANK_CHANGE:
    case ACTIONS.SET_KEYWORDS_POPULARITY:
    case ACTIONS.SET_KEYWORDS_POPULARITY_CHANGE:
    case ACTIONS.SET_KEYWORDS_DOWNLOADS:
    case ACTIONS.SET_KEYWORDS_TOTAL_APPS:
      return updateKeywords(state, action.payload);
    // when user deletes a keyword, we need to remove it from the list
    case ACTIONS.DELETE_KEYWORDS:
      return {
        ...state,
        // remove from state keywords, based on the ids passed in a string separated by comma
        keywords: state.keywords.filter(
          (keyword) => !action.payload.keywordIds.split(',').includes(keyword.keyword_id.toString())
        )
      };
    // when user updates type or relevancy of a keyword
    case ACTIONS.UPDATE_KEYWORDS:
      return {
        ...state,
        keywords: state.keywords.map((keyword) => {
          // update type and relevancy of the keywords that were updated, based on the ids passed in a string separated by comma
          if (action.payload.keywordIds.split(',').includes(keyword.keyword_id.toString())) {
            // we need to change both the id and the name of the type and relevancy in the state as they are linked
            const type = action.payload.type;
            const type_name = action.payload.type_name;
            const relevancy = action.payload.relevancy;
            const relevancy_name = action.payload.relevancy_name;
            return {
              ...keyword,
              type_id: type ? type : keyword.type_id,
              type_name: type_name && type_name !== NONE ? type_name : keyword.type_name,
              // TODO backend should return this as relevancy_id, ask nico to change it
              relevancy_label: relevancy ? relevancy : keyword.relevancy_label,
              relevancy_name:
                relevancy_name && relevancy_name !== NONE ? relevancy_name : keyword.relevancy_name
            };
          }
          return keyword;
        })
      };

    default:
      return state;
  }
};
