import React from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Components
import UserChangeUsernameForm from 'pages/app/UserSettings/UserChangeUsernameForm';
import UserChangePasswordForm from 'pages/app/UserSettings/UserChangePasswordForm';

// Constants
import { URLS } from 'constants/URL';

// Styles
import { Divider, Breadcrumbs } from './styled';
/**
 * @name UserSettings
 * @description Displays information regarding the current logged in user
 */
function UserSettings() {
  const { t } = useTranslation('pages', {
    keyPrefix: 'UserSettings'
  });

  return (
    <React.Fragment>
      <Helmet title={t('user_settings')} />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to={URLS.DASHBOARD}>
              {t('dashboard_link')}
            </Link>
            <Typography>{t('user_settings_breadcrumb')}</Typography>
          </Breadcrumbs>
          <Typography variant="h1" gutterBottom>
            {t('user_settings')}
          </Typography>
          <Typography variant="subtitle1">{t('setup_your_account')}</Typography>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12} md={8}>
          <UserChangeUsernameForm />
        </Grid>
        <Grid item xs={12} md={8}>
          <UserChangePasswordForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UserSettings;
