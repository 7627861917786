import React, { ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Styles
import { Button, DialogContent } from './styled';

interface Props {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  isLoading?: boolean;
  handleExit?: () => void;
  title: string;
  titleTextAlign?: 'left' | 'center' | 'right';
  body: string | ReactNode;
  actionButton?: ReactNode;
  isComponent?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  fullWidth?: boolean;
}

/**
 * @name Modal
 *
 * @description Shared, reusable component to open and close modals with important information.
 * This component handles also an action button, which is displayed inside the modal.
 *
 * @param  handleExit   Function which will be called after the modal unmounts
 * @param  isLoading    Disables `Close` button to prevent unmounting. For action button, you
 *                      will need to pass separate `isLoading` boolean
 * @param  title        Modal title
 * @param titleTextAlign
 * @param  body         Modal body component
 * @param  actionButton Action button component
 * @param  setIsOpen    Function to open or close the modal
 * @param  isOpen       Boolean to check if the modal is open
 * @param  isComponent  If true, then `body` is a component and we do not wrap it with the `<p>` tag.
 * @param  maxWidth     Max width of the modal
 */
function Modal({
  isOpen,
  setIsOpen,
  isLoading,
  handleExit,
  title,
  titleTextAlign = undefined,
  body,
  actionButton,
  isComponent,
  fullWidth = true,
  maxWidth = 'sm'
}: Props) {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={fullWidth}
      closeAfterTransition={true}
      TransitionProps={{
        onExited: handleExit
      }}
      maxWidth={maxWidth}
    >
      <DialogTitle id="alert-dialog-title" textAlign={titleTextAlign}>
        {title}
      </DialogTitle>
      <DialogContent>
        {isComponent ? (
          <React.Fragment>{body}</React.Fragment>
        ) : (
          <DialogContentText id="alert-dialog-description">{body}</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {actionButton}
        <Button disabled={isLoading} onClick={() => handleClose()} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Modal;
