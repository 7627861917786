import MuiBox from '@mui/material/Box';
import styled from '@emotion/styled';
import MuiAccordionSummary from '@mui/material/AccordionSummary';

// Components
import TextInput from 'components/InputText';
import CustomSelect from 'components/Select';

export const Label = styled(MuiBox)`
  font-size: 10px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  height: 12px;
`;

export const SelectLabel = styled(Label)`
  height: 15px;
`;

export const InputText = styled(TextInput)`
  width: 120px;
  font-size: 10px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};

  & .MuiInputBase-root {
    height: 32px;
    font-size: 10px;
  }
`;

export const MinMaxInput = styled(InputText)`
  width: 60px;
`;

export const Select = styled(CustomSelect)`
  width: 120px;
  .MuiInputBase-root {
    height: 32px;
  }
  .MuiInputBase-input {
    padding: 8.5px 8px;
    font-size: 10px;
    box-sizing: border-box;
  }
`;

export const Wrapper = styled(MuiBox)`
  margin-right: ${({ theme }) => theme.spacing(2)};
  height: 47px;
`;

export const Box = styled(MuiBox)`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const SelectBox = styled(MuiBox)`
  display: flex;
  flex-direction: column;
`;
