import { DataGrid } from '@mui/x-data-grid/DataGrid';
import MuiGrid from '@mui/material/Grid';

// Hooks
import useOKSTable from 'hooks/useOKSTable';

// Styles
import { Grid } from './styled';

/**
 * Renders the OKS table.
 */
function OKSTable() {
  const {
    state: { tableColumns, tableRows }
  } = useOKSTable();

  return (
    <MuiGrid>
      <Grid>
        <DataGrid
          rowHeight={32}
          headerHeight={32}
          rows={tableRows || []}
          getRowId={(row) => row?.keyword_id || row?.id || row.name}
          columns={tableColumns}
          hideFooterSelectedRowCount
          paginationMode="client"
          rowsPerPageOptions={[30]}
          pageSize={30}
          sx={{
            width: '100%',
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: 'primary.main',
              fontSize: '10px'
            },
            '& .MuiDataGrid-row': {
              fontSize: '10px'
            }
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'oks_position', sort: 'desc' }]
            }
          }}
        />
      </Grid>
    </MuiGrid>
  );
}

export default OKSTable;
