import { useState } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';

// Utils
import { COLUMNS } from './utils';

// Hooks
import useCSVFileName from 'hooks/useCSVFileName';
import useApp from 'hooks/useApp';
import useAIResearch from 'hooks/AI/useAIResearch';

function AITable() {
  const [rowsToDelete, setRowsToDelete] = useState<number[]>([]);

  const { filteredMainList, updateMainAIListKeyword, handleDeleteKeyword, isLoadingMainList } =
    useAIResearch();
  const { translateKeywords } = useApp();
  const fileName = useCSVFileName('AI_keyword_main_list');

  return (
    <DataGridPro
      loading={isLoadingMainList}
      columns={COLUMNS(
        translateKeywords,
        rowsToDelete,
        setRowsToDelete,
        updateMainAIListKeyword,
        handleDeleteKeyword
      )}
      rows={filteredMainList || []}
      autoHeight
      rowHeight={32}
      getRowId={(row) => row.keyword_id}
      columnHeaderHeight={32}
      hideFooterSelectedRowCount
      pageSizeOptions={[5, 10, 25, 50, 100, 200]}
      initialState={{
        pagination: { paginationModel: { pageSize: 10 } },
        sorting: {
          sortModel: [{ field: 'popularity_score', sort: 'desc' }]
        }
      }}
      pagination={true}
      sx={{
        width: '100%',
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: 'primary.main',
          fontSize: '10px'
        },
        '& .MuiDataGrid-row': {
          fontSize: '10px'
        }
      }}
      slots={{ toolbar: GridToolbar }}
      slotProps={{ toolbar: { csvOptions: { fileName } } }}
    />
  );
}

export default AITable;
