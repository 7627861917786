import { useMemo, useState } from 'react';
/**
 * @name useShowPassword
 * @description small hook to show/hide password in forms
 * */
const TEXT = 'text';
const PASSWORD = 'password';
function useShowPassword(initialShowPw = false) {
  const [showPw, setShowPw] = useState(initialShowPw);

  const passwordInputType = useMemo(() => (showPw ? TEXT : PASSWORD), [showPw]);

  return { showPw, setShowPw, passwordInputType };
}

export default useShowPassword;
