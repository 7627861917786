import styled from '@emotion/styled';

// Components
import Select from 'components/Select/index';

export const NoBorderSelect = styled(Select)`
  outline: none;
  border: none;
  font-size: 10px;
  & .MuiInputBase-root {
    font-size: 10px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;
