// Types
import { UntrackedRanked } from 'types/Keywords/UntrackedRanked';
import { MinMax } from 'contexts/Keywords/UntrackedRanked';

/**
 * Returns filtered keywords based on rank and popularity score
 *
 * @param keywords
 * @param filterBy
 * @param rankMinMax
 * @param spMinMax
 */
export const getFilteredKeywords = (
  keywords: UntrackedRanked[] | undefined,
  filterBy: string,
  rankMinMax: MinMax,
  spMinMax: MinMax
) => {
  if (!keywords) return [];

  return keywords.filter((kw) => {
    const rank = kw.rank;
    const sp = kw.popularity_score;
    const name = kw.name.toLowerCase();
    const filter = filterBy.toLowerCase();

    return (
      rank >= rankMinMax.currentMin &&
      rank <= rankMinMax.currentMax &&
      sp >= spMinMax.currentMin &&
      sp <= spMinMax.currentMax &&
      name.includes(filter)
    );
  });
};
