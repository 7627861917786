import React, { Suspense } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import PlaceIcon from '@mui/icons-material/Place';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

// Assets
import toaster from 'assets/toaster.png';

// Components
import Footer from './Footer';
import AppNav from './Nav';
import Navigation from './Navigation';
import AppDetails from 'components/Sidebar/Navigation/AppDetails';
import { Divider } from 'components/Sidebar/NavListItem/styled';
import SearchProject from 'components/Sidebar/Menu/SearchProject';

// Styles
import { Drawer, Brand, BrandIcon } from './styled';

// Constants
import { URLS } from 'constants/URL';
import { ALLOWED_ROLES } from 'constants/constants';

// Utils
import { buildUrl } from 'utils/utils';
import {
  AppItems,
  dashboardPages,
  quickAccessPages,
  settingsPages
} from 'components/Sidebar/Navigation/sidebarUtilsItems';

// Hooks
import useProject from 'hooks/useProject';
import usePermissions from 'hooks/usePermissions';

/**
 * @name Sidebar
 * @description Sidebar menu to navigate within the app page. only visible inside app page
 **/

const Sidebar = ({ items, ...rest }: { items: AppItems; rest: any }) => {
  const { projectId } = useParams();
  const hasPermission = usePermissions(ALLOWED_ROLES.GENERIC);

  return (
    <Drawer variant="permanent" {...rest}>
      <Grid sx={{ height: '100%' }}>
        <Brand component={NavLink} to={buildUrl(URLS.PROJECT, { projectId })}>
          <BrandIcon src={toaster} alt="Toaster" />
        </Brand>
        <Suspense fallback={<Grid sx={{ height: '56px' }} />}>
          <ProjectName />
        </Suspense>
        {hasPermission && <SearchProject />}
        <Navigation pages={dashboardPages} />
        {/* TODO: uncomment when feature is added */}
        {/*<AppDetails />*/}
        <Divider />
        <AppNav items={items} />
        <Navigation pages={quickAccessPages} />
      </Grid>
      <Grid>
        <Navigation pages={settingsPages} />
        <Footer />
      </Grid>
    </Drawer>
  );
};

export default Sidebar;

function ProjectName() {
  const { project } = useProject();

  return (
    <>
      {project?.name ? (
        <Grid display="flex" justifyContent="center" alignItems="center" p={4}>
          <Chip
            icon={<PlaceIcon />}
            label={project?.name}
            size="small"
            color="primary"
            variant="outlined"
          />
          {project.is_private ? (
            <LockOutlinedIcon color="primary" fontSize="small" sx={{ ml: 2 }} />
          ) : (
            <LockOpenOutlinedIcon color="primary" fontSize="small" sx={{ ml: 2 }} />
          )}
        </Grid>
      ) : (
        <Grid sx={{ height: '56px' }} />
      )}
    </>
  );
}
