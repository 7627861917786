import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Types
import { Models } from 'types/AILabs';

// Components
import Select from 'components/Select';

// Hooks
import useAIResearch from 'hooks/AI/useAIResearch';

const options = () => [
  { value: Models.GPT_35_TURBO, label: i18n.t('pages:AILabs.models.GPT-3.5-TURBO') },
  { value: Models.GPT_4, label: i18n.t('pages:AILabs.models.GPT-4') }
];
function Header() {
  const { t } = useTranslation('pages', { keyPrefix: 'AILabs' });

  const {
    model,
    setModel,
    handleGenerateKeywords,
    reviewList,
    isLoadingReviewList,
    validateReviewList,
    isValidating,
    isGeneratingKeywords
  } = useAIResearch();

  const handleChangeModel = (e: ChangeEvent<HTMLInputElement>) => {
    setModel(e.target.value as Models);
  };

  const handleGenerate = () => {
    handleGenerateKeywords();
  };

  const handleValidateList = () => {
    validateReviewList();
  };

  return (
    <Grid container justifyContent={'flex-end'} gap={2}>
      <Select
        value={model}
        id={'model'}
        options={options()}
        onChange={handleChangeModel}
        width={130}
      />
      <LoadingButton
        variant="contained"
        color="secondary"
        onClick={handleGenerate}
        disabled={!!reviewList.length || isLoadingReviewList}
        loading={isGeneratingKeywords}
        endIcon={<CheckCircleIcon />}
        loadingPosition="end"
      >
        {t('generate')}
      </LoadingButton>
      <LoadingButton
        variant="contained"
        color="secondary"
        onClick={handleValidateList}
        loading={isValidating}
        endIcon={<CheckCircleIcon />}
        loadingPosition="end"
        disabled={!reviewList.length}
      >
        {t('validate')}
      </LoadingButton>
    </Grid>
  );
}

export default Header;
