import React from 'react';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import HeartBrokenOutlinedIcon from '@mui/icons-material/HeartBrokenOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

// Constants
import { FETCH_STATE } from 'api';

interface Props {
  status: string;
  idleText?: string;
  errorText?: string;
  loadingText?: string;
  loadingChild?: React.ReactNode;
  successEmptyText?: string;
  resultsNumber?: number;
}

/**
 * @name FetchStatesIndicator
 * @description component showing all the necessary fetching states
 * @param status {string},
 * @param idleText {string} default 'Search...'
 * @param errorText {string} 'Error'
 * @param loadingText {string}
 * @param loadingChild {node} <CircularProgress color="secondary" />
 * @param successEmptyText {string} 'No results'
 * @param resultsNumber {number}
 * */
const ICON_SIZE = 100;
function FetchStatesIndicator({
  status,
  idleText = 'Search...',
  errorText = 'Error',
  loadingText,
  loadingChild = <CircularProgress color="secondary" />,
  successEmptyText = 'No results',
  resultsNumber
}: Props) {
  return (
    <React.Fragment>
      {/* IDLE */}
      {status === FETCH_STATE.IDLE && (
        <Grid sx={{ textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom>
            {idleText}
          </Typography>
          <ManageSearchIcon sx={{ fontSize: ICON_SIZE }} color="secondary" />
        </Grid>
      )}
      {/* EMPTY */}
      {status === FETCH_STATE.SUCCESS && resultsNumber === 0 && (
        <Grid sx={{ textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom>
            {successEmptyText}
          </Typography>
          <HeartBrokenOutlinedIcon sx={{ fontSize: ICON_SIZE }} color="warning" />
        </Grid>
      )}
      {/* LOADING */}
      {status === FETCH_STATE.LOADING && (
        <Grid sx={{ textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom>
            {loadingText}
          </Typography>
          {loadingChild}
        </Grid>
      )}
      {/* ERROR */}
      {status === FETCH_STATE.ERROR && (
        <Grid sx={{ textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom>
            {errorText}
          </Typography>
          <ErrorOutlineIcon sx={{ fontSize: ICON_SIZE }} color="error" />
        </Grid>
      )}
    </React.Fragment>
  );
}

export default FetchStatesIndicator;
