import React, { useState, useContext, useEffect, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

// Styles
import { Button, FinishButton, DatePicker } from './styled';

// Components
import Modal from 'components/Modal';
import AddExperimentModal from './AddExperimentModal';
import TableFooter from './TableFooter';
import Select from 'components/Select';

// Fetchers
import useExperimentsMutation from 'api/queries/ChangesBacklog/useExperimentsMutation';
import useExperimentBacklogDeleteMutation from 'api/queries/ChangesBacklog/useExperimentBacklogDeleteMutation';
import useExperimentBacklogUpdateMutation from 'api/queries/ChangesBacklog/useExperimentBacklogUpdateMutation';
import useExperimentMutation from 'api/queries/ChangesBacklog/useExperimentsNameMutation';

// Types
import { Params } from 'types/App';
import { Experiment, ExperimentBacklog, ResultKeys } from 'types/ChangesBacklog';

// Contexts
import { ChangesBacklogContext } from 'contexts/ChangesBacklog';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Utils
import {
  COLUMNS,
  updateBacklog,
  getPreviousValue,
  getPreviousExperiment
} from 'pages/app/Application/ChangesBacklog/AddMetadata/utils';

// Hooks
import useApp from 'hooks/useApp';

function AddMetadata() {
  const { t } = useTranslation('pages', {
    keyPrefix: 'ChangesBacklog'
  });
  const queryClient = useQueryClient();
  const [openModal, setOpenModal] = useState(false);
  const [newName, setNewName] = useState('');

  const [openChangeName, setOpenChangeName] = useState(false);
  const [updateName, setUpdateName] = useState('');
  const [updateNameId, setUpdateNameId] = useState<number | null>(null);

  const [date, setDate] = useState<Dayjs>(dayjs());

  const { projectId, appId, platform } = useParams() as Params;
  const { experiments, activeExperimentId, setActiveExperiment, activeExperiment } =
    useContext(ChangesBacklogContext);
  const { app, allTrackedLocales } = useApp();

  useEffect(() => {
    activeExperiment?.date && setDate(dayjs(activeExperiment?.date));
  }, [activeExperiment?.date]);

  const { mutate: updateExperiment, isLoading: isUpdatingName } = useExperimentMutation({
    projectId,
    appId,
    platform
  });
  const { mutate, isLoading } = useExperimentsMutation({ projectId, appId, platform });
  const { mutate: deleteExperimentBacklog } = useExperimentBacklogDeleteMutation({
    projectId,
    appId,
    platform
  });
  const { mutate: updateExperimentBacklog } = useExperimentBacklogUpdateMutation({
    projectId,
    appId,
    platform,
    experimentId: activeExperimentId!
  });

  const countryOptions = allTrackedLocales[app.country]?.map((country) => country.label);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleChangeName = (name: string) => {
    setNewName(name);
  };

  const handleUpdateName = (name: string) => {
    setUpdateName(name);
  };

  // Updates the experiment date and sets the previous value for each backlog
  const handleDateChange = async (date: Dayjs) => {
    setDate(date);
    updateExperiment(
      { date: dayjs(date).format('YYYY-MM-DD'), experimentId: activeExperimentId! },
      {
        onSuccess: () => {
          queryClient.setQueryData(
            [CACHE_KEYS.CHANGES_BACKLOG, { projectId, appId, platform }],
            (prev?: { data: Experiment[] }) =>
              updateBacklog(activeExperimentId!, dayjs(date).format('YYYY-MM-DD'), prev)
          );
        }
      }
    );

    const fetchers = activeExperiment?.app_backlogs?.map((row: ExperimentBacklog) => {
      const previousExperiment = getPreviousExperiment(activeExperiment.date, experiments);
      const previous = getPreviousValue(previousExperiment?.app_backlogs || [], row.asset_type);
      return updateExperimentBacklog(
        { backlogId: row.id, data: { ...row, previous_value: previous } },
        {
          onSuccess: () => {
            queryClient.setQueryData(
              [CACHE_KEYS.CHANGES_BACKLOG, { projectId, appId, platform }],
              (prev?: { data: Experiment[] }) => {
                return {
                  data:
                    prev?.data.map((exp) =>
                      exp.id === activeExperimentId
                        ? {
                            ...exp,
                            app_backlogs: [
                              ...exp.app_backlogs.map((backlog) => {
                                const previousExperiment = getPreviousExperiment(
                                  exp.date,
                                  experiments
                                );
                                const previous = getPreviousValue(
                                  previousExperiment?.app_backlogs || [],
                                  backlog.asset_type
                                );
                                return { ...backlog, previous_value: previous };
                              })
                            ]
                          }
                        : exp
                    ) || []
                };
              }
            );
          }
        }
      );
    });

    // Update all rows
    await Promise.all(fetchers);
  };

  // Handler for creating a new experiment
  const handleAddList = () => {
    const formattedDate = dayjs(new Date()).format('YYYY-MM-DD');
    mutate(
      { name: newName, date: formattedDate },
      {
        onSuccess: (res) => {
          setOpenModal(false);
          queryClient.setQueryData(
            [CACHE_KEYS.CHANGES_BACKLOG, { projectId, appId, platform }],
            (prev?: { data: Experiment[] }) => {
              return {
                data: [...(prev?.data || []), res.data]
              };
            }
          );
          setNewName('');
          setActiveExperiment(res.data.id);
        }
      }
    );
  };

  // Handler for deleting a row
  const handleDeleteRow = (id: number) => {
    deleteExperimentBacklog(
      { backlogId: id, experimentId: activeExperimentId! },
      {
        onSuccess: () => {
          queryClient.setQueryData(
            [CACHE_KEYS.CHANGES_BACKLOG, { projectId, appId, platform }],
            (oldData?: { data: Experiment[] }) => {
              return {
                data:
                  oldData?.data.map((exp) =>
                    exp.id === activeExperimentId
                      ? {
                          ...exp,
                          app_backlogs: exp.app_backlogs.filter((exp) => exp.id !== id)
                        }
                      : exp
                  ) || []
              };
            }
          );
        }
      }
    );
  };

  // Handler for updating a row
  const handleUpdateRow = (id: number, data: ExperimentBacklog) => {
    updateExperimentBacklog({ backlogId: id, data, optimisticUpdate: true });
  };

  const filteredRows = activeExperiment?.app_backlogs
    ?.filter((row: ExperimentBacklog) => !row.is_active)
    .map((row) => ({ ...row, date: activeExperiment.date }));

  // Add rows to historical metadata table
  const handleAddAllRows = async () => {
    // Set all rows to active and pending
    const convertedRows = filteredRows.map((row: ExperimentBacklog) => ({
      ...row,
      is_active: true,
      result: ResultKeys.Pending
    }));

    // Fetching functions for all rows
    const fetchers = convertedRows?.map((row: ExperimentBacklog) =>
      updateExperimentBacklog(
        { backlogId: row.id, data: row },
        {
          onSuccess: () => {
            queryClient.setQueryData(
              [CACHE_KEYS.CHANGES_BACKLOG, { projectId, appId, platform }],
              (prev?: { data: Experiment[] }) => {
                return {
                  data:
                    prev?.data.map((exp) =>
                      exp.id === activeExperimentId
                        ? {
                            ...exp,
                            app_backlogs: [
                              ...exp.app_backlogs.map((exp) => {
                                return { ...exp, is_active: true };
                              })
                            ]
                          }
                        : exp
                    ) || []
                };
              }
            );
          }
        }
      )
    );

    // Update all rows
    await Promise.all(fetchers);
  };

  const handleNameUpdate = () => {
    updateExperiment(
      { experimentId: updateNameId!, name: updateName },
      {
        onSuccess: () => {
          queryClient.setQueryData(
            [CACHE_KEYS.CHANGES_BACKLOG, { projectId, appId, platform }],
            (prev?: { data: Experiment[] }) => {
              return {
                data:
                  prev?.data.map((exp) =>
                    exp.id === updateNameId ? { ...exp, name: updateName } : exp
                  ) || []
              };
            }
          );
          setOpenChangeName(false);
        }
      }
    );
  };

  const renderValue = (expId: number) => {
    return experiments.find((exp) => exp.id === expId)?.name;
  };

  return (
    <>
      <Grid container alignItems="center" mt={4}>
        <Button onClick={handleOpenModal} variant="contained" color="primary">
          {t('add_new_list')}
        </Button>
        <Select
          width={200}
          label={t('experiment')}
          id="experiment"
          onChange={setActiveExperiment}
          options={
            experiments?.map((exp) => ({
              label: (
                <MenuItem
                  name={exp.name}
                  id={exp.id}
                  onEdit={() => {
                    setOpenChangeName(true);
                    setUpdateName(exp.name);
                    setUpdateNameId(exp.id);
                  }}
                />
              ),
              value: exp?.id
            })) || []
          }
          value={activeExperimentId || ''}
          renderValue={renderValue}
        />
      </Grid>
      <DatePicker value={date} onChange={handleDateChange} format="DD/MM/YYYY" />
      <Grid>
        <DataGrid
          onCellEditCommit={(params) => {
            handleUpdateRow(params.row.id, { ...params.row, [params.field]: params.value });
          }}
          getRowHeight={() => 'auto'}
          headerHeight={48}
          autoHeight
          rows={filteredRows || []}
          getRowId={(row) => row?.id}
          columns={COLUMNS(
            countryOptions,
            handleDeleteRow,
            handleUpdateRow,
            activeExperiment?.app_backlogs,
            experiments
          )}
          paginationMode="client"
          rowsPerPageOptions={[30]}
          pageSize={30}
          hideFooterPagination
          hideFooterSelectedRowCount
          components={{ Footer: TableFooter, NoRowsOverlay: Empty }}
          sx={{
            marginTop: 5,
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: '500',
              fontSize: '10px'
            },
            '& .MuiDataGrid-cellContent': {
              fontSize: '10px'
            },
            '& .MuiDataGrid-virtualScrollerContent': {
              height: filteredRows?.length > 0 ? 'auto' : '0px !important'
            }
          }}
        />
      </Grid>
      <FinishButton variant="contained" color="primary" onClick={handleAddAllRows}>
        {t('finish')}
      </FinishButton>
      <Modal
        isLoading={false}
        isOpen={openModal}
        setIsOpen={setOpenModal}
        isComponent
        body={<AddExperimentModal name={newName} onChange={handleChangeName} />}
        title={t('add_new_list')}
        fullWidth={false}
        actionButton={
          <Button variant="contained" color="primary" onClick={handleAddList} disabled={isLoading}>
            {t('add')}
          </Button>
        }
      />
      <Modal
        isLoading={false}
        isOpen={openChangeName}
        setIsOpen={setOpenChangeName}
        isComponent
        body={<AddExperimentModal name={updateName} onChange={handleUpdateName} />}
        title={t('change_name')}
        fullWidth={false}
        actionButton={
          <Button
            variant="contained"
            color="primary"
            onClick={handleNameUpdate}
            disabled={isUpdatingName}
          >
            {t('update')}
          </Button>
        }
      />
    </>
  );
}

export default AddMetadata;

/**
 * Empty component for no rows to avoid having 'No rows' text by DataGrid
 * @constructor
 */
function Empty() {
  return <></>;
}

interface MenuItemProps {
  name: string;
  id: number;
  onEdit: (e: MouseEvent<HTMLButtonElement>, { id, name }: { id: number; name: string }) => void;
}
function MenuItem({ name, id, onEdit }: MenuItemProps) {
  return (
    <Grid container alignItems="center" justifyContent="space-between">
      {name}
      <IconButton onClick={(e) => onEdit(e, { id, name })} size="small" sx={{ height: '18px' }}>
        <EditIcon color="info" fontSize="small" />
      </IconButton>
    </Grid>
  );
}
