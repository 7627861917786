import styled from '@emotion/styled';
import MuiButton from '@mui/material/Button';
import MuiGrid from '@mui/material/Grid';

// Components
import TextInput from 'components/InputText';

export const Button = styled(MuiButton)`
  color: black;
  border: 1px solid black;
  height: 32px;
  font-size: 10px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  svg {
    margin-right: 8px;
  }
`;

export const NotesInput = styled(TextInput)`
  background-color: white;

  .MuiInputBase-root {
    padding: 0;
    height: 250px;
    width: 300px;
  }

  textarea {
    height: 100% !important;
    box-sizing: border-box;
    overflow-y: scroll !important;
  }
`;

export const Grid = styled(MuiGrid)`
  position: relative;
  label {
    position: absolute;
    bottom: 8px;
    right: 8px;
  }
`;
