import styled from '@emotion/styled';
import MuiButton from '@mui/material/Button';
import MuiGrid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const Button = styled(MuiButton)`
  background-color: ${({ theme }) => theme.palette.secondary.secondary};
  color: white;
  font-size: 10px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  height: 32px;
  margin-right: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

export const Grid = styled(MuiGrid)`
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

export const Title = styled(Typography)<{ component: string }>`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  margin-top: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`;
