import React, { ReactNode, useState } from 'react';

// Components
import Modal from 'components/Modal';

// Styles
import { Button } from './styled';

interface Props {
  fullWidth?: boolean;
  isLoading?: boolean;
  disabled: boolean;
  openModalButtonText?: string | ReactNode;
  color?: string;
  handleExit?: () => void;
  title: string;
  body: string | ReactNode;
  actionButton?: ReactNode;
}

/**
 * @name ButtonModal
 * @description Shared, reusable component to open and close modals with important information.
 * This component handles also an action button, which is displayed inside the modal.
 * @param  {Boolean} fullWidth If button which opens the modal should be fullWidth
 * @param  {JSX.Element | String} openModalButtonText Defaults to `More`
 * @param  {Function} handleExit Function which will be called after the modal unmounts
 * @param  {Boolean} isLoading
 * @param  {Boolean} disabled
 * @param  {String} title
 * @param  {JSX.Element | String} body
 * @param  {JSX.Element} actionButton
 * @param  {String} color
 */
function ButtonModal({
  color = 'primary',
  fullWidth,
  isLoading,
  disabled,
  openModalButtonText = 'More',
  handleExit,
  title,
  body,
  actionButton
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(true);
  };
  return (
    <React.Fragment>
      <Button
        fullWidth={fullWidth}
        role="button"
        type="button"
        onClick={() => handleOpen()}
        variant="contained"
        color={color}
        disabled={disabled}
      >
        {openModalButtonText}
      </Button>
      <Modal
        isLoading={isLoading}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleExit={handleExit}
        actionButton={actionButton}
        body={body}
        title={title}
      />
    </React.Fragment>
  );
}

export default ButtonModal;
