import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

// Utils
import StorageService from 'utils/storage';

// Fetchers
import { API, CACHE_KEYS } from 'api';

const useProject = () => {
  const { projectId } = useParams();

  // Get project data. This query reruns when the projectId in the URL changes and
  // is not stalled
  const { data } = useQuery({
    queryKey: [CACHE_KEYS.PROJECTS, { projectId: Number(projectId) }],
    queryFn: () => API.getProject({ projectId }),
    refetchOnWindowFocus: false,
    retry: false,
    suspense: true,
    enabled: projectId !== undefined,
    onSuccess: (responseData) => {
      const { data: project } = responseData;
      const { id: projectId } = project;
      // Set project to localStorage
      StorageService.setLocalStorageLastProjectId(projectId.toString());
    }
  });

  return {
    project: data?.data
  };
};

export default useProject;
