import Grid from '@mui/material/Grid';

// Styles
import { Removed, Added } from './styled';

interface Props {
  previous: string;
  current: string;
}

function Diff({ previous, current }: Props) {
  const oldCodeArray = previous?.split(/[\s,]+/); // split when there is a space or a comma
  const newCodeArray = current?.split(/[\s,]+/);

  const added = newCodeArray?.filter((el) => !oldCodeArray?.includes(el))?.join(', '); // find added keywords
  const removed = oldCodeArray?.filter((el) => !newCodeArray?.includes(el))?.join(', '); // find removed keywords

  return (
    <Grid container flexDirection="column" justifyContent="space-between">
      {removed ? <Removed>-{removed}</Removed> : null}
      {added ? <Added>+{added}</Added> : null}
    </Grid>
  );
}

export default Diff;
