import React from 'react';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';

// Types
import { AlertSeverity } from 'contexts/Snackbar';

interface Props {
  isOpen: boolean;
  severity: AlertSeverity;
  handleClose: () => void;
  className?: string;
  children: React.ReactNode;
}

/**
 * Alert component which can be collapsed.
 *
 * @param {boolean} isOpen                                            - is the Alert open
 * @param {"success" | "info" | "warning" | "error"} severity         - determines the color of the Alert
 * @param {function} handleClose                                      - handler for closing the Alert
 * @param {string} className                                          - className
 * @param children                                                    - Child component
 */
function CollapsableAlert({ isOpen, severity, handleClose, className, children }: Props) {
  return (
    <Collapse className={className} in={isOpen}>
      <Alert severity={severity} onClose={handleClose}>
        {children}
      </Alert>
    </Collapse>
  );
}

export default CollapsableAlert;
