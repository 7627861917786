// eslint-disable-next-line import/named
import { useQuery, UseQueryResult } from '@tanstack/react-query';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Fetchers
import { getPrompts } from 'api/api';

export enum PromptType {
  All = 'all',
  AssetGeneration = 'asset-generation',
  LatentSemantic = 'keyword-generation-latent-semantic',
  Review = 'keyword-generation-review',
  Description = 'keyword-generation-description',
  TitleSubtitle = 'keyword-kg_app_title_subtitle-title'
}

interface Props {
  projectId: number;
  appId: number;
  platform: string;
  promptType: PromptType;
}

export interface Prompt {
  id: number;
  prompt: string;
  promptType: PromptType;
  created_at: string;
}

interface Response {
  data: Prompt[];
}

/**
 * Query function that returns the app description from a store
 *
 * @param projectId   Project id
 * @param appId       App id
 * @param platform    App platform
 * @param promptType  Prompt type
 *
 * */

function usePromptsQuery({
  projectId,
  appId,
  platform,
  promptType
}: Props): UseQueryResult<Response> {
  return useQuery<Response>({
    queryKey: [CACHE_KEYS.AI_PROMPTS, { projectId, appId, platform, promptType }],
    queryFn: () => getPrompts({ projectId, appId, platform, promptType })
  });
}

export default usePromptsQuery;
