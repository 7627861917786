import { useMutation } from '@tanstack/react-query';

// Fetchers
import { createExperimentBacklog } from 'api/api';

// Types
import { AssetTypesKeys, StatusKeys } from 'types/ChangesBacklog';

interface Props {
  projectId: string;
  appId: string;
  platform: string;
  experimentId: number;
}

/**
 * Create experiment backlog mutation
 *
 * @param projectId   Project id
 * @param appId       App id
 * @param platform    App platform
 */

function useExperimentBacklogMutation({ projectId, appId, platform, experimentId }: Props) {
  return useMutation(
    ({
      status,
      assetType,
      current,
      comment,
      language,
      previous
    }: {
      status: StatusKeys;
      assetType: AssetTypesKeys;
      current: string;
      comment: string;
      language: string;
      previous: string;
    }) =>
      createExperimentBacklog({
        projectId,
        appId,
        platform,
        experimentId,
        status,
        assetType,
        current,
        comment,
        language,
        previous
      })
  );
}

export default useExperimentBacklogMutation;
