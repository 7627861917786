// eslint-disable-next-line import/named
import { useQuery, UseQueryResult } from '@tanstack/react-query';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Fetchers
import { getAIKeywordListsToReview } from 'api/api';

interface Props {
  projectId: number;
  appId: number;
  platform: string;
}

/**
 * Query function that returns all the AI keyword lists ready for review
 * @param projectId   Project id
 * @param appId       App id
 * @param platform    App platform
 *
 * */

function useAIReviewListsQuery({
  projectId,
  appId,
  platform
}: Props): UseQueryResult<{ data: { keyword_list_ids: number[] } }> {
  return useQuery({
    queryKey: [CACHE_KEYS.AI_REVIEW_LISTS, { projectId, appId, platform }],
    queryFn: () => getAIKeywordListsToReview({ projectId, appId, platform })
  });
}

export default useAIReviewListsQuery;
