import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

// Styles
import { InputText, MenuItem } from './styled';

// Types
import { MinMax } from 'contexts/Keywords/UntrackedRanked';

interface Props {
  min: number;
  max: number;
  currentMin: number;
  currentMax: number;
  onChange: Dispatch<SetStateAction<MinMax>>;
}

function Dropdown({ onChange, min, max, currentMin, currentMax }: Props) {
  const { t } = useTranslation('components', {
    keyPrefix: 'KeywordTrackingTable.AddRankedKeywordTabs'
  });

  const handleChangeMin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);

    onChange((prev: MinMax) => ({
      ...prev,
      currentMin: value
    }));
  };

  const handleChangeMax = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);

    onChange((prev: MinMax) => ({
      ...prev,
      currentMax: value
    }));
  };

  return (
    <MenuItem>
      <InputText
        handleChange={handleChangeMin}
        label={t('min')}
        type="number"
        value={currentMin}
        InputProps={{ inputProps: { min: min, max: max } }}
      />
      <InputText
        InputProps={{ inputProps: { min: min, max: max } }}
        handleChange={handleChangeMax}
        label={t('max')}
        type="number"
        value={currentMax}
      />
    </MenuItem>
  );
}

export default Dropdown;
