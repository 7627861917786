import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import MuiGrid from '@mui/material/Grid';
import MuiButton from '@mui/material/Button';
import MuiTooltip from '@mui/material/Tooltip';

export const Title = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: 16px;
  //text-align: center;
`;

export const Container = styled(MuiGrid)`
  flex: 1;
  padding: 16px;
  margin-left: 16px;
`;

export const Button = styled(MuiButton)<{ isActive: boolean }>`
  background-color: ${({ isActive, theme }) =>
    isActive ? `${theme.palette.primary.main} !important` : ''};
`;

export const Tooltip = styled(MuiTooltip)`
  & .MuiTooltip-tooltip {
    margin-top: 0 !important;
  }
`;
