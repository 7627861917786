import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import { alpha } from '@mui/system';
import IconButton from '@mui/material/IconButton';

export const KeywordContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  margin: 5px 2px;
  padding: 0 10px;
  color: ${(props) => props.theme.palette.secondary.contrastText};
  background-color: ${(props) => alpha(props.theme.palette.secondary.main, 0.75)};
  &:hover {
    background-color: ${(props) => alpha(props.theme.palette.secondary.main, 0.95)};
  }
`;

export const KeywordText = styled(Typography)`
  color: ${(props) => props.theme.palette.secondary.contrastText};
  margin: 0 5px;
`;

export const Padding1 = styled.div`
  padding: 1px;
`;

export const Padding3IconBtn = styled(IconButton)`
  padding: 4px !important;
`;
