import React, { ChangeEvent } from 'react';
import i18n from 'i18next';
import Box from '@mui/material/Box';

// TODO
import { Container, Translation } from 'contexts/KeywordTrackingTable/styled';

// Components
import ConfirmCancelButton from 'components/ConfirmCancelButton';
import { NoBorderSelect } from 'components/Select/variations';

// Utils
import { reducedRelevancyOptions, reducedTypeOptions } from 'contexts/KeywordTrackingTable/columns';
import { renderAIKWRankWithFallback } from 'utils/utils';

// Types
import { AIMainListKeyword, KeywordAttributes } from 'types/Keywords/AI';
import { SELECT_FILTER_OPTIONS } from 'constants/constants';

interface Row {
  row: AIMainListKeyword;
}

export const COLUMNS = (
  translateKeywords: boolean,
  rowsToDelete: number[],
  setRowsToDelete: (rows: number[]) => void,
  handleUpdateKeyword: (data: KeywordAttributes) => void,
  handleDeleteKeyword: (data: { keywordId: number }) => void
) => [
  {
    field: 'word',
    headerName: i18n.t('pages:AI.research_table.columns.keyword'),
    minWidth: 170,
    flex: 1,
    renderCell: ({ row }: Row) => {
      return (
        <Container container flexDirection="column">
          {row.word}
          {translateKeywords && <Translation>{row.en_translation}</Translation>}
        </Container>
      );
    }
  },
  {
    field: 'keyword_type_id',
    headerName: i18n.t('pages:AI.research_table.columns.type'),
    minWidth: 125,
    flex: 1,
    valueFormatter: ({ value }: { value: 1 | 2 | 3 }) => SELECT_FILTER_OPTIONS.type_id[value],
    renderCell: ({ row }: Row) => {
      const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        handleUpdateKeyword({
          keywordIds: String(row.keyword_id),
          type: Number(event.target.value)
        });
      };
      return (
        <NoBorderSelect
          id="type"
          value={row.keyword_type_id}
          options={reducedTypeOptions()}
          // isCompact={density === 'compact'}
          width={125}
          onChange={handleChange}
          inputProps={{ IconComponent: () => null }}
        />
      );
    }
  },
  {
    field: 'relevancy',
    headerName: i18n.t('pages:AI.research_table.columns.relevancy'),
    minWidth: 125,
    flex: 1,
    valueFormatter: ({ value }: { value: 1 | 2 | 3 | 4 }) =>
      SELECT_FILTER_OPTIONS.relevancy_label[value],
    renderCell: ({ row }: Row) => {
      const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        handleUpdateKeyword({
          keywordIds: String(row.keyword_id),
          relevancy: Number(event.target.value)
        });
      };
      return (
        <NoBorderSelect
          id="relevancy"
          value={row.relevancy}
          options={reducedRelevancyOptions()}
          inputProps={{ IconComponent: () => null }}
          width={125}
          onChange={handleChange}
        />
      );
    }
  },
  {
    field: 'popularity_score',
    headerName: i18n.t('pages:AI.research_table.columns.popularity'),
    flex: 0.75
  },
  {
    field: 'total_apps',
    headerName: i18n.t('pages:AI.research_table.columns.total_apps'),
    flex: 0.75
  },
  {
    field: 'competition',
    headerName: i18n.t('pages:AI.research_table.columns.competition'),
    minWidth: 125,
    flex: 1,
    renderCell: ({ row }: Row) =>
      `${row.relevancy_competitor}/${row.competitor_count} (${Number(
        (row.relevancy_competitor / row.competitor_count || 0) * 100
      ).toFixed(0)}%)`
  },
  {
    field: 'rank',
    headerName: i18n.t('pages:AI.research_table.columns.rank'),
    flex: 0.75,
    valueGetter: ({ row }: { row: AIMainListKeyword }) => renderAIKWRankWithFallback(row.rank)
  },

  {
    field: 'estimated_downloads',
    headerName: i18n.t('pages:AI.research_table.columns.estimated_downloads'),
    flex: 0.85
  },
  {
    field: 'keyword_id',
    headerName: i18n.t('pages:AI.research_table.columns.delete'),
    flex: 0.75,
    editable: false,
    disableExport: true,
    sortable: false,
    renderCell: (props: { value: number }) => {
      const isConfirmed = rowsToDelete.includes(props.value);

      const handleConfirm = () => {
        setRowsToDelete([...rowsToDelete, props.value]);
      };

      const handleUnconfirm = () => {
        setRowsToDelete(rowsToDelete.filter((item) => item !== props.value));
      };

      return (
        <Box display="flex" sx={{ width: '80px' }} justifyContent="center">
          <>
            <ConfirmCancelButton
              type={isConfirmed ? 'confirm' : 'delete'}
              action={() =>
                isConfirmed ? handleDeleteKeyword({ keywordId: props.value }) : handleConfirm()
              }
            />
            {isConfirmed && <ConfirmCancelButton type="cancel" action={() => handleUnconfirm()} />}
          </>
        </Box>
      );
    }
  }
];
