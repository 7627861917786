import i18n from 'i18next';
import { v4 as uuidv4 } from 'uuid';

// Types
import { KeywordsData } from 'contexts/AI/MetadataGeneratorAI';
import { KeywordPhrase } from 'api/queries/AI/Assets/useAIKeywordsData';

const translationPath = 'pages:AI.MetadataGenerator.Assets.Keywords';

interface GroupedRow {
  hierarchy: string[];
  id: string;
  name: string;
  total_estimated_max_downloads: number;
  total_impressions: number;
}

type CompleteKeyword = {
  keyword: string;
  keyword_phrases?: KeywordPhrase[];
  impressions?: number;
  total_impressions?: number;
  total_estimated_max_downloads?: number;
  estimated_max_downloads?: number;
};

export function groupRows(kwData: KeywordsData[]): GroupedRow[] {
  const resultArray: GroupedRow[] = [];

  function transformObject(
    {
      keyword,
      impressions,
      estimated_max_downloads,
      total_estimated_max_downloads,
      total_impressions,
      keyword_phrases = []
    }: CompleteKeyword,
    parentHierarchy: string[] = []
  ) {
    const id = `${keyword.toLowerCase().replace(/\s+/g, '_')}-${uuidv4()}`;
    const hierarchy = [...parentHierarchy, keyword];
    const baseObject = {
      hierarchy,
      name: keyword,
      total_estimated_max_downloads: (total_estimated_max_downloads ||
        estimated_max_downloads) as number,
      total_impressions: (total_impressions || impressions) as number,
      id
    };

    resultArray.push(baseObject);

    keyword_phrases.forEach((phrase) => transformObject(phrase, hierarchy));

    return resultArray;
  }

  kwData.forEach((kw) => transformObject(kw));
  return resultArray;
}

export const groupingColDef = (rows: GroupedRow[]) => ({
  headerName: i18n.t(`${translationPath}.name`),
  minWidth: 200,
  field: 'name',
  valueFormatter: (row: { id?: string | number }) => {
    const item = rows.find((r) => r.id === row.id);
    return item?.name || '';
  }
});

export const COLUMNS = () => [
  {
    field: 'total_impressions',
    headerName: i18n.t(`${translationPath}.total_impressions`),
    type: 'number',
    minWidth: 200
  },
  {
    field: 'total_estimated_max_downloads',
    headerName: i18n.t(`${translationPath}.total_estimated_max_downloads`),
    type: 'number',
    minWidth: 200
  }
];
