import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Hooks
import useAlert from 'hooks/useAlert';
import useAuth from 'hooks/useAuth';

// Fetchers
import { API, ERROR } from 'api';

// Styles
import {
  Card,
  Button,
  ButtonsWrapper,
  LoadingButton,
  TextField
} from 'pages/app/UserSettings/styled';

const UserChangeUsernameSchema = () =>
  Yup.object().shape({
    currentUsername: Yup.string(),
    username: Yup.string()
      .notOneOf(
        [Yup.ref('currentUsername'), null],
        i18n.t('components:UserChangeUsernameForm.not_match_usernames')
      )
      .max(255)
      .required(i18n.t('components:UserChangeUsernameForm.username_required'))
  });

/**
 * @name UserChangeUsernameForm
 * @description Form which enables user to change:
 * - username
 */
function UserChangeUsernameForm() {
  const { user } = useAuth();
  const { username: currentUsername } = user;
  // We do not specify namespace and keyPrefix, as we need also to translate schema. It does not work otherwise.
  const { t } = useTranslation();
  const memoUserChangeUsernameSchema = useMemo(() => UserChangeUsernameSchema(), []);
  const { setAlert } = useAlert();

  /**
   * @name handleOnSubmit
   * @description manages Formik submit function
   */
  const handleOnSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      const { currentUsername, username } = values;
      await editUserMutation.mutateAsync({
        currentUsername,
        username
      });
      setStatus({ success: true });
    } catch (error) {
      let tempErrors = {};
      // If error is raised because of invalid payload
      // print returned errors from the API on the UI
      if (error instanceof ERROR.ApiResponseBadRequest) {
        const { errorData } = error;
        delete Object.assign(errorData, { submit: errorData['non_field_errors'] })[
          'non_field_errors'
        ];

        tempErrors = { ...errorData };
      } else {
        const message =
          error.message || t('components:UserChangeUsernameForm.something_went_wrong');
        tempErrors = { submit: [message] };
        // If this is a general error, display snackbar.
        setAlert(message);
      }

      setErrors(tempErrors);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  const {
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    resetForm,
    isValid,
    dirty,
    isSubmitting,
    touched,
    errors,
    setFieldValue
  } = useFormik({
    initialValues: {
      currentUsername: currentUsername,
      username: '',
      submit: false
    },
    validationSchema: memoUserChangeUsernameSchema,
    onSubmit: handleOnSubmit
  });

  /**
   * handles editing the user through the API. It provides `mutate` function
   * which needs `mutationData` to be passed to it. Mutation data is the same as the
   * arguments that API function expects.
   */
  const editUserMutation = useMutation(
    (mutationData) => {
      return API.editUser(mutationData);
    },
    {
      onSuccess: async () => {
        setAlert(t('components:UserChangeUsernameForm.username_changed_successfully'));
        setFieldValue('currentUsername', values.username, false);
        setFieldValue('username', '', false);
      }
    }
  );

  return (
    <Card mb={6}>
      <CardContent>
        <Typography
          component="h1"
          title={t('components:UserChangeUsernameForm.title')}
          variant="appH1"
          mb={4}
        >
          {t('components:UserChangeUsernameForm.title')}
        </Typography>
        <Typography
          component="p"
          title={t('components:UserChangeUsernameForm.general_settings')}
          variant="subtitle1"
          mb={2}
        >
          {t('components:UserChangeUsernameForm.description')}
        </Typography>

        <form id="UserChangeUsernameForm" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6} my={2}>
              <TextField
                disabled
                id="currentUsername"
                name="currentUsername"
                label={t('components:UserChangeUsernameForm.current_username')}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
                value={values.currentUsername}
                aria-readonly="true"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} my={2}>
              <TextField
                id="username"
                label={t('components:UserChangeUsernameForm.username')}
                variant="outlined"
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                error={Boolean(touched.username && errors.username)}
                helperText={touched.username && errors.username}
              />
            </Grid>
            <ButtonsWrapper item>
              <LoadingButton
                loading={isSubmitting}
                role="button"
                disabled={!(isValid && dirty) || isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
                autoFocus
              >
                {t('components:UserChangeUsernameForm.save')}
              </LoadingButton>
              <Button role="button" onClick={resetForm} type="reset" color="secondary">
                {t('components:UserChangeUsernameForm.cancel')}
              </Button>
            </ButtonsWrapper>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}

export default UserChangeUsernameForm;
