import React from 'react';

// Assets
import toasterIcon from 'assets/toaster.png';
import phitureIcon from 'assets/phiture.png';

// Styles
import { Icon } from './styled';

const ICON_SIZES = {
  sm: 44,
  md: 64,
  lg: 84
};

interface Props {
  isPhiture?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

/**
 * @name BrandIcon
 * @description simple share Brand component
 * @param  isPhiture, determines if the icon is phiture logo or toaster logo
 * @param  size options: sm, md, lg. Determines the size of the logo
 * */
function BrandIcon({ isPhiture, size = 'sm' }: Props) {
  //determine icon to be displayed
  const icon = isPhiture ? phitureIcon : toasterIcon;

  return <Icon src={icon} size={ICON_SIZES[size] || ICON_SIZES.sm} />;
}

export default BrandIcon;
