import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

// Components
import AddMetadata from './AddMetadata';
import CreateExperiment from './CreateExperiment';
import HistoricalMetadata from 'pages/app/Application/ChangesBacklog/HistoricalMetadata';

// Styles
import { Title } from './styled';

// Context
import ChangesBacklogProvider from 'contexts/ChangesBacklog';

// Types
import { Params } from 'types/App';

// Fetchers
import useExperimentsQuery from 'api/queries/ChangesBacklog/useExperimentsQuery';

function ChangesBacklog() {
  const { t } = useTranslation('pages', {
    keyPrefix: 'ChangesBacklog'
  });

  const { projectId, appId, platform } = useParams() as Params;
  const { data, isLoading } = useExperimentsQuery({ projectId, appId, platform });

  if (isLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center" mt={40}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <ChangesBacklogProvider>
      {data?.data.length === 0 ? (
        <CreateExperiment />
      ) : (
        <>
          <Title component="h1">{t('title')}</Title>
          <Divider />
          <AddMetadata />
          <Title component="h1">{t('historical_metadata')}</Title>
          <HistoricalMetadata />
        </>
      )}
    </ChangesBacklogProvider>
  );
}

export default ChangesBacklog;
