import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';

// Components
import Label from 'pages/app/Application/AI/MetadataGenerator/Assets/AssetRow/Label';

// Styles
import { InputText, ScoresContainer, ScoresLabel, ScoresWrapper, Button } from './styled';

// Types
import { GeneratedAsset } from 'api/queries/AI/Assets/useAIAssetsGeneratorQuery';

// Utils
import { nFormatter } from 'utils/format';

const TITLE_SUBTITLE_MAX_LENGTH = 30;

interface AssetRowProps {
  asset: GeneratedAsset;
  onSubmit: (title: string, subtitle: string) => void;
}

/**
 * Row for each generated asset, contains title and subtitle editable inputs, non editable ted for both, non editable overall score
 * TODO: change field names to match backend
 * */
function AssetRow({ asset, onSubmit }: AssetRowProps) {
  const { t } = useTranslation('components', {
    keyPrefix: 'MetadataGeneratorPage.AssetRow'
  });
  const [editableAsset, setEditableAsset] = useState({
    title: asset.title,
    subtitle: asset.subtitle
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setEditableAsset((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    onSubmit(editableAsset.title, editableAsset.subtitle);
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="end"
      sx={{ marginBottom: '8px', height: '50px' }}
    >
      <InputText
        id="title"
        name="title"
        value={editableAsset.title}
        handleChange={handleChange}
        maxLength={TITLE_SUBTITLE_MAX_LENGTH}
        label={
          <Label
            label={t('title')}
            id="title"
            current={editableAsset.title.length}
            max={TITLE_SUBTITLE_MAX_LENGTH}
          />
        }
      />
      <ScoresContainer
        sx={{
          '&&': {
            mb: 2
          }
        }}
      >
        <ScoresLabel>{t('impression_score')}</ScoresLabel>
        <ScoresWrapper color="yellow">{nFormatter(asset.title_total_impressions)}</ScoresWrapper>
      </ScoresContainer>
      <InputText
        id="subtitle"
        name="subtitle"
        value={editableAsset.subtitle}
        handleChange={handleChange}
        maxLength={TITLE_SUBTITLE_MAX_LENGTH}
        label={
          <Label
            label={t('subtitle')}
            id="subtitle"
            current={editableAsset.subtitle.length}
            max={TITLE_SUBTITLE_MAX_LENGTH}
          />
        }
      />
      <ScoresContainer
        sx={{
          '&&': {
            mb: 2
          }
        }}
      >
        <ScoresLabel>{t('impression_score')}</ScoresLabel>
        <ScoresWrapper color="lightblue">
          {nFormatter(asset.subtitle_total_impressions)}
        </ScoresWrapper>
      </ScoresContainer>
      <ScoresContainer
        sx={{
          '&&': {
            mb: 2
          }
        }}
      >
        <ScoresLabel>{t('score')}</ScoresLabel>
        <ScoresWrapper color="orange">
          {nFormatter(asset.title_total_impressions + asset.subtitle_total_impressions)}
        </ScoresWrapper>
      </ScoresContainer>
      <IconButton onClick={handleSubmit} color={'success'}>
        <AddCircleOutlineIcon color={'success'} />
      </IconButton>
    </Stack>
  );
}

export default AssetRow;
