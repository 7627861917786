import React from 'react';
import Box from '@mui/material/Box';

// Components
import Skeleton from 'components/Skeleton';

// Styles
import { TextContainer, RowContainer, AppInfoContainer } from './styled';

interface Props {
  key: number;
}

/**
 * @name AddAppResultsRowLoading
 * @description loading skeleton for row in app search result
 * */
function AddAppResultsRowLoading({ key }: Props) {
  return (
    <RowContainer key={key}>
      <AppInfoContainer>
        <Skeleton height={40} width={40} />
        <TextContainer>
          <Skeleton height={10} width={120} mb={2} />
          <Skeleton height={10} width={70} mb={2} />
          <Skeleton height={10} width={60} mb={2} />
        </TextContainer>
      </AppInfoContainer>
      <Box sx={{ alignSelf: 'center', textAlign: 'center' }}>
        <Skeleton height={35} width={60} />
      </Box>
    </RowContainer>
  );
}

export default AddAppResultsRowLoading;
