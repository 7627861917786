import { PropsWithChildren, useState, createContext, ReactNode } from 'react';

export type AlertSeverity = 'error' | 'warning' | 'info' | 'success';

export interface SnackbarState {
  isOpen: boolean;
  message: string | ReactNode;
  severity: AlertSeverity;
  duration: number | null;
  handleClose: () => void;
  setSnackbar: (
    open: boolean,
    message: string | ReactNode,
    severity: AlertSeverity,
    duration: number | null
  ) => void;
}

export const SnackbarContext = createContext<SnackbarState>({} as SnackbarState);

export const SnackbarProvider = ({ children }: PropsWithChildren) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string | ReactNode>('');
  const [severity, setSeverity] = useState<AlertSeverity>('success');
  const [duration, setDuration] = useState<number | null>(null);

  const handleClose = () => {
    setOpen(false);
  };
  const setSnackbar = (
    open: boolean,
    message: string | ReactNode,
    severity: AlertSeverity,
    duration: number | null
  ) => {
    setOpen(open);
    setMessage(message);
    setSeverity(severity);
    setDuration(duration);
  };
  return (
    <SnackbarContext.Provider
      value={{ isOpen, setSnackbar, message, severity, duration, handleClose }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};
