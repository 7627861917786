import { useMutation } from '@tanstack/react-query';
import { generateAIOKS } from 'api/api';
import { PLATFORMS } from 'types/Platform';

interface Props {
  projectId: number;
  appId: number;
  platform: PLATFORMS;
}

function useAIOKSGeneratorQuery({ projectId, appId, platform }: Props) {
  return useMutation(({ title, subtitle }: { title: string; subtitle: string }) =>
    generateAIOKS({ projectId, appId, platform, title, subtitle })
  );
}

export default useAIOKSGeneratorQuery;
