import React from 'react';
import Typography from '@mui/material/Typography';

interface Props {
  title: string;
}
/**
 * @name PageTitle
 * @description simple component displaying the title of the page
 * @param {string} title
 * */
function PageTitle({ title }: Props) {
  return (
    <Typography variant="h6" gutterBottom>
      {title}
    </Typography>
  );
}

export default PageTitle;
