import styled from '@emotion/styled';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiBox from '@mui/material/Box';

// Components
import CustomSelectFilter from './SelectFilter';
import CustomTextFieldFilter from './TextFieldFilter';
import CustomRangeFilter from './RangeFilter';

export const TextFieldFilter = styled(CustomTextFieldFilter)`
  & .MuiTypography-root {
    font-size: 10px;
  }
`;

export const RangeFilter = styled(CustomRangeFilter)`
  & .MuiTypography-root {
    font-size: 10px;
  }
`;
export const SelectFilter = styled(CustomSelectFilter)`
  p {
    font-size: 10px;
    height: 15px;
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  flex-direction: row-reverse;
  & .MuiAccordionSummary-content {
    min-height: 36px;
    align-items: center;
  }
`;

export const Box = styled(MuiBox)`
  gap: ${(props) => props.theme.spacing(2)};
  padding-bottom: ${(props) => props.theme.spacing(1)};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const ButtonsBox = styled(MuiBox)`
  gap: ${(props) => props.theme.spacing(2)};
  padding-top: ${(props) => props.theme.spacing(7)};
  margin-left: ${(props) => props.theme.spacing(7)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
`;

export const KeywordTrackingTableFiltersWrapper = styled(MuiBox)`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(1)};
  align-items: flex-start;
`;

export const DatePickerWrapper = styled(MuiBox)`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(1)};
  justify-content: space-between;
`;
