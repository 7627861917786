import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// Styles
import { Container, Title, Button, Tooltip } from './styled';

// Hooks
import useMetadataGeneratorAI from 'hooks/AI/useMetadataGeneratorAI';
import { RelatedSearchTerm } from 'types/OKS/Metadata';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

function OKS() {
  const { t } = useTranslation('pages', { keyPrefix: 'AI.MetadataGenerator.Assets.OKS' });
  const { optimalKeywordSet, isGeneratingOKS } = useMetadataGeneratorAI();

  useEffect(() => {
    setActive(null);
    setActiveRelatedTerm([]);
  }, [optimalKeywordSet]);

  const [activeRelatedTerm, setActiveRelatedTerm] = useState<RelatedSearchTerm[]>([]);
  const [active, setActive] = useState<number | null>(null);
  const [copySuccess, setCopySuccess] = useState(false);

  const handleActiveRelatedTerm = (relatedTerm: RelatedSearchTerm[], index: number) => {
    setActive(index);
    setActiveRelatedTerm(relatedTerm);
  };

  // Copy OKS to clipboard
  const handleCopy = () => {
    const copyText = optimalKeywordSet.map((el) => el.name).join(',');
    return navigator.clipboard.writeText(copyText).then(() => {
      setCopySuccess(true);

      setTimeout(() => {
        setCopySuccess(false);
      }, 1000);
    });
  };

  return (
    <Container>
      <Grid container flexDirection={'column'}>
        <Grid container alignItems={'center'}>
          <Title mr={2}>{t('title')}</Title>
          <Tooltip
            title={t('copy_success')}
            open={copySuccess}
            arrow
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -10]
                  }
                }
              ]
            }}
          >
            <IconButton onClick={handleCopy} disabled={optimalKeywordSet.length === 0}>
              {copySuccess ? <CheckCircleIcon color={'success'} /> : <ContentCopyIcon />}
            </IconButton>
          </Tooltip>
        </Grid>
        {isGeneratingOKS ? (
          <Stack alignItems="center" mt={20}>
            <CircularProgress />
          </Stack>
        ) : (
          <Grid container gap={2} mt={4}>
            {optimalKeywordSet.map((el, i) => (
              <Grid key={el.name}>
                <Button
                  variant={'outlined'}
                  color={'secondary'}
                  onClick={() => handleActiveRelatedTerm(el.related_search_terms, i)}
                  isActive={active === i}
                >
                  {el.name}
                </Button>
              </Grid>
            ))}
            {activeRelatedTerm.length > 0 && (
              <Grid mt={2}>
                <Title>{t('related_search_terms')}</Title>
                <Grid container gap={2} mt={4}>
                  {activeRelatedTerm.map((el) => (
                    <Grid key={el.keyword_id}>
                      <Button isActive={false} variant={'outlined'} color={'secondary'}>
                        {el.name}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default OKS;
