import { useContext, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';

// Components
import Dropdown from 'pages/app/Application/KeywordTrackingTable/AddKeywordTabs/Dropdown';

// Assets
import SearchIcon from 'assets/svg/search.svg';

// Styles
import { InputText, Button, GridContainer, Box } from './styled';

// Context
import { CompetitorKeywordsContext } from 'contexts/Keywords/Competitor';

// Types
import { Params } from 'types/App';

// Hooks
import useKeywordLists from 'hooks/useKeywordLists';
import useKeywordTrackingTable from 'hooks/useKeywordTrackingTable';

// Fetcher
import { useAddKeywordsMutation } from 'api/queries/Keywords/useAddKeywordsMutation';

// Constants
import { CACHE_KEYS } from 'api/constants';
import { CompetitorKeywords } from 'types/Competitors';

function RankTabHeader() {
  const queryClient = useQueryClient();

  const { t } = useTranslation('components', {
    keyPrefix: 'KeywordTrackingTable.AddRankedKeywordTabs'
  });

  const {
    filter,
    setFilter,
    keywords,
    rankMinMax,
    setSPMinMax,
    setRankMinMax,
    spMinMax,
    setIsLoading,
    isLoading
  } = useContext(CompetitorKeywordsContext);

  const { refetchKeywords } = useKeywordTrackingTable();

  const [anchorSP, setAnchorSP] = useState<HTMLDivElement | null>(null);
  const [anchorRank, setAnchorRank] = useState<HTMLDivElement | null>(null);
  const openSP = Boolean(anchorSP);
  const openRank = Boolean(anchorRank);
  const handleOpenSP = (e: MouseEvent<HTMLDivElement>) => {
    setAnchorSP(e.currentTarget as HTMLDivElement);
  };
  const handleCloseSP = () => {
    setAnchorSP(null);
  };

  const handleOpenRank = (e: MouseEvent<HTMLDivElement>) => {
    setAnchorRank(e.currentTarget);
  };

  const handleCloseRank = () => {
    setAnchorRank(null);
  };

  /**
   * Handles the change of the filter
   */
  const handleChange = (e: Event) => {
    const filterValue = (e.target as HTMLInputElement).value;
    setFilter(filterValue);
  };

  const { projectId, appId, platform } = useParams() as Params;

  const { listSelected } = useKeywordLists(projectId, Number(appId), platform);

  const { mutate } = useAddKeywordsMutation({
    projectId,
    appId,
    platform,
    keywordListIds: String(listSelected.id)
  });

  const handleAddAllKeywords = () => {
    const allKeywords = keywords?.map((kw) => kw.word);
    const stringKeywords = allKeywords?.join(',') as string;
    setIsLoading(true);
    mutate(stringKeywords, {
      onSuccess: () => {
        queryClient.setQueryData(
          [
            CACHE_KEYS.COMPETITORS_KEYWORDS_RANK,
            {
              projectId: Number(projectId),
              appId: Number(appId),
              platform,
              requestAll: true,
              keywordIds: undefined,
              keywordStrings: undefined
            }
          ],
          ({ data }: { data: CompetitorKeywords[] }) => ({
            data: data?.filter((keyword) => !allKeywords?.includes(keyword.word))
          })
        );
        refetchKeywords();
        setIsLoading(false);
      }
    });
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid display="flex">
        <InputText
          variant="outlined"
          value={filter}
          placeholder={
            isLoading ? t('loading_records') : `${keywords?.length} ${t('input_placeholder')}`
          }
          handleChange={handleChange}
          InputProps={{
            startAdornment: <SearchIcon height={14} width={14} />
          }}
        />
        <GridContainer>
          <Box onClick={handleOpenSP}>{t('SP')}</Box>
          <Menu
            anchorEl={anchorSP}
            open={openSP}
            onClose={handleCloseSP}
            transformOrigin={{ horizontal: 'center', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          >
            <Dropdown
              max={spMinMax.max}
              min={spMinMax.min}
              onChange={setSPMinMax}
              currentMax={spMinMax.currentMax}
              currentMin={spMinMax.currentMin}
            />
          </Menu>
        </GridContainer>
        <GridContainer>
          <Box onClick={handleOpenRank}>{t('rank')}</Box>
          <Menu
            anchorEl={anchorRank}
            open={openRank}
            onClose={handleCloseRank}
            transformOrigin={{ horizontal: 'center', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          >
            <Dropdown
              max={rankMinMax.max}
              min={rankMinMax.min}
              onChange={setRankMinMax}
              currentMax={rankMinMax.currentMax}
              currentMin={rankMinMax.currentMin}
            />
          </Menu>
        </GridContainer>
      </Grid>
      <Button variant="contained" color="secondary" onClick={handleAddAllKeywords}>
        {t('track_all')}
      </Button>
    </Grid>
  );
}

export default RankTabHeader;
