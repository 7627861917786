import React from 'react';

// Components
import Details from './Details';
import Notes from './Notes';
import Competitors from './Competitors';

// Styles
import { Grid } from './styled';

// Contexts
import { ProjectAppsProvider } from 'contexts/ProjectApps';

/**
 * @name AppHeader
 * @description Component displays app Details and Notes in a responsive grid
 */
function AppHeader() {
  return (
    <ProjectAppsProvider>
      <Grid
        data-testid="app-header"
        container
        alignItems="stretch"
        justifyContent="space-between"
        spacing={6}
      >
        <Grid item xs={7}>
          <Details />
        </Grid>
        <Grid item xs={2}>
          <Competitors />
        </Grid>
        <Grid item xs={3}>
          <Notes />
        </Grid>
      </Grid>
    </ProjectAppsProvider>
  );
}

export default AppHeader;
