import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';

// Components
import ModalDataBody from './ModalDataBody';
import Modal from 'components/Modal';

// Styles
import { Select } from './styled';

// hooks
import useFilters from 'hooks/useFilters';
import usePermissions from 'hooks/usePermissions';

// Constant
import {
  ALLOWED_ROLES,
  KEYWORD_RELEVANCY_OPTIONS,
  KEYWORD_TYPE_OPTIONS
} from 'constants/constants';
import { FETCH_STATE } from 'api/constants';
import * as API from 'api/api';
import {
  relevancyOptionsWithNone,
  typeOptionsWithNone
} from 'contexts/KeywordTrackingTable/columns';

/**
 * @name KeywordTrackingTableSelection
 * @description selection action component: allows user to perform actions on selected keywords.
 * - copy selected keywords names to clipboard
 * - bulk change of relevancy and type of selected kws
 * - bulk adds selected kws to one or more keyword list
 * - bulk delete selected keywords
 * @param {Boolean} selectOpen
 * @param {Array} selectedKeywords
 * @param {Array} allKeywords
 * @param {string} listsStatus
 * @param {array} availableLists
 * @param {number} currentListId
 * */

const INITIAL_UPDATE_VALUES = {
  type: '',
  relevancy: ''
};

const ACTION_TYPES = {
  UPDATE: 'UPDATE',
  ADD: 'ADD',
  DELETE: 'DELETE'
};

const DEFAULT_MODAL_STATE = {
  show: false,
  title: '',
  body: '',
  type: ''
};

function KeywordTrackingTableSelection({
  selectOpen,
  selectedKeywords,
  allKeywords,
  listsStatus,
  availableLists,
  currentListId,
  updateKeywordsMutation,
  deleteKeywordsMutation,
  onSuccessfulMutation,
  onErrorMutation
}) {
  const { projectId, appId, platform } = useParams();
  const { t } = useTranslation('components', {
    keyPrefix: 'KeywordTrackingTable.KeywordTrackingTableSelection'
  });
  const hasPermission = usePermissions(ALLOWED_ROLES.GENERIC);
  // state //
  // payload to update (type,relevancy) for selected keywords
  const [{ type, relevancy }, onPayloadToUpdateChange, onPayloadToUpdateReset] =
    useFilters(INITIAL_UPDATE_VALUES);
  // payload to add selected keywords to lists
  const [lists, onPayloadToAddChange] = useState([]);
  // reset lists and type - relevancy
  const onResetPayloads = () => {
    onPayloadToUpdateReset();
    onPayloadToAddChange([]);
  };
  // feedback for copy to clipboard
  const [copyKwsFeedbackOpen, setCopyKwsFeedbackOpen] = useState(false);
  // confirm modal message content. Can be change/addtolist or delete
  const [actionModalState, setActionModalState] = useState(DEFAULT_MODAL_STATE);
  const [mutationStatus, setMutationStatus] = useState(FETCH_STATE.IDLE);

  // copy kws to clipboard //
  const onCopySelected = () => {
    navigator.clipboard.writeText(keywordsNames).then(() => {
      // show copy success feedback
      setCopyKwsFeedbackOpen(true);
    });
  };

  // mutations //

  // update keywords mutation
  // reset modal when mutation is done
  // feedback message handled in parent
  useEffect(() => {
    setMutationStatus(updateKeywordsMutation.status);
    if (updateKeywordsMutation.status === FETCH_STATE.SUCCESS) {
      setActionModalState(DEFAULT_MODAL_STATE);
    }
  }, [updateKeywordsMutation.status]);

  // add keywords to lists mutation
  const addKeywordsToKeywordListsMutation = useMutation(
    (mutationData) => {
      return API.addKeywordsToAppKeywordLists(mutationData);
    },
    {
      onSuccess: () => {
        // sets feedback in parent
        onSuccessfulMutation();
        setMutationStatus(FETCH_STATE.SUCCESS);
        setActionModalState(DEFAULT_MODAL_STATE);
      },
      onError: () => {
        setMutationStatus(FETCH_STATE.ERROR);
        // sets feedback in parent
        onErrorMutation();
      }
    }
  );

  // delete keywords mutation
  // reset modal when mutation is done
  // feedback message handled in parent
  useEffect(() => {
    setMutationStatus(deleteKeywordsMutation.status);
    if (deleteKeywordsMutation.status === FETCH_STATE.SUCCESS) {
      setActionModalState(DEFAULT_MODAL_STATE);
    }
  }, [deleteKeywordsMutation.status]);

  // open mutation confirm action modal
  const openActionModal = (action) => {
    if (action === ACTION_TYPES.UPDATE) {
      setActionModalState({
        show: true,
        title: t(`confirm_${action.toLowerCase()}`),
        body: t(`do_you_apply_changes`, {
          count: selectedKeywords.length,
          ...updateLabelsNames
        }),
        type: action
      });
      return;
    }

    if (action === ACTION_TYPES.ADD) {
      setActionModalState({
        show: true,
        title: t(`confirm_${action.toLowerCase()}`),
        body: t(`do_you_add`, {
          count: selectedKeywords.length,
          ...addToListsNames
        }),
        type: action
      });
      return;
    }

    if (action === ACTION_TYPES.DELETE) {
      setActionModalState({
        show: true,
        title: t(`confirm_${action.toLowerCase()}`),
        body: t(`do_you_delete`, {
          count: selectedKeywords.length
        }),
        type: action
      });
    }
  };

  // confirm mutation action
  const onConfirmClick = () => {
    setMutationStatus(FETCH_STATE.LOADING);
    if (actionModalState.type === ACTION_TYPES.UPDATE) {
      updateKeywordsMutation.mutate({
        projectId,
        appId,
        platform,
        currentListId,
        type,
        relevancy,
        ...updateLabelsNames,
        keywordIds: selectedKeywords.join()
      });
      return;
    }

    if (actionModalState.type === ACTION_TYPES.ADD) {
      const listIDToString = lists.join();
      addKeywordsToKeywordListsMutation.mutate({
        projectId,
        appId,
        platform,
        keywords: keywordsNames,
        keywordListIds: listIDToString
      });
      return;
    }

    if (actionModalState.type === ACTION_TYPES.DELETE) {
      deleteKeywordsMutation.mutate({
        keywordIds: selectedKeywords.join()
      });
    }
  };

  // lifecycle //
  //reset payload every time all keywords are de-selected
  useEffect(() => {
    onResetPayloads();
  }, [selectOpen]);

  // other utils //

  // get keywords names from id, as string separated by comma
  const keywordsNames = selectedKeywords
    .map((keywordId) => allKeywords.find((kw) => kw.keyword_id === keywordId)?.name)
    .join();

  // labels for new type and relevancy, to be displayed in confirm modal
  const updateLabelsNames = {
    type_name: KEYWORD_TYPE_OPTIONS.find((option) => option.value === type).label,
    relevancy_name: KEYWORD_RELEVANCY_OPTIONS.find((option) => option.value === relevancy).label
  };

  const addToListsNames = {
    lists: lists.map((listId) => {
      const list = availableLists.find((list) => list.id === listId);
      return list.name;
    })
  };

  const listsOptions = availableLists.map(({ id, name }) => ({
    value: id,
    label: name
  }));

  return (
    <div>
      {selectOpen && (
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'start', md: 'center' }}
          spacing={6}
          ml={2}
        >
          {/* copy keywords button*/}
          <Button
            variant="outlined"
            startIcon={<ContentCopyIcon />}
            onClick={onCopySelected}
            sx={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
          >
            {t('copy_selected_keywords', {
              count: selectedKeywords.length
            })}
          </Button>
          <Stack direction="row" alignItems="center" spacing={2}>
            {/* relevancy and type update */}
            <Select
              id="relevancy"
              label={t('choose_relevancy')}
              value={relevancy}
              onChange={(e) => onPayloadToUpdateChange('relevancy', e.target.value)}
              options={relevancyOptionsWithNone()}
              width={160}
            />
            <Select
              id="type"
              label={t('choose_type')}
              value={type}
              onChange={(e) => onPayloadToUpdateChange('type', e.target.value)}
              options={typeOptionsWithNone()}
              width={160}
            />
            <Button
              variant="contained"
              color="success"
              onClick={() => openActionModal(ACTION_TYPES.UPDATE)}
              disabled={!type && !relevancy}
            >
              {t('update')}
            </Button>
          </Stack>
          {/* add to list */}
          <Stack direction="row" alignItems="center" spacing={2}>
            <Select
              id="add-to-list"
              status={listsStatus}
              label={t('add_to_lists')}
              value={lists}
              onChange={(e) => onPayloadToAddChange(e.target.value)}
              multiple
              options={listsOptions}
              width={160}
            />
            <Button
              variant="contained"
              color="success"
              onClick={() => openActionModal(ACTION_TYPES.ADD)}
              disabled={!lists.length}
            >
              {t('add')}
            </Button>
          </Stack>
          {/* delete selected*/}
          {hasPermission && (
            <Stack direction="row" alignItems="center" spacing={4}>
              <Button
                variant="contained"
                color="error"
                onClick={() => openActionModal(ACTION_TYPES.DELETE)}
                startIcon={<DeleteIcon fontSize="large" />}
              >
                {t('delete')}
              </Button>
            </Stack>
          )}
          {/* confirm action modal*/}
          <Modal
            isOpen={actionModalState.show}
            title={actionModalState.title}
            setIsOpen={() => setActionModalState({ ...actionModalState, show: false })}
            isLoading={false}
            isComponent
            body={
              <ModalDataBody
                actionTypes={ACTION_TYPES}
                type={actionModalState.type}
                text={actionModalState.body}
              />
            }
            actionButton={
              <Button
                variant="contained"
                endIcon={mutationStatus === FETCH_STATE.LOADING && <CircularProgress size={20} />}
                color={actionModalState.type === ACTION_TYPES.DELETE ? 'error' : 'primary'}
                onClick={onConfirmClick}
              >
                {t('confirm')}
              </Button>
            }
          />
          <Snackbar
            open={copyKwsFeedbackOpen}
            autoHideDuration={1000}
            onClose={() => setCopyKwsFeedbackOpen(false)}
            message={t('keywords_copied')}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          />
        </Stack>
      )}
    </div>
  );
}

KeywordTrackingTableSelection.propTypes = {
  selectOpen: Proptypes.bool,
  selectedKeywords: Proptypes.array,
  allKeywords: Proptypes.array,
  availableLists: Proptypes.array,
  listsStatus: Proptypes.string,
  currentListId: Proptypes.number,
  updateKeywordsMutation: Proptypes.object,
  deleteKeywordsMutation: Proptypes.object,
  onSuccessfulMutation: Proptypes.func,
  onErrorMutation: Proptypes.func
};

export default KeywordTrackingTableSelection;
